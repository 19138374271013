import apiClient from 'services/axios'

export async function searchData(parameter) {
  return apiClient
    .get('/user/target-tlsd-lop-sales/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createData(parameter = {}) {
  return apiClient
    .get('/user/target-tlsd-lop-sales/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/target-tlsd-lop-sales/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeData(values) {
  return apiClient
    .post('/user/target-tlsd-lop-sales/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editData(id, parameter = {}) {
  return apiClient
    .get(`/user/target-tlsd-lop-sales/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateData(id, values) {
  return apiClient
    .post(`/user/target-tlsd-lop-sales/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/target-tlsd-lop-sales/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-tlsd-lop-sales/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
