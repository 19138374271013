export const getErrorMessage = (error, with422 = true) => {
  if (!error) {
    return false
  }
  if (error.response) {
    if (error.response.status !== 422) {
      if (error.response.data) {
        let errMessage = error.response.data.message
        if (error.response.data instanceof Blob) {
          errMessage = error.response.statusText
        }
        return errMessage || SERVER_ERROR
      }
      return SERVER_ERROR
    }
    if (!with422) {
      return SERVER_ERROR
    }
    return error.response.data.errors
  }
  if (error.request) {
    return SERVER_ERROR
  }
  return error.message || SERVER_ERROR
}

const SERVER_ERROR = 'Failed to process data from the server. Please try again.'
export default SERVER_ERROR
