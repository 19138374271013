import apiClient from 'services/axios'

export async function getOutletDist(parameter) {
  return apiClient
    .get('/distributor/outlet-dist/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/distributor/outlet-dist/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadFile(parameter = {}) {
  return apiClient
    .get(`/distributor/outlet-dist/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
