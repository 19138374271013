import apiClient from 'services/axios'

export async function getClaimPromoEpm(parameter = {}) {
  return apiClient
    .get('/user/claim-promo-epm/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterClaimPromoEpm(parameter = {}) {
  return apiClient
    .get('/user/claim-promo-epm/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function moveTempClaimPromoEpm(values) {
  return apiClient
    .post(`/user/claim-promo-epm/move-temp`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
