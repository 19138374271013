import apiClient from 'services/axios'

export async function getDistributorChild(parameter) {
  return apiClient
    .get('/user/distributor-child/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createDistributorChild(parameter = {}) {
  return apiClient
    .get('/user/distributor-child/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeDistributorChild(values) {
  return apiClient
    .post('/user/distributor-child/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editDistributorChild(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor-child/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateDistributorChild(id, values) {
  return apiClient
    .post(`/user/distributor-child/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDistributorChild(id) {
  return apiClient
    .delete(`/user/distributor-child/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listDistributorChild() {
  return apiClient
    .get(`/user/distributor-child/list-distributor-child`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(id, values) {
  return apiClient
    .post(`/user/distributor-child/${id}/reset-password`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forceLogout(id, values) {
  return apiClient
    .post(`/user/distributor-child/${id}/force-logout`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
