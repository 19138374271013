import apiClient from 'services/axios'

export async function getProductBundle(parameter) {
  return apiClient
    .get('/user/product-bundle/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductBundle(parameter = {}) {
  return apiClient
    .get('/user/product-bundle/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductBundle(values) {
  return apiClient
    .post('/user/product-bundle/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductBundle(id, parameter = {}) {
  return apiClient
    .get(`/user/product-bundle/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductBundle(id, values) {
  return apiClient
    .post(`/user/product-bundle/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductBundle(id) {
  return apiClient
    .delete(`/user/product-bundle/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProductBundle(values) {
  return apiClient
    .post('/user/product-bundle/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
