const actions = {
  SET_STATE: 'distributor-child/SET_STATE',
  LOAD_DISTRIBUTOR_CHILD: 'distributor-child/LOAD_DISTRIBUTOR_CHILD',
  CANCEL_DISTRIBUTOR_CHILD: 'distributor-child/CANCEL_DISTRIBUTOR_CHILD',
  LOAD_FORM_CREATE: 'distributor-child/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'distributor-child/LOAD_FORM_EDIT',
  STORE_DISTRIBUTOR_CHILD: 'distributor-child/STORE_DISTRIBUTOR_CHILD',
  LIST_DISTRIBUTOR_CHILD: 'distributor-child/LIST_DISTRIBUTOR_CHILD',
  UPDATE_DISTRIBUTOR_CHILD: 'distributor-child/UPDATE_DISTRIBUTOR_CHILD',
  DELETE_DISTRIBUTOR_CHILD: 'distributor-child/DELETE_DISTRIBUTOR_CHILD',
  RESET_PASSWORD: 'distributor-child/RESET_PASSWORD',
  FORCE_LOGOUT: 'distributor-child/FORCE_LOGOUT',
}

export default actions
