const actions = {
  SET_STATE: 'report-activity-daily-md/SET_STATE',
  SEARCH: 'report-activity-daily-md/SEARCH',
  FILTER: 'report-activity-daily-md/FILTER',
  CANCEL: 'report-activity-daily-md/CANCEL',
  DOWNLOAD: 'report-activity-daily-md/DOWNLOAD',
  REPROCESS_FILE: 'report-activity-daily-md/REPROCESS_FILE',
  LAST_UPDATED: 'report-activity-daily-md/LAST_UPDATED',
}

export default actions
