const actions = {
  SET_STATE: 'target-secondary-sales-rental/SET_STATE',
  SEARCH: 'target-secondary-sales-rental/SEARCH',
  CANCEL: 'target-secondary-sales-rental/CANCEL',
  CANCEL_LIST_OUTLET: 'target-secondary-sales-rental/CANCEL_LIST_OUTLET',
  CREATE: 'target-secondary-sales-rental/CREATE',
  EDIT: 'target-secondary-sales-rental/EDIT',
  STORE: 'target-secondary-sales-rental/STORE',
  UPDATE: 'target-secondary-sales-rental/UPDATE',
  DELETE: 'target-secondary-sales-rental/DELETE',
  FILTER: 'target-secondary-sales-rental/FILTER',
  UPLOAD_FILE: 'target-secondary-sales-rental/UPLOAD_FILE',
  LIST_OUTLET: 'target-secondary-sales-rental/LIST_OUTLET',
  DOWNLOAD: 'target-secondary-sales-rental/DOWNLOAD',
  REMINDER: 'target-secondary-sales-rental/REMINDER',
}

export default actions
