const actions = {
  SET_STATE: 'category-stock/SET_STATE',
  LOAD_CATEGORY_STOCK: 'category-stock/LOAD_CATEGORY_STOCK',
  CANCEL_CATEGORY_STOCK: 'category-stock/CANCEL_CATEGORY_STOCK',
  LOAD_FORM_CREATE: 'category-stock/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-stock/LOAD_FORM_EDIT',
  STORE_CATEGORY_STOCK: 'category-stock/STORE_CATEGORY_STOCK',
  UPDATE_CATEGORY_STOCK: 'category-stock/UPDATE_CATEGORY_STOCK',
  DELETE_CATEGORY_STOCK: 'category-stock/DELETE_CATEGORY_STOCK',
}

export default actions
