const actions = {
  SET_STATE: 'outlet-channel-sub/SET_STATE',
  LOAD_OUTLET_CHANNEL_SUB: 'outlet-channel-sub/LOAD_OUTLET_CHANNEL_SUB',
  CANCEL_OUTLET_CHANNEL_SUB: 'outlet-channel-sub/CANCEL_OUTLET_CHANNEL_SUB',
  LOAD_FORM_CREATE: 'outlet-channel-sub/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-channel-sub/LOAD_FORM_EDIT',
  STORE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub/STORE_OUTLET_CHANNEL_SUB',
  UPDATE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub/UPDATE_OUTLET_CHANNEL_SUB',
  DELETE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub/DELETE_OUTLET_CHANNEL_SUB',
}

export default actions
