const actions = {
  SET_STATE: 'outlet-tl-spv/SET_STATE',
  LOAD_OUTLET_TL_SPV: 'outlet-tl-spv/LOAD_OUTLET_TL_SPV',
  CANCEL_OUTLET_TL_SPV: 'outlet-tl-spv/CANCEL_OUTLET_TL_SPV',
  LOAD_FORM_CREATE: 'outlet-tl-spv/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-tl-spv/LOAD_FORM_EDIT',
  STORE_OUTLET_TL_SPV: 'outlet-tl-spv/STORE_OUTLET_TL_SPV',
  UPDATE_OUTLET_TL_SPV: 'outlet-tl-spv/UPDATE_OUTLET_TL_SPV',
  OPTIONS_DELETE: 'outlet-tl-spv/OPTIONS_DELETE',
  DELETE_OUTLET_TL_SPV: 'outlet-tl-spv/DELETE_OUTLET_TL_SPV',
  GET_PERMISSION: 'outlet-tl-spv/GET_PERMISSION',
  SET_PERMISSION: 'outlet-tl-spv/SET_PERMISSION',
  RESET_PASSWORD: 'outlet-tl-spv/RESET_PASSWORD',
  FORCE_LOGOUT: 'outlet-tl-spv/FORCE_LOGOUT',
  FILTER: 'outlet-tl-spv/FILTER',
  REMINDER: 'outlet-tl-spv/REMINDER',
}

export default actions
