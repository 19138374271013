const actions = {
  SET_STATE: 'program-regular/SET_STATE',
  SEARCH: 'program-regular/SEARCH',
  CANCEL: 'program-regular/CANCEL',
  CREATE: 'program-regular/CREATE',
  EDIT: 'program-regular/EDIT',
  STORE: 'program-regular/STORE',
  UPDATE: 'program-regular/UPDATE',
  DELETE: 'program-regular/DELETE',
  UPLOAD: 'program-regular/UPLOAD',
}

export default actions
