import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/outlet-sales-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/outlet-sales-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/outlet-sales-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-sales-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/outlet-sales-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id, values) {
  return apiClient
    .delete(`/user/outlet-sales-approval/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkApproval(values) {
  return apiClient
    .post(`/user/outlet-sales-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
