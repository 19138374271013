import apiClient from 'services/axios'

export async function filter(parameter) {
  return apiClient
    .get('/user/report-based-estimate/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function lastUpdatedFile(parameter) {
  return apiClient
    .get('/user/report-based-estimate/last-updated-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/report-based-estimate/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reprocessFile(values) {
  return apiClient
    .post('/user/report-based-estimate/reprocess-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function searchLog(parameter) {
  return apiClient
    .get('/user/report-based-estimate/log-upload-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadUploadFile(parameter) {
  return apiClient
    .get(`/user/report-based-estimate/download-upload-file/${parameter.id}`, {
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterDist(parameter) {
  return apiClient
    .get('/distributor/report-based-estimate/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadDist(parameter) {
  return apiClient
    .get('/distributor/report-based-estimate/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
