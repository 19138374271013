import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-draft/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function upload(values) {
  return apiClient
    .post('/distributor/trans-dist-upload-draft/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function options(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-draft/options-upload-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter, onProgress) {
  const parent = parameter.dist_parent_id
  const child = parameter.dist_child_id
  return apiClient
    .get(`/distributor/trans-dist-upload-draft/download-file/${parent}/child/${child}`, {
      responseType: 'blob',
      onDownloadProgress: onProgress,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function submit(parameter) {
  const { id } = parameter
  return apiClient
    .post(`/distributor/trans-dist-upload-draft/submit/${id}`, [])
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDraft(parameter) {
  const { id } = parameter
  return apiClient
    .delete(`/distributor/trans-dist-upload-draft/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteChild(parameter) {
  const parent = parameter.dist_parent_id
  const child = parameter.dist_child_id
  return apiClient
    .delete(`/distributor/trans-dist-upload-draft/${parent}/child/${child}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-draft/filter-draft', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
