const actions = {
  SET_STATE: 'distributor-child-inactive/SET_STATE',
  LOAD_DISTRIBUTOR_CHILD: 'distributor-child-inactive/LOAD_DISTRIBUTOR_CHILD',
  CANCEL_DISTRIBUTOR_CHILD: 'distributor-child-inactive/CANCEL_DISTRIBUTOR_CHILD',
  LOAD_FORM_CREATE: 'distributor-child-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'distributor-child-inactive/LOAD_FORM_EDIT',
  STORE_DISTRIBUTOR_CHILD: 'distributor-child-inactive/STORE_DISTRIBUTOR_CHILD',
  LIST_DISTRIBUTOR_CHILD: 'distributor-child-inactive/LIST_DISTRIBUTOR_CHILD',
  UPDATE_DISTRIBUTOR_CHILD: 'distributor-child-inactive/UPDATE_DISTRIBUTOR_CHILD',
  DELETE_DISTRIBUTOR_CHILD: 'distributor-child-inactive/DELETE_DISTRIBUTOR_CHILD',
  RESET_PASSWORD: 'distributor-child-inactive/RESET_PASSWORD',
  FORCE_LOGOUT: 'distributor-child-inactive/FORCE_LOGOUT',
}

export default actions
