import apiClient from 'services/axios'

export async function getOutletTlSpv(parameter) {
  return apiClient
    .get('/user/outlet-tl-spv/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletTlSpv(parameter = {}) {
  return apiClient
    .get('/user/outlet-tl-spv/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletTlSpv(values) {
  return apiClient
    .post('/user/outlet-tl-spv/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletTlSpv(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-tl-spv/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletTlSpv(id, values) {
  return apiClient
    .post(`/user/outlet-tl-spv/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionsDelete(id) {
  return apiClient
    .get(`/user/outlet-tl-spv/${id}/options-destroy`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletTlSpv(id, values) {
  return apiClient
    .delete(`/user/outlet-tl-spv/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getPermissions(id) {
  return apiClient
    .get(`/user/outlet-tl-spv/${id}/permission`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setPermissions(id, values) {
  return apiClient
    .post(`/user/outlet-tl-spv/${id}/permission`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(id, values) {
  return apiClient
    .post(`/user/outlet-tl-spv/${id}/reset-password`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forceLogout(id) {
  return apiClient
    .post(`/user/outlet-tl-spv/${id}/force-logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get(`/user/outlet-tl-spv/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-tl-spv/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
