const actions = {
  SET_STATE: 'notification/SET_STATE',
  LOAD_NOTIFICATION: 'notification/LOAD_NOTIFICATION',
  CANCEL_NOTIFICATION: 'notification/CANCEL_NOTIFICATION',
  LOAD_FORM_CREATE: 'notification/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'notification/LOAD_FORM_EDIT',
  STORE_NOTIFICATION: 'notification/STORE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'notification/UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'notification/DELETE_NOTIFICATION',
  READ_ALL: 'notification/READ_ALL',
  READ: 'notification/READ',
}

export default actions
