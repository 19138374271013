const actions = {
  SET_STATE: 'permission/SET_STATE',
  LOAD_PERMISSION: 'permission/LOAD_PERMISSION',
  CANCEL_PERMISSION: 'permission/CANCEL_PERMISSION',
  LOAD_FORM_CREATE: 'permission/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'permission/LOAD_FORM_EDIT',
  STORE_PERMISSION: 'permission/STORE_PERMISSION',
  UPDATE_PERMISSION: 'permission/UPDATE_PERMISSION',
  DELETE_PERMISSION: 'permission/DELETE_PERMISSION',
}

export default actions
