const actions = {
  SET_STATE: 'outlet-inactive/SET_STATE',
  LOAD_OUTLET_INACTIVE: 'outlet-inactive/LOAD_OUTLET_INACTIVE',
  CANCEL_OUTLET_INACTIVE: 'outlet-inactive/CANCEL_OUTLET_INACTIVE',
  CANCEL_LIST_OUTLET: 'outlet-inactive/CANCEL_LIST_OUTLET',
  LOAD_FORM_CREATE: 'outlet-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_INACTIVE: 'outlet-inactive/STORE_OUTLET_INACTIVE',
  UPDATE_OUTLET_INACTIVE: 'outlet-inactive/UPDATE_OUTLET_INACTIVE',
  DELETE_OUTLET_INACTIVE: 'outlet-inactive/DELETE_OUTLET_INACTIVE',
  LOAD_FORM_DISTRIBUTOR_CHILD_OUTLET_INACTIVE:
    'outlet-inactive/LOAD_FORM_DISTRIBUTOR_CHILD_OUTLET_INACTIVE',
  LIST_OUTLET: 'outlet-inactive/LIST_OUTLET',
}

export default actions
