const actions = {
  SET_STATE: 'consumer-channel/SET_STATE',
  SEARCH: 'consumer-channel/SEARCH',
  CANCEL: 'consumer-channel/CANCEL',
  CREATE: 'consumer-channel/CREATE',
  EDIT: 'consumer-channel/EDIT',
  STORE: 'consumer-channel/STORE',
  UPDATE: 'consumer-channel/UPDATE',
  DELETE: 'consumer-channel/DELETE',
  UPLOAD: 'consumer-channel/UPLOAD',
  LIST_PRODUCT: 'consumer-channel/LIST_PRODUCT',
}

export default actions
