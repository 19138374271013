const actions = {
  SET_STATE: 'distributor-inactive/SET_STATE',
  SEARCH: 'distributor-inactive/SEARCH',
  CANCEL: 'distributor-inactive/CANCEL',
  CREATE: 'distributor-inactive/CREATE',
  EDIT: 'distributor-inactive/EDIT',
  STORE: 'distributor-inactive/STORE',
  STORE_WITH_CHILD: 'distributor-inactive/STORE_WITH_CHILD',
  UPDATE: 'distributor-inactive/UPDATE',
  DELETE: 'distributor-inactive/DELETE',
}

export default actions
