import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/product-bundle-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset() {
  return apiClient
    .delete(`/user/product-bundle-temp/reset`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function save(values) {
  return apiClient
    .post(`/user/product-bundle-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
