const actions = {
  SET_STATE: 'product-range-inactive/SET_STATE',
  LOAD_PRODUCT_RANGE: 'product-range-inactive/LOAD_PRODUCT_RANGE',
  CANCEL_PRODUCT_RANGE: 'product-range-inactive/CANCEL_PRODUCT_RANGE',
  LOAD_FORM_CREATE: 'product-range-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-range-inactive/LOAD_FORM_EDIT',
  STORE_PRODUCT_RANGE: 'product-range-inactive/STORE_PRODUCT_RANGE',
  UPDATE_PRODUCT_RANGE: 'product-range-inactive/UPDATE_PRODUCT_RANGE',
  DELETE_PRODUCT_RANGE: 'product-range-inactive/DELETE_PRODUCT_RANGE',
}

export default actions
