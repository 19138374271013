import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-sfa/management'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })

  const result = yield race({
    data: call(mService.filterManagement, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_MAP_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMap: true,
    },
  })

  const result = yield race({
    data: call(mService.mapDataManagement, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMap: false,
      errorMap: null,
    }
    if (response) {
      newPayload.dataMap = response.data
    } else {
      newPayload.errorMap = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DAILY_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDaily: true,
    },
  })

  const result = yield race({
    data: call(mService.dailyDataManagement, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDaily: false,
      errorDaily: null,
    }
    if (response) {
      newPayload.dataDaily = response.data
    } else {
      newPayload.errorDaily = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DAILY_SUMMARY_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDailySummary: true,
    },
  })

  const result = yield race({
    data: call(mService.dailyDataManagementSummary, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDailySummary: false,
      errorDailySummary: null,
    }
    if (response) {
      newPayload.dataDailySummary = response.data
    } else {
      newPayload.errorDailySummary = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_MONTHLY_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMonthly: true,
    },
  })

  const result = yield race({
    data: call(mService.monthlyDataManagement, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMonthly: false,
      errorMonthly: null,
    }
    if (response) {
      newPayload.dataMonthly = response.data
    } else {
      newPayload.errorMonthly = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.LOAD_MAP_DATA, LOAD_MAP_DATA),
    takeLatest(actions.LOAD_DAILY_DATA, LOAD_DAILY_DATA),
    takeLatest(actions.LOAD_DAILY_SUMMARY_DATA, LOAD_DAILY_SUMMARY_DATA),
    takeLatest(actions.LOAD_MONTHLY_DATA, LOAD_MONTHLY_DATA),
  ])
}
