const actions = {
  SET_STATE: 'stock/SET_STATE',
  SEARCH: 'stock/SEARCH',
  CANCEL: 'stock/CANCEL',
  FILTER: 'stock/FILTER',
  OPTION: 'stock/OPTION',
  UPLOAD: 'stock/UPLOAD',
}

export default actions
