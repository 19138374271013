import apiClient from 'services/axios'

export async function getProductPrimaryBlock(parameter) {
  return apiClient
    .get('/user/product-primary-block/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductPrimaryBlock(parameter = {}) {
  return apiClient
    .get('/user/product-primary-block/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductPrimaryBlock(values) {
  return apiClient
    .post('/user/product-primary-block/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductPrimaryBlock(id, parameter = {}) {
  return apiClient
    .get(`/user/product-primary-block/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductPrimaryBlock(id, values) {
  return apiClient
    .post(`/user/product-primary-block/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductPrimaryBlock(id) {
  return apiClient
    .delete(`/user/product-primary-block/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-primary-block/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
