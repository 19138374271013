const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_SSO: 'user/LOGIN_SSO',
  LOAD_CURRENT_ACCOUNT_SSO: 'user/LOAD_CURRENT_ACCOUNT_SSO',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_PROFILE: 'user/LOAD_PROFILE',
  CANCEL_PROFILE: 'user/CANCEL_PROFILE',
  LOGOUT: 'user/LOGOUT',
  UPDATE_PROFILE: 'user/UPDATE_PROFILE',
  USER_PROFILE: 'user/USER_PROFILE',
  UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  UPDATE_PROFILE_IMAGE: 'user/UPDATE_PROFILE_IMAGE',
  UNAUTHENTICATED: 'user/UNAUTHENTICATED',
  AUTHENTICATED: 'user/AUTHENTICATED',
}

export default actions
