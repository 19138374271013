import apiClient from 'services/axios'

export async function getConsumerDistributorRange(parameter) {
  return apiClient
    .get('/user/sfa-consumer-distributor-range/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerDistributorRange(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-distributor-range/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerDistributorRange(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-range/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerDistributorRange(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-range/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerDistributorRange(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-distributor-range/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerDistributorRange(id) {
  return apiClient
    .delete(`/user/sfa-consumer-distributor-range/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerDistributorRange(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-range/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-range/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
