import apiClient from 'services/axios'

export async function getProductDistBlockApproval(parameter) {
  return apiClient
    .get('/user/product-dist-block-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductDistBlockApproval(parameter = {}) {
  return apiClient
    .get('/user/product-dist-block-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductDistBlockApproval(values) {
  return apiClient
    .post('/user/product-dist-block-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductDistBlockApproval(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist-block-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductDistBlockApproval(id, values) {
  return apiClient
    .post(`/user/product-dist-block-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDistBlockApproval(id) {
  return apiClient
    .delete(`/user/product-dist-block-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkProductDistBlockApproval(values) {
  return apiClient
    .post(`/user/product-dist-block-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
