const actions = {
  SET_STATE: 'range-variant/SET_STATE',
  LOAD_RANGE_VARIANT: 'range-variant/LOAD_RANGE_VARIANT',
  CANCEL_RANGE_VARIANT: 'range-variant/CANCEL_RANGE_VARIANT',
  LOAD_FORM_CREATE: 'range-variant/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'range-variant/LOAD_FORM_EDIT',
  STORE_RANGE_VARIANT: 'range-variant/STORE_RANGE_VARIANT',
  UPDATE_RANGE_VARIANT: 'range-variant/UPDATE_RANGE_VARIANT',
  DELETE_RANGE_VARIANT: 'range-variant/DELETE_RANGE_VARIANT',
}

export default actions
