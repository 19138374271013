const actions = {
  SET_STATE: 'outlet-temp/SET_STATE',
  LOAD_OUTLET_TEMP: 'outlet-temp/LOAD_OUTLET_TEMP',
  CANCEL_OUTLET_TEMP: 'outlet-temp/CANCEL_OUTLET_TEMP',
  LOAD_FORM_CREATE: 'outlet-temp/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-temp/LOAD_FORM_EDIT',
  STORE_OUTLET_TEMP: 'outlet-temp/STORE_OUTLET_TEMP',
  UPDATE_OUTLET_TEMP: 'outlet-temp/UPDATE_OUTLET_TEMP',
  DELETE_OUTLET_TEMP: 'outlet-temp/DELETE_OUTLET_TEMP',
  DOWNLOAD_OUTLET_TEMP: 'outlet-temp/DOWNLOAD_OUTLET_TEMP',
  UPLOAD_OUTLET_TEMP: 'outlet-temp/UPLOAD_OUTLET_TEMP',
  SAVE_OUTLET_TEMP: 'outlet-temp/SAVE_OUTLET_TEMP',
  RESET_OUTLET_TEMP: 'outlet-temp/RESET_OUTLET_TEMP',
  DOWNLOAD_ALL: 'outlet-temp/DOWNLOAD_ALL',
}

export default actions
