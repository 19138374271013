const actions = {
  SET_STATE: 'report-display-salesman/SET_STATE',
  SEARCH: 'report-display-salesman/SEARCH',
  FILTER: 'report-display-salesman/FILTER',
  CANCEL: 'report-display-salesman/CANCEL',
  DOWNLOAD: 'report-display-salesman/DOWNLOAD',
  REPROCESS_FILE: 'report-display-salesman/REPROCESS_FILE',
  LAST_UPDATED: 'report-display-salesman/LAST_UPDATED',
}

export default actions
