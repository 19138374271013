const actions = {
  SET_STATE: 'banner/SET_STATE',
  SEARCH: 'banner/SEARCH',
  CANCEL: 'banner/CANCEL',
  CREATE: 'banner/CREATE',
  EDIT: 'banner/EDIT',
  STORE: 'banner/STORE',
  UPDATE: 'banner/UPDATE',
  DELETE: 'banner/DELETE',
}

export default actions
