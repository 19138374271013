import apiClient from 'services/axios'

export async function getBanner(parameter) {
  return apiClient
    .get('/user/sfa-banner/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBanner(parameter = {}) {
  return apiClient
    .get('/user/sfa-banner/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeBanner(values) {
  return apiClient
    .post('/user/sfa-banner/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editBanner(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-banner/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBanner(id, values) {
  return apiClient
    .post(`/user/sfa-banner/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteBanner(id) {
  return apiClient
    .delete(`/user/sfa-banner/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
