const actions = {
  SET_STATE: 'report-sales-secondary-mtd/SET_STATE',
  SEARCH: 'report-sales-secondary-mtd/SEARCH',
  FILTER: 'report-sales-secondary-mtd/FILTER',
  CANCEL: 'report-sales-secondary-mtd/CANCEL',
  DOWNLOAD: 'report-sales-secondary-mtd/DOWNLOAD',
  REPROCESS_FILE: 'report-sales-secondary-mtd/REPROCESS_FILE',
  LAST_UPDATED: 'report-sales-secondary-mtd/LAST_UPDATED',
}

export default actions
