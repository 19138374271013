const actions = {
  SET_STATE: 'outlet-latlng-image/SET_STATE',
  SEARCH: 'outlet-latlng-image/SEARCH',
  CANCEL: 'outlet-latlng-image/CANCEL',
  EDIT: 'outlet-latlng-image/EDIT',
  UPDATE: 'outlet-latlng-image/UPDATE',
  FILTER: 'outlet-latlng-image/FILTER',
  RESET: 'outlet-latlng-image/RESET',
  UPLOAD_FILE: 'outlet-latlng-image/UPLOAD_FILE',
}

export default actions
