const actions = {
  SET_STATE: 'product-top-monthly-inactive/SET_STATE',
  SEARCH: 'product-top-monthly-inactive/SEARCH',
  CANCEL: 'product-top-monthly-inactive/CANCEL',
  CREATE: 'product-top-monthly-inactive/CREATE',
  EDIT: 'product-top-monthly-inactive/EDIT',
  STORE: 'product-top-monthly-inactive/STORE',
  UPDATE: 'product-top-monthly-inactive/UPDATE',
  DELETE: 'product-top-monthly-inactive/DELETE',
}

export default actions
