import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/outlet-sales-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/outlet-sales-temp/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/outlet-sales-temp/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-sales-temp/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/outlet-sales-temp/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/outlet-sales-temp/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter = {}) {
  return apiClient
    .get(`/user/outlet-sales-temp/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function upload(values) {
  return apiClient
    .post('/user/outlet-sales-temp/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(values) {
  return apiClient
    .delete(`/user/outlet-sales-temp/reset`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function save(values) {
  return apiClient
    .post(`/user/outlet-sales-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
