const actions = {
  SET_STATE: 'outlet-group-inactive/SET_STATE',
  LOAD_OUTLET_GROUP: 'outlet-group-inactive/LOAD_OUTLET_GROUP',
  CANCEL_OUTLET_GROUP: 'outlet-group-inactive/CANCEL_OUTLET_GROUP',
  LOAD_FORM_CREATE: 'outlet-group-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-group-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_GROUP: 'outlet-group-inactive/STORE_OUTLET_GROUP',
  UPDATE_OUTLET_GROUP: 'outlet-group-inactive/UPDATE_OUTLET_GROUP',
  DELETE_OUTLET_GROUP: 'outlet-group-inactive/DELETE_OUTLET_GROUP',
}

export default actions
