import apiClient from 'services/axios'

export async function getProductPrimaryDeviasi(parameter) {
  return apiClient
    .get('/user/product-primary-deviasi/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionMapping(parameter = {}) {
  return apiClient
    .get('/user/product-primary-deviasi/option-mapping', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setMapping(id, parameter = {}) {
  return apiClient
    .post(`/user/product-primary-deviasi/${id}/mapping`, {
      ...parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function block(id, parameter = {}) {
  return apiClient
    .post(`/user/product-primary-deviasi/${id}/block`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-primary-deviasi/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
