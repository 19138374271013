const actions = {
  SET_STATE: 'target-incentive-mss/SET_STATE',
  SEARCH: 'target-incentive-mss/SEARCH',
  CREATE: 'target-incentive-mss/CREATE',
  EDIT: 'target-incentive-mss/EDIT',
  STORE: 'target-incentive-mss/STORE',
  UPDATE: 'target-incentive-mss/UPDATE',
  DELETE: 'target-incentive-mss/DELETE',
  CANCEL: 'target-incentive-mss/CANCEL',
  UPLOAD_FILE: 'target-incentive-mss/UPLOAD_FILE',
}

export default actions
