import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/target-secondary-sales-outlet/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-sales-outlet/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/target-secondary-sales-outlet/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/target-secondary-sales-outlet/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/target-secondary-sales-outlet/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/target-secondary-sales-outlet/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-sales-outlet/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/target-secondary-sales-outlet/outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-secondary-sales-outlet/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/target-secondary-sales-outlet/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/target-secondary-sales-outlet/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
