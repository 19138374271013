import apiClient from 'services/axios'

export async function getQuiz(parameter) {
  return apiClient
    .get('/user/sfa-quiz/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createQuiz(parameter = {}) {
  return apiClient
    .get('/user/sfa-quiz/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeQuiz(values) {
  return apiClient
    .post('/user/sfa-quiz/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editQuiz(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-quiz/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateQuiz(id, values) {
  return apiClient
    .post(`/user/sfa-quiz/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteQuiz(id) {
  return apiClient
    .delete(`/user/sfa-quiz/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadQuiz(values) {
  return apiClient
    .post('/user/sfa-quiz/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
