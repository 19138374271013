const actions = {
  SET_STATE: 'outlet-channel-inactive/SET_STATE',
  LOAD_OUTLET_CHANNEL: 'outlet-channel-inactive/LOAD_OUTLET_CHANNEL',
  CANCEL_OUTLET_CHANNEL: 'outlet-channel-inactive/CANCEL_OUTLET_CHANNEL',
  LOAD_FORM_CREATE: 'outlet-channel-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-channel-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_CHANNEL: 'outlet-channel-inactive/STORE_OUTLET_CHANNEL',
  UPDATE_OUTLET_CHANNEL: 'outlet-channel-inactive/UPDATE_OUTLET_CHANNEL',
  DELETE_OUTLET_CHANNEL: 'outlet-channel-inactive/DELETE_OUTLET_CHANNEL',
}

export default actions
