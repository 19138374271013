import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as myService from 'services/webservice/user-access/trans-dist-upload-stock-distributor'
// import { saveAs } from 'file-saver'
import actions from './actions'

const loadingDownloads = []

function* DOWNLOAD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(myService.download, payload.id),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const mToken = result.data.response.config.headers.Authorization.replace('Bearer ', '')

      window.location.href = `${result.data.response.request.responseURL}&token=${mToken}`

      newPayload.messageDownloadFile = `File ${filename} is successfully downloaded!`
      newPayload.messageDownloadFileHeader = 'SUCCESS'
    } else {
      newPayload.messageDownloadFileHeader = 'FAILURE'
      newPayload.messageDownloadFile = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(myService.upload, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SEARCH_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.transDistUpload)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(myService.searchList, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataDistUpload = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SEARCH_DETAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.transDistUpload)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(myService.searchDetail, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataDistUpload = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTION_DETAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(myService.options, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataOptions = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* REPROCESS() {
  while (true) {
    const { payload } = yield take(actions.REPROCESS)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(myService.reprocess, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
        messageBulkResponse: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageBulkResponse = response.data
      }
      newPayload.messageBulkResponseError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_PARENT() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PARENT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(myService.deleteParent, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
        messageBulkResponse: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageBulkResponse = response.data
      }
      newPayload.messageBulkResponseError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_CHILD() {
  while (true) {
    const { payload } = yield take(actions.DELETE_CHILD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(myService.deleteChild, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = response.data
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DOWNLOAD_CHILD({ payload }) {
  loadingDownloads.push(payload.id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: loadingDownloads,
    },
  })
  const result = yield race({
    data: call(myService.downloadChild, payload, event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total)
      put({
        type: actions.SET_STATE,
        payload: {
          progressDownload: `${percentCompleted} %`,
        },
      })
    }),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingDownload: loadingDownloads,
      error: null,
    }

    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      newPayload.messageDownloadFile = `File ${filename} is successfully downloaded!`
      newPayload.messageDownloadFileHeader = 'SUCCESS'
    } else {
      newPayload.messageDownloadFileHeader = 'FAILURE'
      newPayload.messageDownloadFile = error
    }

    const index = loadingDownloads.indexOf(payload.id)
    if (index > -1) {
      loadingDownloads.splice(index, 1)
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DETAIL_LOG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDetail: true,
    },
  })
  const result = yield race({
    data: call(myService.detailLog, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDetail: false,
      error: null,
    }
    if (response) {
      newPayload.dataDetailLogs = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(myService.filter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH_LIST, SEARCH_LIST),
    takeLatest(actions.SEARCH_DETAIL, SEARCH_DETAIL),
    takeLatest(actions.OPTION_DETAIL, OPTION_DETAIL),
    takeLatest(actions.FILTER, FILTER),
    takeLatest(actions.DETAIL_LOG, DETAIL_LOG),
    takeLatest(actions.DOWNLOAD, DOWNLOAD),
    takeLatest(actions.DOWNLOAD_CHILD, DOWNLOAD_CHILD),
    UPLOAD(),
    REPROCESS(),
    DELETE_PARENT(),
    DELETE_CHILD(),
  ])
}
