const actions = {
  SET_STATE: 'consumer-channel-range/SET_STATE',
  SEARCH: 'consumer-channel-range/SEARCH',
  CANCEL: 'consumer-channel-range/CANCEL',
  CREATE: 'consumer-channel-range/CREATE',
  EDIT: 'consumer-channel-range/EDIT',
  STORE: 'consumer-channel-range/STORE',
  UPDATE: 'consumer-channel-range/UPDATE',
  DELETE: 'consumer-channel-range/DELETE',
  UPLOAD: 'consumer-channel-range/UPLOAD',
  LIST_PRODUCT: 'consumer-channel-range/LIST_PRODUCT',
}

export default actions
