import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as roleService from 'services/webservice/user-access/role'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_ROLE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.role)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(roleService.getRole, payload),
    cancel: take(actions.CANCEL_ROLE),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataRole = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataRolePermission: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  switch (action.type) {
    case actions.GET_ROLE_PERMISSION:
      result = yield race({
        data: call(roleService.getRolePermission, payload.id),
        cancel: take(actions.CANCEL_ROLE),
      })
      break
    default:
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.GET_ROLE_PERMISSION:
        newPayload.dataRolePermission = response ? response.data.permissions : null
        break
      default:
    }

    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_ROLE() {
  while (true) {
    const { payload } = yield take(actions.STORE_ROLE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(roleService.storeRole, payload),
      cancel: take(actions.CANCEL_ROLE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_ROLE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_ROLE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(roleService.updateRole, currentId, payload),
      cancel: take(actions.CANCEL_ROLE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_ROLE() {
  while (true) {
    const { payload } = yield take(actions.DELETE_ROLE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(roleService.deleteRole, payload.id),
      cancel: take(actions.CANCEL_ROLE),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_ROLE_PERMISSION() {
  while (true) {
    const { payload } = yield take(actions.SET_ROLE_PERMISSION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(roleService.setRolePermission, currentId, payload),
      cancel: take(actions.CANCEL_ROLE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_FILE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingUpload: true,
        messageUploadFile: null,
      },
    })
    const result = yield race({
      data: call(roleService.uploadFile, payload),
      cancel: take(actions.CANCEL_ROLE),
    })
    if (result.data) {
      const newPayload = {
        loadingUpload: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ROLE, GET_ROLE),
    takeLatest([actions.GET_ROLE_PERMISSION], GET_FORM),
    STORE_ROLE(),
    UPDATE_ROLE(),
    DELETE_ROLE(),
    SET_ROLE_PERMISSION(),
    UPLOAD(),
  ])
}
