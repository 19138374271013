const actions = {
  SET_STATE: 'approval-balance-summary/SET_STATE',
  CANCEL: 'approval-balance-summary/CANCEL',
  LIST: 'approval-balance-summary/LIST',
  FILTER: 'approval-balance-summary/FILTER',
  DOWNLOAD: 'approval-balance-summary/DOWNLOAD',
  REMINDER: 'approval-balance-summary/REMINDER',
  APPROVE: 'approval-balance-summary/APPROVE',
}

export default actions
