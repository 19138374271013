const actions = {
  SET_STATE: 'product-primary-deviasi/SET_STATE',
  SEARCH: 'product-primary-deviasi/SEARCH',
  CANCEL: 'product-primary-deviasi/CANCEL',
  OPTION_MAPPING: 'product-primary-deviasi/OPTION_MAPPING',
  SET_MAPPING: 'product-primary-deviasi/SET_MAPPING',
  UPLOAD_FILE: 'product-primary-deviasi/UPLOAD_FILE',
  BLOCK: 'product-primary-deviasi/BLOCK',
}

export default actions
