import apiClient from 'services/axios'

export async function getDistributorPrimaryDeviasi(parameter) {
  return apiClient
    .get('/user/distributor-primary-deviasi/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionMapping(parameter = {}) {
  return apiClient
    .get('/user/distributor-primary-deviasi/option-mapping', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setMapping(id, parameter = {}) {
  return apiClient
    .post(`/user/distributor-primary-deviasi/${id}/mapping`, {
      ...parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function block(id, parameter = {}) {
  return apiClient
    .post(`/user/distributor-primary-deviasi/${id}/block`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/distributor-primary-deviasi/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
