import apiClient from 'services/axios'

export async function getProductDistTemp(parameter) {
  return apiClient
    .get('/user/product-dist-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDistTemp(id) {
  return apiClient
    .delete(`/user/product-dist-temp/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateProductDistTemp(parameter = {}) {
  return apiClient
    .get(`/user/product-dist-temp/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProductDistTempV2(values) {
  return apiClient
    .post(`/user/product-dist-temp/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProductDistTemp(values) {
  return apiClient
    .post('/user/product-dist-temp/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetProductDistTemp(values) {
  return apiClient
    .delete(`/user/product-dist-temp/reset`, { params: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveProductDistTemp(values) {
  return apiClient
    .post(`/user/product-dist-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
