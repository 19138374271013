const actions = {
  SET_STATE: 'product-dist-block-approval/SET_STATE',
  LOAD_PRODUCT_DIST_BLOCK_APPROVAL: 'product-dist-block-approval/LOAD_PRODUCT_DIST_BLOCK_APPROVAL',
  CANCEL_PRODUCT_DIST_BLOCK_APPROVAL:
    'product-dist-block-approval/CANCEL_PRODUCT_DIST_BLOCK_APPROVAL',
  LOAD_FORM_CREATE: 'product-dist-block-approval/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist-block-approval/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST_BLOCK_APPROVAL:
    'product-dist-block-approval/STORE_PRODUCT_DIST_BLOCK_APPROVAL',
  UPDATE_PRODUCT_DIST_BLOCK_APPROVAL:
    'product-dist-block-approval/UPDATE_PRODUCT_DIST_BLOCK_APPROVAL',
  DELETE_PRODUCT_DIST_BLOCK_APPROVAL:
    'product-dist-block-approval/DELETE_PRODUCT_DIST_BLOCK_APPROVAL',
  BULK_PRODUCT_DIST_BLOCK_APPROVAL: 'product-dist-block-approval/BULK_PRODUCT_DIST_BLOCK_APPROVAL',
  NEED_RELOAD: 'product-dist-block-approval/NEED_RELOAD',
}

export default actions
