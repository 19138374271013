const actions = {
  SET_STATE: 'trans-dist-upload-stock-draft-distributor/SET_STATE',
  SEARCH: 'trans-dist-upload-stock-draft-distributor/SEARCH',
  CANCEL: 'trans-dist-upload-stock-draft-distributor/CANCEL',
  UPLOAD: 'trans-dist-upload-stock-draft-distributor/UPLOAD',
  DOWNLOAD: 'trans-dist-upload-stock-draft-distributor/DOWNLOAD',
  OPTIONS: 'trans-dist-upload-stock-draft-distributor/OPTIONS',
  SUBMIT: 'trans-dist-upload-stock-draft-distributor/SUBMIT',
  DELETE: 'trans-dist-upload-stock-draft-distributor/DELETE',
  DELETE_CHILD: 'trans-dist-upload-stock-draft-distributor/DELETE_CHILD',
  FILTER: 'trans-dist-upload-stock-draft-distributor/FILTER',
}

export default actions
