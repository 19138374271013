import apiClient from 'services/axios'

export async function getOutletDistApproval(parameter) {
  return apiClient
    .get('/user/outlet-dist-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getOutletDistApprovalNew(parameter) {
  return apiClient
    .get('/user/outlet-dist-approval/search-new-outlet', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletDistApproval(id) {
  return apiClient
    .delete(`/user/outlet-dist-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkOutletDistApproval(values) {
  return apiClient
    .post(`/user/outlet-dist-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
