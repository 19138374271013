const actions = {
  SET_STATE: 'report-mr/SET_STATE',
  SEARCH: 'report-mr/SEARCH',
  FILTER: 'report-mr/FILTER',
  CANCEL: 'report-mr/CANCEL',
  DOWNLOAD: 'report-mr/DOWNLOAD',
  REPROCESS_FILE: 'report-mr/REPROCESS_FILE',
  LAST_UPDATED: 'report-mr/LAST_UPDATED',
}

export default actions
