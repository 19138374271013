const actions = {
  SET_STATE: 'trans-dist-upload-stock-draft/SET_STATE',
  SEARCH: 'trans-dist-upload-stock-draft/SEARCH',
  CANCEL: 'trans-dist-upload-stock-draft/CANCEL',
  UPLOAD: 'trans-dist-upload-stock-draft/UPLOAD',
  DOWNLOAD: 'trans-dist-upload-stock-draft/DOWNLOAD',
  OPTIONS: 'trans-dist-upload-stock-draft/OPTIONS',
  SUBMIT: 'trans-dist-upload-stock-draft/SUBMIT',
  DELETE: 'trans-dist-upload-stock-draft/DELETE',
  DELETE_CHILD: 'trans-dist-upload-stock-draft/DELETE_CHILD',
  FILTER: 'trans-dist-upload-stock-draft/FILTER',
}

export default actions
