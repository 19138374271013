const actions = {
  SET_STATE: 'category-inactive/SET_STATE',
  LOAD_CATEGORY: 'category-inactive/LOAD_CATEGORY',
  CANCEL_CATEGORY: 'category-inactive/CANCEL_CATEGORY',
  LOAD_FORM_CREATE: 'category-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-inactive/LOAD_FORM_EDIT',
  STORE_CATEGORY: 'category-inactive/STORE_CATEGORY',
  UPDATE_CATEGORY: 'category-inactive/UPDATE_CATEGORY',
  DELETE_CATEGORY: 'category-inactive/DELETE_CATEGORY',
}

export default actions
