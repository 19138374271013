const actions = {
  SET_STATE: 'product-inactive/SET_STATE',
  LOAD_PRODUCT_INACTIVE: 'product-inactive/LOAD_PRODUCT_INACTIVE',
  CANCEL_PRODUCT_INACTIVE: 'product-inactive/CANCEL_PRODUCT_INACTIVE',
  LOAD_FORM_CREATE: 'product-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-inactive/LOAD_FORM_EDIT',
  STORE_PRODUCT_INACTIVE: 'product-inactive/STORE_PRODUCT_INACTIVE',
  UPDATE_PRODUCT_INACTIVE: 'product-inactive/UPDATE_PRODUCT_INACTIVE',
  DELETE_PRODUCT_INACTIVE: 'product-inactive/DELETE_PRODUCT_INACTIVE',
  CREATE_RANGE: 'product-inactive/CREATE_RANGE',
  CREATE_VARIANT: 'product-inactive/CREATE_VARIANT',
  CREATE_RANGE_VARIANT: 'product-inactive/CREATE_RANGE_VARIANT',
  CREATE_RANGE_MARKETING: 'product-inactive/CREATE_RANGE_MARKETING',
  CREATE_CATEGORY: 'product-inactive/CREATE_CATEGORY',
  CREATE_CATEGORY_SUB: 'product-inactive/CREATE_CATEGORY_SUB',
  CREATE_CATEGORY_CEO: 'product-inactive/CREATE_CATEGORY_CEO',
  CREATE_CATEGORY_ACC: 'product-inactive/CREATE_CATEGORY_ACC',
  CREATE_CATEGORY_ACCENTURE: 'product-inactive/CREATE_CATEGORY_ACCENTURE',
}

export default actions
