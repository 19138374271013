import apiClient from 'services/axios'

export async function getBrandGroup(parameter) {
  return apiClient
    .get('/user/brand-group/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBrandGroup(parameter = {}) {
  return apiClient
    .get('/user/brand-group/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeBrandGroup(values) {
  return apiClient
    .post('/user/brand-group/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editBrandGroup(id, parameter = {}) {
  return apiClient
    .get(`/user/brand-group/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBrandGroup(id, values) {
  return apiClient
    .post(`/user/brand-group/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteBrandGroup(id) {
  return apiClient
    .delete(`/user/brand-group/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
