import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-sfa/sales'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })
  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.filterSalesDistributor : mService.filterSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_MAP_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMap: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.mapDataSalesDistributor : mService.mapDataSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMap: false,
      errorMap: null,
    }
    if (response) {
      newPayload.dataMap = response.data
    } else {
      newPayload.errorMap = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DAILY_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDaily: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.dailyDataSalesDistributor : mService.dailyDataSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDaily: false,
      errorDaily: null,
    }
    if (response) {
      newPayload.dataDaily = response.data
    } else {
      newPayload.errorDaily = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_ABSEN_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAbsen: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.absenDataSalesDistributor : mService.absenDataSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAbsen: false,
      errorAbsen: null,
    }
    if (response) {
      newPayload.dataAbsen = response.data
    } else {
      newPayload.errorAbsen = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPDATE_ABSEN() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_ABSEN_DATA)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingUpdateAbsen: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.updateAbsenDataSales, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingUpdateAbsen: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUpdateAbsen = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorUpdateAbsen = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CANCEL_ABSEN() {
  while (true) {
    const { payload } = yield take(actions.CANCEL_ABSEN_DATA)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCancelAbsen: true,
        errorCancelAbsen: null,
        messageCancelAbsen: null,
      },
    })
    const result = yield race({
      data: call(mService.cancelAbsenDataSales, payload.id),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingCancelAbsen: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageCancelAbsen = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorCancelAbsen = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.LOAD_MAP_DATA, LOAD_MAP_DATA),
    takeLatest(actions.LOAD_DAILY_DATA, LOAD_DAILY_DATA),
    takeLatest(actions.LOAD_ABSEN_DATA, LOAD_ABSEN_DATA),
    UPDATE_ABSEN(),
    CANCEL_ABSEN(),
  ])
}
