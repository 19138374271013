const actions = {
  SET_STATE: 'category-accenture/SET_STATE',
  LOAD_CATEGORY_ACCENTURE: 'category-accenture/LOAD_CATEGORY_ACCENTURE',
  CANCEL_CATEGORY_ACCENTURE: 'category-accenture/CANCEL_CATEGORY_ACCENTURE',
  LOAD_FORM_CREATE: 'category-accenture/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-accenture/LOAD_FORM_EDIT',
  STORE_CATEGORY_ACCENTURE: 'category-accenture/STORE_CATEGORY_ACCENTURE',
  UPDATE_CATEGORY_ACCENTURE: 'category-accenture/UPDATE_CATEGORY_ACCENTURE',
  DELETE_CATEGORY_ACCENTURE: 'category-accenture/DELETE_CATEGORY_ACCENTURE',
}

export default actions
