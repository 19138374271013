const actions = {
  SET_STATE: 'category-sub-inactive/SET_STATE',
  LOAD_CATEGORY_SUB: 'category-sub-inactive/LOAD_CATEGORY_SUB',
  CANCEL_CATEGORY_SUB: 'category-sub-inactive/CANCEL_CATEGORY_SUB',
  LOAD_FORM_CREATE: 'category-sub-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-sub-inactive/LOAD_FORM_EDIT',
  STORE_CATEGORY_SUB: 'category-sub-inactive/STORE_CATEGORY_SUB',
  UPDATE_CATEGORY_SUB: 'category-sub-inactive/UPDATE_CATEGORY_SUB',
  DELETE_CATEGORY_SUB: 'category-sub-inactive/DELETE_CATEGORY_SUB',
}

export default actions
