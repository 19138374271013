const actions = {
  SET_STATE: 'consumer-channel-brand/SET_STATE',
  SEARCH: 'consumer-channel-brand/SEARCH',
  CANCEL: 'consumer-channel-brand/CANCEL',
  CREATE: 'consumer-channel-brand/CREATE',
  EDIT: 'consumer-channel-brand/EDIT',
  STORE: 'consumer-channel-brand/STORE',
  UPDATE: 'consumer-channel-brand/UPDATE',
  DELETE: 'consumer-channel-brand/DELETE',
  UPLOAD: 'consumer-channel-brand/UPLOAD',
  LIST_PRODUCT: 'consumer-channel-brand/LIST_PRODUCT',
}

export default actions
