const actions = {
  SET_STATE: 'region-inactive/SET_STATE',
  LOAD_REGION: 'region-inactive/LOAD_REGION',
  CANCEL_REGION: 'region-inactive/CANCEL_REGION',
  LOAD_FORM_CREATE: 'region-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'region-inactive/LOAD_FORM_EDIT',
  STORE_REGION: 'region-inactive/STORE_REGION',
  UPDATE_REGION: 'region-inactive/UPDATE_REGION',
  DELETE_REGION: 'region-inactive/DELETE_REGION',
}

export default actions
