import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/outlet-sales/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/outlet-sales/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter(parameter) {
  return apiClient
    .get('/user/outlet-sales/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/outlet-sales/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-sales/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/outlet-sales/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/outlet-sales/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function permission(parameter) {
  return apiClient
    .get('/user/outlet-sales/permission', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-sales/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function switchSalesName(values) {
  return apiClient
    .post(`/user/outlet-sales/switch-sales-name`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
