const actions = {
  SET_STATE: 'month-inactive/SET_STATE',
  LOAD_MONTH: 'month-inactive/LOAD_MONTH',
  CANCEL_MONTH: 'month-inactive/CANCEL_MONTH',
  LOAD_FORM_CREATE: 'month-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'month-inactive/LOAD_FORM_EDIT',
  STORE_MONTH: 'month-inactive/STORE_MONTH',
  UPDATE_MONTH: 'month-inactive/UPDATE_MONTH',
  DELETE_MONTH: 'month-inactive/DELETE_MONTH',
}

export default actions
