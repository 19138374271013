import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as transDistUploadWeeklyService from 'services/webservice/user-access/trans-dist-upload-stock-weekly'
import { saveAs } from 'file-saver'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(
    state => state.transDistUploadStockWeekly,
  )
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(transDistUploadWeeklyService.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataTransDistUploadWeekly = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })
  const result = yield race({
    data: call(transDistUploadWeeklyService.filters, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataOptions = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDownload: true,
        messageDownload: null,
      },
    })
    const result = yield race({
      data: call(transDistUploadWeeklyService.download, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDownload: false,
        messageDownload: null,
        errorDownload: null,
      }
      const { response, error } = result.data
      if (response) {
        const filename = response.headers['content-disposition'].split('filename=')[1]
        const file = new File([response.data], `${filename.replaceAll('"', '')}`)
        saveAs(file)
        newPayload.messageDownload = `File ${filename.replaceAll(
          '"',
          '',
        )} is successfully downloaded!`
      }
      newPayload.errorDownload = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SEARCH, SEARCH), takeLatest(actions.FILTER, FILTER), DOWNLOAD()])
}
