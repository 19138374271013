const actions = {
  SET_STATE: 'user-list-inactive/SET_STATE',
  LOAD_USER_LIST: 'user-list-inactive/LOAD_USER_LIST',
  CANCEL_USER_LIST: 'user-list-inactive/CANCEL_USER_LIST',
  LOAD_FORM_CREATE: 'user-list-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'user-list-inactive/LOAD_FORM_EDIT',
  STORE_USER_LIST: 'user-list-inactive/STORE_USER_LIST',
  UPDATE_USER_LIST: 'user-list-inactive/UPDATE_USER_LIST',
  DELETE_USER_LIST: 'user-list-inactive/DELETE_USER_LIST',
  GET_USER_PERMISSION: 'user-list-inactive/GET_USER_PERMISSION',
  SET_USER_PERMISSION: 'user-list-inactive/SET_USER_PERMISSION',
  FORCE_LOGOUT: 'user-list-inactive/FORCE_LOGOUT',
  RESET_PASSWORD: 'user-list-inactive/RESET_PASSWORD',
}

export default actions
