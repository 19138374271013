const actions = {
  SET_STATE: 'period/SET_STATE',
  LOAD_PERIOD: 'period/LOAD_PERIOD',
  CANCEL_PERIOD: 'period/CANCEL_PERIOD',
  LOAD_FORM_CREATE: 'period/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'period/LOAD_FORM_EDIT',
  STORE_PERIOD: 'period/STORE_PERIOD',
  UPDATE_PERIOD: 'period/UPDATE_PERIOD',
  DELETE_PERIOD: 'period/DELETE_PERIOD',
}

export default actions
