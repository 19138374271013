import apiClient from 'services/axios'

export async function detail(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-stock/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(id, onProgress) {
  return apiClient
    .get(`/distributor/trans-dist-upload-stock/download-file/${id}`, {
      responseType: 'blob',
      onDownloadProgress: onProgress,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function upload(values) {
  return apiClient
    .post('/distributor/trans-dist-upload-stock/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reprocess(values) {
  return apiClient
    .post('/distributor/trans-dist-upload-stock/reprocess-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteParent(parameter) {
  return apiClient
    .delete(`/distributor/trans-dist-upload-stock`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteChild(parameter) {
  const { id } = parameter
  return apiClient
    .delete(`/distributor/trans-dist-upload-stock/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadChild(parameter, onProgress) {
  const child = parameter.dist_child_id
  return apiClient
    .get(`/distributor/trans-dist-upload-stock/download-file/${child}`, {
      responseType: 'blob',
      onDownloadProgress: onProgress,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function detailLog(parameter) {
  const { id } = parameter
  return apiClient
    .get(`/distributor/trans-dist-upload-stock/detail-log/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function doCombine(values) {
  return apiClient
    .post(`/distributor/trans-dist-upload-stock/combine-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function doReuncombine(values) {
  return apiClient
    .post(`/distributor/trans-dist-upload-stock/reuncombine-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function searchList(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-stock/search-list', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function searchDetail(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-stock/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function options(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-stock/filter-detail', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter(parameter) {
  return apiClient
    .get('/distributor/trans-dist-upload-stock/filter-list', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
