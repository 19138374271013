const actions = {
  SET_STATE: 'brand-category/SET_STATE',
  LOAD_BRAND_CATEGORY: 'brand-category/LOAD_BRAND_CATEGORY',
  CANCEL_BRAND_CATEGORY: 'brand-category/CANCEL_BRAND_CATEGORY',
  LOAD_FORM_CREATE: 'brand-category/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'brand-category/LOAD_FORM_EDIT',
  STORE_BRAND_CATEGORY: 'brand-category/STORE_BRAND_CATEGORY',
  UPDATE_BRAND_CATEGORY: 'brand-category/UPDATE_BRAND_CATEGORY',
  DELETE_BRAND_CATEGORY: 'brand-category/DELETE_BRAND_CATEGORY',
}

export default actions
