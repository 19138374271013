import apiClient from 'services/axios'

export async function getProductPrimary(parameter) {
  return apiClient
    .get('/user/product-primary/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductPrimary(parameter = {}) {
  return apiClient
    .get('/user/product-primary/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductPrimary(values) {
  return apiClient
    .post('/user/product-primary/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductPrimary(id, parameter = {}) {
  return apiClient
    .get(`/user/product-primary/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductPrimary(id, values) {
  return apiClient
    .post(`/user/product-primary/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductPrimary(id) {
  return apiClient
    .delete(`/user/product-primary/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-primary/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
