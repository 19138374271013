const actions = {
  SET_STATE: 'outlet-spg-temp/SET_STATE',
  SEARCH: 'outlet-spg-temp/SEARCH',
  CANCEL: 'outlet-spg-temp/CANCEL',
  SAVE: 'outlet-spg-temp/SAVE',
  RESET: 'outlet-spg-temp/RESET',
  UPLOAD_FILE: 'outlet-spg-temp/UPLOAD_FILE',
}

export default actions
