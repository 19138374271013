import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as notificationService from 'services/webservice/user-access/notification'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_NOTIFICATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.notification)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(notificationService.getNotification, payload),
    cancel: take(actions.CANCEL_NOTIFICATION),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataNotification = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataNotificationCreate: null,
    dataNotificationEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.LOAD_FORM_CREATE
  if (isCreate) {
    result = yield race({
      data: call(notificationService.createNotification),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
  } else {
    result = yield race({
      data: call(notificationService.editNotification, payload.id),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataNotificationCreate = response ? response.data : null
    } else {
      newPayload.dataNotificationEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_NOTIFICATION() {
  while (true) {
    const { payload } = yield take(actions.STORE_NOTIFICATION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(notificationService.storeNotification, payload),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_NOTIFICATION() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_NOTIFICATION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(notificationService.updateNotification, currentId, payload),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_NOTIFICATION() {
  while (true) {
    const { payload } = yield take(actions.DELETE_NOTIFICATION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(notificationService.deleteNotification, payload.id),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* READ_ALL_NOTIFICATION() {
  while (true) {
    const { payload } = yield take(actions.READ_ALL)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingReadAll: true,
        messageReadAllSuccess: null,
      },
    })
    const result = yield race({
      data: call(notificationService.readAllNotification, payload),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
    if (result.data) {
      const newPayload = {
        loadingReadAll: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageReadAllSuccess = response.data
      }
      newPayload.messageReadAllError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* READ_NOTIFICATION() {
  while (true) {
    const { payload } = yield take(actions.READ)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingRead: true,
        messageReadSuccess: null,
      },
    })
    const result = yield race({
      data: call(notificationService.readNotification, payload),
      cancel: take(actions.CANCEL_NOTIFICATION),
    })
    if (result.data) {
      const newPayload = {
        loadingRead: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageReadSuccess = response.data
      }
      newPayload.messageReadError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_NOTIFICATION, GET_NOTIFICATION),
    takeLatest([actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT], GET_FORM),
    STORE_NOTIFICATION(),
    UPDATE_NOTIFICATION(),
    DELETE_NOTIFICATION(),
    READ_ALL_NOTIFICATION(),
    READ_NOTIFICATION(),
  ])
}
