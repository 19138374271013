import apiClient from 'services/axios'

export async function getOutletSalesSpv(parameter) {
  return apiClient
    .get('/user/outlet-sales-spv/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletSalesSpv(parameter = {}) {
  return apiClient
    .get('/user/outlet-sales-spv/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletSalesSpv(values) {
  return apiClient
    .post('/user/outlet-sales-spv/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletSalesSpv(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-sales-spv/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletSalesSpv(id, values) {
  return apiClient
    .post(`/user/outlet-sales-spv/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletSalesSpv(id) {
  return apiClient
    .delete(`/user/outlet-sales-spv/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get(`/user/outlet-sales-spv/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-sales-spv/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
