const actions = {
  SET_STATE: 'target-primary-sales/SET_STATE',
  SEARCH: 'target-primary-sales/SEARCH',
  CANCEL: 'target-primary-sales/CANCEL',
  CREATE: 'target-primary-sales/CREATE',
  EDIT: 'target-primary-sales/EDIT',
  STORE: 'target-primary-sales/STORE',
  UPDATE: 'target-primary-sales/UPDATE',
  DELETE: 'target-primary-sales/DELETE',
  FILTER: 'target-primary-sales/FILTER',
  UPLOAD_FILE: 'target-primary-sales/UPLOAD_FILE',
  REMINDER: 'target-primary-sales/REMINDER',
}

export default actions
