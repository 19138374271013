import apiClient from 'services/axios'

export async function getConsumerChannelProductBundle(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel-product-bundle/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerChannelProductBundle(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-channel-product-bundle/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerChannelProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-product-bundle/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerChannelProductBundle(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-product-bundle/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerChannelProductBundle(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-channel-product-bundle/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerChannelProductBundle(id) {
  return apiClient
    .delete(`/user/sfa-consumer-channel-product-bundle/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerChannelProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-product-bundle/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-product-bundle/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
