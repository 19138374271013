import apiClient from 'services/axios'

export async function getOutletChannel(parameter) {
  return apiClient
    .get('/user/outlet-channel/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletChannel(parameter = {}) {
  return apiClient
    .get('/user/outlet-channel/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletChannel(values) {
  return apiClient
    .post('/user/outlet-channel/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletChannel(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-channel/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletChannel(id, values) {
  return apiClient
    .post(`/user/outlet-channel/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletChannel(id) {
  return apiClient
    .delete(`/user/outlet-channel/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
