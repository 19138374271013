import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/target-secondary-channel-sub-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id, values) {
  return apiClient
    .delete(`/user/target-secondary-channel-sub-approval/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkApproval(values) {
  return apiClient
    .post(`/user/target-secondary-channel-sub-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
