import apiClient from 'services/axios'

export async function getSpgType(parameter) {
  return apiClient
    .get('/user/spg-type/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSpgType(parameter = {}) {
  return apiClient
    .get('/user/spg-type/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSpgType(values) {
  return apiClient
    .post('/user/spg-type/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSpgType(id, parameter = {}) {
  return apiClient
    .get(`/user/spg-type/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSpgType(id, values) {
  return apiClient
    .post(`/user/spg-type/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSpgType(id) {
  return apiClient
    .delete(`/user/spg-type/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
