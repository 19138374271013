const actions = {
  SET_STATE: 'product-top/SET_STATE',
  LOAD_PRODUCT_TOP: 'product-top/LOAD_PRODUCT_TOP',
  CANCEL_PRODUCT_TOP: 'product-top/CANCEL_PRODUCT_TOP',
  LOAD_FORM_CREATE: 'product-top/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-top/LOAD_FORM_EDIT',
  STORE_PRODUCT_TOP: 'product-top/STORE_PRODUCT_TOP',
  UPDATE_PRODUCT_TOP: 'product-top/UPDATE_PRODUCT_TOP',
  DELETE_PRODUCT_TOP: 'product-top/DELETE_PRODUCT_TOP',
  UPLOAD_FILE: 'product-top/UPLOAD_FILE',
}

export default actions
