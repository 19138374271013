import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as outletDistTempService from 'services/webservice/user-access/outlet-dist-temp'
import { saveAs } from 'file-saver'
import actions from './actions'

function* GET_OUTLET_DIST_TEMP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.outletDistTemp)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(outletDistTempService.getOutletDistTemp, payload),
    cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataOutletDistTemp = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DELETE_OUTLET_DIST_TEMP() {
  while (true) {
    const { payload } = yield take(actions.DELETE_OUTLET_DIST_TEMP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(outletDistTempService.deleteOutletDistTemp, payload.id),
      cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DOWNLOAD_OUTLET_DIST_TEMP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: true,
      messageDownloadFile: null,
      errorDownload: null,
    },
  })
  const result = yield race({
    data: call(outletDistTempService.downloadTemplateOutletDistTemp, payload),
    cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDownload: false,
      errorDownload: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadFile = `File is successfully downloaded!`
    } else {
      newPayload.errorDownload = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD_OUTLET_DIST_TEMP() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_OUTLET_DIST_TEMP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(outletDistTempService.uploadOutletDistTemp, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SAVE_OUTLET_DIST_TEMP() {
  while (true) {
    const { payload } = yield take(actions.SAVE_OUTLET_DIST_TEMP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingSave: true,
      },
    })
    const result = yield race({
      data: call(outletDistTempService.saveOutletDistTemp, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
    })
    if (result.data) {
      const newPayload = {
        loadingSave: false,
        messageUploadFile: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET_OUTLET_DIST_TEMP() {
  while (true) {
    const { payload } = yield take(actions.RESET_OUTLET_DIST_TEMP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingReset: true,
        errorReset: null,
        messageResetSuccess: null,
      },
    })
    const result = yield race({
      data: call(outletDistTempService.resetOutletDistTemp, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_TEMP),
    })
    if (result.data) {
      const newPayload = {
        loadingReset: false,
        messageResetSuccess: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageResetSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorReset = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_OUTLET_DIST_TEMP, GET_OUTLET_DIST_TEMP),
    takeLatest(actions.DOWNLOAD_OUTLET_DIST_TEMP, DOWNLOAD_OUTLET_DIST_TEMP),
    UPLOAD_OUTLET_DIST_TEMP(),
    DELETE_OUTLET_DIST_TEMP(),
    SAVE_OUTLET_DIST_TEMP(),
    RESET_OUTLET_DIST_TEMP(),
  ])
}
