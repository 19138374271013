const actions = {
  SET_STATE: 'role/SET_STATE',
  LOAD_ROLE: 'role/LOAD_ROLE',
  CANCEL_ROLE: 'role/CANCEL_ROLE',
  LOAD_FORM_CREATE: 'role/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'role/LOAD_FORM_EDIT',
  STORE_ROLE: 'role/STORE_ROLE',
  UPDATE_ROLE: 'role/UPDATE_ROLE',
  DELETE_ROLE: 'role/DELETE_ROLE',
  UPLOAD_FILE: 'role/UPLOAD_FILE',
  GET_ROLE_PERMISSION: 'role/GET_ROLE_PERMISSION',
  SET_ROLE_PERMISSION: 'role/SET_ROLE_PERMISSION',
}

export default actions
