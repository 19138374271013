import apiClient from 'services/axios'

export async function getBrandBusiness(parameter = {}) {
  return apiClient
    .get('/user/brand-business/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBrandBusiness(parameter = {}) {
  return apiClient
    .get('/user/brand-business/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeBrandBusiness(values) {
  return apiClient
    .post('/user/brand-business/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editBrandBusiness(id, parameter = {}) {
  return apiClient
    .get(`/user/brand-business/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBrandBusiness(id, values) {
  return apiClient
    .post(`/user/brand-business/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteBrandBusiness(id) {
  return apiClient
    .delete(`/user/brand-business/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
