const actions = {
  SET_STATE: 'outlet-sales-spv-inactive/SET_STATE',
  LOAD_OUTLET_SALES_SPV: 'outlet-sales-spv-inactive/LOAD_OUTLET_SALES_SPV',
  CANCEL_OUTLET_SALES_SPV: 'outlet-sales-spv-inactive/CANCEL_OUTLET_SALES_SPV',
  LOAD_FORM_CREATE: 'outlet-sales-spv-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-sales-spv-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_SALES_SPV: 'outlet-sales-spv-inactive/STORE_OUTLET_SALES_SPV',
  UPDATE_OUTLET_SALES_SPV: 'outlet-sales-spv-inactive/UPDATE_OUTLET_SALES_SPV',
  DELETE_OUTLET_SALES_SPV: 'outlet-sales-spv-inactive/DELETE_OUTLET_SALES_SPV',
}

export default actions
