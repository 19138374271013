import actions from './actions'

const initialState = {
  dataFilter: [],
  loadingFilter: false,
  errorFilter: null,

  dataKindis: [],
  loadingKindis: false,
  errorKindis: null,

  dataMonitoring: [],
  loadingMonitoring: false,
  errorMonitoring: null,

  dataParettoMt: [],
  loadingParettoMt: false,
  errorParettoMt: null,

  dataParettoGt: [],
  loadingParettoGt: false,
  errorParettoGt: null,
}

export default function dashboardKindisReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
