import apiClient from 'services/axios'

export async function getAreaSub(parameter = {}) {
  return apiClient
    .get('/user/area-sub/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createAreaSub(parameter = {}) {
  return apiClient
    .get('/user/area-sub/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeAreaSub(values) {
  return apiClient
    .post('/user/area-sub/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editAreaSub(id, parameter = {}) {
  return apiClient
    .get(`/user/area-sub/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateAreaSub(id, values) {
  return apiClient
    .post(`/user/area-sub/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteAreaSub(id) {
  return apiClient
    .delete(`/user/area-sub/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
