const actions = {
  SET_STATE: 'outlet-spg-position/SET_STATE',
  LOAD_SPG_POSITION: 'outlet-spg-position/LOAD_SPG_POSITION',
  CANCEL_SPG_POSITION: 'outlet-spg-position/CANCEL_SPG_POSITION',
  LOAD_FORM_CREATE: 'outlet-spg-position/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-spg-position/LOAD_FORM_EDIT',
  STORE_SPG_POSITION: 'outlet-spg-position/STORE_SPG_POSITION',
  UPDATE_SPG_POSITION: 'outlet-spg-position/UPDATE_SPG_POSITION',
  DELETE_SPG_POSITION: 'outlet-spg-position/DELETE_SPG_POSITION',
}

export default actions
