import actions from './actions'

const initialState = {
  dataFilter: [],
  loadingFilter: false,
  errorFilter: null,

  dataTotal: [],
  hasLoadTotal: false,
  loadingTotal: false,
  errorTotal: null,

  dataGrowth: [],
  loadingGrowth: false,
  errorGrowth: null,

  loadingDownload: false,
  messageSuccess: null,
  messageError: null,
}

export default function dashboardUserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
