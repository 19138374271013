const actions = {
  SET_STATE: 'dashboard-user/SET_STATE',
  LOAD_FILTER: 'dashboard-user/LOAD_FILTER',
  TOTAL_USER: 'dashboard-user/TOTAL_USER',
  GROWTH_USER: 'dashboard-user/GROWTH_USER',
  DOWNLOAD_USER: 'dashboard-user/DOWNLOAD_USER',
  CANCEL: 'dashboard-user/CANCEL',
}

export default actions
