const actions = {
  SET_STATE: 'area-inactive/SET_STATE',
  LOAD_AREA: 'area-inactive/LOAD_AREA',
  LOAD_FORM_CREATE: 'area-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'area-inactive/LOAD_FORM_EDIT',
  STORE_AREA: 'area-inactive/STORE_AREA',
  UPDATE_AREA: 'area-inactive/UPDATE_AREA',
  DELETE_AREA: 'area-inactive/DELETE_AREA',
  CANCEL_AREA: 'area-inactive/CANCEL_AREA',
}

export default actions
