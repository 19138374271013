const actions = {
  SET_STATE: 'outlet-priority/SET_STATE',
  SEARCH: 'outlet-priority/SEARCH',
  CANCEL: 'outlet-priority/CANCEL',
  CANCEL_LIST_OUTLET: 'outlet-priority/CANCEL_LIST_OUTLET',
  CREATE: 'outlet-priority/CREATE',
  EDIT: 'outlet-priority/EDIT',
  STORE: 'outlet-priority/STORE',
  UPDATE: 'outlet-priority/UPDATE',
  DELETE: 'outlet-priority/DELETE',
  FILTER: 'outlet-priority/FILTER',
  UPLOAD_FILE: 'outlet-priority/UPLOAD_FILE',
  LIST_OUTLET: 'outlet-priority/LIST_OUTLET',
  DOWNLOAD: 'outlet-priority/DOWNLOAD',
}

export default actions
