const actions = {
  SET_STATE: 'month/SET_STATE',
  LOAD_MONTH: 'month/LOAD_MONTH',
  CANCEL_MONTH: 'month/CANCEL_MONTH',
  LOAD_FORM_CREATE: 'month/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'month/LOAD_FORM_EDIT',
  STORE_MONTH: 'month/STORE_MONTH',
  UPDATE_MONTH: 'month/UPDATE_MONTH',
  DELETE_MONTH: 'month/DELETE_MONTH',
}

export default actions
