import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-user'
import { saveAs } from 'file-saver'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })

  const result = yield race({
    data: call(mService.loadFilter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* TOTAL_USER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingTotal: true,
    },
  })

  const result = yield race({
    data: call(mService.totalUser, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingTotal: false,
      errorTotal: null,
    }
    if (response) {
      newPayload.dataTotal = response.data
      newPayload.hasLoadTotal = true
    } else {
      newPayload.errorTotal = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GROWTH_USER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingGrowth: true,
    },
  })

  const result = yield race({
    data: call(mService.growthUser, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingGrowth: false,
      errorGrowth: null,
    }
    if (response) {
      newPayload.dataGrowth = response.data
    } else {
      newPayload.errorGrowth = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD_USER)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDownload: true,
      },
    })
    const result = yield race({
      data: call(mService.downloadUser, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDownload: false,
      }
      const { response, error } = result.data
      if (response) {
        const filename = response.headers['content-disposition'].split('filename=')[1]
        const file = new File([response.data], `${filename.replaceAll('"', '')}`)
        saveAs(file)
        newPayload.messageSuccess = `File ${filename.replaceAll(
          '"',
          '',
        )} is successfully downloaded!`
      }
      newPayload.messageError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.TOTAL_USER, TOTAL_USER),
    takeLatest(actions.GROWTH_USER, GROWTH_USER),
    DOWNLOAD(),
  ])
}
