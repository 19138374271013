import apiClient from 'services/axios'

export async function getOutletDistBlockApproval(parameter) {
  return apiClient
    .get('/user/outlet-dist-block-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletDistBlockApproval(parameter = {}) {
  return apiClient
    .get('/user/outlet-dist-block-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletDistBlockApproval(values) {
  return apiClient
    .post('/user/outlet-dist-block-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletDistBlockApproval(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-block-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletDistBlockApproval(id, values) {
  return apiClient
    .post(`/user/outlet-dist-block-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletDistBlockApproval(id) {
  return apiClient
    .delete(`/user/outlet-dist-block-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkOutletDistBlockApproval(values) {
  return apiClient
    .post(`/user/outlet-dist-block-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
