const actions = {
  SET_STATE: 'report-no-trans-three-mma/SET_STATE',
  FILTER: 'report-no-trans-three-mma/FILTER',
  CANCEL: 'report-no-trans-three-mma/CANCEL',
  DOWNLOAD: 'report-no-trans-three-mma/DOWNLOAD',
  REPROCESS_FILE: 'report-no-trans-three-mma/REPROCESS_FILE',
  LAST_UPDATED: 'report-no-trans-three-mma/LAST_UPDATED',
}

export default actions
