const actions = {
  SET_STATE: 'shipping-point/SET_STATE',
  LOAD_SHIPPING_POINT: 'shipping-point/LOAD_SHIPPING_POINT',
  CANCEL_SHIPPING_POINT: 'shipping-point/CANCEL_SHIPPING_POINT',
  LOAD_FORM_CREATE: 'shipping-point/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'shipping-point/LOAD_FORM_EDIT',
  STORE_SHIPPING_POINT: 'shipping-point/STORE_SHIPPING_POINT',
  UPDATE_SHIPPING_POINT: 'shipping-point/UPDATE_SHIPPING_POINT',
  DELETE_SHIPPING_POINT: 'shipping-point/DELETE_SHIPPING_POINT',
}

export default actions
