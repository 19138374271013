const actions = {
  SET_STATE: 'dashboard-chart/SET_STATE',

  LOAD_DISTRIBUTOR_NEW_TERMINATE: 'dashboard-chart/LOAD_DISTRIBUTOR_NEW_TERMINATE',

  LOAD_FILTER: 'dashboard-chart/LOAD_FILTER',
  LOAD_CHART_SALES_DIAGRAM: 'dashboard-chart/LOAD_CHART_SALES_DIAGRAM',

  CHANGE_CURRENT_PERIOD: 'dashboard-chart/CHANGE_CURRENT_PERIOD',

  LOAD_CHART_REGISTER_OUTLET: 'dashboard-chart/LOAD_CHART_REGISTER_OUTLET',
  LOAD_CHART_ACTIVE_OUTLET: 'dashboard-chart/LOAD_CHART_ACTIVE_OUTLET',
  LOAD_CHART_EFFECTIVE_CALL: 'dashboard-chart/LOAD_CHART_EFFECTIVE_CALL',

  LOAD_CHART_LINE_OF_PRODUCT: 'dashboard-chart/LOAD_CHART_LINE_OF_PRODUCT',

  LOAD_CHART_AT_RISK: 'dashboard-chart/LOAD_CHART_AT_RISK',

  LOAD_DATA_SALES_MTD_YTD: 'dashboard-chart/LOAD_DATA_SALES_MTD_YTD',

  //
  LOAD_CHART_ACHIEVEMENT_BRAND: 'dashboard-chart/LOAD_CHART_ACHIEVEMENT_BRAND',
  LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB: 'dashboard-chart/LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB',

  // pareto MT & GT
  LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT: 'dashboard-chart/LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT',
  LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT: 'dashboard-chart/LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT',

  // ro npd type channel
  LOAD_RO_NPD_TYPE_CHANNEL: 'dashboard-chart/LOAD_RO_NPD_TYPE_CHANNEL',
  LOAD_FILTER_PF_MONTHLY: 'dashboard-chart/LOAD_FILTER_PF_MONTHLY',
  LOAD_RO_PF_MONTHLY: 'dashboard-chart/LOAD_RO_PF_MONTHLY',

  // mma pf monthly
  LOAD_SIX_MMA_PF_MONTHLY: 'dashboard-chart/LOAD_SIX_MMA_PF_MONTHLY',
  LOAD_FILTER_SIX_MMA_PF_MONTHLY: 'dashboard-chart/LOAD_FILTER_SIX_MMA_PF_MONTHLY',

  // Channel no transaction
  LOAD_CHART_CHANNEL_NO_TRANSACTION: 'dashboard-chart/LOAD_CHART_CHANNEL_NO_TRANSACTION',
  LOAD_PRODUCT_TRANSACTION_CHANNEL: 'dashboard-chart/LOAD_PRODUCT_TRANSACTION_CHANNEL',
  LOAD_FILTER_PRODUCT_TRANSACTION_CHANNEL:
    'dashboard-chart/LOAD_FILTER_PRODUCT_TRANSACTION_CHANNEL',

  // SALES
  LOAD_CHART_ACHIEVEMENT_SALES: 'dashboard-chart/LOAD_CHART_ACHIEVEMENT_SALES',
  LOAD_CHART_ACHIEVEMENT_BRAND_SALES: 'dashboard-chart/LOAD_CHART_ACHIEVEMENT_BRAND_SALES',
  LOAD_CHART_ACHIEVEMENT_BRAND_SALES_FILTER:
    'dashboard-chart/LOAD_CHART_ACHIEVEMENT_BRAND_SALES_FILTER',
  LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES:
    'dashboard-chart/LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES',
  LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES_FILTER:
    'dashboard-chart/LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES_FILTER',
  LOAD_CHART_RO_NPD_SALES: 'dashboard-chart/LOAD_CHART_RO_NPD_SALES',
  LOAD_CHART_RO_PF_MONTHLY: 'dashboard-chart/LOAD_CHART_RO_PF_MONTHLY',
  LOAD_FILTER_PF_MONTHLY_SALES: 'dashboard-chart/LOAD_FILTER_PF_MONTHLY_SALES',
  LOAD_CHART_RATE_BS: 'dashboard-chart/LOAD_CHART_RATE_BS',
  LOAD_FILTER_RATE_BS: 'dashboard-chart/LOAD_FILTER_RATE_BS',

  CANCEL: 'dashboard-chart/CANCEL',
}

export default actions
