const actions = {
  SET_STATE: 'trans-dist-status-cube/SET_STATE',
  SEARCH: 'trans-dist-status-cube/SEARCH',
  FILTER: 'trans-dist-status-cube/FILTER',
  CANCEL: 'trans-dist-status-cube/CANCEL',
  LOG_DETAIL: 'trans-dist-status-cube/LOG_DETAIL',
  REPROCESS: 'trans-dist-status-cube/REPROCESS',
}

export default actions
