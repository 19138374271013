const actions = {
  SET_STATE: 'outlet-dist/SET_STATE',
  LOAD_OUTLET_DIST: 'outlet-dist/LOAD_OUTLET_DIST',
  CANCEL_OUTLET_DIST: 'outlet-dist/CANCEL_OUTLET_DIST',
  LOAD_FORM_CREATE: 'outlet-dist/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-dist/LOAD_FORM_EDIT',
  STORE_OUTLET_DIST: 'outlet-dist/STORE_OUTLET_DIST',
  UPDATE_OUTLET_DIST: 'outlet-dist/UPDATE_OUTLET_DIST',
  DELETE_OUTLET_DIST: 'outlet-dist/DELETE_OUTLET_DIST',
  NEED_RELOAD: 'outlet-dist/NEED_RELOAD',
  PERMISSION_OUTLET_DIST: 'outlet-dist/PERMISSION_OUTLET_DIST',
  FILTER: 'outlet-dist/FILTER',
}

export default actions
