const actions = {
  SET_STATE: 'trans-dist-status-cube-mma-mmax/SET_STATE',
  SEARCH: 'trans-dist-status-cube-mma-mmax/SEARCH',
  FILTER: 'trans-dist-status-cube-mma-mmax/FILTER',
  CANCEL: 'trans-dist-status-cube-mma-mmax/CANCEL',
  LOG_DETAIL: 'trans-dist-status-cube-mma-mmax/LOG_DETAIL',
  REPROCESS: 'trans-dist-status-cube-mma-mmax/REPROCESS',
}

export default actions
