const actions = {
  SET_STATE: 'outlet-sales-spv/SET_STATE',
  LOAD_OUTLET_SALES_SPV: 'outlet-sales-spv/LOAD_OUTLET_SALES_SPV',
  CANCEL_OUTLET_SALES_SPV: 'outlet-sales-spv/CANCEL_OUTLET_SALES_SPV',
  LOAD_FORM_CREATE: 'outlet-sales-spv/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-sales-spv/LOAD_FORM_EDIT',
  STORE_OUTLET_SALES_SPV: 'outlet-sales-spv/STORE_OUTLET_SALES_SPV',
  UPDATE_OUTLET_SALES_SPV: 'outlet-sales-spv/UPDATE_OUTLET_SALES_SPV',
  DELETE_OUTLET_SALES_SPV: 'outlet-sales-spv/DELETE_OUTLET_SALES_SPV',
  FILTER: 'outlet-sales-spv/FILTER',
  REMINDER: 'outlet-sales-spv/REMINDER',
}

export default actions
