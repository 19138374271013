import apiClient from 'services/axios'

export async function getShippingPoint(parameter) {
  return apiClient
    .get('/user/shipping-point/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createShippingPoint(parameter = {}) {
  return apiClient
    .get('/user/shipping-point/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeShippingPoint(values) {
  return apiClient
    .post('/user/shipping-point/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editShippingPoint(id, parameter = {}) {
  return apiClient
    .get(`/user/shipping-point/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateShippingPoint(id, values) {
  return apiClient
    .post(`/user/shipping-point/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteShippingPoint(id) {
  return apiClient
    .delete(`/user/shipping-point/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
