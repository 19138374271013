const actions = {
  SET_STATE: 'outlet-group/SET_STATE',
  LOAD_OUTLET_GROUP: 'outlet-group/LOAD_OUTLET_GROUP',
  CANCEL_OUTLET_GROUP: 'outlet-group/CANCEL_OUTLET_GROUP',
  LOAD_FORM_CREATE: 'outlet-group/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-group/LOAD_FORM_EDIT',
  STORE_OUTLET_GROUP: 'outlet-group/STORE_OUTLET_GROUP',
  UPDATE_OUTLET_GROUP: 'outlet-group/UPDATE_OUTLET_GROUP',
  DELETE_OUTLET_GROUP: 'outlet-group/DELETE_OUTLET_GROUP',
  UPLOAD_FILE: 'outlet-group/UPLOAD_FILE',
}

export default actions
