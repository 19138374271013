import actions from './actions'

const initialState = {
  loadingFilter: false,
  loadingMap: false,
  loadingDaily: false,
  loadingAbsen: false,
  loadingUpdateAbsen: false,
  loadingCancelAbsen: false,

  dataFilter: null,
  dataMap: null,
  dataDaily: null,
  dataAbsen: null,

  messageUpdateAbsen: null,
  messageCancelAbsen: null,

  errorUpdateAbsen: null,
  errorCancelAbsen: null,
  errorFilter: null,
  errorMap: null,
  errorDaily: null,
  errorAbsen: null,
}

export default function dashboardSfaSales(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
