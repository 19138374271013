const actions = {
  SET_STATE: 'product-primary/SET_STATE',
  SEARCH: 'product-primary/SEARCH',
  CANCEL: 'product-primary/CANCEL',
  CREATE: 'product-primary/CREATE',
  EDIT: 'product-primary/EDIT',
  STORE: 'product-primary/STORE',
  UPDATE: 'product-primary/UPDATE',
  DELETE: 'product-primary/DELETE',
  UPLOAD_FILE: 'product-primary/UPLOAD_FILE',
}

export default actions
