const actions = {
  SET_STATE: 'product-temp/SET_STATE',
  LOAD_PRODUCT_TEMP: 'product-temp/LOAD_PRODUCT_TEMP',
  CANCEL_PRODUCT_TEMP: 'product-temp/CANCEL_PRODUCT_TEMP',
  LOAD_FORM_CREATE: 'product-temp/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-temp/LOAD_FORM_EDIT',
  STORE_PRODUCT_TEMP: 'product-temp/STORE_PRODUCT_TEMP',
  UPDATE_PRODUCT_TEMP: 'product-temp/UPDATE_PRODUCT_TEMP',
  DELETE_PRODUCT_TEMP: 'product-temp/DELETE_PRODUCT_TEMP',
  DOWNLOAD_PRODUCT_TEMP: 'product-temp/DOWNLOAD_PRODUCT_TEMP',
  UPLOAD_PRODUCT_TEMP: 'product-temp/UPLOAD_PRODUCT_TEMP',
  RESET_PRODUCT_TEMP: 'product-temp/RESET_PRODUCT_TEMP',
  SAVE_PRODUCT_TEMP: 'product-temp/SAVE_PRODUCT_TEMP',
}

export default actions
