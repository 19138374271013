import apiClient from 'services/axios'

export async function getDistributorEpmBlock(parameter) {
  return apiClient
    .get('/user/distributor-epm-block/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createDistributorEpmBlock(parameter = {}) {
  return apiClient
    .get('/user/distributor-epm-block/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeDistributorEpmBlock(values) {
  return apiClient
    .post('/user/distributor-epm-block/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editDistributorEpmBlock(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor-epm-block/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateDistributorEpmBlock(id, values) {
  return apiClient
    .post(`/user/distributor-epm-block/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDistributorEpmBlock(id) {
  return apiClient
    .delete(`/user/distributor-epm-block/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/distributor-epm-block/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
