const actions = {
  SET_STATE: 'outlet-dist-deviasi/SET_STATE',
  LOAD_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/LOAD_OUTLET_DIST_DEVIASI',
  CANCEL_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/CANCEL_OUTLET_DIST_DEVIASI',
  LOAD_FORM_CREATE: 'outlet-dist-deviasi/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-dist-deviasi/LOAD_FORM_EDIT',
  STORE_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/STORE_OUTLET_DIST_DEVIASI',
  UPDATE_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/UPDATE_OUTLET_DIST_DEVIASI',
  DELETE_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/DELETE_OUTLET_DIST_DEVIASI',
  UPLOAD_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/UPLOAD_OUTLET_DIST_DEVIASI',
  DOWNLOAD_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/DOWNLOAD_OUTLET_DIST_DEVIASI',
  BLOCK_OUTLET_DIST_DEVIASI: 'outlet-dist-deviasi/BLOCK_OUTLET_DIST_DEVIASI',
  OPTION_MAPPING_NOO: 'outlet-dist-deviasi/OPTION_MAPPING_NOO',
  OPTION_MAPPING_ADD_SALESMAN: 'outlet-dist-deviasi/OPTION_MAPPING_ADD_SALESMAN',
  OPTION_MAPPING_REFERENSI: 'outlet-dist-deviasi/OPTION_MAPPING_REFERENSI',
  SET_MAPPING: 'outlet-dist-deviasi/SET_MAPPING',
  GET_OUTLET: 'outlet-dist-deviasi/GET_OUTLET',
  LIST_OUTLET: 'outlet-dist-deviasi/LIST_OUTLET',
  CANCEL_LIST_OUTLET: 'outlet-dist-deviasi/CANCEL_LIST_OUTLET',
}

export default actions
