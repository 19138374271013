import apiClient from 'services/axios'

export async function getSetting(parameter) {
  return apiClient
    .get('/user/setting/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSetting(parameter = {}) {
  return apiClient
    .get('/user/setting/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSetting(values) {
  return apiClient
    .post('/user/setting/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSetting(id, parameter = {}) {
  return apiClient
    .get(`/user/setting/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSetting(id, values) {
  return apiClient
    .post(`/user/setting/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSetting(id) {
  return apiClient
    .delete(`/user/setting/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
