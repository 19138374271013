import actions from './actions'

const initialState = {
  current_year: null,
  current_month: null,
  current_period: null,

  dataFilter: [],
  loading: false,
  error: null,
  errorForm: false,
  errorDelete: null,
  messageFormSuccess: null,
  messageDelete: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },

  dataChartSalesDiagram: null,
  loadingChartSalesDiagram: false,
  errorChartSalesDiagram: null,

  // chart ke-2
  dataChartRegisterOutlet: null,
  errorChartRegisterOutlet: null,

  dataChartActiveOutlet: null,
  errorChartActiveOutlet: null,

  dataChartEffectiveCall: null,
  errorChartEffectiveCall: null,

  dataChartLineOfProduct: null,
  errorChartLineOfProduct: null,
  loadingChartRegisterOutletCs: false,

  // chart ke-3
  dataChartAchievementBrand: null,
  errorChartAchievementBrand: null,
  loadingChartAchievementBrand: false,

  dataChartAchievementChannelSub: null,
  errorChartAchievementChannelSub: null,
  loadingChartAchievementChannelSub: false,

  // data pareto mt & gt
  dataAchievementTopParetoMt: null,
  errorAchievementTopParetoMt: null,
  loadingAchievementTopParetoMt: false,

  dataAchievementTopParetoGt: null,
  errorAchievementTopParetoGt: null,
  loadingAchievementTopParetoGt: false,

  // Channel no transaction
  dataChannelNoTransaction: null,
  errorChannelNoTransaction: null,
  loadingChannelNoTransaction: false,
}

export default function dashboardEnsevalChartReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
