import actions from './actions'

const initialState = {
  dataStock: null,
  loading: false,
  dataFilters: null,
  loadingFilter: false,
  error: null,
  errorFilter: null,
  dataOptions: null,
  loadingOption: false,
  errorOption: null,
  loadingUpload: false,
  messageUploadFile: null,
  messageUploadFileError: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
