const actions = {
  SET_STATE: 'outlet-tl-inactive/SET_STATE',
  LOAD_OUTLET_TL: 'outlet-tl-inactive/LOAD_OUTLET_TL',
  CANCEL_OUTLET_TL: 'outlet-tl-inactive/CANCEL_OUTLET_TL',
  LOAD_FORM_CREATE: 'outlet-tl-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-tl-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_TL: 'outlet-tl-inactive/STORE_OUTLET_TL',
  UPDATE_OUTLET_TL: 'outlet-tl-inactive/UPDATE_OUTLET_TL',
  OPTIONS_DELETE: 'outlet-tl-inactive/OPTIONS_DELETE',
  DELETE_OUTLET_TL: 'outlet-tl-inactive/DELETE_OUTLET_TL',
  GET_PERMISSION: 'outlet-tl-inactive/GET_PERMISSION',
  SET_PERMISSION: 'outlet-tl-inactive/SET_PERMISSION',
  RESET_PASSWORD: 'outlet-tl-inactive/RESET_PASSWORD',
  FORCE_LOGOUT: 'outlet-tl-inactive/FORCE_LOGOUT',
}

export default actions
