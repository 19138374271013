const actions = {
  SET_STATE: 'consumer-outlet-brand/SET_STATE',
  SEARCH: 'consumer-outlet-brand/SEARCH',
  CANCEL: 'consumer-outlet-brand/CANCEL',
  CREATE: 'consumer-outlet-brand/CREATE',
  EDIT: 'consumer-outlet-brand/EDIT',
  STORE: 'consumer-outlet-brand/STORE',
  UPDATE: 'consumer-outlet-brand/UPDATE',
  DELETE: 'consumer-outlet-brand/DELETE',
  UPLOAD: 'consumer-outlet-brand/UPLOAD',
  CANCEL_LIST_OUTLET: 'consumer-outlet-brand/CANCEL_LIST_OUTLET',
  LIST_OUTLET: 'consumer-outlet-brand/LIST_OUTLET',
  LIST_PRODUCT: 'consumer-outlet-brand/LIST_PRODUCT',
}

export default actions
