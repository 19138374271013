import apiClient from 'services/axios'

export async function getProductPrice(parameter) {
  return apiClient
    .get('/user/product-price/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductPrice(parameter = {}) {
  return apiClient
    .get('/user/product-price/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductPrice(values) {
  return apiClient
    .post('/user/product-price/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductPrice(id, parameter = {}) {
  return apiClient
    .get(`/user/product-price/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductPrice(id, values) {
  return apiClient
    .post(`/user/product-price/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductPrice(id) {
  return apiClient
    .delete(`/user/product-price/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get('/user/product-price/filter')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
