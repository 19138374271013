import apiClient from 'services/axios'

export async function getCategoryCeo(parameter) {
  return apiClient
    .get('/user/category-ceo/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategoryCeo(parameter = {}) {
  return apiClient
    .get('/user/category-ceo/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryCeo(values) {
  return apiClient
    .post('/user/category-ceo/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategoryCeo(id, parameter = {}) {
  return apiClient
    .get(`/user/category-ceo/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategoryCeo(id, values) {
  return apiClient
    .post(`/user/category-ceo/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategoryCeo(id) {
  return apiClient
    .delete(`/user/category-ceo/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
