import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-kindis'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadFilterDistributor : mService.loadFilter,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* KINDIS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingKindis: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(distributor.authorized ? mService.kindisDistributor : mService.kindis, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingKindis: false,
      errorKindis: null,
    }
    if (response) {
      newPayload.dataKindis = response.data
    } else {
      newPayload.errorKindis = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* MONITORING({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMonitoring: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.monitoringDistributor : mService.monitoring,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMonitoring: false,
      errorMonitoring: null,
    }
    if (response) {
      newPayload.dataMonitoring = response.data
    } else {
      newPayload.errorMonitoring = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PARETTO_GT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingParettoGt: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.topParettoGtDistributor : mService.topParettoGt,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingParettoGt: false,
      errorParettoGt: null,
    }
    if (response) {
      newPayload.dataParettoGt = response.data
    } else {
      newPayload.errorParettoGt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PARETTO_MT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingParettoMt: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.topParettoMtDistributor : mService.topParettoMt,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingParettoMt: false,
      errorParettoMt: null,
    }
    if (response) {
      newPayload.dataParettoMt = response.data
    } else {
      newPayload.errorParettoMt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.KINDIS, KINDIS),
    takeLatest(actions.MONITORING, MONITORING),
    takeLatest(actions.PARETTO_GT, PARETTO_GT),
    takeLatest(actions.PARETTO_MT, PARETTO_MT),
  ])
}
