const actions = {
  SET_STATE: 'product-price/SET_STATE',
  SEARCH: 'product-price/SEARCH',
  CANCEL: 'product-price/CANCEL',
  CREATE: 'product-price/CREATE',
  EDIT: 'product-price/EDIT',
  STORE: 'product-price/STORE',
  UPDATE: 'product-price/UPDATE',
  DELETE: 'product-price/DELETE',
  FILTER: 'product-price/FILTER',
}

export default actions
