const actions = {
  SET_STATE: 'category-acc/SET_STATE',
  LOAD_CATEGORY_ACC: 'category-acc/LOAD_CATEGORY_ACC',
  CANCEL_CATEGORY_ACC: 'category-acc/CANCEL_CATEGORY_ACC',
  LOAD_FORM_CREATE: 'category-acc/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-acc/LOAD_FORM_EDIT',
  STORE_CATEGORY_ACC: 'category-acc/STORE_CATEGORY_ACC',
  UPDATE_CATEGORY_ACC: 'category-acc/UPDATE_CATEGORY_ACC',
  DELETE_CATEGORY_ACC: 'category-acc/DELETE_CATEGORY_ACC',
}

export default actions
