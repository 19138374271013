import apiClient from 'services/axios'

export async function getSpgPosition(parameter) {
  return apiClient
    .get('/user/spg-position/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSpgPosition(parameter = {}) {
  return apiClient
    .get('/user/spg-position/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSpgPosition(values) {
  return apiClient
    .post('/user/spg-position/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSpgPosition(id, parameter = {}) {
  return apiClient
    .get(`/user/spg-position/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSpgPosition(id, values) {
  return apiClient
    .post(`/user/spg-position/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSpgPosition(id) {
  return apiClient
    .delete(`/user/spg-position/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
