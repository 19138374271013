const actions = {
  SET_STATE: 'dashboard-sfa-ro/SET_STATE',
  LOAD_FILTER: 'dashboard-sfa-ro/LOAD_FILTER',

  LOAD_MAP_DATA: 'dashboard-sfa-ro/LOAD_MAP_DATA',
  LOAD_TARGET_CALL: 'dashboard-sfa-ro/LOAD_TARGET_CALL',

  CANCEL: 'dashboard-sfa-ro/CANCEL',
}

export default actions
