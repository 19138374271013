import apiClient from 'services/axios'

export async function getProductDist(parameter) {
  return apiClient
    .get('/user/product-dist/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductDist(parameter = {}) {
  return apiClient
    .get('/user/product-dist/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductDist(values) {
  return apiClient
    .post('/user/product-dist/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductDist(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductDist(id, values) {
  return apiClient
    .post(`/user/product-dist/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDist(id) {
  return apiClient
    .delete(`/user/product-dist/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function permissionProductDist(parameter = {}) {
  return apiClient
    .get(`/user/product-dist/permission`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get('/user/product-dist/filter')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/product-dist/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminderBlock(values) {
  return apiClient
    .post(`/user/product-dist-block/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
