import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as userListService from 'services/webservice/user-access/user-list'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_USER_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.userListInactive)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(userListService.getUserList, payload),
    cancel: take(actions.CANCEL_USER_LIST),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataUserList = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataUserListCreate: null,
    dataUserListEdit: null,
    dataUserListPermission: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  // const isCreate = action.type === actions.LOAD_FORM_CREATE
  switch (action.type) {
    case actions.LOAD_FORM_CREATE:
      result = yield race({
        data: call(userListService.createUserList),
        cancel: take(actions.CANCEL_USER_LIST),
      })
      break
    case actions.LOAD_FORM_EDIT:
      result = yield race({
        data: call(userListService.editUserList, payload.id),
        cancel: take(actions.CANCEL_USER_LIST),
      })
      break
    case actions.GET_USER_PERMISSION:
      result = yield race({
        data: call(userListService.getUserPermissions, payload.id),
        cancel: take(actions.CANCEL_USER_LIST),
      })
      break
    default:
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.LOAD_FORM_CREATE:
        newPayload.dataUserListCreate = response ? response.data : null
        break
      case actions.LOAD_FORM_EDIT:
        newPayload.dataUserListEdit = response ? response.data : null
        break
      case actions.GET_USER_PERMISSION:
        newPayload.dataUserListPermission = response ? response.data : null
        break
      default:
    }

    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_USER_LIST() {
  while (true) {
    const { payload } = yield take(actions.STORE_USER_LIST)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(userListService.storeUserList, payload),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_USER_LIST() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_USER_LIST)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(userListService.updateUserList, currentId, payload),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_USER_LIST() {
  while (true) {
    const { payload } = yield take(actions.DELETE_USER_LIST)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(userListService.deleteUserList, payload.id),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_USER_PERMISSION() {
  while (true) {
    const { payload } = yield take(actions.SET_USER_PERMISSION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(userListService.setUserPermissions, currentId, payload),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* FORCE_LOGOUT() {
  while (true) {
    const { payload } = yield take(actions.FORCE_LOGOUT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForceLogout: true,
        messageForceLogoutError: null,
        messageForceLogoutSuccess: null,
      },
    })
    const result = yield race({
      data: call(userListService.forceLogout, payload.id),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingForceLogout: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageForceLogoutSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageForceLogoutError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET_PASSWORD() {
  while (true) {
    const { payload } = yield take(actions.RESET_PASSWORD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingResetPassword: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(userListService.resetPassword, currentId, payload),
      cancel: take(actions.CANCEL_USER_LIST),
    })
    if (result.data) {
      const newPayload = {
        loadingResetPassword: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageResetPasswordSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageResetPasswordError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_USER_LIST, GET_USER_LIST),
    takeLatest(
      [actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT, actions.GET_USER_PERMISSION],
      GET_FORM,
    ),
    STORE_USER_LIST(),
    UPDATE_USER_LIST(),
    DELETE_USER_LIST(),
    SET_USER_PERMISSION(),
    FORCE_LOGOUT(),
    RESET_PASSWORD(),
  ])
}
