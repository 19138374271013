const actions = {
  SET_STATE: 'brand-inactive/SET_STATE',
  LOAD_BRAND: 'brand-inactive/LOAD_BRAND',
  CANCEL_BRAND: 'brand-inactive/CANCEL_BRAND',
  LOAD_FORM_CREATE: 'brand-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'brand-inactive/LOAD_FORM_EDIT',
  STORE_BRAND: 'brand-inactive/STORE_BRAND',
  UPDATE_BRAND: 'brand-inactive/UPDATE_BRAND',
  DELETE_BRAND: 'brand-inactive/DELETE_BRAND',
}

export default actions
