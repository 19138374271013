import apiClient from 'services/axios'

export async function filter(parameter) {
  return apiClient
    .get('/user/report-no-trans-four-mma-outlet/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function lastUpdatedFile(parameter) {
  return apiClient
    .get('/user/report-no-trans-four-mma-outlet/last-updated-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/report-no-trans-four-mma-outlet/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reprocessFile(values) {
  return apiClient
    .post('/user/report-no-trans-four-mma-outlet/reprocess-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
