const actions = {
  SET_STATE: 'outlet-sales-type-inactive/SET_STATE',
  LOAD_SALES_TYPE: 'outlet-sales-type-inactive/LOAD_SALES_TYPE',
  CANCEL_SALES_TYPE: 'outlet-sales-type-inactive/CANCEL_SALES_TYPE',
  LOAD_FORM_CREATE: 'outlet-sales-type-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-sales-type-inactive/LOAD_FORM_EDIT',
  STORE_SALES_TYPE: 'outlet-sales-type-inactive/STORE_SALES_TYPE',
  UPDATE_SALES_TYPE: 'outlet-sales-type-inactive/UPDATE_SALES_TYPE',
  DELETE_SALES_TYPE: 'outlet-sales-type-inactive/DELETE_SALES_TYPE',
}

export default actions
