import { all, takeEvery, put, call, take, race } from 'redux-saga/effects'
import { notification } from 'antd'
import * as rect from 'services/rect-distributors'
import { getErrorMessage } from 'services/helper/message'
import { push } from 'connected-react-router'
import store from 'store'
import actions from './actions'

const mapAuthProviders = {
  rect: {
    login: rect.login,
    currentAccount: rect.currentAccount,
    logout: rect.logout,
    updatePassword: rect.updatePassword,
    resetPassword: rect.resetPassword,
    updateProfile: rect.updateProfile,
    updateProfileImage: rect.updateProfileImage,
  },
}

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user-distributor/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const result = yield call(mapAuthProviders.rect.login, username, password)
  let { response } = result
  const { error } = result
  if (response) {
    response = response.data
    store.set('token', response.token)
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        authorized: true,
        ...setUserProfile(response),
        loading: false,
        loadingLogout: false,
        loadingInit: false,
        error: null,
        errorInit: null,
      },
    })
    yield put({
      type: 'user-distributor/AUTHENTICATED',
    })
    notification.success({
      message: 'SUCCESS',
      description: 'Successfully login to your distributor account!',
    })
  } else {
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loading: false,
        error,
      },
    })
  }
}

export function* UPDATE_PASSWORD() {
  while (true) {
    const action = yield take('user-distributor/UPDATE_PASSWORD')
    const { payload } = action
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loadingUpdatePassword: true,
        clearFormPassword: false,
        errorForm: null,
      },
    })
    const { result } = yield race({
      result: call(mapAuthProviders.rect.updatePassword, payload),
      cancel: take('user-distributor/CANCEL_PROFILE'),
    })
    const { response, error } = result
    if (response) {
      notification.success({
        message: 'SUCCESS',
        description: response.data.message,
      })
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loadingUpdatePassword: false,
          clearFormPassword: true,
          errorForm: null,
          has_change_default_password: 1,
        },
      })
    } else {
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loadingUpdatePassword: false,
          errorForm: error,
        },
      })
    }
  }
}

export function* UPDATE_PROFILE() {
  while (true) {
    const action = yield take('user-distributor/UPDATE_PROFILE')
    const { payload } = action
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loadingProfile: true,
        errorForm: null,
      },
    })
    const { result } = yield race({
      result: call(mapAuthProviders.rect.updateProfile, payload),
      cancel: take('user-distributor/CANCEL_PROFILE'),
    })
    if (result) {
      const { response, error } = result
      if (response) {
        notification.success({
          message: 'SUCCESS',
          description: response.data.message,
        })
      }
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          ...setUserProfile(response ? response.data.data || null : null),
          loadingProfile: false,
          errorForm: error,
        },
      })
    }
  }
}

function setUserProfile(response) {
  if (!response) {
    return {}
  }
  return {
    id: response.id,
    nik: response.nik,
    user_name: response.user_name,
    user_phone: response.user_phone,
    username: response.username,
    user_image: response.user_image,
    email: response.user_email,
    role: response.role,
    has_change_default_password: response.has_change_default_password,
  }
}

export function* LOAD_PROFILE() {
  // if (window.location.href.indexOf('/distributor/') > -1) {
  while (true) {
    yield take('user-distributor/LOAD_PROFILE')
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loadingProfile: true,
        errorForm: null,
      },
    })
    const { result } = yield race({
      result: call(mapAuthProviders.rect.currentAccount),
      cancel: take('user-distributor/CANCEL_PROFILE'),
    })
    if (result) {
      let { response } = result
      const { error } = result
      if (response) {
        response = response.data
        yield put({
          type: 'user-distributor/SET_STATE',
          payload: {
            authorized: true,
            // ...setUserProfile(response),
            ...response,
            email: response.dist_child_email,
            user_name: response.dist_child_admin_name || response.username,
            loadingProfile: false,
            dataLoadProfile: response,
            isProfileLoaded: true,
            errorProfile: null,
          },
        })
      } else {
        yield put({
          type: 'user-distributor/SET_STATE',
          payload: {
            loadingProfile: false,
            errorProfile: error,
          },
        })
      }
    }
  }
  // }
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (window.location.href.indexOf('/distributor/') > -1) {
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loadingInit: true,
      },
    })
    const result = yield call(mapAuthProviders.rect.currentAccount)
    let { response } = result
    const { error } = result
    if (response) {
      response = response.data
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          authorized: true,
          ...setUserProfile(response),
          dataLoadProfile: response,
          loadingInit: false,
          errorInit: null,
        },
      })
    } else {
      const { response: errorResponse } = error
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loadingInit: false,
          errorInit: errorResponse && errorResponse.status === 401 ? null : error,
        },
      })
    }
  }
}

export function* LOGOUT() {
  yield put({
    type: 'user-distributor/SET_STATE',
    payload: {
      loadingLogout: true,
    },
  })
  const { response, error } = yield call(mapAuthProviders.rect.logout)

  if (response) {
    yield put({
      type: 'user-distributor/UNAUTHENTICATED',
    })
    // notification.success({
    //   message: 'SUCCESS',
    //   description: 'Successfully log out distributor from your account!',
    // })
    yield put(push('/auth-admin/login'))
  } else {
    const { response: errorResponse } = error
    if (!(errorResponse && errorResponse.status === 401)) {
      const defaultMessage = getErrorMessage(error, false)
      notification.error({
        message: 'FAILURE',
        description: defaultMessage,
      })

      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loadingLogout: false,
        },
      })
    }
  }
}

function* UPDATE_PROFILE_IMAGE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PROFILE_IMAGE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingImage: true,
      },
    })
    const result = yield race({
      data: call(mapAuthProviders.rect.updateProfileImage, payload),
      cancel: take(actions.CANCEL_PROFILE),
    })
    if (result.data) {
      const { response, error } = result.data
      const newPayload = {
        loadingImage: false,
      }
      if (response) {
        newPayload.messageUploadFile = response.data
        newPayload.user_image = response.data.data.user_image
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export function* RESET_PASSWORD() {
  while (true) {
    const action = yield take('user-distributor/RESET_PASSWORD')
    const { payload } = action
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(mapAuthProviders.rect.resetPassword, payload)
    let { response } = result
    const { error } = result
    if (response) {
      response = response.data
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loading: false,
          error: null,
        },
      })
      notification.success({
        message: 'SUCCESS',
        description: response.message,
      })
      yield put(push('/auth-admin/login'))
    } else {
      // const defaultMessage = getErrorMessage(error, false)
      // notification.error({
      //   message: 'FAILURE',
      //   description: defaultMessage,
      // })
      yield put({
        type: 'user-distributor/SET_STATE',
        payload: {
          loading: false,
          error,
        },
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    LOAD_PROFILE(),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    // takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    // LOAD_CURRENT_ACCOUNT(),
    UPDATE_PASSWORD(),
    RESET_PASSWORD(),
    UPDATE_PROFILE(),
    UPDATE_PROFILE_IMAGE(),
  ])
}
