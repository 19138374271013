const actions = {
  SET_STATE: 'product/SET_STATE',
  LOAD_PRODUCT: 'product/LOAD_PRODUCT',
  CANCEL_PRODUCT: 'product/CANCEL_PRODUCT',
  LOAD_FORM_CREATE: 'product/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product/LOAD_FORM_EDIT',
  STORE_PRODUCT: 'product/STORE_PRODUCT',
  UPDATE_PRODUCT: 'product/UPDATE_PRODUCT',
  DELETE_PRODUCT: 'product/DELETE_PRODUCT',
  FILTER_PRODUCT: 'product/FILTER_PRODUCT',
  PERMISSION_PRODUCT: 'product/PERMISSION_PRODUCT',
  REMINDER: 'product/REMINDER',
}

export default actions
