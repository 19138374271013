const actions = {
  SET_STATE: 'consumer-distributor-range/SET_STATE',
  SEARCH: 'consumer-distributor-range/SEARCH',
  CANCEL: 'consumer-distributor-range/CANCEL',
  CREATE: 'consumer-distributor-range/CREATE',
  EDIT: 'consumer-distributor-range/EDIT',
  STORE: 'consumer-distributor-range/STORE',
  UPDATE: 'consumer-distributor-range/UPDATE',
  DELETE: 'consumer-distributor-range/DELETE',
  UPLOAD: 'consumer-distributor-range/UPLOAD',
  LIST_PRODUCT: 'consumer-distributor-range/LIST_PRODUCT',
}

export default actions
