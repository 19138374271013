const actions = {
  SET_STATE: 'variant/SET_STATE',
  LOAD_VARIANT: 'variant/LOAD_VARIANT',
  CANCEL_VARIANT: 'variant/CANCEL_VARIANT',
  LOAD_FORM_CREATE: 'variant/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'variant/LOAD_FORM_EDIT',
  STORE_VARIANT: 'variant/STORE_VARIANT',
  UPDATE_VARIANT: 'variant/UPDATE_VARIANT',
  DELETE_VARIANT: 'variant/DELETE_VARIANT',
}

export default actions
