import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/outlet'
// import { getErrorMessage } from 'services/helper/message'
import { saveAs } from 'file-saver'
import actions from './actions'

function* GET_OUTLET_ACCESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(mService.getOutlet, payload),
    cancel: take(actions.CANCEL_OUTLET_ACCESS),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataOutletAccess = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* FILTER_OUTLET_ACCESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })
  const result = yield race({
    data: call(mService.filterOutlet, payload),
    cancel: take(actions.CANCEL_OUTLET_ACCESS),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataOutletAccessFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PERMISSION_OUTLET_ACCESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(mService.permissionOutlet, payload),
    cancel: take(actions.CANCEL_OUTLET_ACCESS),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataOutletAccessPermission = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* REMINDER() {
  while (true) {
    const { payload } = yield take(actions.REMINDER)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingReminder: true,
      },
    })
    const result = yield race({
      data: call(mService.reminder, payload),
      cancel: take(actions.CANCEL_OUTLET_ACCESS),
    })
    if (result.data) {
      const newPayload = {
        loadingReminder: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageReminderSuccess = response.data
      }
      newPayload.messageReminderError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DOWNLOAD_ALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownloadAll: true,
      messageDownloadAll: null,
      error: null,
    },
  })
  const result = yield race({
    data: call(mService.downloadAll, payload),
    cancel: take(actions.CANCEL_OUTLET_ACCESS),
  })
  if (result.data) {
    const newPayload = {
      loadingDownloadAll: false,
      messageDownloadAll: null,
      error: null,
    }
    const { response, error } = result.data
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadAll = `File ${filename.replaceAll(
        '"',
        '',
      )} is successfully downloaded!`
    }
    newPayload.error = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD_ALL_INACTIVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownloadAllInactive: true,
      messageDownloadAll: null,
      error: null,
    },
  })
  const result = yield race({
    data: call(mService.downloadAllInactive, payload),
    cancel: take(actions.CANCEL_OUTLET_ACCESS),
  })
  if (result.data) {
    const newPayload = {
      loadingDownloadAllInactive: false,
      messageDownloadAllInactive: null,
      error: null,
    }
    const { response, error } = result.data
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadAllInactive = `File ${filename.replaceAll(
        '"',
        '',
      )} is successfully downloaded!`
    }
    newPayload.error = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_OUTLET_ACCESS, GET_OUTLET_ACCESS),
    takeLatest(actions.FILTER_OUTLET_ACCESS, FILTER_OUTLET_ACCESS),
    takeLatest(actions.PERMISSION_OUTLET_ACCESS, PERMISSION_OUTLET_ACCESS),
    takeLatest(actions.DOWNLOAD_ALL, DOWNLOAD_ALL),
    takeLatest(actions.DOWNLOAD_ALL_INACTIVE, DOWNLOAD_ALL_INACTIVE),
    REMINDER(),
  ])
}
