const actions = {
  SET_STATE: 'report-accuration-pjp/SET_STATE',
  SEARCH: 'report-accuration-pjp/SEARCH',
  FILTER: 'report-accuration-pjp/FILTER',
  CANCEL: 'report-accuration-pjp/CANCEL',
  DOWNLOAD: 'report-accuration-pjp/DOWNLOAD',
  REPROCESS_FILE: 'report-accuration-pjp/REPROCESS_FILE',
  LAST_UPDATED: 'report-accuration-pjp/LAST_UPDATED',
}

export default actions
