const actions = {
  SET_STATE: 'brand-business/SET_STATE',
  LOAD_BRAND_BUSINESS: 'brand-business/LOAD_BRAND_BUSINESS',
  LOAD_FORM_CREATE: 'brand-business/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'brand-business/LOAD_FORM_EDIT',
  STORE_BRAND_BUSINESS: 'brand-business/STORE_BRAND_BUSINESS',
  UPDATE_BRAND_BUSINESS: 'brand-business/UPDATE_BRAND_BUSINESS',
  DELETE_BRAND_BUSINESS: 'brand-business/DELETE_BRAND_BUSINESS',
  CANCEL_BRAND_BUSINESS: 'brand-business/CANCEL_BRAND_BUSINESS',
}

export default actions
