import apiClient from 'services/axios'

export async function getProductDist(parameter) {
  return apiClient
    .get('/distributor/product-dist/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/distributor/product-dist/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadFile(parameter = {}) {
  return apiClient
    .get(`/distributor/product-dist/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
