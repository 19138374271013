import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as reportService from 'services/webservice/user-access/report-mail'
import actions from './actions'

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(reportService.filter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilters = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SEND() {
  while (true) {
    const { payload } = yield take(actions.SEND)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        messageSuccess: null,
      },
    })
    const result = yield race({
      data: call(reportService.send, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loading: false,
        error: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageSuccess = response.data.message
      }
      newPayload.messageError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FILTER, FILTER), SEND()])
}
