import apiClient from 'services/axios'

export async function getDistributorPrimary(parameter) {
  return apiClient
    .get('/user/distributor-primary/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createDistributorPrimary(parameter = {}) {
  return apiClient
    .get('/user/distributor-primary/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeDistributorPrimary(values) {
  return apiClient
    .post('/user/distributor-primary/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editDistributorPrimary(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor-primary/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateDistributorPrimary(id, values) {
  return apiClient
    .post(`/user/distributor-primary/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDistributorPrimary(id) {
  return apiClient
    .delete(`/user/distributor-primary/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/distributor-primary/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
