const actions = {
  SET_STATE: 'user-list/SET_STATE',
  LOAD_USER_LIST: 'user-list/LOAD_USER_LIST',
  CANCEL_USER_LIST: 'user-list/CANCEL_USER_LIST',
  LOAD_FORM_CREATE: 'user-list/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'user-list/LOAD_FORM_EDIT',
  STORE_USER_LIST: 'user-list/STORE_USER_LIST',
  UPDATE_USER_LIST: 'user-list/UPDATE_USER_LIST',
  DELETE_USER_LIST: 'user-list/DELETE_USER_LIST',
  GET_USER_PERMISSION: 'user-list/GET_USER_PERMISSION',
  SET_USER_PERMISSION: 'user-list/SET_USER_PERMISSION',
  FORCE_LOGOUT: 'user-list/FORCE_LOGOUT',
  RESET_PASSWORD: 'user-list/RESET_PASSWORD',
  FILTER: 'user-list/FILTER',
}

export default actions
