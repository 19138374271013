import apiClient from 'services/axios'

export async function getOutletGroup(parameter) {
  return apiClient
    .get('/user/outlet-group/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletGroup(parameter = {}) {
  return apiClient
    .get('/user/outlet-group/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletGroup(values) {
  return apiClient
    .post('/user/outlet-group/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletGroup(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-group/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletGroup(id, values) {
  return apiClient
    .post(`/user/outlet-group/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletGroup(id) {
  return apiClient
    .delete(`/user/outlet-group/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/outlet-group/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
