const actions = {
  SET_STATE: 'consumer-outlet-product/SET_STATE',
  SEARCH: 'consumer-outlet-product/SEARCH',
  CANCEL: 'consumer-outlet-product/CANCEL',
  CREATE: 'consumer-outlet-product/CREATE',
  EDIT: 'consumer-outlet-product/EDIT',
  STORE: 'consumer-outlet-product/STORE',
  UPDATE: 'consumer-outlet-product/UPDATE',
  DELETE: 'consumer-outlet-product/DELETE',
  UPLOAD: 'consumer-outlet-product/UPLOAD',
  CANCEL_LIST_OUTLET: 'consumer-outlet-product/CANCEL_LIST_OUTLET',
  LIST_OUTLET: 'consumer-outlet-product/LIST_OUTLET',
  LIST_PRODUCT: 'consumer-outlet-product/LIST_PRODUCT',
}

export default actions
