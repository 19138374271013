const actions = {
  SET_STATE: 'consumer-outlet-product-bundle/SET_STATE',
  SEARCH: 'consumer-outlet-product-bundle/SEARCH',
  CANCEL: 'consumer-outlet-product-bundle/CANCEL',
  CREATE: 'consumer-outlet-product-bundle/CREATE',
  EDIT: 'consumer-outlet-product-bundle/EDIT',
  STORE: 'consumer-outlet-product-bundle/STORE',
  UPDATE: 'consumer-outlet-product-bundle/UPDATE',
  DELETE: 'consumer-outlet-product-bundle/DELETE',
  UPLOAD: 'consumer-outlet-product-bundle/UPLOAD',
  CANCEL_LIST_OUTLET: 'consumer-outlet-product-bundle/CANCEL_LIST_OUTLET',
  LIST_OUTLET: 'consumer-outlet-product-bundle/LIST_OUTLET',
  LIST_PRODUCT: 'consumer-outlet-product-bundle/LIST_PRODUCT',
}

export default actions
