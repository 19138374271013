import apiClient from 'services/axios'

export async function getConsumerOutletBrand(parameter) {
  return apiClient
    .get('/user/sfa-consumer-outlet-brand/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerOutletBrand(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-outlet-brand/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerOutletBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-brand/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerOutletBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-brand/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerOutletBrand(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-outlet-brand/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerOutletBrand(id) {
  return apiClient
    .delete(`/user/sfa-consumer-outlet-brand/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerOutletBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-brand/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-brand/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-brand/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
