const actions = {
  SET_STATE: 'product-dist-deviasi/SET_STATE',
  LOAD_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/LOAD_PRODUCT_DIST_DEVIASI',
  CANCEL_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/CANCEL_PRODUCT_DIST_DEVIASI',
  LOAD_FORM_CREATE: 'product-dist-deviasi/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist-deviasi/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/STORE_PRODUCT_DIST_DEVIASI',
  UPDATE_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/UPDATE_PRODUCT_DIST_DEVIASI',
  DELETE_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/DELETE_PRODUCT_DIST_DEVIASI',
  DOWNLOAD_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/DOWNLOAD_PRODUCT_DIST_DEVIASI',
  UPLOAD_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/UPLOAD_PRODUCT_DIST_DEVIASI',
  BLOCK_PRODUCT_DIST_DEVIASI: 'product-dist-deviasi/BLOCK_PRODUCT_DIST_DEVIASI',
  OPTION_MAPPING: 'product-dist-deviasi/OPTION_MAPPING',
  SET_MAPPING: 'product-dist-deviasi/SET_MAPPING',
}

export default actions
