import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/trans-dist-status-cube-mma-mmax/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function logDetail(parameter) {
  const cubeId = parameter.id
  return apiClient
    .get(`/user/trans-dist-status-cube-mma-mmax/detail-log/${cubeId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters(parameter) {
  return apiClient
    .get('/user/trans-dist-status-cube-mma-mmax/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reprocess(values) {
  return apiClient
    .post('/user/trans-dist-status-cube-mma-mmax/reprocess', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
