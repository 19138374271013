import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel-product-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset() {
  return apiClient
    .delete(`/user/sfa-consumer-channel-product-temp/reset`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function save() {
  return apiClient
    .post(`/user/sfa-consumer-channel-product-temp/save`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
