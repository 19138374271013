const actions = {
  SET_STATE: 'product-range/SET_STATE',
  LOAD_PRODUCT_RANGE: 'product-range/LOAD_PRODUCT_RANGE',
  CANCEL_PRODUCT_RANGE: 'product-range/CANCEL_PRODUCT_RANGE',
  LOAD_FORM_CREATE: 'product-range/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-range/LOAD_FORM_EDIT',
  STORE_PRODUCT_RANGE: 'product-range/STORE_PRODUCT_RANGE',
  UPDATE_PRODUCT_RANGE: 'product-range/UPDATE_PRODUCT_RANGE',
  DELETE_PRODUCT_RANGE: 'product-range/DELETE_PRODUCT_RANGE',
}

export default actions
