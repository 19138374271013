import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/stock'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_STOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.stock)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(mService.getStock, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.dataStock = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* FILTER_STOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })
  const result = yield race({
    data: call(mService.filterStock, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilters = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTION_STOCK({ payload }) {
  const { reset } = payload || {}
  const payloadLoading = {
    loadingOption: true,
  }
  if (reset) {
    payloadLoading.errorOption = null
    payloadLoading.dataOptions = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: payloadLoading,
  })
  const result = yield race({
    data: call(mService.optionsUploadStock, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingOption: false,
      errorOption: null,
    }
    if (response) {
      newPayload.dataOptions = response.data
    } else {
      newPayload.errorOption = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingUpload: true,
        messageUploadFile: null,
      },
    })
    const result = yield race({
      data: call(mService.uploadStock, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingUpload: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, GET_STOCK),
    takeLatest(actions.FILTER, FILTER_STOCK),
    takeLatest(actions.OPTION, OPTION_STOCK),
    UPLOAD(),
  ])
}
