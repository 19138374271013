const actions = {
  SET_STATE: 'outlet-tl-spv-inactive/SET_STATE',
  LOAD_OUTLET_TL_SPV: 'outlet-tl-spv-inactive/LOAD_OUTLET_TL_SPV',
  CANCEL_OUTLET_TL_SPV: 'outlet-tl-spv-inactive/CANCEL_OUTLET_TL_SPV',
  LOAD_FORM_CREATE: 'outlet-tl-spv-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-tl-spv-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_TL_SPV: 'outlet-tl-spv-inactive/STORE_OUTLET_TL_SPV',
  UPDATE_OUTLET_TL_SPV: 'outlet-tl-spv-inactive/UPDATE_OUTLET_TL_SPV',
  OPTIONS_DELETE: 'outlet-tl-spv-inactive/OPTIONS_DELETE',
  DELETE_OUTLET_TL_SPV: 'outlet-tl-spv-inactive/DELETE_OUTLET_TL_SPV',
  GET_PERMISSION: 'outlet-tl-spv-inactive/GET_PERMISSION',
  SET_PERMISSION: 'outlet-tl-spv-inactive/SET_PERMISSION',
  RESET_PASSWORD: 'outlet-tl-spv-inactive/RESET_PASSWORD',
  FORCE_LOGOUT: 'outlet-tl-spv-inactive/FORCE_LOGOUT',
}

export default actions
