import apiClient from 'services/axios'

export async function getCategoryStock(parameter) {
  return apiClient
    .get('/user/category-stock/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategoryStock(parameter = {}) {
  return apiClient
    .get('/user/category-stock/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryStock(values) {
  return apiClient
    .post('/user/category-stock/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategoryStock(id, parameter = {}) {
  return apiClient
    .get(`/user/category-stock/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategoryStock(id, values) {
  return apiClient
    .post(`/user/category-stock/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategoryStock(id) {
  return apiClient
    .delete(`/user/category-stock/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
