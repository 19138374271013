import actions from './actions'

const initialState = {
  dataDistUploadDaily: null,
  dataDistUploadDailyCreate: null,
  dataDistUploadDailyEdit: null,
  dataDetailLogs: null,
  dataOptions: null,
  loading: false,
  loadingFilter: false,
  loadingDownload: false,
  errorDownload: false,
  errorFilter: false,
  messageError: null,
  messageSuccess: null,
  messageDownload: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function transDistUploadDailyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
