const actions = {
  SET_STATE: 'target-secondary-dist/SET_STATE',
  SEARCH: 'target-secondary-dist/SEARCH',
  CANCEL: 'target-secondary-dist/CANCEL',
  CREATE: 'target-secondary-dist/CREATE',
  EDIT: 'target-secondary-dist/EDIT',
  STORE: 'target-secondary-dist/STORE',
  UPDATE: 'target-secondary-dist/UPDATE',
  DELETE: 'target-secondary-dist/DELETE',
  FILTER: 'target-secondary-dist/FILTER',
  UPLOAD_FILE: 'target-secondary-dist/UPLOAD_FILE',
  REMINDER: 'target-secondary-dist/REMINDER',
}

export default actions
