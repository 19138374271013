const actions = {
  SET_STATE: 'product-listing/SET_STATE',
  SEARCH: 'product-listing/SEARCH',
  CANCEL: 'product-listing/CANCEL',
  CANCEL_LIST_OUTLET: 'product-listing/CANCEL_LIST_OUTLET',
  CREATE: 'product-listing/CREATE',
  EDIT: 'product-listing/EDIT',
  STORE: 'product-listing/STORE',
  UPDATE: 'product-listing/UPDATE',
  DELETE: 'product-listing/DELETE',
  FILTER: 'product-listing/FILTER',
  UPLOAD_FILE: 'product-listing/UPLOAD_FILE',
  LIST_OUTLET: 'product-listing/LIST_OUTLET',
  LIST_PRODUCT: 'product-listing/LIST_PRODUCT',
}

export default actions
