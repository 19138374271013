import apiClient from 'services/axios'

export async function getOutletPriority(parameter) {
  return apiClient
    .get('/user/outlet-priority/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletPriority(parameter = {}) {
  return apiClient
    .get('/user/outlet-priority/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletPriority(values) {
  return apiClient
    .post('/user/outlet-priority/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletPriority(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-priority/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletPriority(id, values) {
  return apiClient
    .post(`/user/outlet-priority/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletPriority(id) {
  return apiClient
    .delete(`/user/outlet-priority/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/outlet-priority/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/outlet-priority/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/outlet-priority/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/outlet-priority/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
