const actions = {
  SET_STATE: 'pk-module-inactive/SET_STATE',
  SEARCH: 'pk-module-inactive/SEARCH',
  CANCEL: 'pk-module-inactive/CANCEL',
  CREATE: 'pk-module-inactive/CREATE',
  EDIT: 'pk-module-inactive/EDIT',
  STORE: 'pk-module-inactive/STORE',
  UPDATE: 'pk-module-inactive/UPDATE',
  DELETE: 'pk-module-inactive/DELETE',
}

export default actions
