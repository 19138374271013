const actions = {
  SET_STATE: 'product-dist/SET_STATE',
  LOAD_PRODUCT_DIST: 'product-dist/LOAD_PRODUCT_DIST',
  CANCEL_PRODUCT_DIST: 'product-dist/CANCEL_PRODUCT_DIST',
  LOAD_FORM_CREATE: 'product-dist/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST: 'product-dist/STORE_PRODUCT_DIST',
  UPDATE_PRODUCT_DIST: 'product-dist/UPDATE_PRODUCT_DIST',
  DELETE_PRODUCT_DIST: 'product-dist/DELETE_PRODUCT_DIST',
  NEED_RELOAD: 'product-dist/NEED_RELOAD',
  PERMISSION_PRODUCT_DIST: 'product-dist/PERMISSION_PRODUCT_DIST',
  FILTER: 'product-dist/FILTER',
  REMINDER: 'product-dist/REMINDER',
  REMINDER_BLOCK: 'product-dist/REMINDER_BLOCK',
}

export default actions
