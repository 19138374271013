import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as service from 'services/webservice/user-access/outlet-spg'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.outletSpgInactive)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(service.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataSearch = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataCreate: null,
    dataEdit: null,
    dataPermission: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  switch (action.type) {
    case actions.CREATE:
      result = yield race({
        data: call(service.create),
        cancel: take(actions.CANCEL),
      })
      break
    case actions.EDIT:
      result = yield race({
        data: call(service.edit, payload.id),
        cancel: take(actions.CANCEL),
      })
      break
    case actions.GET_PERMISSION:
      result = yield race({
        data: call(service.getPermissions, payload.id),
        cancel: take(actions.CANCEL),
      })
      break
    default:
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.CREATE:
        newPayload.dataCreate = response ? response.data : null
        break
      case actions.EDIT:
        newPayload.dataEdit = response ? response.data : null
        break
      case actions.GET_PERMISSION:
        newPayload.dataPermission = response ? response.data : null
        break
      default:
    }

    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTIONS_DELETE(action) {
  const { payload } = action
  let newPayload = {
    loadingOptionsDelete: true,
    dataDelete: null,
  }

  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })

  const result = yield race({
    data: call(service.optionsDelete, payload.id),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    newPayload = {
      loadingOptionsDelete: false,
    }
    const { response, error } = result.data
    newPayload.dataDelete = response ? response.data : null

    newPayload.errorOptionsDelete = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE() {
  while (true) {
    const { payload } = yield take(actions.STORE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(service.store, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(service.update, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE() {
  while (true) {
    const { payload } = yield take(actions.DELETE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(service.deleteData, payload.id, { dist_child_id: payload.dist_child_id }),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_PERMISSION() {
  while (true) {
    const { payload } = yield take(actions.SET_PERMISSION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(service.setPermissions, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* FORCE_LOGOUT() {
  while (true) {
    const { payload } = yield take(actions.FORCE_LOGOUT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForceLogout: true,
        messageForceLogoutError: null,
        messageForceLogoutSuccess: null,
      },
    })
    const result = yield race({
      data: call(service.forceLogout, payload.id),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForceLogout: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageForceLogoutSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageForceLogoutError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET_PASSWORD() {
  while (true) {
    const { payload } = yield take(actions.RESET_PASSWORD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingResetPassword: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(service.resetPassword, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingResetPassword: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageResetPasswordSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageResetPasswordError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, SEARCH),
    takeLatest([actions.CREATE, actions.EDIT, actions.GET_PERMISSION], GET_FORM),
    takeLatest(actions.OPTIONS_DELETE, OPTIONS_DELETE),
    STORE(),
    UPDATE(),
    DELETE(),
    SET_PERMISSION(),
    RESET_PASSWORD(),
    FORCE_LOGOUT(),
  ])
}
