import apiClient from 'services/axios'

export async function getCategory(parameter) {
  return apiClient
    .get('/user/category/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategory(parameter = {}) {
  return apiClient
    .get('/user/category/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategory(values) {
  return apiClient
    .post('/user/category/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategory(id, parameter = {}) {
  return apiClient
    .get(`/user/category/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategory(id, values) {
  return apiClient
    .post(`/user/category/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategory(id) {
  return apiClient
    .delete(`/user/category/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
