import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as productDistService from 'services/webservice/user-access/product-dist'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_PRODUCT_DIST_ACCESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(productDistService.getProductDist, payload),
    cancel: take(actions.CANCEL_PRODUCT_DIST_ACCESS),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataProductDistAccess = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PERMISSION_PRODUCT_DIST_ACCESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(productDistService.permissionProductDist, payload),
    cancel: take(actions.CANCEL_PRODUCT_DIST_ACCESS),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataProductDistAccessPermission = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PRODUCT_DIST_ACCESS, GET_PRODUCT_DIST_ACCESS),
    takeLatest(actions.PERMISSION_PRODUCT_DIST_ACCESS, PERMISSION_PRODUCT_DIST_ACCESS),
  ])
}
