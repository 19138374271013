const actions = {
  SET_STATE: 'product-mss-monthly/SET_STATE',
  SEARCH: 'product-mss-monthly/SEARCH',
  CANCEL: 'product-mss-monthly/CANCEL',
  CREATE: 'product-mss-monthly/CREATE',
  FILTER: 'product-mss-monthly/FILTER',
  EDIT: 'product-mss-monthly/EDIT',
  STORE: 'product-mss-monthly/STORE',
  UPDATE: 'product-mss-monthly/UPDATE',
  DELETE: 'product-mss-monthly/DELETE',
  UPLOAD_FILE: 'product-mss-monthly/UPLOAD_FILE',
  CANCEL_LIST_OUTLET: 'product-mss-monthly/CANCEL_LIST_OUTLET',
  LIST_OUTLET: 'product-mss-monthly/LIST_OUTLET',
}

export default actions
