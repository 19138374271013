const actions = {
  SET_STATE: 'report-activity-merchandising/SET_STATE',
  SEARCH: 'report-activity-merchandising/SEARCH',
  FILTER: 'report-activity-merchandising/FILTER',
  CANCEL: 'report-activity-merchandising/CANCEL',
  DOWNLOAD: 'report-activity-merchandising/DOWNLOAD',
  REPROCESS_FILE: 'report-activity-merchandising/REPROCESS_FILE',
  LAST_UPDATED: 'report-activity-merchandising/LAST_UPDATED',
}

export default actions
