import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user, userDistributor }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  user,
  userDistributor,
  permissions: user.permissions,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  user,
  userDistributor,
  permissions,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const setVisibilityMenu = theTitle => {
    let visible = false
    if (user.authorized) {
      visible = false
      if (permissions && theTitle) {
        if (permissions[`${theTitle.toLowerCase()}`] === '1') {
          visible = true
        } else if (permissions[`${theTitle.toLowerCase()}`] === '0') {
          visible = false
        } else {
          visible = true
        }
      }
    } else if (userDistributor.authorized) {
      visible = true
    }
    return visible
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (setVisibilityMenu(item.attribute)) {
        if (item.category) {
          return <Menu.ItemGroup key={Math.random()} title={item.title} />
        }
        if (item.url) {
          return (
            <Menu.Item key={key} disabled={disabled}>
              {item.target && (
                <a href={url} target={item.target} rel="noopener noreferrer">
                  <span className={style.title}>{title}</span>
                  {count && <span className="badge badge-success ml-2">{count}</span>}
                  {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
                </a>
              )}
              {!item.target && (
                <Link to={url}>
                  <span className={style.title}>{title}</span>
                  {count && <span className="badge badge-success ml-2">{count}</span>}
                  {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
                </Link>
              )}
            </Menu.Item>
          )
        }
      }
      return (
        setVisibilityMenu(item.attribute) &&
        key && (
          <Menu.Item key={key} disabled={disabled}>
            <span className={style.title}>{title}</span>
            {count && <span className="badge badge-success ml-2">{count}</span>}
            {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
          </Menu.Item>
        )
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          let countChild = 0
          for (let i = 0; i < menuItem.children.length; i += 1) {
            if (setVisibilityMenu(menuItem.children[i].attribute)) {
              countChild += 1
            }
          }
          if (countChild > 0) {
            const subMenuTitle = (
              <span key={menuItem.key}>
                <span className={style.title}>{menuItem.title}</span>
                {menuItem.count && (
                  <span className="badge badge-success ml-2">{menuItem.count}</span>
                )}
                {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              </span>
            )
            return (
              <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
                {generateSubmenu(menuItem.children)}
              </Menu.SubMenu>
            )
          }
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      // if(setVisibilityMenu(menuItem.title)){
      let newMenuItem = []
      // if (menuItem.roles && !menuItem.roles.includes(userDist.role)) {
      //   return null
      // }
      let authType = ''
      if (user.authorized) {
        authType = 'user'
      } else if (userDistributor.authorized) {
        authType = 'distributor'
      }
      if (menuItem.auths.includes(authType)) {
        if (menuItem.children) {
          let countChild = 0
          for (let i = 0; i < menuItem.children.length; i += 1) {
            const firstChild = menuItem.children[i]
            if (setVisibilityMenu(firstChild.attribute)) {
              countChild += 1
            }
            if (firstChild.children) {
              for (let j = 0; j < firstChild.children.length; j += 1) {
                const secondChild = firstChild.children[j]
                if (setVisibilityMenu(secondChild.attribute)) {
                  countChild += 1
                }
              }
            }
          }
          if (countChild > 0) {
            const subMenuTitle = (
              <span key={menuItem.key}>
                <span className={style.title}>{menuItem.title}</span>
                {menuItem.count && (
                  <span className="badge badge-success ml-2">{menuItem.count}</span>
                )}
                {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              </span>
            )
            return (
              <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
                {generateSubmenu(menuItem.children)}
              </Menu.SubMenu>
            )
          }
        }
        // }
        newMenuItem = menuItem
      }
      return generateItem(newMenuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            {/* <img src="resources/images/unza.png" className={style.imgresize} alt="wipro Unza" /> */}
            <img
              src={`${process.env.PUBLIC_URL}/resources/images/wipro.svg`}
              className="mr-2"
              alt="Star BI 3.0"
            />
            <div className={classNames(style.name)} style={{ color: '#595c97' }}>
              Star BI 3.0
            </div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
