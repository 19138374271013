import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/distributor/distributor-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(parameter = {}) {
  return apiClient
    .get(`/distributor/distributor/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(values) {
  return apiClient
    .post(`/distributor/distributor/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/distributor/distributor-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
