import apiClient from 'services/axios'

export async function getMonth(parameter) {
  return apiClient
    .get('/user/month/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createMonth(parameter = {}) {
  return apiClient
    .get('/user/month/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeMonth(values) {
  return apiClient
    .post('/user/month/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editMonth(id, parameter = {}) {
  return apiClient
    .get(`/user/month/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateMonth(id, values) {
  return apiClient
    .post(`/user/month/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteMonth(id) {
  return apiClient
    .delete(`/user/month/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
