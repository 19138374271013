const actions = {
  SET_STATE: 'category-ceo-inactive/SET_STATE',
  LOAD_CATEGORY_CEO: 'category-ceo-inactive/LOAD_CATEGORY_CEO',
  CANCEL_CATEGORY_CEO: 'category-ceo-inactive/CANCEL_CATEGORY_CEO',
  LOAD_FORM_CREATE: 'category-ceo-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-ceo-inactive/LOAD_FORM_EDIT',
  STORE_CATEGORY_CEO: 'category-ceo-inactive/STORE_CATEGORY_CEO',
  UPDATE_CATEGORY_CEO: 'category-ceo-inactive/UPDATE_CATEGORY_CEO',
  DELETE_CATEGORY_CEO: 'category-ceo-inactive/DELETE_CATEGORY_CEO',
}

export default actions
