import apiClient from 'services/axios'

export async function getOutletTl(parameter) {
  return apiClient
    .get('/user/outlet-tl/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletTl(parameter = {}) {
  return apiClient
    .get('/user/outlet-tl/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletTl(values) {
  return apiClient
    .post('/user/outlet-tl/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletTl(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-tl/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletTl(id, values) {
  return apiClient
    .post(`/user/outlet-tl/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionsDelete(id) {
  return apiClient
    .get(`/user/outlet-tl/${id}/options-destroy`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletTl(id, values) {
  return apiClient
    .delete(`/user/outlet-tl/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getPermissions(id) {
  return apiClient
    .get(`/user/outlet-tl/${id}/permission`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setPermissions(id, values) {
  return apiClient
    .post(`/user/outlet-tl/${id}/permission`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(id, values) {
  return apiClient
    .post(`/user/outlet-tl/${id}/reset-password`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forceLogout(id) {
  return apiClient
    .post(`/user/outlet-tl/${id}/force-logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get(`/user/outlet-tl/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-tl/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
