const actions = {
  SET_STATE: 'outlet-channel-sub-inactive/SET_STATE',
  LOAD_OUTLET_CHANNEL_SUB: 'outlet-channel-sub-inactive/LOAD_OUTLET_CHANNEL_SUB',
  CANCEL_OUTLET_CHANNEL_SUB: 'outlet-channel-sub-inactive/CANCEL_OUTLET_CHANNEL_SUB',
  LOAD_FORM_CREATE: 'outlet-channel-sub-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-channel-sub-inactive/LOAD_FORM_EDIT',
  STORE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub-inactive/STORE_OUTLET_CHANNEL_SUB',
  UPDATE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub-inactive/UPDATE_OUTLET_CHANNEL_SUB',
  DELETE_OUTLET_CHANNEL_SUB: 'outlet-channel-sub-inactive/DELETE_OUTLET_CHANNEL_SUB',
}

export default actions
