const actions = {
  SET_STATE: 'version-inactive/SET_STATE',
  LOAD_VERSION: 'version-inactive/LOAD_VERSION',
  CANCEL_VERSION: 'version-inactive/CANCEL_VERSION',
  LOAD_FORM_CREATE: 'version-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'version-inactive/LOAD_FORM_EDIT',
  STORE_VERSION: 'version-inactive/STORE_VERSION',
  UPDATE_VERSION: 'version-inactive/UPDATE_VERSION',
  APP_VERSION: 'version-inactive/APP_VERSION',
  DELETE_VERSION: 'version-inactive/DELETE_VERSION',
}

export default actions
