import apiClient from 'services/axios'

export async function getOutlet(parameter) {
  return apiClient
    .get('/user/outlet/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getOutletDistributor(parameter) {
  return apiClient
    .get('/distributor/outlet/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutlet(parameter = {}) {
  return apiClient
    .get('/user/outlet/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutlet(values) {
  return apiClient
    .post('/user/outlet/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutlet(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutlet(id, values) {
  return apiClient
    .post(`/user/outlet/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutlet(id, values) {
  return (
    apiClient
      // .delete(`/user/outlet/${id}`, { data: values })
      .post(`/user/outlet/${id}/delete`, values)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  )
}

export async function distributorChildOutlet(id) {
  return apiClient
    .get(`/user/outlet/${id}/distributor-child-outlet`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterOutlet(parameter = {}) {
  return apiClient
    .get('/user/outlet/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function permissionOutlet(parameter = {}) {
  return apiClient
    .get(`/user/outlet/permission`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadAll(parameter) {
  return apiClient
    .get('/user/outlet/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadAllInactive(parameter) {
  return apiClient
    .get('/user/outlet/download-file-inactive', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/outlet/list-outlet-parent`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
