const actions = {
  SET_STATE: 'category-acc-inactive/SET_STATE',
  LOAD_CATEGORY_ACC: 'category-acc-inactive/LOAD_CATEGORY_ACC',
  CANCEL_CATEGORY_ACC: 'category-acc-inactive/CANCEL_CATEGORY_ACC',
  LOAD_FORM_CREATE: 'category-acc-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-acc-inactive/LOAD_FORM_EDIT',
  STORE_CATEGORY_ACC: 'category-acc-inactive/STORE_CATEGORY_ACC',
  UPDATE_CATEGORY_ACC: 'category-acc-inactive/UPDATE_CATEGORY_ACC',
  DELETE_CATEGORY_ACC: 'category-acc-inactive/DELETE_CATEGORY_ACC',
}

export default actions
