const actions = {
  SET_STATE: 'report-based-estimate/SET_STATE',
  SEARCH: 'report-based-estimate/SEARCH',
  FILTER: 'report-based-estimate/FILTER',
  CANCEL: 'report-based-estimate/CANCEL',
  DOWNLOAD: 'report-based-estimate/DOWNLOAD',
  REPROCESS_FILE: 'report-based-estimate/REPROCESS_FILE',
  LAST_UPDATED: 'report-based-estimate/LAST_UPDATED',
  SEARCH_LOG: 'report-based-estimate/SEARCH_LOG',
  DOWNLOAD_UPLOAD: 'report-based-estimate/DOWNLOAD_UPLOAD',
}

export default actions
