const actions = {
  SET_STATE: 'trans-dist-upload-stock/SET_STATE',
  LOAD_UNCOMBINE: 'trans-dist-upload-stock/LOAD_UNCOMBINE',
  SEARCH_DETAIL: 'trans-dist-upload-stock/SEARCH_DETAIL',
  SEARCH_LIST: 'trans-dist-upload-stock/SEARCH_LIST',
  CANCEL: 'trans-dist-upload-stock/CANCEL',
  LOAD_FORM_CREATE: 'trans-dist-upload-stock/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'trans-dist-upload-stock/LOAD_FORM_EDIT',
  STORE: 'trans-dist-upload-stock/STORE',
  UPDATE: 'trans-dist-upload-stock/UPDATE',
  DELETE: 'trans-dist-upload-stock/DELETE',
  DOWNLOAD: 'trans-dist-upload-stock/DOWNLOAD',
  UPLOAD: 'trans-dist-upload-stock/UPLOAD',
  RESET: 'trans-dist-upload-stock/RESET',
  SAVE: 'trans-dist-upload-stock/SAVE',
  OPTION_DETAIL: 'trans-dist-upload-stock/OPTION_DETAIL',
  REPROCESS: 'trans-dist-upload-stock/REPROCESS',
  DELETE_PARENT: 'trans-dist-upload-stock/DELETE_PARENT',
  DELETE_CHILD: 'trans-dist-upload-stock/DELETE_CHILD',
  DOWNLOAD_CHILD: 'trans-dist-upload-stock/DOWNLOAD_CHILD',
  DETAIL_LOG: 'trans-dist-upload-stock/DETAIL_LOG',
  FILTER: 'trans-dist-upload-stock/FILTER',
}

export default actions
