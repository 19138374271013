const actions = {
  SET_STATE: 'position/SET_STATE',
  LOAD_POSITION: 'position/LOAD_POSITION',
  CANCEL_POSITION: 'position/CANCEL_POSITION',
  LOAD_FORM_CREATE: 'position/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'position/LOAD_FORM_EDIT',
  STORE_POSITION: 'position/STORE_POSITION',
  UPDATE_POSITION: 'position/UPDATE_POSITION',
  DELETE_POSITION: 'position/DELETE_POSITION',
  DOWNLOAD: 'position/DOWNLOAD',
  FILTER: 'position/FILTER',
}

export default actions
