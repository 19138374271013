const actions = {
  SET_STATE: 'report-top-paretto/SET_STATE',
  SEARCH: 'report-top-paretto/SEARCH',
  FILTER: 'report-top-paretto/FILTER',
  CANCEL: 'report-top-paretto/CANCEL',
  DOWNLOAD: 'report-top-paretto/DOWNLOAD',
  REPROCESS_FILE: 'report-top-paretto/REPROCESS_FILE',
  LAST_UPDATED: 'report-top-paretto/LAST_UPDATED',
}

export default actions
