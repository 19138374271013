const actions = {
  SET_STATE: 'product-dist-block/SET_STATE',
  LOAD_PRODUCT_DIST_BLOCK: 'product-dist-block/LOAD_PRODUCT_DIST_BLOCK',
  CANCEL_PRODUCT_DIST_BLOCK: 'product-dist-block/CANCEL_PRODUCT_DIST_BLOCK',
  LOAD_FORM_CREATE: 'product-dist-block/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist-block/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST_BLOCK: 'product-dist-block/STORE_PRODUCT_DIST_BLOCK',
  UPDATE_PRODUCT_DIST_BLOCK: 'product-dist-block/UPDATE_PRODUCT_DIST_BLOCK',
  DELETE_PRODUCT_DIST_BLOCK: 'product-dist-block/DELETE_PRODUCT_DIST_BLOCK',
  NEED_RELOAD: 'product-dist-block/NEED_RELOAD',
}

export default actions
