const actions = {
  SET_STATE: 'target-secondary-sales-outlet/SET_STATE',
  SEARCH: 'target-secondary-sales-outlet/SEARCH',
  CANCEL: 'target-secondary-sales-outlet/CANCEL',
  CANCEL_LIST_OUTLET: 'target-secondary-sales-outlet/CANCEL_LIST_OUTLET',
  CREATE: 'target-secondary-sales-outlet/CREATE',
  EDIT: 'target-secondary-sales-outlet/EDIT',
  STORE: 'target-secondary-sales-outlet/STORE',
  UPDATE: 'target-secondary-sales-outlet/UPDATE',
  DELETE: 'target-secondary-sales-outlet/DELETE',
  FILTER: 'target-secondary-sales-outlet/FILTER',
  UPLOAD_FILE: 'target-secondary-sales-outlet/UPLOAD_FILE',
  LIST_OUTLET: 'target-secondary-sales-outlet/LIST_OUTLET',
  DOWNLOAD: 'target-secondary-sales-outlet/DOWNLOAD',
  REMINDER: 'target-secondary-sales-outlet/REMINDER',
}

export default actions
