const actions = {
  SET_STATE: 'range-marketing-inactive/SET_STATE',
  LOAD_RANGE_MARKETING: 'range-marketing-inactive/LOAD_RANGE_MARKETING',
  CANCEL_RANGE_MARKETING: 'range-marketing-inactive/CANCEL_RANGE_MARKETING',
  LOAD_FORM_CREATE: 'range-marketing-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'range-marketing-inactive/LOAD_FORM_EDIT',
  STORE_RANGE_MARKETING: 'range-marketing-inactive/STORE_RANGE_MARKETING',
  UPDATE_RANGE_MARKETING: 'range-marketing-inactive/UPDATE_RANGE_MARKETING',
  DELETE_RANGE_MARKETING: 'range-marketing-inactive/DELETE_RANGE_MARKETING',
}

export default actions
