const actions = {
  SET_STATE: 'year-inactive/SET_STATE',
  LOAD_YEAR: 'year-inactive/LOAD_YEAR',
  CANCEL_YEAR: 'year-inactive/CANCEL_YEAR',
  LOAD_FORM_CREATE: 'year-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'year-inactive/LOAD_FORM_EDIT',
  STORE_YEAR: 'year-inactive/STORE_YEAR',
  UPDATE_YEAR: 'year-inactive/UPDATE_YEAR',
  DELETE_YEAR: 'year-inactive/DELETE_YEAR',
}

export default actions
