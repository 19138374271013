const actions = {
  SET_STATE: 'daily-send/SET_STATE',
  SEARCH: 'daily-send/SEARCH',
  CANCEL: 'daily-send/CANCEL',
  FILTER: 'daily-send/FILTER',
  OPTION: 'daily-send/OPTION',
  UPLOAD: 'daily-send/UPLOAD',
  CREATE: 'daily-send/CREATE',
  EDIT: 'daily-send/EDIT',
  STORE: 'daily-send/STORE',
  UPDATE: 'daily-send/UPDATE',
  DELETE: 'daily-send/DELETE',
}

export default actions
