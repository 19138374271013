import apiClient from 'services/axios'

export async function getProductTemp(parameter) {
  return apiClient
    .get('/user/product-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductTemp(parameter = {}) {
  return apiClient
    .get('/user/product-temp/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductTemp(values) {
  return apiClient
    .post('/user/product-temp/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductTemp(id, parameter = {}) {
  return apiClient
    .get(`/user/product-temp/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductTemp(id, values) {
  return apiClient
    .post(`/user/product-temp/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductTemp(id) {
  return apiClient
    .delete(`/user/product-temp/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetProductTemp() {
  return apiClient
    .delete(`/user/product-temp/reset`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveProductTemp() {
  return apiClient
    .post(`/user/product-temp/save`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateProductTemp() {
  return apiClient
    .get(`/user/product-temp/download-file`, { responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProductTemp(values) {
  return apiClient
    .post('/user/product-temp/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
