import apiClient from 'services/axios'

export async function getOutletApproval(parameter) {
  return apiClient
    .get('/user/outlet-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletApproval(parameter = {}) {
  return apiClient
    .get('/user/outlet-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletApproval(values) {
  return apiClient
    .post('/user/outlet-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletApproval(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletApproval(id, values) {
  return apiClient
    .post(`/user/outlet-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletApproval(id, values) {
  return apiClient
    .delete(`/user/outlet-approval/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkOutletApproval(values) {
  return apiClient
    .post(`/user/outlet-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
