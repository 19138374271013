const actions = {
  SET_STATE: 'outlet-spg/SET_STATE',
  SEARCH: 'outlet-spg/SEARCH',
  CANCEL: 'outlet-spg/CANCEL',
  CREATE: 'outlet-spg/CREATE',
  EDIT: 'outlet-spg/EDIT',
  STORE: 'outlet-spg/STORE',
  UPDATE: 'outlet-spg/UPDATE',
  OPTIONS_DELETE: 'outlet-spg/OPTIONS_DELETE',
  DELETE: 'outlet-spg/DELETE',
  GET_PERMISSION: 'outlet-spg/GET_PERMISSION',
  SET_PERMISSION: 'outlet-spg/SET_PERMISSION',
  FORCE_LOGOUT: 'outlet-spg/FORCE_LOGOUT',
  RESET_PASSWORD: 'outlet-spg/RESET_PASSWORD',
  FILTER: 'outlet-spg/FILTER',
  REMINDER: 'outlet-spg/REMINDER',
}

export default actions
