import apiClient from 'services/axios'

export async function filterPeriod(parameter) {
  return apiClient
    .get('/user/closing-month/filter-period-outlet', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function periodically(parameter) {
  return apiClient
    .get('/user/closing-month/periodically-outlet', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(values) {
  return apiClient
    .post(`/user/closing-month/update-outlet`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
