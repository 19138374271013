import apiClient from 'services/axios'

export async function getCategoryAccenture(parameter) {
  return apiClient
    .get('/user/category-accenture/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategoryAccenture(parameter = {}) {
  return apiClient
    .get('/user/category-accenture/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryAccenture(values) {
  return apiClient
    .post('/user/category-accenture/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategoryAccenture(id, parameter = {}) {
  return apiClient
    .get(`/user/category-accenture/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategoryAccenture(id, values) {
  return apiClient
    .post(`/user/category-accenture/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategoryAccenture(id) {
  return apiClient
    .delete(`/user/category-accenture/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
