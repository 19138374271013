const actions = {
  SET_STATE: 'shipping-point-inactive/SET_STATE',
  LOAD_SHIPPING_POINT: 'shipping-point-inactive/LOAD_SHIPPING_POINT',
  CANCEL_SHIPPING_POINT: 'shipping-point-inactive/CANCEL_SHIPPING_POINT',
  LOAD_FORM_CREATE: 'shipping-point-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'shipping-point-inactive/LOAD_FORM_EDIT',
  STORE_SHIPPING_POINT: 'shipping-point-inactive/STORE_SHIPPING_POINT',
  UPDATE_SHIPPING_POINT: 'shipping-point-inactive/UPDATE_SHIPPING_POINT',
  DELETE_SHIPPING_POINT: 'shipping-point-inactive/DELETE_SHIPPING_POINT',
}

export default actions
