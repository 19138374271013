import actions from './actions'

const initialState = {
  dataOutletTl: null,
  dataOutletTlCreate: null,
  dataOutletTlEdit: null,
  dataOptions: null,
  dataDelete: null,
  dataListPermission: null,
  loadingResetPassword: false,
  loadingOptionsDelete: false,
  loadingForceLogout: false,
  loadingForm: false,
  loadingDelete: false,
  loading: false,
  loadingFilter: false,
  loadingReminder: false,
  errorFilter: null,
  error: null,
  errorForm: false,
  errorOptionsDelete: false,
  errorDelete: null,
  messageFormSuccess: null,
  messageReminderSuccess: null,
  messageReminderError: null,
  messageResetPasswordSuccess: null,
  messageResetPasswordError: null,
  messageForceLogoutSuccess: null,
  messageForceLogoutError: null,
  messageDelete: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function outletTlInactiveReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL_OUTLET_TL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
