import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as transDistUploadService from 'services/webservice/user-access/trans-dist-upload-stock'
import actions from './actions'

function* GET_DETAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.uploadDetailStock)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(transDistUploadService.detail, payload),
    cancel: take(actions.CANCEL_DETAIL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataUploadDetail = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTION_DETAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(transDistUploadService.options, payload),
    cancel: take(actions.CANCEL_DETAIL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataOptionUploadDetail = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_DETAIL, GET_DETAIL),
    takeLatest(actions.OPTION_DETAIL, OPTION_DETAIL),
  ])
}
