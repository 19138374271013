const actions = {
  SET_STATE: 'product-active/SET_STATE',
  LOAD_PRODUCT_ACTIVE: 'product-active/LOAD_PRODUCT_ACTIVE',
  CANCEL_PRODUCT_ACTIVE: 'product-active/CANCEL_PRODUCT_ACTIVE',
  LOAD_FORM_CREATE: 'product-active/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-active/LOAD_FORM_EDIT',
  STORE_PRODUCT_ACTIVE: 'product-active/STORE_PRODUCT_ACTIVE',
  UPDATE_PRODUCT_ACTIVE: 'product-active/UPDATE_PRODUCT_ACTIVE',
  DELETE_PRODUCT_ACTIVE: 'product-active/DELETE_PRODUCT_ACTIVE',
  CREATE_RANGE: 'product-active/CREATE_RANGE',
  CREATE_VARIANT: 'product-active/CREATE_VARIANT',
  CREATE_RANGE_VARIANT: 'product-active/CREATE_RANGE_VARIANT',
  CREATE_RANGE_MARKETING: 'product-active/CREATE_RANGE_MARKETING',
  CREATE_CATEGORY: 'product-active/CREATE_CATEGORY',
  CREATE_CATEGORY_SUB: 'product-active/CREATE_CATEGORY_SUB',
  CREATE_CATEGORY_CEO: 'product-active/CREATE_CATEGORY_CEO',
  CREATE_CATEGORY_ACC: 'product-active/CREATE_CATEGORY_ACC',
  CREATE_CATEGORY_ACCENTURE: 'product-active/CREATE_CATEGORY_ACCENTURE',
}

export default actions
