const actions = {
  SET_STATE: 'period-inactive/SET_STATE',
  LOAD_PERIOD: 'period-inactive/LOAD_PERIOD',
  CANCEL_PERIOD: 'period-inactive/CANCEL_PERIOD',
  LOAD_FORM_CREATE: 'period-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'period-inactive/LOAD_FORM_EDIT',
  STORE_PERIOD: 'period-inactive/STORE_PERIOD',
  UPDATE_PERIOD: 'period-inactive/UPDATE_PERIOD',
  DELETE_PERIOD: 'period-inactive/DELETE_PERIOD',
}

export default actions
