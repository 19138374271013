const actions = {
  SET_STATE: 'trade-promo/SET_STATE',
  SEARCH: 'trade-promo/SEARCH',
  CANCEL: 'trade-promo/CANCEL',
  CREATE: 'trade-promo/CREATE',
  EDIT: 'trade-promo/EDIT',
  STORE: 'trade-promo/STORE',
  UPDATE: 'trade-promo/UPDATE',
  DELETE: 'trade-promo/DELETE',
}

export default actions
