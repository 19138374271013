import React, { useEffect, useState } from 'react'
import moment from 'moment'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  const [dt, setDt] = useState(new Date().toLocaleString())
  useEffect(() => {
    const secTimer = setInterval(() => {
      setDt(moment().format('dddd, DD-MMM-YYYY HH:mm:ss'))
    }, 1000)

    return () => clearInterval(secTimer)
  }, [])
  return (
    <div className={style.topbar}>
      <div className={style.rightitem} style={{ marginRight: '75px' }}>
        {dt}
      </div>
      <div className={style.rightitem}>
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
