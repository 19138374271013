import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { Button, Spin, notification } from 'antd'
import { ReloadOutlined, LoadingOutlined, HomeOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import store from 'store'
// import OneSignal from 'react-onesignal'
import messageError, { getErrorMessage } from 'services/helper/message'
import customHook from 'services/helper/hook'
import Formatters from 'services/helper/Formatters'
import PublicAdminLayout from './admin/Public'
import AuthAdminLayout from './admin/Auth'
import MainAdminLayout from './admin/Main'

import PublicDistributorLayout from './distributor/Public'
import AuthDistributorLayout from './distributor/Auth'
import MainDistributorLayout from './distributor/Main'

const Layouts = {
  publicDistributor: PublicDistributorLayout,
  authDistributor: AuthDistributorLayout,
  mainDistributor: MainDistributorLayout,
  publicAdmin: PublicAdminLayout,
  authAdmin: AuthAdminLayout,
  mainAdmin: MainAdminLayout,
}

// const nameApp = process.env.REACT_APP_BASE_NAME || null;

const mapStateToProps = ({ user, userDistributor }) => ({ user, userDistributor })
let previousPath = ''

const Layout = ({ dispatch, user, userDistributor, children, location: { pathname, search } }) => {
  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/admin') {
      return 'publicAdmin'
    }
    if (pathname === '/distributor') {
      return 'publicDistributor'
    }
    if (/^\/auth-admin(?=\/|$)/i.test(pathname) || /^\/auth-distributor(?=\/|$)/i.test(pathname)) {
      return 'authAdmin'
    }
    // if (/^\/auth-distributor(?=\/|$)/i.test(pathname)) {
    //   return 'authDistributor'
    // }
    if (pathname === '/') {
      return 'authAdmin'
    }
    return 'mainAdmin'
  }
  const Container = Layouts[getLayout()]
  let isUserAuthorized = false
  let isUserLoadingInit = false
  const actionLoad = 'user/LOAD_CURRENT_ACCOUNT'
  let initialRedirect = ''
  let redirectPasswordRequire = ''
  // const appID = process.env.REACT_APP_NOTIFICATION_KEY
  // const isDistributorLoadingInit = userDistributor.loadingInit
  let hasChangeDefaultPassword = 0
  let hasErrorInit = null
  const errorInit = user.errorInit || {}
  let isAuthAdminLayout = false

  isAuthAdminLayout = getLayout() === 'authAdmin'
  isUserLoadingInit = user.loadingInit || userDistributor.loadingInit
  hasErrorInit = user.errorInit != null || userDistributor.errorInit != null
  isUserAuthorized = user.authorized || userDistributor.authorized

  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath && !isUserLoadingInit) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // const options = {
  //   allowLocalhostAsSecureOrigin: true,
  //   autoResubscribe: true,
  //   autoRegister: true,
  //   notifyButton: {
  //     enable: false,
  //   },
  // }

  // const events = [
  //   {
  //     listener: 'on',
  //     event: 'subscriptionChange',
  //     callback: isSubscribed => {
  //       if (isSubscribed) {
  //         console.log('The user subscription state is now:', isSubscribed)
  //       }
  //     },
  //   },
  //   {
  //     event: 'notificationDisplay',
  //     callback: event => {
  //       console.warn('OneSignal notification displayed:', event)
  //     },
  //   },
  //   {
  //     event: 'notificationDismiss',
  //     callback: event => {
  //       console.warn('OneSignal notification dismissed:', event)
  //     },
  //   },
  // ]

  // OneSignal.initialize(appID, options, events)

  Spin.setDefaultIndicator(<LoadingOutlined spin style={{ fontSize: 36 }} />)

  const BootstrappedLayout = () => {
    const loaderElement = (
      <div className="initial__loading" style={{ backgroundImage: 'none' }}>
        <div
          className="text-center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/wipro-login.png`}
            style={{ width: '100px', height: 'auto' }}
            alt="wipro Unza"
          />
          <p>
            <strong>Star BI 3.0</strong>
          </p>
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDFweCIgIGhlaWdodD0iNDFweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciPiAgICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBuZy1hdHRyLXN0cm9rZT0ie3tjb25maWcuY29sb3J9fSIgbmctYXR0ci1zdHJva2Utd2lkdGg9Int7Y29uZmlnLndpZHRofX0iIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5fX0iIHN0cm9rZT0iIzAxOTBmZSIgc3Ryb2tlLXdpZHRoPSIxMCIgcj0iMzUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2NC45MzM2MTQzMTM0NjQxNSA1Ni45Nzc4NzE0Mzc4MjEzOCIgdHJhbnNmb3JtPSJyb3RhdGUoODQgNTAgNTApIj4gICAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgY2FsY01vZGU9ImxpbmVhciIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSIgZHVyPSIxcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPiAgICA8L2NpcmNsZT4gIDwvc3ZnPg=="
            alt="loader"
          />
        </div>
      </div>
    )
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (
      isUserLoadingInit ||
      hasErrorInit ||
      pathname === '/redirect-sso' ||
      ((pathname === '/token-sso' || pathname === '/login-sso') &&
        (user.loadingSso === null || user.loadingSso))
    ) {
      return (
        <div className="initial__loading" style={{ backgroundImage: 'none' }}>
          <div
            className="text-center"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/resources/images/wipro-login.png`}
              style={{ width: '100px', height: 'auto' }}
              alt="wipro Unza"
            />
            <p>
              <strong>Star BI 3.0</strong>
            </p>
            {(isUserLoadingInit ||
              (pathname === '/redirect-sso' && !hasErrorInit) ||
              ((pathname === '/token-sso' || pathname === '/login-sso') &&
                (user.loadingSso === null || user.loadingSso) &&
                !hasErrorInit)) && (
              // eslint-disable-next-line react/jsx-indent
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDFweCIgIGhlaWdodD0iNDFweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciPiAgICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBuZy1hdHRyLXN0cm9rZT0ie3tjb25maWcuY29sb3J9fSIgbmctYXR0ci1zdHJva2Utd2lkdGg9Int7Y29uZmlnLndpZHRofX0iIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5fX0iIHN0cm9rZT0iIzAxOTBmZSIgc3Ryb2tlLXdpZHRoPSIxMCIgcj0iMzUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2NC45MzM2MTQzMTM0NjQxNSA1Ni45Nzc4NzE0Mzc4MjEzOCIgdHJhbnNmb3JtPSJyb3RhdGUoODQgNTAgNTApIj4gICAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgY2FsY01vZGU9ImxpbmVhciIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSIgZHVyPSIxcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPiAgICA8L2NpcmNsZT4gIDwvc3ZnPg=="
                alt="loader"
              />
            )}
            {!isUserLoadingInit && hasErrorInit && (
              <Fragment>
                <p className="mb-1 text-dark">
                  {getErrorMessage(errorInit, false) || messageError}
                </p>
                <Button
                  type="link"
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    dispatch({
                      type: actionLoad,
                    })
                  }}
                >
                  Reload
                </Button>
              </Fragment>
            )}
          </div>
        </div>
      )
    }

    if (
      (pathname === '/token-sso' || pathname === '/login-sso') &&
      user.loadingSso != null &&
      !user.loadingSso
    ) {
      const errorSso = sessionStorage.getItem('error_sso')
      if (errorSso !== null && errorSso !== undefined) {
        sessionStorage.removeItem('error_sso')
        return (
          <div className="initial__loading" style={{ backgroundImage: 'none' }}>
            <div
              className="text-center"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/resources/images/wipro-login.png`}
                style={{ width: '100px', height: 'auto' }}
                alt="wipro Unza"
              />
              <p>
                <strong>Star BI 3.0</strong>
              </p>
              <Fragment>
                <p className="mb-1 text-dark">{errorSso}</p>
                <Button
                  type="link"
                  icon={<HomeOutlined />}
                  onClick={() => {
                    window.location.href = '/auth-admin/login'
                  }}
                >
                  Go to Home Page
                </Button>
              </Fragment>
            </div>
          </div>
        )
      }
      return <Redirect to="/auth-admin/login" />
    }

    // redirect to login page if current is not login page and user not authorize
    if (isUserAuthorized) {
      if (user.authorized) {
        hasChangeDefaultPassword = user.has_change_default_password
        redirectPasswordRequire = '/admin/profile'
        initialRedirect = '/admin/profile'
        store.set('authtype', 'user')
      } else {
        hasChangeDefaultPassword = userDistributor.has_change_default_password
        redirectPasswordRequire = '/distributor/profile'
        initialRedirect = '/distributor/home'
        store.set('authtype', 'distributor')
      }
      if (parseInt(hasChangeDefaultPassword, 0) === 0 && pathname !== redirectPasswordRequire) {
        if (pathname === '/') {
          window.location.href = redirectPasswordRequire
          return loaderElement
        }
        notification.info({
          message: 'Update Password',
          description: 'Please change your password to continue using the application.',
        })

        return <Redirect to={redirectPasswordRequire} />
      }

      if (isAuthAdminLayout) {
        if (pathname === '/') {
          window.location.href = initialRedirect
          return loaderElement
        }
        return <Redirect to={initialRedirect} />
      }
    } else if (!isAuthAdminLayout) {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = process.env.REACT_APP_SSO_URL
        return loaderElement
      }

      if (pathname === '/') {
        window.location.href = '/auth-admin/login'
        return null
      }
      return <Redirect to="/auth-admin/login" />
    }
    if (pathname === '/') {
      window.location.href = '/auth-admin/login'
      return loaderElement
    }

    if (pathname === '/auth-admin/login') {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = process.env.REACT_APP_SSO_URL
        return loaderElement
      }
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  customHook.useDidMountEffect(async () => {
    if (!isUserLoadingInit && !hasErrorInit) {
      if (pathname === '/redirect-sso') {
        const state = Formatters.randomString(40)
        const codeVerifier = Formatters.randomString(128)
        store.set('state_sso', state)
        store.set('code_verifier_sso', codeVerifier)
        const codeChallenge = await Formatters.generateChallenge(codeVerifier)
        const urlSso = `${process.env.REACT_APP_SSO_URL_AUTHORIZE}?client_id=${process.env.REACT_APP_SSO_ID}
        &redirect_uri=${process.env.REACT_APP_URL}/token-sso&code_challenge=${codeChallenge}&state=${state}`
        window.location.href = urlSso
      } else if (pathname === '/token-sso') {
        const state = store.get('state_sso')
        const codeVerifier = store.get('code_verifier_sso')
        store.remove('state_sso')
        store.remove('code_verifier_sso')
        const searchParams = new URLSearchParams(search)
        const paramState = searchParams.get('state')
        if (state !== paramState) {
          sessionStorage.setItem('error_sso', 'The state of the Wipro SSO login is not match.')
          // notification.error({
          //   message: 'Login Failed',
          //   description: ,
          // })
          dispatch({
            type: 'user/SET_STATE',
            payload: {
              loadingSso: false,
            },
          })
        } else {
          const code = searchParams.get('code')
          const token = searchParams.get('token')
          dispatch({
            type: 'user/LOGIN_SSO',
            payload: {
              code,
              token,
              code_verifier: codeVerifier,
            },
          })
        }
      } else if (pathname === '/login-sso') {
        const searchParams = new URLSearchParams(search)
        store.set('token', searchParams.get('token'))
        dispatch({
          type: 'user/LOAD_CURRENT_ACCOUNT_SSO',
        })
      } else {
        store.remove('state_sso')
        store.remove('code_verifier_sso')
      }
    }
  }, [isUserLoadingInit, hasErrorInit, pathname])

  return (
    <Fragment>
      <Helmet titleTemplate="Star BI 3.0 | %s" title="Unza Vitalis" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
