const actions = {
  SET_STATE: 'target-secondary-sales-spg/SET_STATE',
  SEARCH: 'target-secondary-sales-spg/SEARCH',
  CANCEL: 'target-secondary-sales-spg/CANCEL',
  CANCEL_LIST_OUTLET: 'target-secondary-sales-spg/CANCEL_LIST_OUTLET',
  CREATE: 'target-secondary-sales-spg/CREATE',
  EDIT: 'target-secondary-sales-spg/EDIT',
  STORE: 'target-secondary-sales-spg/STORE',
  UPDATE: 'target-secondary-sales-spg/UPDATE',
  DELETE: 'target-secondary-sales-spg/DELETE',
  FILTER: 'target-secondary-sales-spg/FILTER',
  UPLOAD_FILE: 'target-secondary-sales-spg/UPLOAD_FILE',
  LIST_OUTLET: 'target-secondary-sales-spg/LIST_OUTLET',
  DOWNLOAD: 'target-secondary-sales-spg/DOWNLOAD',
  REMINDER: 'target-secondary-sales-spg/REMINDER',
}

export default actions
