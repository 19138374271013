const actions = {
  SET_STATE: 'product-bundle/SET_STATE',
  LOAD_PRODUCT_BUNDLE: 'product-bundle/LOAD_PRODUCT_BUNDLE',
  CANCEL_PRODUCT_BUNDLE: 'product-bundle/CANCEL_PRODUCT_BUNDLE',
  LOAD_FORM_CREATE: 'product-bundle/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-bundle/LOAD_FORM_EDIT',
  STORE_PRODUCT_BUNDLE: 'product-bundle/STORE_PRODUCT_BUNDLE',
  UPDATE_PRODUCT_BUNDLE: 'product-bundle/UPDATE_PRODUCT_BUNDLE',
  DELETE_PRODUCT_BUNDLE: 'product-bundle/DELETE_PRODUCT_BUNDLE',
  UPLOAD: 'product-bundle/UPLOAD',
}

export default actions
