const actions = {
  SET_STATE: 'setting/SET_STATE',
  LOAD_SETTING: 'setting/LOAD_SETTING',
  CANCEL_SETTING: 'setting/CANCEL_SETTING',
  LOAD_FORM_CREATE: 'setting/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'setting/LOAD_FORM_EDIT',
  STORE_SETTING: 'setting/STORE_SETTING',
  UPDATE_SETTING: 'setting/UPDATE_SETTING',
  DELETE_SETTING: 'setting/DELETE_SETTING',
}

export default actions
