import apiClient from 'services/axios'

export async function login(username, password) {
  return apiClient
    .post('/distributor/login', {
      username,
      password,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePassword(params) {
  return apiClient
    .post('/distributor/change-password', params)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProfile(dataProfile) {
  return apiClient
    .post('/distributor/update-profile', dataProfile)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProfileImage(values) {
  const formData = new FormData()
  formData.append('distributor_image', values)

  return apiClient
    .post('/distributor/update-profile-image', formData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function currentAccount() {
  return apiClient
    .get('/distributor/profile')
    .then(response => {
      return { response }
    })
    .catch(error => ({ error }))
}

// NANTI DIPINDAH KE FOLDER WEB SERVICE AJA
export async function distributorSearch() {
  return apiClient
    .get('/distributor/distributor/search')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function logout() {
  return apiClient
    .post('/distributor/logout')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(values) {
  return apiClient
    .post('/distributor/reset-password', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
