const actions = {
  SET_STATE: 'outlet-spg-type/SET_STATE',
  LOAD_SPG_TYPE: 'outlet-spg-type/LOAD_SPG_TYPE',
  CANCEL_SPG_TYPE: 'outlet-spg-type/CANCEL_SPG_TYPE',
  LOAD_FORM_CREATE: 'outlet-spg-type/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-spg-type/LOAD_FORM_EDIT',
  STORE_SPG_TYPE: 'outlet-spg-type/STORE_SPG_TYPE',
  UPDATE_SPG_TYPE: 'outlet-spg-type/UPDATE_SPG_TYPE',
  DELETE_SPG_TYPE: 'outlet-spg-type/DELETE_SPG_TYPE',
}

export default actions
