import actions from './actions'

const initialState = {
  dataCategoryAccenture: null,
  dataCategoryAccentureCreate: null,
  dataCategoryAccentureEdit: null,
  loadingForm: false,
  loadingDelete: false,
  loading: false,
  error: null,
  errorForm: false,
  errorDelete: null,
  messageFormSuccess: null,
  messageDelete: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function categoryAccentureInactiveReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL_CATEGORY_ACCENTURE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
