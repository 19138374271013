import apiClient from 'services/axios'

export async function getOutletDistDeviasi(parameter) {
  return apiClient
    .get('/user/outlet-dist-deviasi/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletDistDeviasi(parameter = {}) {
  return apiClient
    .get('/user/outlet-dist-deviasi/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletDistDeviasi(values) {
  return apiClient
    .post('/user/outlet-dist-deviasi/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletDistDeviasi(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-deviasi/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletDistDeviasi(id, values) {
  return apiClient
    .post(`/user/outlet-dist-deviasi/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletDistDeviasi(id) {
  return apiClient
    .delete(`/user/outlet-dist-deviasi/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadOutletDistDeviasi(values) {
  return apiClient
    .post(`/user/outlet-dist-deviasi/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateOutletDistDeviasi(parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-deviasi/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function blockOutletDistDeviasi(id, parameter = {}) {
  return apiClient
    .post(`/user/outlet-dist-deviasi/${id}/block`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionMapping(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-deviasi/${id}/option-mapping`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setMapping(id, parameter = {}) {
  return apiClient
    .post(`/user/outlet-dist-deviasi/${id}/mapping`, {
      ...parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getOutlet(parameter) {
  return apiClient
    .get('/user/outlet/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-deviasi/list-outlet-parent`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
