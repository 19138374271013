import apiClient from 'services/axios'

export async function loadFilter() {
  return apiClient
    .get('user/dashboard/filter-kindis')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function kindis(parameter) {
  return apiClient
    .get('user/dashboard/kindis', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function monitoring(parameter) {
  return apiClient
    .get('user/dashboard/monitoring-distributor', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function topParettoMt(parameter) {
  return apiClient
    .get('user/dashboard/kindis-top-paretto-mt', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function topParettoGt(parameter) {
  return apiClient
    .get('user/dashboard/kindis-top-paretto-gt', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadFilterDistributor() {
  return apiClient
    .get('distributor/dashboard/filter-kindis')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function kindisDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/kindis', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function monitoringDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/monitoring-distributor', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function topParettoMtDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/kindis-top-paretto-mt', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function topParettoGtDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/kindis-top-paretto-gt', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
