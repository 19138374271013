const actions = {
  SET_STATE: 'dashboard-enseval-chart/SET_STATE',

  LOAD_FILTER: 'dashboard-enseval-chart/LOAD_FILTER',
  LOAD_CHART_SALES_DIAGRAM: 'dashboard-enseval-chart/LOAD_CHART_SALES_DIAGRAM',

  CHANGE_CURRENT_PERIOD: 'dashboard-enseval-chart/CHANGE_CURRENT_PERIOD',

  LOAD_CHART_REGISTER_OUTLET: 'dashboard-enseval-chart/LOAD_CHART_REGISTER_OUTLET',
  LOAD_CHART_ACTIVE_OUTLET: 'dashboard-enseval-chart/LOAD_CHART_ACTIVE_OUTLET',
  LOAD_CHART_EFFECTIVE_CALL: 'dashboard-enseval-chart/LOAD_CHART_EFFECTIVE_CALL',

  LOAD_CHART_LINE_OF_PRODUCT: 'dashboard-enseval-chart/LOAD_CHART_LINE_OF_PRODUCT',

  //
  LOAD_CHART_ACHIEVEMENT_BRAND: 'dashboard-enseval-chart/LOAD_CHART_ACHIEVEMENT_BRAND',
  LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB: 'dashboard-enseval-chart/LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB',

  // pareto MT & GT
  LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT:
    'dashboard-enseval-chart/LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT',
  LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT:
    'dashboard-enseval-chart/LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT',

  // Channel no transaction
  LOAD_CHART_CHANNEL_NO_TRANSACTION: 'dashboard-enseval-chart/LOAD_CHART_CHANNEL_NO_TRANSACTION',
  LOAD_PRODUCT_TRANSACTION_CHANNEL: 'dashboard-enseval-chart/LOAD_PRODUCT_TRANSACTION_CHANNEL',

  CANCEL: 'dashboard-enseval-chart/CANCEL',
}

export default actions
