const actions = {
  SET_STATE: 'report-productivity-by-master/SET_STATE',
  FILTER: 'report-productivity-by-master/FILTER',
  CANCEL: 'report-productivity-by-master/CANCEL',
  DOWNLOAD: 'report-productivity-by-master/DOWNLOAD',
  LIST_OUTLET: 'report-productivity-by-master/LIST_OUTLET',
  CANCEL_LIST_OUTLET: 'report-productivity-by-master/CANCEL_LIST_OUTLET',
}

export default actions
