const actions = {
  SET_STATE: 'report-posm-detail/SET_STATE',
  SEARCH: 'report-posm-detail/SEARCH',
  FILTER: 'report-posm-detail/FILTER',
  CANCEL: 'report-posm-detail/CANCEL',
  DOWNLOAD: 'report-posm-detail/DOWNLOAD',
  REPROCESS_FILE: 'report-posm-detail/REPROCESS_FILE',
  LAST_UPDATED: 'report-posm-detail/LAST_UPDATED',
}

export default actions
