import { useRef, useEffect } from 'react'

const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(null)

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [ref, value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const addBodyClass = className => document.body.classList.add(className)
const removeBodyClass = className => document.body.classList.remove(className)

const useBodyClass = (visible, className) => {
  useEffect(() => {
    // Set up
    if (visible) {
      console.log('VISIBLE')
      if (className instanceof Array) {
        className.map(addBodyClass)
      } else {
        addBodyClass(className)
      }
    }
    // Clean up
    return () => {
      console.log('CLEAN UP')
      if (className instanceof Array) {
        className.map(removeBodyClass)
      } else {
        removeBodyClass(className)
      }
    }
  }, [visible, className])
}

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

const customHook = {
  usePrevious,
  useBodyClass,
  useDidMountEffect,
}

export default customHook
