import apiClient from 'services/axios'

export async function list(parameter) {
  return apiClient
    .get('/user/approval-balance-summary', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter() {
  return apiClient
    .get(`/user/approval-balance-summary/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(id) {
  return apiClient
    .get(`/user/approval-balance-summary/download-file/${id}`, {
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder() {
  return apiClient
    .post(`/user/approval-balance-summary/reminder-approval`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function approve(id) {
  return apiClient
    .post(`/user/approval-balance-summary/approve/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
