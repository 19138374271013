const actions = {
  SET_STATE: 'area-sub/SET_STATE',
  LOAD_AREA_SUB: 'area-sub/LOAD_AREA_SUB',
  CANCEL_AREA_SUB: 'area-sub/CANCEL_AREA_SUB',
  LOAD_FORM_CREATE: 'area-sub/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'area-sub/LOAD_FORM_EDIT',
  STORE_AREA_SUB: 'area-sub/STORE_AREA_SUB',
  UPDATE_AREA_SUB: 'area-sub/UPDATE_AREA_SUB',
  DELETE_AREA_SUB: 'area-sub/DELETE_AREA_SUB',
}

export default actions
