import apiClient from 'services/axios'

export async function getConsumerChannelProduct(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel-product/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerChannelProduct(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-channel-product/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerChannelProduct(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-product/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerChannelProduct(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-product/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerChannelProduct(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-channel-product/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerChannelProduct(id) {
  return apiClient
    .delete(`/user/sfa-consumer-channel-product/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerChannelProduct(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-product/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-product/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
