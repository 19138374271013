import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/closing-month/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterPeriod(parameter) {
  return apiClient
    .get('/user/closing-month/filter-period', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function periodically(parameter) {
  return apiClient
    .get('/user/closing-month/periodically', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listYearly(parameter) {
  const year = parameter.dist_parent_id
  return apiClient
    .get(`/user/closing-month/yearly/${year}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(parameter) {
  const { id } = parameter
  return apiClient
    .get(`/user/closing-month/${id}/edit`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(values) {
  return apiClient
    .post(`/user/closing-month/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
