import React, { Fragment } from 'react'
import { Tag, Space } from 'antd'

const approvalRow = (textNew, textOld) => {
  const textNewFormat = textNew === null || textNew === undefined ? null : textNew
  const textOldFormat = textOld === null || textOld === undefined ? null : textOld
  let htmlText = <span> {textNewFormat} </span>
  if (textNewFormat !== textOldFormat) {
    if (textOldFormat !== null) {
      htmlText = (
        <span>
          {textNewFormat} <br />
          <del>{textOldFormat}</del>
        </span>
      )
    } else {
      htmlText = <span className="text-success"> {textNewFormat} </span>
    }
  }
  return htmlText
}

const statusApprovalRow = (statusNew, statusOld, positive) => {
  if (statusOld === null || statusOld === undefined || statusOld === statusNew) {
    return <Tag color={statusNew === positive ? '#87d068' : '#f50'}>{statusNew}</Tag>
  }
  return (
    <Space direction="vertical">
      <Tag color={statusNew === positive ? '#87d068' : '#f50'}>{statusNew}</Tag>
      <Tag color={statusOld === positive ? '#87d068' : '#f50'}>
        <del>{statusOld}</del>
      </Tag>
    </Space>
  )
}

const formatStatusRow = (text, record) => {
  const newFormat =
    record.dist_child_status === 'Active' ? `<span className='text-danger'>${text}</span>` : text
  return newFormat
}

const converterStatus = textStatus => {
  const arrStatus = ['idle', 'process', 'waiting', 'error', 'finish', 'error - finish', 'skipped']
  const arrColor = ['#6c757d', '#595c97', '#4b7cf3', '#f50000', '#87d068', '#e8c02e', '#42baf9']

  const currentStatus = textStatus ? textStatus.toLowerCase() : '-'
  const colorStatus = textStatus ? arrColor[arrStatus.indexOf(currentStatus)] : '#ffffff'

  const htmlText = <Tag color={colorStatus}>{textStatus}</Tag>
  return htmlText
}

const toRupiah = (text, onlyNumb = false) => {
  let rupiah = Number(text).toLocaleString('en', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
  rupiah = rupiah.replace('.000', '')
  const regex = new RegExp('^(.+\\.[0-9]+?)(0+)$')
  rupiah = rupiah.replace(regex, '$1')

  if (onlyNumb) {
    rupiah = rupiah.replace('IDR', '')
  }
  return rupiah
}

const toNumberFormat = text => {
  return Number(text).toLocaleString('en')
}

const buildErrorMessage422 = (hasErrorForm, errorMessageForm, field) => ({
  hasFeedback: hasErrorForm && errorMessageForm[field],
  help: hasErrorForm && errorMessageForm[field] && (
    <Fragment>
      {errorMessageForm[field].map(error => {
        return (
          <p key={error} className="mb-0">
            {error}
          </p>
        )
      })}
    </Fragment>
  ),
  validateStatus: hasErrorForm && errorMessageForm[field] ? 'error' : '',
})

const dummyUserImage =
  'https://www.flaticon.com/svg/vstatic/svg/1077/1077114.svg?token=exp=1620367089~hmac=815478d17a66747b01dcadac02833fcf'

const croppedCenter = size => {
  const mSize = size
  const cc = {
    width: mSize,
    height: mSize,
    border: '1px solid silver',
    borderRadius: '6px',
    objectFit: 'none',
    objectPosition: 'center',
  }
  return cc
}

const truncateString = (text, count = 100, suffix = '...') => {
  return text.slice(0, count) + (text.length > count ? suffix : '')
}

/**
 * Creates an array of length `size` of random bytes
 * @param size
 * @returns Array of random ints (0 to 255)
 */
const getRandomValues = (size: number) => {
  return crypto.getRandomValues(new Uint8Array(size))
}

/** Generate cryptographically strong random string
 * @param size The desired length of the string
 * @returns The random string
 */
const randomString = (size: number) => {
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
  let result = ''
  const randomUints = getRandomValues(size)
  for (let i = 0; i < size; i += 1) {
    // cap the value of the randomIndex to mask.length - 1
    const randomIndex = randomUints[i] % mask.length
    result += mask[randomIndex]
  }
  return result
}

const generateChallenge = async codeVerifier => {
  const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))
  // Generate base64url string
  // btoa is deprecated in Node.js but is used here for web browser compatibility
  // (which has no good replacement yet, see also https://github.com/whatwg/html/issues/6811)
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=/g, '')
}

const Formatters = {
  converterStatus,
  approvalRow,
  statusApprovalRow,
  toRupiah,
  toNumberFormat,
  croppedCenter,
  formatStatusRow,
  buildErrorMessage422,
  truncateString,
  dummyUserImage,
  randomString,
  generateChallenge,
}

export default Formatters
