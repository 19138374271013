const actions = {
  SET_STATE: 'outlet-md/SET_STATE',
  SEARCH: 'outlet-md/SEARCH',
  CANCEL: 'outlet-md/CANCEL',
  CREATE: 'outlet-md/CREATE',
  EDIT: 'outlet-md/EDIT',
  STORE: 'outlet-md/STORE',
  UPDATE: 'outlet-md/UPDATE',
  DELETE: 'outlet-md/DELETE',
  GET_PERMISSION: 'outlet-md/GET_PERMISSION',
  SET_PERMISSION: 'outlet-md/SET_PERMISSION',
  FORCE_LOGOUT: 'outlet-md/FORCE_LOGOUT',
  RESET_PASSWORD: 'outlet-md/RESET_PASSWORD',
  FILTER: 'outlet-md/FILTER',
  REMINDER: 'outlet-md/REMINDER',
}

export default actions
