import apiClient from 'services/axios'

export async function getConsumerDistributor(parameter) {
  return apiClient
    .get('/user/sfa-consumer-distributor/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerDistributor(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-distributor/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerDistributor(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerDistributor(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerDistributor(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-distributor/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerDistributor(id) {
  return apiClient
    .delete(`/user/sfa-consumer-distributor/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerDistributor(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
