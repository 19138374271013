import apiClient from 'services/axios'

export async function getUserList(parameter) {
  return apiClient
    .get('/user/user/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createUserList(parameter = {}) {
  return apiClient
    .get('/user/user/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeUserList(values) {
  return apiClient
    .post('/user/user/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editUserList(id, parameter = {}) {
  return apiClient
    .get(`/user/user/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateUserList(id, values) {
  return apiClient
    .post(`/user/user/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getUserPermissions(id) {
  return apiClient
    .get(`/user/user/${id}/permission`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setUserPermissions(id, values) {
  return apiClient
    .post(`/user/user/${id}/permission`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(id, values) {
  return apiClient
    .post(`/user/user/${id}/reset-password`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forceLogout(id) {
  return apiClient
    .post(`/user/user/${id}/force-logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteUserList(id) {
  return apiClient
    .delete(`/user/user/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter() {
  return apiClient
    .get(`/user/user/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
