import apiClient from 'services/axios'

export async function filterManagement(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/filter-management', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataManagement(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/map-data-management', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataManagement(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/daily-data-management', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataManagementSummary(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/daily-data-management-summary', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function monthlyDataManagement(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/monthly-data-management', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
