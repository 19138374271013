const actions = {
  SET_STATE: 'outlet-approval/SET_STATE',
  LOAD_OUTLET_APPROVAL: 'outlet-approval/LOAD_OUTLET_APPROVAL',
  CANCEL_OUTLET_APPROVAL: 'outlet-approval/CANCEL_OUTLET_APPROVAL',
  LOAD_FORM_CREATE: 'outlet-approval/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-approval/LOAD_FORM_EDIT',
  STORE_OUTLET_APPROVAL: 'outlet-approval/STORE_OUTLET_APPROVAL',
  UPDATE_OUTLET_APPROVAL: 'outlet-approval/UPDATE_OUTLET_APPROVAL',
  DELETE_OUTLET_APPROVAL: 'outlet-approval/DELETE_OUTLET_APPROVAL',
  BULK_OUTLET_APPROVAL: 'outlet-approval/BULK_OUTLET_APPROVAL',
}

export default actions
