const actions = {
  SET_STATE: 'target-secondary-sales-lcs/SET_STATE',
  SEARCH: 'target-secondary-sales-lcs/SEARCH',
  CANCEL: 'target-secondary-sales-lcs/CANCEL',
  CANCEL_LIST_OUTLET: 'target-secondary-sales-lcs/CANCEL_LIST_OUTLET',
  CREATE: 'target-secondary-sales-lcs/CREATE',
  EDIT: 'target-secondary-sales-lcs/EDIT',
  STORE: 'target-secondary-sales-lcs/STORE',
  UPDATE: 'target-secondary-sales-lcs/UPDATE',
  DELETE: 'target-secondary-sales-lcs/DELETE',
  FILTER: 'target-secondary-sales-lcs/FILTER',
  LIST_OUTLET: 'target-secondary-sales-lcs/LIST_OUTLET',
  UPLOAD_FILE: 'target-secondary-sales-lcs/UPLOAD_FILE',
  DOWNLOAD: 'target-secondary-sales-lcs/DOWNLOAD',
  REMINDER: 'target-secondary-sales-lcs/REMINDER',
}

export default actions
