const actions = {
  SET_STATE: 'dashboard-sfa-md/SET_STATE',
  LOAD_FILTER: 'dashboard-sfa-md/LOAD_FILTER',

  LOAD_MAP_DATA: 'dashboard-sfa-md/LOAD_MAP_DATA',
  LOAD_DAILY_DATA: 'dashboard-sfa-md/LOAD_DAILY_DATA',

  CANCEL: 'dashboard-sfa-md/CANCEL',
}

export default actions
