import apiClient from 'services/axios'

export async function filterRo(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/filter-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataRo(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/map-data-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function targetCallRo(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/target-call-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterRoDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/filter-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataRoDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/map-data-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function targetCallRoDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/target-call-ro', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
