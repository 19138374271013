const actions = {
  SET_STATE: 'trans-dist-upload/SET_STATE',
  LOAD_UNCOMBINE: 'trans-dist-upload/LOAD_UNCOMBINE',
  SEARCH_DETAIL: 'trans-dist-upload/SEARCH_DETAIL',
  SEARCH_LIST: 'trans-dist-upload/SEARCH_LIST',
  CANCEL: 'trans-dist-upload/CANCEL',
  LOAD_FORM_CREATE: 'trans-dist-upload/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'trans-dist-upload/LOAD_FORM_EDIT',
  STORE: 'trans-dist-upload/STORE',
  UPDATE: 'trans-dist-upload/UPDATE',
  DELETE: 'trans-dist-upload/DELETE',
  DOWNLOAD: 'trans-dist-upload/DOWNLOAD',
  UPLOAD: 'trans-dist-upload/UPLOAD',
  RESET: 'trans-dist-upload/RESET',
  SAVE: 'trans-dist-upload/SAVE',
  OPTION_DETAIL: 'trans-dist-upload/OPTION_DETAIL',
  REPROCESS: 'trans-dist-upload/REPROCESS',
  DELETE_PARENT: 'trans-dist-upload/DELETE_PARENT',
  DELETE_CHILD: 'trans-dist-upload/DELETE_CHILD',
  DOWNLOAD_CHILD: 'trans-dist-upload/DOWNLOAD_CHILD',
  DETAIL_LOG: 'trans-dist-upload/DETAIL_LOG',
  FILTER: 'trans-dist-upload/FILTER',
}

export default actions
