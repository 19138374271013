const actions = {
  SET_STATE: 'report-call/SET_STATE',
  SEARCH: 'report-call/SEARCH',
  FILTER: 'report-call/FILTER',
  CANCEL: 'report-call/CANCEL',
  DOWNLOAD: 'report-call/DOWNLOAD',
  REPROCESS_FILE: 'report-call/REPROCESS_FILE',
  LAST_UPDATED: 'report-call/LAST_UPDATED',
}

export default actions
