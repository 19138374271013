const actions = {
  SET_STATE: 'outlet-dist-approval-new/SET_STATE',
  LOAD_OUTLET_DIST_APPROVAL: 'outlet-dist-approval-new/LOAD_OUTLET_DIST_APPROVAL',
  CANCEL_OUTLET_DIST_APPROVAL: 'outlet-dist-approval-new/CANCEL_OUTLET_DIST_APPROVAL',
  DELETE_OUTLET_DIST_APPROVAL: 'outlet-dist-approval-new/DELETE_OUTLET_DIST_APPROVAL',
  BULK_OUTLET_DIST_APPROVAL: 'outlet-dist-approval-new/BULK_OUTLET_DIST_APPROVAL',
  NEED_RELOAD: 'outlet-dist-approval-new/NEED_RELOAD',
}

export default actions
