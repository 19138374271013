const actions = {
  SET_STATE: 'quiz-inactive/SET_STATE',
  SEARCH: 'quiz-inactive/SEARCH',
  CANCEL: 'quiz-inactive/CANCEL',
  CREATE: 'quiz-inactive/CREATE',
  EDIT: 'quiz-inactive/EDIT',
  STORE: 'quiz-inactive/STORE',
  UPDATE: 'quiz-inactive/UPDATE',
  DELETE: 'quiz-inactive/DELETE',
}

export default actions
