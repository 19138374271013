const actions = {
  SET_STATE: 'distributor/SET_STATE',
  SEARCH: 'distributor/SEARCH',
  CANCEL: 'distributor/CANCEL',
  CREATE: 'distributor/CREATE',
  EDIT: 'distributor/EDIT',
  STORE: 'distributor/STORE',
  STORE_WITH_CHILD: 'distributor/STORE_WITH_CHILD',
  UPDATE: 'distributor/UPDATE',
  DELETE: 'distributor/DELETE',
  REMINDER: 'distributor/REMINDER',
}

export default actions
