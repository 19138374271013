import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    )

    try {
      const component = await componentImport()

      window.localStorage.setItem('page-has-been-force-refreshed', 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

const defaultRoutes = [
  {
    path: '/auth-admin/login',
    Component: lazyWithRetry(() => import('pages/auth-admin/login')),
    exact: true,
  },
  // {
  //   path: '/auth-admin/login',
  //   Component: lazyWithRetry(() => import('pages/auth-admin/login')),
  //   exact: true,
  // },
  // {
  //   path: '/auth-admin/forgot-password',
  //   Component: lazyWithRetry(() => import('pages/auth-admin/forgot-password')),
  //   exact: true,
  // },
  // {
  //   path: '/auth-admin/reset-password',
  //   Component: lazyWithRetry(() => import('pages/auth-admin/reset-password')),
  //   exact: true,
  // },
  // {
  //   path: '/auth-distributor/forgot-password',
  //   Component: lazyWithRetry(() => import('pages/auth-distributor/forgot-password')),
  //   exact: true,
  // },
  // {
  //   path: '/auth-distributor/reset-password',
  //   Component: lazyWithRetry(() => import('pages/auth-distributor/reset-password')),
  //   exact: true,
  // },
  {
    path: '/admin/404',
    Component: lazyWithRetry(() => import('pages/auth-admin/404')),
    exact: true,
  },
  {
    path: '/admin/500',
    Component: lazyWithRetry(() => import('pages/auth-admin/500')),
    exact: true,
  },
  {
    path: '/distributor/404',
    Component: lazyWithRetry(() => import('pages/auth-distributor/404')),
    exact: true,
  },
]

const routes = [
  {
    path: '/admin/dashboard/progress-approval',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/progress-approval')),
    exact: true,
  },
  {
    path: '/admin/dashboard/distributor',
    Component: lazyWithRetry(() => import('pages/admin/dashboard')),
    exact: true,
  },
  // {
  //   path: '/admin/dashboard/distributor-enseval',
  //   Component: lazyWithRetry(() => import('pages/admin/dashboard/index-enseval')),
  //   exact: true,
  // },
  {
    path: '/admin/dashboard/sales',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-sales')),
    exact: true,
  },
  {
    path: '/admin/dashboard/daily-send',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-daily-send')),
    exact: true,
  },
  {
    path: '/admin/dashboard/sfa',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-sfa')),
    exact: true,
  },
  {
    path: '/admin/dashboard/kindis',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-kindis')),
    exact: true,
  },
  {
    path: '/admin/dashboard/user',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-user')),
    exact: true,
  },
  {
    path: '/admin/manage-system/user-wipro',
    Component: lazyWithRetry(() => import('pages/admin/manage-system/user-wipro')),
    exact: true,
  },
  {
    path: '/admin/manage-system/permission',
    Component: lazyWithRetry(() => import('pages/admin/manage-system/permission')),
    exact: true,
  },
  {
    path: '/admin/manage-system/role',
    Component: lazyWithRetry(() => import('pages/admin/manage-system/role')),
    exact: true,
  },

  // master distributor section
  {
    path: '/admin/master-data/mst-distributor/distributor-primary',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/mst-distributor/distributor-primary'),
    ),
    exact: true,
  },
  // {
  //   path: '/admin/master-data/mst-distributor/distributor-epm',
  //   Component: lazyWithRetry(() =>
  //     import('pages/admin/master-data/mst-distributor/distributor-epm'),
  //   ),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/mst-distributor/distributor',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/distributor')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/distributor-child',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/mst-distributor/distributor-child'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/area',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/area')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/area-sub',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/area-sub')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/region',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/region')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/position',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/position')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/epm-area-sub',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/epm-area-sub')),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/shipping-point',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/mst-distributor/shipping-point'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/mst-distributor/mensa-area-sub',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/mst-distributor/mensa-area-sub'),
    ),
    exact: true,
  },

  {
    path: '/admin/master-data/mst-distributor/balance-summary',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/mst-distributor/balance-summary'),
    ),
    exact: true,
  },

  // {
  //   path: '/admin/master-data/claim-promo-epm',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/claim-promo-epm')),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/daily-send',
    Component: lazyWithRetry(() => import('pages/admin/master-data/daily-send')),
    exact: true,
  },

  // master product section
  {
    path: '/admin/master-data/product/brand-group',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/brand-group')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/brand',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/brand')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/brand-business',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/brand-business')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/brand-category',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/brand-category')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-range',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-range')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/range-variant',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/range-variant')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/range-marketing',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/range-marketing')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/variant',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/variant')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/category',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/category')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/category-sub',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/category-sub')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/category-ceo',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/category-ceo')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/category-marketing',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/category-acc')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/category-vitalis',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/category-vitalis')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-bundle',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-bundle')),
    exact: true,
  },
  // {
  //   path: '/admin/master-data/product/category-accenture',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/product/category-accenture')),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/product/product',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-dist',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-dist')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-mss-monthly',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-mss-monthly')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-top',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-top')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-top-monthly',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-top-monthly')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-listing',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-listing')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-price',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-price')),
    exact: true,
  },
  {
    path: '/admin/master-data/product/product-primary',
    Component: lazyWithRetry(() => import('pages/admin/master-data/product/product-primary')),
    exact: true,
  },

  // master stock
  // {
  //   path: '/admin/master-data/stock/category-stock',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/stock/category-stock')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/master-data/stock/category-sub-stock',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/stock/category-sub-stock')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/master-data/stock/stock',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/stock/stock')),
  //   exact: true,
  // },

  // master outlet section
  {
    path: '/admin/master-data/outlet/outlet-channel',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-channel')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-priority',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-priority')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-dist',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-dist')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-latlng-image',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-latlng-image')),
    exact: true,
  },
  // {
  //   path: '/admin/master-data/outlet/certificate-gt-online',
  //   Component: lazyWithRetry(() =>
  //     import('pages/admin/master-data/outlet/certificate-gt-online'),
  //   ),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/outlet/outlet-channel-sub',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-channel-sub')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-group',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-group')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-tl',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-tl')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-tl-spv',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-tl-spv')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-sales-spv',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-sales-spv')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-sales',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-sales')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-md',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-md')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-spg',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-spg')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-dist-deviasi',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-dist-deviasi')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-dist-block',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-dist-block')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-sales-type',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-sales-type')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-spg-type',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-spg-type')),
    exact: true,
  },
  {
    path: '/admin/master-data/outlet/outlet-spg-position',
    Component: lazyWithRetry(() => import('pages/admin/master-data/outlet/outlet-spg-position')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/month',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/month')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/year',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/year')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/period',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/period')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/version',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/version')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/schedule',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/schedule')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/schedule-accounting',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/schedule-accounting')),
    exact: true,
  },
  {
    path: '/admin/master-data/plugin/closing-month',
    Component: lazyWithRetry(() => import('pages/admin/master-data/plugin/closing-month')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-dist',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-secondary-dist')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-brand',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-secondary-brand')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-channel-sub',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-channel-sub'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-primary-sales',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-primary-sales')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-primary-brand',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-primary-brand')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/forecast-target-secondary-sales',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/forecast-target-secondary-sales'),
    ),
    exact: true,
  },
  // {
  //   path: '/admin/master-data/target/target-secondary-sales',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-secondary-sales')),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/target/target-secondary-sales-cb',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-sales-cb'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-sales-lcs',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-sales-lcs'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-sales-rental',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-sales-rental'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-sales-outlet',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-sales-outlet'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-secondary-sales-spg',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-data/target/target-secondary-sales-spg'),
    ),
    exact: true,
  },
  // {
  //   path: '/admin/master-data/target/target-incentive-mss',
  //   Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-incentive-mss')),
  //   exact: true,
  // },
  {
    path: '/admin/master-data/target/target-tlsd-lop-sales',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-tlsd-lop-sales')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-ro-sales',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-ro-sales')),
    exact: true,
  },
  {
    path: '/admin/master-data/target/target-day-of-work',
    Component: lazyWithRetry(() => import('pages/admin/master-data/target/target-day-of-work')),
    exact: true,
  },
  {
    path: '/admin/master-data/business',
    Component: lazyWithRetry(() => import('pages/admin/master-data/business')),
    exact: true,
  },
  {
    path: '/admin/import-data/sap-product',
    Component: lazyWithRetry(() => import('pages/admin/import-data/sap-product')),
    exact: true,
  },
  {
    path: '/admin/master-code-trans/code-distributor',
    Component: lazyWithRetry(() => import('pages/admin/master-code-trans/code-distributor')),
    exact: true,
  },
  {
    path: '/admin/master-code-trans/code-distributor-deviation',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-code-trans/code-distributor-deviation'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/upload-converter',
    Component: lazyWithRetry(() => import('pages/admin/monitoring-data/upload-converter')),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/upload-converter-new',
    Component: lazyWithRetry(() => import('pages/admin/monitoring-data/upload-converter-new')),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/upload-converter-new-stock',
    Component: lazyWithRetry(() =>
      import('pages/admin/monitoring-data/upload-converter-new-stock'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/ar',
    Component: lazyWithRetry(() => import('pages/admin/master-data/mst-distributor/ar')),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail',
    Component: lazyWithRetry(() => import('pages/admin/monitoring-data/data-upload-detail')),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail-stock',
    Component: lazyWithRetry(() => import('pages/admin/monitoring-data/data-upload-detail-stock')),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail/queue-process',
    Component: lazyWithRetry(() =>
      import('pages/admin/monitoring-data/data-upload-detail/queue-process'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail/queue-process-stock',
    Component: lazyWithRetry(() =>
      import('pages/admin/monitoring-data/data-upload-detail-stock/queue-process'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail/uncombine-data',
    Component: lazyWithRetry(() =>
      import('pages/admin/monitoring-data/data-upload-detail/uncombine-data'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-detail/combine-data',
    Component: lazyWithRetry(() =>
      import('pages/admin/monitoring-data/data-upload-detail/combine-data'),
    ),
    exact: true,
  },
  {
    path: '/admin/monitoring-data/data-upload-weekly',
    Component: lazyWithRetry(() => import('pages/admin/monitoring-data/data-upload-weekly')),
    exact: true,
  },
  {
    path: '/admin/report-mail',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-mail')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-target-ach-period-by-brand',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-target-ach-period-by-brand'),
    ),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-ach-by-brand',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ach-by-brand')),
  //   exact: true,
  // },

  // {
  //   path: '/admin/export-report/report-top-paretto',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-top-paretto')),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-history-outlet',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-history-outlet')),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-product-top-100',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-product-top-100')),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-product-top-135-and-100',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-product-top-100-and-50'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-opsc-cosmo-by-outlet-and-range',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-opsc-cosmo-by-outlet-and-range'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-ass',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-ass')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-asm',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-asm')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-rsm',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-rsm')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-rps',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-rps')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-ass',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-ass')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-asm',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-asm')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-rsm',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-rsm')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-rps',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-rps')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-tl-spv',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-tl-spv')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-tl',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-tl')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-spg',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-spg')),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-incentive-spv',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-spv')),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-incentive-group-promotor-manager',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-incentive-group-promotor-manager'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-homt',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-homt')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-nkam',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-nkam')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-kam',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-kam')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-sos',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-sos')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-som',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-som')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-soe',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-soe')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-som',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-som')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-quarter-soe',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-quarter-soe')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-salesman',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-incentive-salesman')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-incentive-distributor',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-incentive-distributor'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-no-trans-three-mma-product',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-no-trans-three-mma')),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-no-trans-three-mma-outlet',
  //   Component: lazyWithRetry(() =>
  //     import('pages/admin/export-report/report-no-trans-three-mma-outlet-only'),
  //   ),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-no-trans-two-mma-outlet',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-no-trans-two-mma-outlet'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-no-trans-four-mma-outlet',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-no-trans-four-mma-outlet'),
    ),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-noo-tracking',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-noo-tracking')),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-productivity-by-master',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-productivity-by-master'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-time-series',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-time-series')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-time-series-promotor',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-time-series-promotor')),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-ach-by-type-channel',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ach-by-type-channel')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-ao-by-type-channel',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ao-by-type-channel')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-ach-by-salesman',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ach-by-salesman')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-brand-by-salesman',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-brand-by-salesman')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-ec-by-salesman',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ec-by-salesman')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-ao-by-salesman',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-ao-by-salesman')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-type-channel-by-salesman',
  //   Component: lazyWithRetry(() =>
  //     import('pages/admin/export-report/report-type-channel-by-salesman'),
  //   ),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-business-review',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-business-review')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-mr',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-mr')),
    exact: true,
  },
  // {
  //   path: '/admin/export-report/report-rom',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-rom')),
  //   exact: true,
  // },
  // {
  //   path: '/admin/export-report/report-md-jago-jualan',
  //   Component: lazyWithRetry(() => import('pages/admin/export-report/report-md-jago-jualan')),
  //   exact: true,
  // },
  {
    path: '/admin/export-report/report-mss',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-mss')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-primary-sales',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-primary-sales')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-primary-sales-by-brand',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-primary-sales-by-brand'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-weekly-primary-sales',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-weekly-primary-sales')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-do-by-daily',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-do-by-daily')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-do',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-do')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-so-vs-do',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-so-vs-do')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-do-by-distributor',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-do-by-distributor')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-balance-summary',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-balance-summary')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-sales-secondary-mtd',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-sales-secondary-mtd')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-sales-secondary-mtd-by-brand',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-sales-secondary-mtd-by-brand'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-productivity-sfa-vs-star',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-productivity-sfa-vs-star'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-daily-brand-sec-sales-summary',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-daily-brand-sec-sales-summary'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-sec-sales-and-productivity',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-sec-sales-and-productivity'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-sec-sales-by-brand',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-sec-sales-by-brand')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-ka-sec-sales-and-productivity',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report/report-ka-sec-sales-and-productivity'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report/report-ewom',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-ewom')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-recap-salesman-ewom',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-recap-salesman-ewom')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-recap-md-spg-ewom',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-recap-md-spg-ewom')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-based-estimate',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-based-estimate')),
    exact: true,
  },
  {
    path: '/admin/export-report/report-be-ass',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-be-ass')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-daily-tagging-monitoring',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-daily-tagging-monitoring'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-call',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-call')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-absen-promotor',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-absen-promotor')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-absen',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-absen')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-posm-detail',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-posm-detail')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-posm-summary',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-posm-summary')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-accuration-pjp',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-accuration-pjp')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-competitor',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-competitor')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-activity-daily-md',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-activity-daily-md'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-activity-merchandising',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-activity-merchandising'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-activity-daily-sfa',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-activity-daily-sfa'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-absen-sfa',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-absen-sfa')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-productivity-sfa',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-productivity-sfa')),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-journey-salesman-sfa',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-journey-salesman-sfa'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-activity-daily-spv',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-activity-daily-spv'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-absen-spv',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-absen-spv')),
    exact: true,
  },

  {
    path: '/admin/export-report/report-incentive-md',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-incentive-md')),
    exact: true,
  },

  {
    path: '/admin/export-report-sfa/report-po-summary-salesman',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-po-summary-salesman'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-po-detail-salesman',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-po-detail-salesman'),
    ),
    exact: true,
  },
  {
    path: '/admin/export-report-sfa/report-display-salesman',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-display-salesman')),
    exact: true,
  },
  {
    path: '/admin/setting',
    Component: lazyWithRetry(() => import('pages/admin/setting')),
    exact: true,
  },
  {
    path: '/admin/notification',
    Component: lazyWithRetry(() => import('pages/admin/notification')),
    exact: true,
  },
  {
    path: '/admin/setting-converter',
    Component: lazyWithRetry(() => import('pages/admin/setting-converter')),
    exact: true,
  },
  {
    path: '/admin/setting-po-summary-salesman',
    Component: lazyWithRetry(() => import('pages/admin/setting-po-summary-salesman')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-upload-daily',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-upload-daily')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-upload-weekly',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-upload-weekly')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-upload-stock-daily',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-upload-stock-daily')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-upload-stock-weekly',
    Component: lazyWithRetry(() =>
      import('pages/admin/transaction/trans-dist-upload-stock-weekly'),
    ),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-final-report',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-final-report')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-final-report-stock',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-final-report-stock')),
    exact: true,
  },
  {
    path: '/admin/transaction/convert-union',
    Component: lazyWithRetry(() => import('pages/admin/transaction/convert-union')),
    exact: true,
  },
  {
    path: '/admin/transaction/convert-union-stock',
    Component: lazyWithRetry(() => import('pages/admin/transaction/convert-union-stock')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-status-discount',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-status-discount')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-status-cube',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-status-cube')),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-status-cube-mma-mmax',
    Component: lazyWithRetry(() =>
      import('pages/admin/transaction/trans-dist-status-cube-mma-mmax'),
    ),
    exact: true,
  },
  {
    path: '/admin/transaction/trans-dist-status-cube-ewom',
    Component: lazyWithRetry(() => import('pages/admin/transaction/trans-dist-status-cube-ewom')),
    exact: true,
  },

  // Master SFA
  {
    path: '/admin/master-sfa/product/product-top',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/product/product-top')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/banner',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/banner')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/pk-module',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/pk-module')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/trade-promo',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/trade-promo')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/quiz',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/quiz')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/program/rank',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/program/rank')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/program/regular',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/program/regular')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/program/rental',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/program/rental')),
    exact: true,
  },

  {
    path: '/admin/master-sfa/consumer/channel-product-bundle',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-sfa/consumer/channel-product-bundle'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/channel-product',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/channel-product')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/channel-range',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/channel-range')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/channel-brand',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/channel-brand')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/channel',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/channel')),
    exact: true,
  },

  {
    path: '/admin/master-sfa/consumer/distributor-product-bundle',
    Component: lazyWithRetry(() =>
      import('pages/admin/master-sfa/consumer/distributor-product-bundle'),
    ),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/distributor-product',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/distributor-product')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/distributor-range',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/distributor-range')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/distributor-brand',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/distributor-brand')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/distributor',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/distributor')),
    exact: true,
  },

  {
    path: '/admin/master-sfa/consumer/outlet-product-bundle',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/outlet-product-bundle')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/outlet-product',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/outlet-product')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/outlet-range',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/outlet-range')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/outlet-brand',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/outlet-brand')),
    exact: true,
  },
  {
    path: '/admin/master-sfa/consumer/outlet',
    Component: lazyWithRetry(() => import('pages/admin/master-sfa/consumer/outlet')),
    exact: true,
  },

  // Auth Pages

  // {
  //   path: '/admin/register',
  //   Component: lazyWithRetry(() => import('pages/auth-admin/register')),
  //   exact: true,
  // },
  {
    path: '/admin/profile',
    Component: lazyWithRetry(() => import('pages/auth-admin/profile')),
    exact: true,
  },
  // {
  //   path: '/admin/lockscreen',
  //   Component: lazyWithRetry(() => import('pages/auth-admin/lockscreen')),
  //   exact: true,
  // },
]

const routesDistributor = [
  // distributor page
  {
    path: '/distributor/home',
    Component: lazyWithRetry(() => import('pages/distributor/home')),
    exact: true,
  },
  {
    path: '/distributor/dashboard/distributor',
    Component: lazyWithRetry(() => import('pages/admin/dashboard')),
    exact: true,
  },
  {
    path: '/distributor/dashboard/sales',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-sales')),
    exact: true,
  },
  {
    path: '/distributor/dashboard/sfa',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-sfa')),
    exact: true,
  },
  {
    path: '/distributor/dashboard/kindis',
    Component: lazyWithRetry(() => import('pages/admin/dashboard/index-kindis')),
    exact: true,
  },
  {
    path: '/distributor/upload-converter-distributor',
    Component: lazyWithRetry(() => import('pages/distributor/upload-converter-distributor')),
    exact: true,
  },
  {
    path: '/distributor/upload-converter-distributor-stock',
    Component: lazyWithRetry(() => import('pages/distributor/upload-converter-distributor-stock')),
    exact: true,
  },
  {
    path: '/distributor/monitoring-data',
    Component: lazyWithRetry(() => import('pages/distributor/monitoring-data')),
    exact: true,
  },
  {
    path: '/distributor/monitoring-data-stock',
    Component: lazyWithRetry(() => import('pages/distributor/monitoring-data-stock')),
    exact: true,
  },
  {
    path: '/distributor/trans-final-report',
    Component: lazyWithRetry(() => import('pages/distributor/trans-final-report')),
    exact: true,
  },
  {
    path: '/distributor/trans-final-report-stock',
    Component: lazyWithRetry(() => import('pages/distributor/trans-final-report-stock')),
    exact: true,
  },
  {
    path: '/distributor/product-dist',
    Component: lazyWithRetry(() => import('pages/distributor/master-data/product-dist')),
    exact: true,
  },
  {
    path: '/distributor/outlet-dist',
    Component: lazyWithRetry(() => import('pages/distributor/master-data/outlet-dist')),
    exact: true,
  },
  {
    path: '/distributor/profile',
    Component: lazyWithRetry(() => import('pages/distributor/profile')),
    exact: true,
  },
  // {
  //   path: '/distributor/queue-process',
  //   Component: lazyWithRetry(() => import('pages/distributor/queue-process')),
  //   exact: true,
  // },
  // {
  //   path: '/distributor/queue-process-stock',
  //   Component: lazyWithRetry(() => import('pages/distributor/queue-process-stock')),
  //   exact: true,
  // },
  {
    path: '/distributor/export-report-sfa/report-journey-salesman-sfa',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-journey-salesman-sfa'),
    ),
    exact: true,
  },
  {
    path: '/distributor/export-report-sfa/report-activity-daily-sfa',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-activity-daily-sfa'),
    ),
    exact: true,
  },
  {
    path: '/distributor/export-report-sfa/report-absen-sfa',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-absen-sfa')),
    exact: true,
  },
  {
    path: '/distributor/export-report-sfa/report-productivity-sfa',
    Component: lazyWithRetry(() => import('pages/admin/export-report-sfa/report-productivity-sfa')),
    exact: true,
  },
  {
    path: '/distributor/export-report-sfa/report-po-summary-salesman',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-po-summary-salesman'),
    ),
    exact: true,
  },
  {
    path: '/distributor/export-report-sfa/report-po-detail-salesman',
    Component: lazyWithRetry(() =>
      import('pages/admin/export-report-sfa/report-po-detail-salesman'),
    ),
    exact: true,
  },
  {
    path: '/distributor/export-report/report-business-review',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-business-review')),
    exact: true,
  },
  {
    path: '/distributor/export-report/report-mr',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-mr')),
    exact: true,
  },
  {
    path: '/distributor/export-report/report-ewom',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-ewom')),
    exact: true,
  },
  {
    path: '/distributor/export-report/report-based-estimate',
    Component: lazyWithRetry(() => import('pages/admin/export-report/report-based-estimate')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user, userDistributor }) => ({
  routerAnimation: settings.routerAnimation,
  user,
  userDistributor,
})

const Router = ({ history, routerAnimation, user, userDistributor }) => {
  let redirectRoute = <Redirect to="/admin/404" />
  let selectedRoutes = []
  if (user.authorized || userDistributor.authorized) {
    if (user.authorized) {
      selectedRoutes = routes
      redirectRoute = <Redirect to="/admin/404" />
    } else {
      selectedRoutes = routesDistributor
      redirectRoute = <Redirect to="/distributor/404" />
    }
  }
  selectedRoutes = defaultRoutes.concat(selectedRoutes)
  return (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <Layout>
          <Route
            render={state => {
              const { location } = state
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={location.pathname}
                    appear
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    <Switch location={location}>
                      <Route
                        exact
                        path="/admin"
                        render={() => <Redirect to="/admin/manage-system/user-wipro" />}
                      />
                      {selectedRoutes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))}
                      {redirectRoute}
                    </Switch>
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          />
        </Layout>
      </ConnectedRouter>
    </BrowserRouter>
  )
}

export default connect(mapStateToProps)(Router)
