const actions = {
  SET_STATE: 'outlet-latlng-image-inactive/SET_STATE',
  SEARCH: 'outlet-latlng-image-inactive/SEARCH',
  CANCEL: 'outlet-latlng-image-inactive/CANCEL',
  EDIT: 'outlet-latlng-image-inactive/EDIT',
  RESET: 'outlet-latlng-image-inactive/RESET',
  UPDATE: 'outlet-latlng-image-inactive/UPDATE',
}

export default actions
