import apiClient from 'services/axios'

export async function getConsumerOutlet(parameter) {
  return apiClient
    .get('/user/sfa-consumer-outlet/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerOutlet(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-outlet/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerOutlet(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerOutlet(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerOutlet(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-outlet/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerOutlet(id) {
  return apiClient
    .delete(`/user/sfa-consumer-outlet/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerOutlet(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
