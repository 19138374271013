import apiClient from 'services/axios'

export async function getConsumerChannel(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerChannel(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-channel/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerChannel(values) {
  return apiClient
    .post('/user/sfa-consumer-channel/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerChannel(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerChannel(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-channel/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerChannel(id) {
  return apiClient
    .delete(`/user/sfa-consumer-channel/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerChannel(values) {
  return apiClient
    .post('/user/sfa-consumer-channel/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
