const actions = {
  SET_STATE: 'report-po-summary-salesman/SET_STATE',
  SEARCH: 'report-po-summary-salesman/SEARCH',
  FILTER: 'report-po-summary-salesman/FILTER',
  CANCEL: 'report-po-summary-salesman/CANCEL',
  DOWNLOAD: 'report-po-summary-salesman/DOWNLOAD',
  DOWNLOAD_FILE_DIST: 'report-po-summary-salesman/DOWNLOAD_FILE_DIST',
  REPROCESS_FILE: 'report-po-summary-salesman/REPROCESS_FILE',
  LAST_UPDATED: 'report-po-summary-salesman/LAST_UPDATED',
  LIST_DATA: 'report-po-summary-salesman/LIST_DATA',
}

export default actions
