import apiClient from 'services/axios'

// FILTER
export async function loadFilter(parameter) {
  return apiClient
    .get('user/dashboard/filter-distributor-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartSalesDiagram(parameter) {
  return apiClient
    .get('user/dashboard/achievement', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartRegisterOutlet(parameter) {
  return apiClient
    .get('user/dashboard/register-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartActiveOutlet(parameter) {
  return apiClient
    .get('user/dashboard/active-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartEffectiveCall(parameter) {
  return apiClient
    .get('user/dashboard/effective-call', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartLineOfProduct(parameter) {
  return apiClient
    .get('user/dashboard/line-of-product', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartAtRisk(parameter) {
  return apiClient
    .get('user/dashboard/at-risk', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSalesMtdYtd(parameter) {
  return apiClient
    .get('user/dashboard/sales-mtd-ytd', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementBrand(parameter) {
  return apiClient
    .get('user/dashboard/achievement-brand', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementChannelSub(parameter) {
  return apiClient
    .get('user/dashboard/achievement-channel-sub', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoMt(parameter) {
  return apiClient
    .get('user/dashboard/achievement-top-paretto-mt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoGt(parameter) {
  return apiClient
    .get('user/dashboard/achievement-top-paretto-gt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoNpdTypeChannel(parameter) {
  return apiClient
    .get('user/dashboard/ro-ao-npd-by-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoPfMonthly(parameter) {
  return apiClient
    .get('user/dashboard/ro-ao-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadFilterRoPfMonthly(parameter) {
  return apiClient
    .get('user/dashboard/filter-product-top-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSixMmaPfMonthly(parameter) {
  return apiClient
    .get('user/dashboard/six-mma-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChannelNoTransaction(parameter) {
  return apiClient
    .get('user/dashboard/no-trans-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadDistributorNewTerminate(parameter) {
  return apiClient
    .get('user/dashboard/distributor-new-terminate', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadProductTransactionChannel(parameter) {
  return apiClient
    .get('user/dashboard/no-trans-pf-monthly-by-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

// TYPE : SALES

export async function loadAchievementSales(parameter) {
  return apiClient
    .get('user/dashboard/achievement-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementBrandSales(parameter) {
  return apiClient
    .get('user/dashboard/achievement-brand-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementBrandSalesSpecific(parameter) {
  return apiClient
    .get(`user/dashboard/achievement-brand-sales/${parameter.kode_sales}`, { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementChannelSubSales(parameter) {
  return apiClient
    .get('user/dashboard/achievement-channel-sub-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementChannelSubSalesSpecific(parameter) {
  return apiClient
    .get(`user/dashboard/achievement-channel-sub-sales/${parameter.kode_sales}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoNpdSales(parameter) {
  return apiClient
    .get('user/dashboard/ro-ao-npd-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoPfMonthlySales(parameter) {
  return apiClient
    .get('user/dashboard/ro-ao-pf-monthly-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRateBs(parameter) {
  return apiClient
    .get('user/dashboard/six-mma-pf-monthly-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

// FILTER FOR DISTRIBUTOR USER

export async function loadFilterDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/filter-distributor-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadFilterRoPfMonthlyDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/filter-product-top-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

// TYPE : DISTRIBUTOR FOR DISTRIBUTOR USER

export async function loadChartSalesDiagramDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartRegisterOutletDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/register-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartActiveOutletDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/active-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartEffectiveCallDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/effective-call', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartLineOfProductDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/line-of-product', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartAtRiskDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/at-risk', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSalesMtdYtdDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/sales-mtd-ytd', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementBrandDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-brand', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementChannelSubDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-channel-sub', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoMtDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-top-paretto-mt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoGtDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-top-paretto-gt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoNpdTypeChannelDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/ro-ao-npd-by-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoPfMonthlyDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/ro-ao-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSixMmaPfMonthlyDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/six-mma-pf-monthly', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChannelNoTransactionDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/no-trans-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadProductTransactionChannelDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/no-trans-pf-monthly-by-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

// TYPE : SALES FOR DISTRIBUTOR USER

export async function loadAchievementSalesDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementBrandSalesDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-brand-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementBrandSalesSpecificDistributor(parameter) {
  return apiClient
    .get(`distributor/dashboard/achievement-brand-sales/${parameter.kode_sales}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementChannelSubSalesDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/achievement-channel-sub-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAchievementChannelSubSalesSpecificDistributor(parameter) {
  return apiClient
    .get(`distributor/dashboard/achievement-channel-sub-sales/${parameter.kode_sales}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoNpdSalesDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/ro-ao-npd-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRoPfMonthlySalesDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/ro-ao-pf-monthly-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRateBsDistributor(parameter) {
  return apiClient
    .get('distributor/dashboard/six-mma-pf-monthly-by-sales', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
