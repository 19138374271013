import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as productService from 'services/webservice/user-access/product'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_PRODUCT_INACTIVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.productInactive)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  payload.product_status = 'Inactive'
  const result = yield race({
    data: call(productService.getProduct, payload),
    cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataProductInactive = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataProductInactiveCreate: null,
    dataProductInactiveEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.LOAD_FORM_CREATE
  if (isCreate) {
    result = yield race({
      data: call(productService.createProduct),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
  } else {
    result = yield race({
      data: call(productService.editProduct, payload.id),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataProductInactiveCreate = response ? response.data : null
    } else {
      newPayload.dataProductInactiveEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_PRODUCT_INACTIVE() {
  while (true) {
    const { payload } = yield take(actions.STORE_PRODUCT_INACTIVE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(productService.storeProduct, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_PRODUCT_INACTIVE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PRODUCT_INACTIVE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(productService.updateProduct, currentId, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_PRODUCT_INACTIVE() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PRODUCT_INACTIVE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(productService.deleteProduct, payload.id),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_CATEGORY() {
  while (true) {
    const { payload } = yield take(actions.CREATE_CATEGORY)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeCategory, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_CATEGORY_CEO() {
  while (true) {
    const { payload } = yield take(actions.CREATE_CATEGORY_CEO)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeCategoryCeo, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_CATEGORY_ACC() {
  while (true) {
    const { payload } = yield take(actions.CREATE_CATEGORY_ACC)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeCategoryAcc, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_CATEGORY_ACCENTURE() {
  while (true) {
    const { payload } = yield take(actions.CREATE_CATEGORY_ACCENTURE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeCategoryAccenture, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_CATEGORY_SUB() {
  while (true) {
    const { payload } = yield take(actions.CREATE_CATEGORY_SUB)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeCategorySub, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_RANGE() {
  while (true) {
    const { payload } = yield take(actions.CREATE_RANGE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeRange, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_VARIANT() {
  while (true) {
    const { payload } = yield take(actions.CREATE_VARIANT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeVariant, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_RANGE_VARIANT() {
  while (true) {
    const { payload } = yield take(actions.CREATE_RANGE_VARIANT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeRangeVariant, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      console.log(error)
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* CREATE_RANGE_MARKETING() {
  while (true) {
    const { payload } = yield take(actions.CREATE_RANGE_MARKETING)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingCreateChild: true,
      },
    })
    const result = yield race({
      data: call(productService.storeRangeMarketing, payload),
      cancel: take(actions.CANCEL_PRODUCT_INACTIVE),
    })
    if (result.data) {
      const newPayload = {
        loadingCreateChild: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormChildSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      console.log(error)
      newPayload.errorFormChild = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PRODUCT_INACTIVE, GET_PRODUCT_INACTIVE),
    takeLatest([actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT], GET_FORM),
    STORE_PRODUCT_INACTIVE(),
    UPDATE_PRODUCT_INACTIVE(),
    DELETE_PRODUCT_INACTIVE(),
    CREATE_CATEGORY(),
    CREATE_CATEGORY_CEO(),
    CREATE_CATEGORY_ACCENTURE(),
    CREATE_CATEGORY_ACC(),
    CREATE_CATEGORY_SUB(),
    CREATE_RANGE(),
    CREATE_VARIANT(),
    CREATE_RANGE_VARIANT(),
    CREATE_RANGE_MARKETING(),
  ])
}
