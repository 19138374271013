import apiClient from 'services/axios'

export async function getAr(parameter) {
  return apiClient
    .get('/user/ar/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createAr(parameter = {}) {
  return apiClient
    .get('/user/ar/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeAr(values) {
  return apiClient
    .post('/user/ar/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editAr(id, parameter = {}) {
  return apiClient
    .get(`/user/ar/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateAr(id, values) {
  return apiClient
    .post(`/user/ar/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteAr(id) {
  return apiClient
    .delete(`/user/ar/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/ar/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/ar/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
