import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as rangeVariantService from 'services/webservice/user-access/range-variant'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_RANGE_VARIANT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.rangeVariant)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(rangeVariantService.getRangeVariant, payload),
    cancel: take(actions.CANCEL_RANGE_VARIANT),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataRangeVariant = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataRangeVariantCreate: null,
    dataRangeVariantEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.LOAD_FORM_CREATE
  if (isCreate) {
    result = yield race({
      data: call(rangeVariantService.createRangeVariant),
      cancel: take(actions.CANCEL_RANGE_VARIANT),
    })
  } else {
    result = yield race({
      data: call(rangeVariantService.editRangeVariant, payload.id),
      cancel: take(actions.CANCEL_RANGE_VARIANT),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataRangeVariantCreate = response ? response.data : null
    } else {
      newPayload.dataRangeVariantEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_RANGE_VARIANT() {
  while (true) {
    const { payload } = yield take(actions.STORE_RANGE_VARIANT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(rangeVariantService.storeRangeVariant, payload),
      cancel: take(actions.CANCEL_RANGE_VARIANT),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_RANGE_VARIANT() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_RANGE_VARIANT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(rangeVariantService.updateRangeVariant, currentId, payload),
      cancel: take(actions.CANCEL_RANGE_VARIANT),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_RANGE_VARIANT() {
  while (true) {
    const { payload } = yield take(actions.DELETE_RANGE_VARIANT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(rangeVariantService.deleteRangeVariant, payload.id),
      cancel: take(actions.CANCEL_RANGE_VARIANT),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_RANGE_VARIANT, GET_RANGE_VARIANT),
    takeLatest([actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT], GET_FORM),
    STORE_RANGE_VARIANT(),
    UPDATE_RANGE_VARIANT(),
    DELETE_RANGE_VARIANT(),
  ])
}
