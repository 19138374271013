const actions = {
  SET_STATE: 'product-approval/SET_STATE',
  LOAD_PRODUCT_APPROVAL: 'product-approval/LOAD_PRODUCT_APPROVAL',
  CANCEL_PRODUCT_APPROVAL: 'product-approval/CANCEL_PRODUCT_APPROVAL',
  LOAD_FORM_CREATE: 'product-approval/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-approval/LOAD_FORM_EDIT',
  STORE_PRODUCT_APPROVAL: 'product-approval/STORE_PRODUCT_APPROVAL',
  UPDATE_PRODUCT_APPROVAL: 'product-approval/UPDATE_PRODUCT_APPROVAL',
  DELETE_PRODUCT_APPROVAL: 'product-approval/DELETE_PRODUCT_APPROVAL',
  BULK_PRODUCT_APPROVAL: 'product-approval/BULK_PRODUCT_APPROVAL',
}

export default actions
