const actions = {
  SET_STATE: 'target-secondary-brand/SET_STATE',
  SEARCH: 'target-secondary-brand/SEARCH',
  CANCEL: 'target-secondary-brand/CANCEL',
  CREATE: 'target-secondary-brand/CREATE',
  EDIT: 'target-secondary-brand/EDIT',
  STORE: 'target-secondary-brand/STORE',
  UPDATE: 'target-secondary-brand/UPDATE',
  DELETE: 'target-secondary-brand/DELETE',
  FILTER: 'target-secondary-brand/FILTER',
  UPLOAD_FILE: 'target-secondary-brand/UPLOAD_FILE',
  REMINDER: 'target-secondary-brand/REMINDER',
}

export default actions
