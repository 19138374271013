import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/product-dist-deviasi'
import { saveAs } from 'file-saver'
import actions from './actions'

function* GET_PRODUCT_DIST_DEVIASI({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.productDistDeviasi)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(mService.getProductDistDeviasi, payload),
    cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataProductDistDeviasi = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataProductDistDeviasiCreate: null,
    dataProductDistDeviasiEdit: null,
    dataMapping: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let dataCall = null
  switch (action.type) {
    case actions.LOAD_FORM_CREATE:
      dataCall = call(mService.createProductDistDeviasi)
      break
    case actions.LOAD_FORM_EDIT:
      dataCall = call(mService.createProductDistDeviasi, payload.id)
      break
    case actions.OPTION_MAPPING:
      dataCall = call(mService.optionMapping, {
        id: payload.id,
        dist_child_id: payload.dist_child_id,
      })
      break

    default:
      break
  }
  const result = yield race({
    data: dataCall,
    cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
  })

  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.LOAD_FORM_CREATE:
        newPayload.dataProductDistDeviasiCreate = response ? response.data : null
        break
      case actions.LOAD_FORM_EDIT:
        newPayload.dataProductDistDeviasiEdit = response ? response.data : null
        break
      case actions.OPTION_MAPPING:
        newPayload.dataMapping = response ? response.data : null
        break

      default:
        break
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_PRODUCT_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.STORE_PRODUCT_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(mService.storeProductDistDeviasi, payload),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_PRODUCT_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PRODUCT_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.updateProductDistDeviasi, currentId, payload),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_PRODUCT_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PRODUCT_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(mService.deleteProductDistDeviasi, payload.id),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DOWNLOAD_PRODUCT_DIST_DEVIASI({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: true,
      messageDownloadFile: null,
      errorDownload: null,
    },
  })
  const result = yield race({
    data: call(mService.downloadTemplateProductDistDeviasi, payload),
    cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDownload: false,
      errorDownload: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadFile = `File is successfully downloaded!`
    } else {
      newPayload.errorDownload = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD_PRODUCT_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_PRODUCT_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(mService.uploadProductDistDeviasi, payload),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
        messageUploadFile: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* BLOCK_PRODUCT_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.BLOCK_PRODUCT_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        errorForm: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.blockProductDistDeviasi, currentId, payload),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_MAPPING() {
  while (true) {
    const { payload } = yield take(actions.SET_MAPPING)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        errorForm: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.setMapping, currentId, payload),
      cancel: take(actions.CANCEL_PRODUCT_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PRODUCT_DIST_DEVIASI, GET_PRODUCT_DIST_DEVIASI),
    takeLatest(
      [actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT, actions.OPTION_MAPPING],
      GET_FORM,
    ),
    takeLatest(actions.DOWNLOAD_PRODUCT_DIST_DEVIASI, DOWNLOAD_PRODUCT_DIST_DEVIASI),
    STORE_PRODUCT_DIST_DEVIASI(),
    UPDATE_PRODUCT_DIST_DEVIASI(),
    UPLOAD_PRODUCT_DIST_DEVIASI(),
    BLOCK_PRODUCT_DIST_DEVIASI(),
    SET_MAPPING(),
    DELETE_PRODUCT_DIST_DEVIASI(),
  ])
}
