const actions = {
  SET_STATE: 'category-vitalis/SET_STATE',
  LOAD_CATEGORY_VITALIS: 'category-vitalis/LOAD_CATEGORY_VITALIS',
  LOAD_FORM_CREATE: 'category-vitalis/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-vitalis/LOAD_FORM_EDIT',
  STORE_CATEGORY_VITALIS: 'category-vitalis/STORE_CATEGORY_VITALIS',
  UPDATE_CATEGORY_VITALIS: 'category-vitalis/UPDATE_CATEGORY_VITALIS',
  DELETE_CATEGORY_VITALIS: 'category-vitalis/DELETE_CATEGORY_VITALIS',
  CANCEL_CATEGORY_VITALIS: 'category-vitalis/CANCEL_CATEGORY_VITALIS',
}

export default actions
