import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/distributor/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/distributor/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/distributor/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeWithChild(values) {
  return apiClient
    .post('/user/distributor/store-with-child', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/distributor/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/distributor/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(values) {
  return apiClient
    .post('/user/distributor/reset-password', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post('/user/distributor/reminder-approval', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
