import axios from 'axios'
import store from 'store'
import { store as storeRedux } from 'index'
// import { notification } from 'antd'
import Qs from 'qs'

const { CancelToken } = axios
let source = CancelToken.source()

export const cancelAllRequest = () => {
  source.cancel()
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'http://localhost:8001/api/v1',
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  cancelToken: source.token,
  paramsSerializer: params => {
    return Qs.stringify(params)
  },
})

export const renewCancelToken = () => {
  source = CancelToken.source()
  apiClient.defaults.cancelToken = source.token
}

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('token')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error

  // JIKA ERROR 401 AND USER DALAM KEADAAN LOGIN
  if (response) {
    if (response.status === 401 && storeRedux.getState().user.authorized) {
      storeRedux.dispatch({
        type: 'user/UNAUTHENTICATED',
      })
      // notification.info({
      //   message: 'Session Ended',
      //   description: 'Please login again to your account.',
      // })
    } else if (
      response.status === 403 &&
      response.data &&
      response.data.type === 'password' &&
      storeRedux.getState().user.authorized
    ) {
      storeRedux.dispatch({
        type: 'user/SET_STATE',
        payload: {
          has_change_default_password: 0,
        },
      })
    } else if (response.status === 401 && storeRedux.getState().userDistributor.authorized) {
      storeRedux.dispatch({
        type: 'user-distributor/UNAUTHENTICATED',
      })
      // notification.info({
      //   message: 'Session Ended',
      //   description: 'Please login again to your account.',
      // })
    } else if (
      response.status === 403 &&
      response.data &&
      response.data.type === 'password' &&
      storeRedux.getState().userDistributor.authorized
    ) {
      storeRedux.dispatch({
        type: 'user-distributor/SET_STATE',
        payload: {
          has_change_default_password: 0,
        },
      })
    }
  }

  if (
    error.request.responseType === 'blob' &&
    error.response != null &&
    error.response.data != null &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        error.response.data = JSON.parse(reader.result)
        resolve(Promise.reject(error))
      }

      reader.onerror = () => {
        reject(error)
      }

      reader.readAsText(error.response.data)
    })
  }

  return Promise.reject(error)
})

export default apiClient
