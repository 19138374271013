import apiClient from 'services/axios'

export async function getNotification(parameter) {
  return apiClient
    .get('/user/notification/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createNotification(parameter = {}) {
  return apiClient
    .get('/user/notification/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeNotification(values) {
  return apiClient
    .post('/user/notification/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editNotification(id, parameter = {}) {
  return apiClient
    .get(`/user/notification/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateNotification(id, values) {
  return apiClient
    .post(`/user/notification/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteNotification(id) {
  return apiClient
    .delete(`/user/notification/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function readAllNotification(values) {
  return apiClient
    .post(`/user/notification/read-all`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function readNotification(values) {
  return apiClient
    .post(`/user/notification/${values.id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
