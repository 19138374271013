import apiClient from 'services/axios'

export async function getOutletDistTemp(parameter) {
  return apiClient
    .get('/user/outlet-dist-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getOutletDistTempNew(parameter) {
  return apiClient
    .get('/user/outlet-dist-temp/search-new-outlet', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletDistTemp(parameter = {}) {
  return apiClient
    .get('/user/outlet-dist-temp/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletDistTemp(values) {
  return apiClient
    .post('/user/outlet-dist-temp/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletDistTemp(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-temp/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletDistTemp(id, values) {
  return apiClient
    .post(`/user/outlet-dist-temp/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletDistTemp(id) {
  return apiClient
    .delete(`/user/outlet-dist-temp/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadOutletDistTemp(values) {
  return apiClient
    .post(`/user/outlet-dist-temp/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateOutletDistTemp(parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist-temp/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetOutletDistTemp(values) {
  return apiClient
    .delete(`/user/outlet-dist-temp/reset`, { params: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveOutletDistTemp(values) {
  return apiClient
    .post(`/user/outlet-dist-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
