import apiClient from 'services/axios'

export async function getRangeMarketing(parameter) {
  return apiClient
    .get('/user/range-marketing/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createRangeMarketing(parameter = {}) {
  return apiClient
    .get('/user/range-marketing/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRangeMarketing(values) {
  return apiClient
    .post('/user/range-marketing/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editRangeMarketing(id, parameter = {}) {
  return apiClient
    .get(`/user/range-marketing/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateRangeMarketing(id, values) {
  return apiClient
    .post(`/user/range-marketing/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteRangeMarketing(id) {
  return apiClient
    .delete(`/user/range-marketing/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
