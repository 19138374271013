import apiClient from 'services/axios'

export async function getTargetSecondaryChannelSub(parameter) {
  return apiClient
    .get('/user/target-secondary-channel-sub/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createTargetSecondaryChannelSub(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-channel-sub/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeTargetSecondaryChannelSub(values) {
  return apiClient
    .post('/user/target-secondary-channel-sub/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editTargetSecondaryChannelSub(id, parameter = {}) {
  return apiClient
    .get(`/user/target-secondary-channel-sub/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateTargetSecondaryChannelSub(id, values) {
  return apiClient
    .post(`/user/target-secondary-channel-sub/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteTargetSecondaryChannelSub(id) {
  return apiClient
    .delete(`/user/target-secondary-channel-sub/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-channel-sub/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-secondary-channel-sub/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/target-secondary-channel-sub/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
