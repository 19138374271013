import apiClient from 'services/axios'

export async function loadFilter() {
  return apiClient
    .get('user/dashboard/filter-user')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function totalUser() {
  return apiClient
    .get('user/dashboard/total-user')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function growthUser(parameter) {
  return apiClient
    .get('user/dashboard/growth-user', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadUser(parameter) {
  return apiClient
    .get('user/dashboard/download-user', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
