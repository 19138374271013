import { all, takeLatest, put, call, select, take, race } from 'redux-saga/effects'
import * as myService from 'services/webservice/user-access/distributor-primary-deviasi'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_DISTRIBUTOR_PRIMARY_DEVIASI({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(
    state => state.distributorPrimaryDeviasi,
  )
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(myService.getDistributorPrimaryDeviasi, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataDistributorPrimaryDeviasi = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

// function* UPLOAD() {
//   while (true) {
//     const { payload } = yield take(actions.UPLOAD_FILE)
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         loadingUpload: true,
//         messageUploadFile: null,
//       },
//     })
//     const result = yield race({
//       data: call(myService.uploadFile, payload),
//       cancel: take(actions.CANCEL),
//     })
//     if (result.data) {
//       const newPayload = {
//         loadingUpload: false,
//       }
//       const { response, error } = result.data
//       if (response) {
//         newPayload.messageUploadFile = response.data
//       }
//       newPayload.messageUploadFileError = error
//       yield put({
//         type: actions.SET_STATE,
//         payload: newPayload,
//       })
//     }
//   }
// }

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataDistributorPrimaryDeviasiMapping: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  const dataCall = call(myService.optionMapping, { id: payload.id })

  const result = yield race({
    data: dataCall,
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    newPayload.dataDistributorPrimaryDeviasiMapping = response ? response.data : null
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SET_MAPPING() {
  while (true) {
    const { payload } = yield take(actions.SET_MAPPING)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        errorForm: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(myService.setMapping, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_FILE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingUpload: true,
        messageUploadFile: null,
      },
    })
    const result = yield race({
      data: call(myService.uploadFile, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingUpload: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* BLOCK() {
  while (true) {
    const { payload } = yield take(actions.BLOCK)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingBlock: true,
        errorBlock: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(myService.block, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingBlock: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageBlockSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorBlock = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, GET_DISTRIBUTOR_PRIMARY_DEVIASI),
    takeLatest([actions.OPTION_MAPPING], GET_FORM),
    SET_MAPPING(),
    UPLOAD(),
    BLOCK(),
  ])
}
