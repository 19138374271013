import apiClient from 'services/axios'

export async function getProduct(parameter) {
  return apiClient
    .get('/user/product/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProduct(parameter = {}) {
  return apiClient
    .get('/user/product/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterProduct(parameter = {}) {
  return apiClient
    .get('/user/product/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProduct(values) {
  return apiClient
    .post('/user/product/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProduct(id, parameter = {}) {
  return apiClient
    .get(`/user/product/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProduct(id, values) {
  return apiClient
    .post(`/user/product/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProduct(id) {
  return apiClient
    .delete(`/user/product/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function permissionProduct(parameter = {}) {
  return apiClient
    .get(`/user/product/permission`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRange(values) {
  return apiClient
    .post('/user/product/create-range', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeVariant(values) {
  return apiClient
    .post('/user/product/create-variant', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRangeMarketing(values) {
  return apiClient
    .post('/user/product/create-range-marketing', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRangeVariant(values) {
  return apiClient
    .post('/user/product/create-range-variant', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategory(values) {
  return apiClient
    .post('/user/product/create-category', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryCeo(values) {
  return apiClient
    .post('/user/product/create-category-ceo', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryAcc(values) {
  return apiClient
    .post('/user/product/create-category-acc', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategorySub(values) {
  return apiClient
    .post('/user/product/create-category-sub', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryAccenture(values) {
  return apiClient
    .post('/user/product/create-category-accenture', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post('/user/product/reminder-approval', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
