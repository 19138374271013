const actions = {
  SET_STATE: 'category/SET_STATE',
  LOAD_CATEGORY: 'category/LOAD_CATEGORY',
  CANCEL_CATEGORY: 'category/CANCEL_CATEGORY',
  LOAD_FORM_CREATE: 'category/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category/LOAD_FORM_EDIT',
  STORE_CATEGORY: 'category/STORE_CATEGORY',
  UPDATE_CATEGORY: 'category/UPDATE_CATEGORY',
  DELETE_CATEGORY: 'category/DELETE_CATEGORY',
}

export default actions
