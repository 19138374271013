const actions = {
  SET_STATE: 'dashboard-kindis/SET_STATE',
  LOAD_FILTER: 'dashboard-kindis/LOAD_FILTER',
  KINDIS: 'dashboard-kindis/KINDIS',
  MONITORING: 'dashboard-kindis/MONITORING',
  PARETTO_MT: 'dashboard-kindis/PARETTO_MT',
  PARETTO_GT: 'dashboard-kindis/PARETTO_GT',
  CANCEL: 'dashboard-kindis/CANCEL',
}

export default actions
