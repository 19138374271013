const actions = {
  SET_STATE: 'business/SET_STATE',
  SEARCH: 'business/SEARCH',
  CREATE: 'business/CREATE',
  EDIT: 'business/EDIT',
  STORE: 'business/STORE',
  UPDATE: 'business/UPDATE',
  DELETE: 'business/DELETE',
  CANCEL: 'business/CANCEL',
}

export default actions
