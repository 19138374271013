import actions from './actions'

const initialState = {
  dataApproval: null,
  dataFilters: null,
  loadingApprove: false,
  loadingReminder: false,
  loading: false,
  loadingFilter: false,
  loadingDownload: false,
  errorDownload: false,
  error: null,
  errorFilter: null,
  messageReminderSuccess: null,
  messageReminderError: null,
  messageApproveSuccess: null,
  messageApproveError: null,
  messageDownload: null,
}

export default function approvalBalanceSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
