const actions = {
  SET_STATE: 'report-be-ass/SET_STATE',
  SEARCH: 'report-be-ass/SEARCH',
  FILTER: 'report-be-ass/FILTER',
  CANCEL: 'report-be-ass/CANCEL',
  DOWNLOAD: 'report-be-ass/DOWNLOAD',
  SEARCH_LOG: 'report-be-ass/SEARCH_LOG',
  DOWNLOAD_UPLOAD: 'report-be-ass/DOWNLOAD_UPLOAD',
}

export default actions
