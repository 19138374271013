const actions = {
  SET_STATE: 'target-secondary-channel-sub/SET_STATE',
  SEARCH: 'target-secondary-channel-sub/SEARCH',
  CANCEL: 'target-secondary-channel-sub/CANCEL',
  CREATE: 'target-secondary-channel-sub/CREATE',
  EDIT: 'target-secondary-channel-sub/EDIT',
  STORE: 'target-secondary-channel-sub/STORE',
  UPDATE: 'target-secondary-channel-sub/UPDATE',
  DELETE: 'target-secondary-channel-sub/DELETE',
  FILTER: 'target-secondary-channel-sub/FILTER',
  UPLOAD_FILE: 'target-secondary-channel-sub/UPLOAD_FILE',
  REMINDER: 'target-secondary-channel-sub/REMINDER',
}

export default actions
