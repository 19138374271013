const actions = {
  SET_STATE: 'distributor-primary/SET_STATE',
  SEARCH: 'distributor-primary/SEARCH',
  CANCEL: 'distributor-primary/CANCEL',
  CREATE: 'distributor-primary/CREATE',
  EDIT: 'distributor-primary/EDIT',
  STORE: 'distributor-primary/STORE',
  UPDATE: 'distributor-primary/UPDATE',
  DELETE: 'distributor-primary/DELETE',
  UPLOAD_FILE: 'distributor-primary/UPLOAD_FILE',
}

export default actions
