const actions = {
  SET_STATE: 'report-posm-summary/SET_STATE',
  SEARCH: 'report-posm-summary/SEARCH',
  FILTER: 'report-posm-summary/FILTER',
  CANCEL: 'report-posm-summary/CANCEL',
  DOWNLOAD: 'report-posm-summary/DOWNLOAD',
  REPROCESS_FILE: 'report-posm-summary/REPROCESS_FILE',
  LAST_UPDATED: 'report-posm-summary/LAST_UPDATED',
}

export default actions
