const actions = {
  SET_STATE: 'outlet-tl/SET_STATE',
  LOAD_OUTLET_TL: 'outlet-tl/LOAD_OUTLET_TL',
  CANCEL_OUTLET_TL: 'outlet-tl/CANCEL_OUTLET_TL',
  LOAD_FORM_CREATE: 'outlet-tl/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-tl/LOAD_FORM_EDIT',
  STORE_OUTLET_TL: 'outlet-tl/STORE_OUTLET_TL',
  UPDATE_OUTLET_TL: 'outlet-tl/UPDATE_OUTLET_TL',
  OPTIONS_DELETE: 'outlet-tl/OPTIONS_DELETE',
  DELETE_OUTLET_TL: 'outlet-tl/DELETE_OUTLET_TL',
  GET_PERMISSION: 'outlet-tl/GET_PERMISSION',
  SET_PERMISSION: 'outlet-tl/SET_PERMISSION',
  RESET_PASSWORD: 'outlet-tl/RESET_PASSWORD',
  FORCE_LOGOUT: 'outlet-tl/FORCE_LOGOUT',
  FILTER: 'outlet-tl/FILTER',
  REMINDER: 'outlet-tl/REMINDER',
}

export default actions
