import apiClient from 'services/axios'

export async function getPermission(parameter) {
  return apiClient
    .get('/user/permission/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createPermission(parameter = {}) {
  return apiClient
    .get('/user/permission/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storePermission(values) {
  return apiClient
    .post('/user/permission/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editPermission(id, parameter = {}) {
  return apiClient
    .get(`/user/permission/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePermission(id, values) {
  return apiClient
    .post(`/user/permission/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deletePermission(id) {
  return apiClient
    .delete(`/user/permission/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
