import actions from './actions'

const initialState = {
  id: '',
  nik: '',
  user_name: '',
  username: '',
  user_phone: '',
  user_image: '',
  email: '',
  role: '',
  dataLoadProfile: null,
  messageUploadFile: null,
  messageUploadFileError: null,
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  has_change_default_password: 0,
  loadingInit: false,
  // UNTUK LOADING KETIKA SUBMIT LOGIN, UPDATE PASSWORD
  loading: false,
  // UNTUK LOADING KETIKA LOAD PROFILE, SUBMIT PROFILE
  loadingProfile: false,
  clearFormPassword: false,
  isProfileLoaded: false,
  loadingLogout: false,
  loadingUpdatePassword: false,
  loadingImage: false,
  messageFormSuccess: null,
  // UNTUK ERROR KETIKA SUBMIT LOGIN, UPDATE PASSWORD
  error: null,
  // UNTUK ERROR KETIKA LOAD PROFILE, SUBMIT PROFILE
  errorProfile: null,
  errorInit: null,
  errorForm: null,
}

export default function userDistributorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL_PROFILE:
      return {
        ...state,
        loading: false,
        clearFormPassword: false,
        loadingProfile: false,
        isProfileLoaded: false,
        error: null,
        errorProfile: null,
      }
    default:
      return state
  }
}
