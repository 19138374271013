const actions = {
  SET_STATE: 'region/SET_STATE',
  LOAD_REGION: 'region/LOAD_REGION',
  CANCEL_REGION: 'region/CANCEL_REGION',
  LOAD_FORM_CREATE: 'region/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'region/LOAD_FORM_EDIT',
  STORE_REGION: 'region/STORE_REGION',
  UPDATE_REGION: 'region/UPDATE_REGION',
  DELETE_REGION: 'region/DELETE_REGION',
}

export default actions
