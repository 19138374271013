const actions = {
  SET_STATE: 'outlet-sales-inactive/SET_STATE',
  SEARCH: 'outlet-sales-inactive/SEARCH',
  CANCEL: 'outlet-sales-inactive/CANCEL',
  CREATE: 'outlet-sales-inactive/CREATE',
  EDIT: 'outlet-sales-inactive/EDIT',
  STORE: 'outlet-sales-inactive/STORE',
  UPDATE: 'outlet-sales-inactive/UPDATE',
  SWITCH_SALES_NAME: 'outlet-sales-inactive/SWITCH_SALES_NAME',
  DELETE: 'outlet-sales-inactive/DELETE',
}

export default actions
