const actions = {
  SET_STATE: 'outlet-dist-block/SET_STATE',
  LOAD_OUTLET_DIST_BLOCK: 'outlet-dist-block/LOAD_OUTLET_DIST_BLOCK',
  CANCEL_OUTLET_DIST_BLOCK: 'outlet-dist-block/CANCEL_OUTLET_DIST_BLOCK',
  LOAD_FORM_CREATE: 'outlet-dist-block/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-dist-block/LOAD_FORM_EDIT',
  STORE_OUTLET_DIST_BLOCK: 'outlet-dist-block/STORE_OUTLET_DIST_BLOCK',
  UPDATE_OUTLET_DIST_BLOCK: 'outlet-dist-block/UPDATE_OUTLET_DIST_BLOCK',
  DELETE_OUTLET_DIST_BLOCK: 'outlet-dist-block/DELETE_OUTLET_DIST_BLOCK',
  NEED_RELOAD: 'outlet-dist-block/NEED_RELOAD',
}

export default actions
