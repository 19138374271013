import apiClient from 'services/axios'

export async function getBrand(parameter) {
  return apiClient
    .get('/user/brand/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBrand(parameter = {}) {
  return apiClient
    .get('/user/brand/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeBrand(values) {
  return apiClient
    .post('/user/brand/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/brand/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBrand(id, values) {
  return apiClient
    .post(`/user/brand/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteBrand(id) {
  return apiClient
    .delete(`/user/brand/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
