import apiClient from 'services/axios'

export async function getEpmAreaSub(parameter) {
  return apiClient
    .get('/user/epm-area-sub/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createEpmAreaSub(parameter = {}) {
  return apiClient
    .get('/user/epm-area-sub/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeEpmAreaSub(values) {
  return apiClient
    .post('/user/epm-area-sub/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editEpmAreaSub(id, parameter = {}) {
  return apiClient
    .get(`/user/epm-area-sub/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateEpmAreaSub(id, values) {
  return apiClient
    .post(`/user/epm-area-sub/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteEpmAreaSub(id) {
  return apiClient
    .delete(`/user/epm-area-sub/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
