import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as service from 'services/webservice/user-access/distributor-file'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.distributorFile)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(service.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataSearch = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataCreate: null,
    dataEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  const result = yield race({
    data: call(service.edit),
    cancel: take(actions.CANCEL),
  })
  // const isCreate = action.type === actions.CREATE
  // if (isCreate) {
  //   result = yield race({
  //     data: call(service.create),
  //     cancel: take(actions.CANCEL),
  //   })
  // } else {
  // result = yield race({
  //   data: call(service.edit),
  //   cancel: take(actions.CANCEL),
  // })
  // }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    // if (isCreate) {
    //   newPayload.dataCreate = response ? response.data : null
    // } else {
    newPayload.dataEdit = response ? response.data : null
    // }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPDATE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    // const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(service.update, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE() {
  while (true) {
    const { payload } = yield take(actions.DELETE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(service.deleteData, payload.id),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, SEARCH),
    takeLatest([actions.EDIT], GET_FORM),
    UPDATE(),
    DELETE(),
  ])
}
