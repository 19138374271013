import actions from './actions'

const initialState = {
  dataReport: null,
  dataCreate: null,
  dataEdit: null,
  dataFilters: [],
  loading: false,
  loadingDownload: false,
  loadingLastUpdated: false,
  loadingReprocess: false,
  messageSuccess: null,
  messageError: null,
  messageDownloadFile: null,
  messageDownloadFileHeader: null,
  messageBulkResponse: null,
  messageBulkResponseError: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function reportBusinessReviewReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
