const actions = {
  SET_STATE: 'report-type-channel-by-salesman/SET_STATE',
  SEARCH: 'report-type-channel-by-salesman/SEARCH',
  FILTER: 'report-type-channel-by-salesman/FILTER',
  CANCEL: 'report-type-channel-by-salesman/CANCEL',
  DOWNLOAD: 'report-type-channel-by-salesman/DOWNLOAD',
  REPROCESS_FILE: 'report-type-channel-by-salesman/REPROCESS_FILE',
  LAST_UPDATED: 'report-type-channel-by-salesman/LAST_UPDATED',
}

export default actions
