const actions = {
  SET_STATE: 'quiz/SET_STATE',
  SEARCH: 'quiz/SEARCH',
  CANCEL: 'quiz/CANCEL',
  CREATE: 'quiz/CREATE',
  EDIT: 'quiz/EDIT',
  STORE: 'quiz/STORE',
  UPDATE: 'quiz/UPDATE',
  DELETE: 'quiz/DELETE',
  UPLOAD: 'quiz/UPLOAD',
}

export default actions
