import apiClient from 'services/axios'

export async function getProgramRegular(parameter) {
  return apiClient
    .get('/user/sfa-program-regular/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProgramRegular(parameter = {}) {
  return apiClient
    .get('/user/sfa-program-regular/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProgramRegular(values) {
  return apiClient
    .post('/user/sfa-program-regular/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProgramRegular(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-program-regular/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProgramRegular(id, values) {
  return apiClient
    .post(`/user/sfa-program-regular/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProgramRegular(id) {
  return apiClient
    .delete(`/user/sfa-program-regular/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProgramRegular(values) {
  return apiClient
    .post('/user/sfa-program-regular/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
