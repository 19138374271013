import apiClient from 'services/axios'

export async function getRangeVariant(parameter) {
  return apiClient
    .get('/user/range-variant/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createRangeVariant(parameter = {}) {
  return apiClient
    .get('/user/range-variant/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRangeVariant(values) {
  return apiClient
    .post('/user/range-variant/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editRangeVariant(id, parameter = {}) {
  return apiClient
    .get(`/user/range-variant/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateRangeVariant(id, values) {
  return apiClient
    .post(`/user/range-variant/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteRangeVariant(id) {
  return apiClient
    .delete(`/user/range-variant/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
