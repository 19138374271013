const actions = {
  SET_STATE: 'setting-po-summary-salesman/SET_STATE',
  LOAD_SETTING_PO_SUMMARY_SALESMAN: 'setting-po-summary-salesman/LOAD_SETTING_PO_SUMMARY_SALESMAN',
  CANCEL_SETTING_PO_SUMMARY_SALESMAN:
    'setting-po-summary-salesman/CANCEL_SETTING_PO_SUMMARY_SALESMAN',
  LOAD_FORM_CREATE: 'setting-po-summary-salesman/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'setting-po-summary-salesman/LOAD_FORM_EDIT',
  STORE_SETTING_PO_SUMMARY_SALESMAN:
    'setting-po-summary-salesman/STORE_SETTING_PO_SUMMARY_SALESMAN',
  UPDATE_SETTING_PO_SUMMARY_SALESMAN:
    'setting-po-summary-salesman/UPDATE_SETTING_PO_SUMMARY_SALESMAN',
  DELETE_SETTING_PO_SUMMARY_SALESMAN:
    'setting-po-summary-salesman/DELETE_SETTING_PO_SUMMARY_SALESMAN',
}

export default actions
