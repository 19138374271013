import apiClient from 'services/axios'

export async function getSettingConverter(parameter) {
  return apiClient
    .get('/user/setting-converter/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSettingConverter(parameter = {}) {
  return apiClient
    .get('/user/setting-converter/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSettingConverter(values) {
  return apiClient
    .post('/user/setting-converter/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSettingConverter(id, parameter = {}) {
  return apiClient
    .get(`/user/setting-converter/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSettingConverter(id, values) {
  return apiClient
    .post(`/user/setting-converter/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSettingConverter(id) {
  return apiClient
    .delete(`/user/setting-converter/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
