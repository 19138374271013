const actions = {
  SET_STATE: 'outlet-spg-type-inactive/SET_STATE',
  LOAD_SPG_TYPE: 'outlet-spg-type-inactive/LOAD_SPG_TYPE',
  CANCEL_SPG_TYPE: 'outlet-spg-type-inactive/CANCEL_SPG_TYPE',
  LOAD_FORM_CREATE: 'outlet-spg-type-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-spg-type-inactive/LOAD_FORM_EDIT',
  STORE_SPG_TYPE: 'outlet-spg-type-inactive/STORE_SPG_TYPE',
  UPDATE_SPG_TYPE: 'outlet-spg-type-inactive/UPDATE_SPG_TYPE',
  DELETE_SPG_TYPE: 'outlet-spg-type-inactive/DELETE_SPG_TYPE',
}

export default actions
