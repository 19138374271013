import apiClient from 'services/axios'

export async function getConsumerChannelRange(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel-range/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerChannelRange(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-channel-range/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerChannelRange(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-range/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerChannelRange(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-range/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerChannelRange(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-channel-range/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerChannelRange(id) {
  return apiClient
    .delete(`/user/sfa-consumer-channel-range/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerChannelRange(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-range/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-range/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
