const actions = {
  SET_STATE: 'outlet-dist-approval/SET_STATE',
  LOAD_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/LOAD_OUTLET_DIST_APPROVAL',
  CANCEL_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/CANCEL_OUTLET_DIST_APPROVAL',
  LOAD_FORM_CREATE: 'outlet-dist-approval/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-dist-approval/LOAD_FORM_EDIT',
  STORE_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/STORE_OUTLET_DIST_APPROVAL',
  UPDATE_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/UPDATE_OUTLET_DIST_APPROVAL',
  DELETE_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/DELETE_OUTLET_DIST_APPROVAL',
  BULK_OUTLET_DIST_APPROVAL: 'outlet-dist-approval/BULK_OUTLET_DIST_APPROVAL',
  NEED_RELOAD: 'outlet-dist-approval/NEED_RELOAD',
}

export default actions
