import apiClient from 'services/axios'

export async function getProductApproval(parameter) {
  return apiClient
    .get('/user/product-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductApproval(parameter = {}) {
  return apiClient
    .get('/user/product-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductApproval(values) {
  return apiClient
    .post('/user/product-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductApproval(id, parameter = {}) {
  return apiClient
    .get(`/user/product-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductApproval(id, values) {
  return apiClient
    .post(`/user/product-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductApproval(id) {
  return apiClient
    .delete(`/user/product-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkProductApproval(values) {
  return apiClient
    .post(`/user/product-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
