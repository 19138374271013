const actions = {
  SET_STATE: 'outlet-sales-type/SET_STATE',
  LOAD_SALES_TYPE: 'outlet-sales-type/LOAD_SALES_TYPE',
  CANCEL_SALES_TYPE: 'outlet-sales-type/CANCEL_SALES_TYPE',
  LOAD_FORM_CREATE: 'outlet-sales-type/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-sales-type/LOAD_FORM_EDIT',
  STORE_SALES_TYPE: 'outlet-sales-type/STORE_SALES_TYPE',
  UPDATE_SALES_TYPE: 'outlet-sales-type/UPDATE_SALES_TYPE',
  DELETE_SALES_TYPE: 'outlet-sales-type/DELETE_SALES_TYPE',
}

export default actions
