const actions = {
  SET_STATE: 'area-sub-inactive/SET_STATE',
  LOAD_AREA_SUB: 'area-sub-inactive/LOAD_AREA_SUB',
  CANCEL_AREA_SUB: 'area-sub-inactive/CANCEL_AREA_SUB',
  LOAD_FORM_CREATE: 'area-sub-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'area-sub-inactive/LOAD_FORM_EDIT',
  STORE_AREA_SUB: 'area-sub-inactive/STORE_AREA_SUB',
  UPDATE_AREA_SUB: 'area-sub-inactive/UPDATE_AREA_SUB',
  DELETE_AREA_SUB: 'area-sub-inactive/DELETE_AREA_SUB',
}

export default actions
