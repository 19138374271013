import apiClient from 'services/axios'

export async function filter(parameter) {
  return apiClient
    .get('/user/report-mail/options-report-mail', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function send(values) {
  return apiClient
    .post('/user/report-mail/send-report-mail', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
