const actions = {
  SET_STATE: 'brand-group-inactive/SET_STATE',
  LOAD_BRAND_GROUP: 'brand-group-inactive/LOAD_BRAND_GROUP',
  LOAD_FORM_CREATE: 'brand-group-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'brand-group-inactive/LOAD_FORM_EDIT',
  STORE_BRAND_GROUP: 'brand-group-inactive/STORE_BRAND_GROUP',
  UPDATE_BRAND_GROUP: 'brand-group-inactive/UPDATE_BRAND_GROUP',
  DELETE_BRAND_GROUP: 'brand-group-inactive/DELETE_BRAND_GROUP',
  CANCEL_BRAND_GROUP: 'brand-group-inactive/CANCEL_BRAND_GROUP',
}

export default actions
