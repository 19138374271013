const actions = {
  SET_STATE: 'distributor-primary-block/SET_STATE',
  SEARCH: 'distributor-primary-block/SEARCH',
  CANCEL: 'distributor-primary-block/CANCEL',
  CREATE: 'distributor-primary-block/CREATE',
  EDIT: 'distributor-primary-block/EDIT',
  STORE: 'distributor-primary-block/STORE',
  UPDATE: 'distributor-primary-block/UPDATE',
  DELETE: 'distributor-primary-block/DELETE',
  UPLOAD_FILE: 'distributor-primary-block/UPLOAD_FILE',
}

export default actions
