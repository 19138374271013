const actions = {
  SET_STATE: 'range-marketing/SET_STATE',
  LOAD_RANGE_MARKETING: 'range-marketing/LOAD_RANGE_MARKETING',
  CANCEL_RANGE_MARKETING: 'range-marketing/CANCEL_RANGE_MARKETING',
  LOAD_FORM_CREATE: 'range-marketing/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'range-marketing/LOAD_FORM_EDIT',
  STORE_RANGE_MARKETING: 'range-marketing/STORE_RANGE_MARKETING',
  UPDATE_RANGE_MARKETING: 'range-marketing/UPDATE_RANGE_MARKETING',
  DELETE_RANGE_MARKETING: 'range-marketing/DELETE_RANGE_MARKETING',
}

export default actions
