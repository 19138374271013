import apiClient from 'services/axios'

export async function getCategorySubStock(parameter) {
  return apiClient
    .get('/user/category-sub-stock/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategorySubStock(parameter = {}) {
  return apiClient
    .get('/user/category-sub-stock/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategorySubStock(values) {
  return apiClient
    .post('/user/category-sub-stock/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategorySubStock(id, parameter = {}) {
  return apiClient
    .get(`/user/category-sub-stock/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategorySubStock(id, values) {
  return apiClient
    .post(`/user/category-sub-stock/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategorySubStock(id) {
  return apiClient
    .delete(`/user/category-sub-stock/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
