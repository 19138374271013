import apiClient from 'services/axios'

export async function getOutletDist(parameter) {
  return apiClient
    .get('/user/outlet-dist/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletDist(parameter = {}) {
  return apiClient
    .get('/user/outlet-dist/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletDist(values) {
  return apiClient
    .post('/user/outlet-dist/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletDist(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletDist(id, values) {
  return apiClient
    .post(`/user/outlet-dist/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletDist(id) {
  return apiClient
    .delete(`/user/outlet-dist/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function permissionOutletDist(parameter = {}) {
  return apiClient
    .get(`/user/outlet-dist/permission`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get('/user/outlet-dist/filter')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-dist/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminderBlock(values) {
  return apiClient
    .post(`/user/outlet-dist-block/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
