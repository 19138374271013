import apiClient from 'services/axios'

export async function getConsumerChannelBrand(parameter) {
  return apiClient
    .get('/user/sfa-consumer-channel-brand/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerChannelBrand(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-channel-brand/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerChannelBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-brand/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerChannelBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-brand/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerChannelBrand(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-channel-brand/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerChannelBrand(id) {
  return apiClient
    .delete(`/user/sfa-consumer-channel-brand/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerChannelBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-channel-brand/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-channel-brand/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
