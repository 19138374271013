const actions = {
  SET_STATE: 'user-distributor/SET_STATE',
  LOGIN: 'user-distributor/LOGIN',
  REGISTER: 'user-distributor/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user-distributor/LOAD_CURRENT_ACCOUNT',
  LOAD_PROFILE: 'user-distributor/LOAD_PROFILE',
  CANCEL_PROFILE: 'user-distributor/CANCEL_PROFILE',
  LOGOUT: 'user-distributor/LOGOUT',
  UPDATE_PROFILE: 'user-distributor/UPDATE_PROFILE',
  USER_PROFILE: 'user-distributor/USER_PROFILE',
  UPDATE_PASSWORD: 'user-distributor/UPDATE_PASSWORD',
  UPDATE_PROFILE_IMAGE: 'user-distributor/UPDATE_PROFILE_IMAGE',
  UNAUTHENTICATED: 'user-distributor/UNAUTHENTICATED',
  AUTHENTICATED: 'user-distributor/AUTHENTICATED',
  RESET_PASSWORD: 'user-distributor/RESET_PASSWORD',
}

export default actions
