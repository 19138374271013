import actions from './actions'

const initialState = {
  current_year: null,
  current_month: null,
  current_period: null,

  dataFilter: [],
  loading: false,
  error: null,
  errorForm: false,
  errorDelete: null,
  messageFormSuccess: null,
  messageDelete: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },

  dataChartSalesDiagram: null,
  loadingChartSalesDiagram: false,
  errorChartSalesDiagram: null,

  // chart ke-2
  dataChartRegisterOutlet: null,
  errorChartRegisterOutlet: null,

  dataChartActiveOutlet: null,
  errorChartActiveOutlet: null,

  dataChartEffectiveCall: null,
  errorChartEffectiveCall: null,

  dataChartLineOfProduct: null,
  errorChartLineOfProduct: null,

  dataChartAtRisk: null,
  errorChartAtRisk: null,

  loadingChartRegisterOutletCs: false,

  dataSalesMtdYtd: null,
  errorSalesMtdYtd: null,
  loadingSalesMtdYtd: false,

  // chart ke-3
  dataChartAchievementBrand: null,
  errorChartAchievementBrand: null,
  loadingChartAchievementBrand: false,

  dataChartAchievementChannelSub: null,
  errorChartAchievementChannelSub: null,
  loadingChartAchievementChannelSub: false,

  // data pareto mt & gt
  dataAchievementTopParetoMt: null,
  errorAchievementTopParetoMt: null,
  loadingAchievementTopParetoMt: false,

  dataAchievementTopParetoGt: null,
  errorAchievementTopParetoGt: null,
  loadingAchievementTopParetoGt: false,

  // data RO AO NPD type channel
  dataRoNpdTypeChannel: null,
  errorRoNpdTypeChannel: null,
  loadingRoNpdTypeChannel: false,

  // data RO AO Pf Monthly
  dataFilterProductTopPfMonthly: null,
  errorFilterProductTopPfMonthly: null,
  dataRoPfMonthly: null,
  errorRoPfMonthly: null,
  loadingRopPfMonthly: false,

  // 6 MMA PF MONTHLY
  dataMmaPfMonthly: null,
  errorMmaPfMonthly: null,
  loadingMmaPfMonthly: false,
  dataFilterMmaPfMonthly: null,
  errorFilterMmaPfMonthly: null,

  // Channel no transaction
  dataChannelNoTransaction: null,
  errorChannelNoTransaction: null,
  loadingChannelNoTransaction: false,

  // distributor new terminate
  dataDistributorNewTerminate: null,
  errorDistributorNewTerminate: null,
  loadingDistributorNewTerminate: false,

  // product transaction channel
  dataProductTransactionChannel: null,
  errorProductTransactionChannel: null,
  loadingProductTransactionChannel: false,
  dataFilterProductTransactionChannel: null,
  errorFilterProductTransactionChannel: null,

  // SALES
  dataAchievementSales: null,
  errorAchievementSales: null,

  dataAchievementBrandSales: null,
  loadingAchievementBrandSales: false,
  errorAchievementBrandSales: null,
  dataAchievementBrandSalesFromFilter: null,
  line_chart_sales_id: null,

  dataAchievementChannelSubSales: null,
  loadingAchievementChannelSubSales: false,
  errorAchievementChannelSubSales: null,
  dataAchievementChannelSubSalesFromFilter: null,
  line_chart_sales_id_channel: null,

  dataRoNpdSales: null,
  errorRoNpdSales: null,
  loadingRoNpdSales: false,

  dataRoPfMonthlySales: null,
  errorRoPfMonthlySales: null,
  dataFilterRoPfMonthlySales: null,
  loadingRoPfMonthlySales: false,
  // untuk filter bisa memakai {dataFilterProductTopPfMonthly}

  dataRateBs: null,
  errorRateBs: null,
  loadingRateBs: false,
  dataFilterRateBs: null,
}

export default function dashboardChartReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
