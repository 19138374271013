import apiClient from 'services/axios'

export async function getConsumerDistributorBrand(parameter) {
  return apiClient
    .get('/user/sfa-consumer-distributor-brand/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerDistributorBrand(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-distributor-brand/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerDistributorBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-brand/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerDistributorBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-brand/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerDistributorBrand(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-distributor-brand/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerDistributorBrand(id) {
  return apiClient
    .delete(`/user/sfa-consumer-distributor-brand/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerDistributorBrand(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-brand/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-brand/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
