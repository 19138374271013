import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as transDistUploadService from 'services/webservice/user-access/trans-dist-upload'
import { saveAs } from 'file-saver'
import actions from './actions'

const loadingDownloads = []

function* GET_COMBINE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.combine)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(transDistUploadService.combine, payload),
    cancel: take(actions.CANCEL_COMBINE),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataCombine = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD_COMBINE({ payload }) {
  loadingDownloads.push(payload.id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: loadingDownloads,
    },
  })
  const result = yield race({
    data: call(transDistUploadService.download, payload.id, event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total)
      put({
        type: actions.SET_STATE,
        payload: {
          progressDownload: `${percentCompleted} %`,
        },
      })
    }),
    cancel: take(actions.CANCEL_COMBINE),
  })
  if (result.data) {
    const { response, error } = result.data
    const index = loadingDownloads.indexOf(payload.id)
    if (index > -1) {
      loadingDownloads.splice(index, 1)
    }
    console.log(loadingDownloads)

    const newPayload = {
      loadingDownload: loadingDownloads,
      finishDownloadId: payload.id,
      error: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadFile = `File is successfully downloaded!`
      newPayload.messageDownloadFileHeader = 'SUCCESS'
    } else {
      newPayload.messageDownloadFileHeader = 'FAILURE'
      newPayload.messageDownloadFile = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_COMBINE, GET_COMBINE),
    takeLatest(actions.DOWNLOAD_COMBINE, DOWNLOAD_COMBINE),
  ])
}
