import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import actions from './actions'

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataCreate: null,
    dataEdit: null,
    dataFilters: null,
    listSales: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  switch (action.type) {
    case actions.CREATE:
      result = yield race({
        data: call(),
        cancel: take(actions.CANCEL),
      })
      break
    case actions.EDIT:
      result = yield race({
        data: call(payload.id),
        cancel: take(actions.CANCEL),
      })
      break
    case actions.FILTER:
      result = yield race({
        data: call(payload),
        cancel: take(actions.CANCEL),
      })
      break
    case actions.LIST_SALES:
      result = yield race({
        data: call(payload.id),
        cancel: take(actions.CANCEL),
      })
      break

    default:
      break
  }

  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.CREATE:
        newPayload.dataCreate = response ? response.data : null
        break
      case actions.EDIT:
        newPayload.dataEdit = response ? response.data : null
        break
      case actions.FILTER:
        newPayload.dataFilters = response ? response.data : null
        break
      case actions.LIST_SALES:
        newPayload.listSales = response ? response.data : null
        break
      default:
        break
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest([actions.LIST_SALES], GET_FORM)])
}
