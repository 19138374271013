import apiClient from 'services/axios'

export async function getProductRange(parameter) {
  return apiClient
    .get('/user/range/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductRange(parameter = {}) {
  return apiClient
    .get('/user/range/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductRange(values) {
  return apiClient
    .post('/user/range/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductRange(id, parameter = {}) {
  return apiClient
    .get(`/user/range/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductRange(id, values) {
  return apiClient
    .post(`/user/range/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductRange(id) {
  return apiClient
    .delete(`/user/range/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
