import apiClient from 'services/axios'

export async function getCategoryVitalis(parameter = {}) {
  return apiClient
    .get('/user/category-vitalis/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategoryVitalis(parameter = {}) {
  return apiClient
    .get('/user/category-vitalis/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryVitalis(values) {
  return apiClient
    .post('/user/category-vitalis/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategoryVitalis(id, parameter = {}) {
  return apiClient
    .get(`/user/category-vitalis/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategoryVitalis(id, values) {
  return apiClient
    .post(`/user/category-vitalis/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategoryVitalis(id) {
  return apiClient
    .delete(`/user/category-vitalis/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
