import actions from './actions'

const initialState = {
  dataFilters: [],
  loading: false,
  messageSuccess: null,
  messageError: null,
}

export default function reportIncentiveSalesmanReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
