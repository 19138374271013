const actions = {
  SET_STATE: 'category-accenture-inactive/SET_STATE',
  LOAD_CATEGORY_ACCENTURE: 'category-accenture-inactive/LOAD_CATEGORY_ACCENTURE',
  CANCEL_CATEGORY_ACCENTURE: 'category-accenture-inactive/CANCEL_CATEGORY_ACCENTURE',
  LOAD_FORM_CREATE: 'category-accenture-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-accenture-inactive/LOAD_FORM_EDIT',
  STORE_CATEGORY_ACCENTURE: 'category-accenture-inactive/STORE_CATEGORY_ACCENTURE',
  UPDATE_CATEGORY_ACCENTURE: 'category-accenture-inactive/UPDATE_CATEGORY_ACCENTURE',
  DELETE_CATEGORY_ACCENTURE: 'category-accenture-inactive/DELETE_CATEGORY_ACCENTURE',
}

export default actions
