import apiClient from 'services/axios'

export async function getProductTop(parameter) {
  return apiClient
    .get('/user/product-top/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductTop(parameter = {}) {
  return apiClient
    .get('/user/product-top/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductTop(values) {
  return apiClient
    .post('/user/product-top/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductTop(id, parameter = {}) {
  return apiClient
    .get(`/user/product-top/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductTop(id, values) {
  return apiClient
    .post(`/user/product-top/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductTop(id) {
  return apiClient
    .delete(`/user/product-top/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-top/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
