import apiClient from 'services/axios'

export async function getPosition(parameter) {
  return apiClient
    .get('/user/position/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createPosition(parameter = {}) {
  return apiClient
    .get('/user/position/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storePosition(values) {
  return apiClient
    .post('/user/position/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editPosition(id, parameter = {}) {
  return apiClient
    .get(`/user/position/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePosition(id, values) {
  return apiClient
    .post(`/user/position/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deletePosition(id) {
  return apiClient
    .delete(`/user/position/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download() {
  return apiClient
    .get(`/user/position/download-file`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter() {
  return apiClient
    .get(`/user/position/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
