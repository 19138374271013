const actions = {
  SET_STATE: 'target-day-of-work/SET_STATE',
  SEARCH: 'target-day-of-work/SEARCH',
  CREATE: 'target-day-of-work/CREATE',
  EDIT: 'target-day-of-work/EDIT',
  STORE: 'target-day-of-work/STORE',
  UPDATE: 'target-day-of-work/UPDATE',
  DELETE: 'target-day-of-work/DELETE',
  CANCEL: 'target-day-of-work/CANCEL',
  UPLOAD_FILE: 'target-day-of-work/UPLOAD_FILE',
}

export default actions
