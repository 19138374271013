import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-sfa/md'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(distributor.authorized ? mService.filterMdDistributor : mService.filterMd, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_MAP_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMap: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.mapDataMdDistributor : mService.mapDataMd,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMap: false,
      errorMap: null,
    }
    if (response) {
      newPayload.dataMap = response.data
    } else {
      newPayload.errorMap = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DAILY_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDaily: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.dailyDataMdDistributor : mService.dailyDataMd,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDaily: false,
      errorDaily: null,
    }
    if (response) {
      newPayload.dataDaily = response.data
    } else {
      newPayload.errorDaily = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.LOAD_MAP_DATA, LOAD_MAP_DATA),
    takeLatest(actions.LOAD_DAILY_DATA, LOAD_DAILY_DATA),
  ])
}
