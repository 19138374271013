const actions = {
  SET_STATE: 'report-absen-spv/SET_STATE',
  SEARCH: 'report-absen-spv/SEARCH',
  FILTER: 'report-absen-spv/FILTER',
  CANCEL: 'report-absen-spv/CANCEL',
  DOWNLOAD: 'report-absen-spv/DOWNLOAD',
  DOWNLOAD_TEXT: 'report-absen-spv/DOWNLOAD_TEXT',
  REPROCESS_FILE: 'report-absen-spv/REPROCESS_FILE',
  LAST_UPDATED: 'report-absen-spv/LAST_UPDATED',
}

export default actions
