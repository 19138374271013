const actions = {
  SET_STATE: 'category-ceo/SET_STATE',
  LOAD_CATEGORY_CEO: 'category-ceo/LOAD_CATEGORY_CEO',
  CANCEL_CATEGORY_CEO: 'category-ceo/CANCEL_CATEGORY_CEO',
  LOAD_FORM_CREATE: 'category-ceo/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-ceo/LOAD_FORM_EDIT',
  STORE_CATEGORY_CEO: 'category-ceo/STORE_CATEGORY_CEO',
  UPDATE_CATEGORY_CEO: 'category-ceo/UPDATE_CATEGORY_CEO',
  DELETE_CATEGORY_CEO: 'category-ceo/DELETE_CATEGORY_CEO',
}

export default actions
