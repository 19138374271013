import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as sfaProductTopService from 'services/webservice/user-access/master-sfa/product-top'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_SFA_PRODUCT_TOP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.sfaProductTop)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(sfaProductTopService.getSfaProductTop, payload),
    cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataSfaProductTop = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataSfaProductTopCreate: null,
    dataSfaProductTopEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.LOAD_FORM_CREATE
  if (isCreate) {
    result = yield race({
      data: call(sfaProductTopService.createSfaProductTop),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
  } else {
    result = yield race({
      data: call(sfaProductTopService.editSfaProductTop, payload.id),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataSfaProductTopCreate = response ? response.data : null
    } else {
      newPayload.dataSfaProductTopEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_SFA_PRODUCT_TOP() {
  while (true) {
    const { payload } = yield take(actions.STORE_SFA_PRODUCT_TOP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(sfaProductTopService.storeSfaProductTop, payload),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_SFA_PRODUCT_TOP() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_SFA_PRODUCT_TOP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(sfaProductTopService.updateSfaProductTop, currentId, payload),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_SFA_PRODUCT_TOP() {
  while (true) {
    const { payload } = yield take(actions.DELETE_SFA_PRODUCT_TOP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(sfaProductTopService.deleteSfaProductTop, payload.id),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_FILE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingUpload: true,
        messageUploadFile: null,
      },
    })
    const result = yield race({
      data: call(sfaProductTopService.uploadFile, payload),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
    if (result.data) {
      const newPayload = {
        loadingUpload: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* LIST_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingOutlet: true,
      listOutlet: null,
    },
  })
  const result = yield race({
    data: call(sfaProductTopService.listOutlet, payload),
    cancel: take([
      actions.CANCEL_SFA_PRODUCT_TOP,
      actions.CANCEL_LIST_OUTLET,
      actions.STORE_SFA_PRODUCT_TOP,
      actions.UPDATE_SFA_PRODUCT_TOP,
    ]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingOutlet: false,
      errorOutlet: null,
    }
    if (response) {
      newPayload.listOutlet = response.data
    } else {
      newPayload.errorOutlet = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        listOutlet: null,
        loadingOutlet: false,
        errorOutlet: null,
      },
    })
  }
}

function* FLUSH_MSS() {
  while (true) {
    const { payload } = yield take(actions.FLUSH_MSS_SFA_PRODUCT_TOP)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingFlushMss: true,
      },
    })
    const result = yield race({
      data: call(sfaProductTopService.flushDataMss, payload),
      cancel: take(actions.CANCEL_SFA_PRODUCT_TOP),
    })
    if (result.data) {
      const newPayload = {
        loadingFlushMss: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFlushMssSuccess = response.data
      }
      newPayload.messageFlushMssError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_SFA_PRODUCT_TOP, GET_SFA_PRODUCT_TOP),
    takeLatest([actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT], GET_FORM),
    takeLatest(actions.LIST_OUTLET, LIST_OUTLET),
    STORE_SFA_PRODUCT_TOP(),
    UPDATE_SFA_PRODUCT_TOP(),
    DELETE_SFA_PRODUCT_TOP(),
    UPLOAD(),
    FLUSH_MSS(),
  ])
}
