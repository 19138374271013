import apiClient from 'services/axios'

export async function filter(parameter) {
  return apiClient
    .get('/user/report-be-ass/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/report-be-ass/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function searchLog(parameter) {
  return apiClient
    .get('/user/report-be-ass/log-upload-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadUploadFile(parameter) {
  return apiClient
    .get(`/user/report-be-ass/download-upload-file/${parameter.id}`, {
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
