const actions = {
  SET_STATE: 'sfa-product-top-inactive/SET_STATE',
  LOAD_SFA_PRODUCT_TOP: 'sfa-product-top-inactive/LOAD_SFA_PRODUCT_TOP',
  CANCEL_SFA_PRODUCT_TOP: 'sfa-product-top-inactive/CANCEL_SFA_PRODUCT_TOP',
  CANCEL_LIST_OUTLET: 'sfa-product-top-inactive/CANCEL_LIST_OUTLET',
  LOAD_FORM_CREATE: 'sfa-product-top-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'sfa-product-top-inactive/LOAD_FORM_EDIT',
  STORE_SFA_PRODUCT_TOP: 'sfa-product-top-inactive/STORE_SFA_PRODUCT_TOP',
  UPDATE_SFA_PRODUCT_TOP: 'sfa-product-top-inactive/UPDATE_SFA_PRODUCT_TOP',
  DELETE_SFA_PRODUCT_TOP: 'sfa-product-top-inactive/DELETE_SFA_PRODUCT_TOP',
  LIST_OUTLET: 'sfa-product-top-inactive/LIST_OUTLET',
}

export default actions
