import apiClient from 'services/axios'

export async function getRegion(parameter) {
  return apiClient
    .get('/user/region/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createRegion(parameter = {}) {
  return apiClient
    .get('/user/region/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRegion(values) {
  return apiClient
    .post('/user/region/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editRegion(id, parameter = {}) {
  return apiClient
    .get(`/user/region/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateRegion(id, values) {
  return apiClient
    .post(`/user/region/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteRegion(id) {
  return apiClient
    .delete(`/user/region/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
