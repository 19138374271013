const actions = {
  SET_STATE: 'report-recap-md-spg-ewom/SET_STATE',
  SEARCH: 'report-recap-md-spg-ewom/SEARCH',
  FILTER: 'report-recap-md-spg-ewom/FILTER',
  CANCEL: 'report-recap-md-spg-ewom/CANCEL',
  DOWNLOAD: 'report-recap-md-spg-ewom/DOWNLOAD',
  REPROCESS_FILE: 'report-recap-md-spg-ewom/REPROCESS_FILE',
  LAST_UPDATED: 'report-recap-md-spg-ewom/LAST_UPDATED',
  CREATE_SNAPSHOT: 'report-recap-md-spg-ewom/CREATE_SNAPSHOT',
}

export default actions
