import apiClient from 'services/axios'

export async function filterSales(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/filter-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataSales(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/map-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataSales(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/daily-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function absenDataSales(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/absen-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateAbsenDataSales(id, values) {
  return apiClient
    .post(`/user/dashboard-sfa/absen-data-sales/${id}/time-off`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function cancelAbsenDataSales(id) {
  return apiClient
    .delete(`/user/dashboard-sfa/absen-data-sales/${id}/cancel-time-off`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterSalesDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/filter-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataSalesDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/map-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataSalesDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/daily-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function absenDataSalesDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/absen-data-sales', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
