import apiClient from 'services/axios'

export async function getProgramRental(parameter) {
  return apiClient
    .get('/user/sfa-program-rental/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProgramRental(parameter = {}) {
  return apiClient
    .get('/user/sfa-program-rental/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProgramRental(values) {
  return apiClient
    .post('/user/sfa-program-rental/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProgramRental(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-program-rental/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProgramRental(id, values) {
  return apiClient
    .post(`/user/sfa-program-rental/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProgramRental(id) {
  return apiClient
    .delete(`/user/sfa-program-rental/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProgramRental(values) {
  return apiClient
    .post('/user/sfa-program-rental/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-program-rental/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
