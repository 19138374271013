import localeAntd from 'antd/es/locale/en_US'

export const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.backToHome': 'Back to Home',
  add: 'Add',
  edit: 'Edit',
  mapping: 'Mapping',
  active: 'Active',
  inactive: 'Inactive',
  activate: 'Activate',
  inactivate: 'Inactivate',
  cancel_time_off: 'Cancel Time Off',
  update_time_off: 'Update Time Off',
  delete: 'Delete',
  on: 'ON',
  off: 'OFF',
  block: 'Block',
  unblock: 'Unblock',
  approveQuestion: 'Are you sure you want to approve this data ?',
  submitQuestion: 'Are you sure you want to submit this data ?',
  deleteQuestion: 'Are you sure you want to delete this data ?',
  flushQuestion: 'Are you sure you want to flush :type data ?',
  notFound: 'NOT FOUND',
  blockQuestion: 'Are you sure you want to block this data?',
  resetQuestion: 'Are you sure you want to reset all of the :model ?',
  saveQuestion: 'Are you sure you want to save all of the :model ?',
  chooseDistChild: 'Please select dist child above before :action current data',
  chooseChannelSub: 'Please select channel sub above before :action current data',
  chooseParentDist: 'Please select parent distributor above before :action current data',
  statusQuestion: 'Area you sure you want to :status this data ?',
  resetPasswordQuestion: 'Are you sure you want to reset current :subject password ?',
  forceLogoutQuestion: 'Area you sure you want to force logout current :subject ?',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
