import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard'
import actions from './actions'

function* PROGRESS_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressProduct: true,
    },
  })

  const result = yield race({
    data: call(mService.progressProduct, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressProduct: false,
      errorProgressProduct: null,
    }
    if (response) {
      newPayload.progressProduct = response.data
    } else {
      newPayload.errorProgressProduct = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_PRODUCT_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressProductDist: true,
    },
  })

  const result = yield race({
    data: call(mService.progressProductDist, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressProductDist: false,
      errorProgressProductDist: null,
    }
    if (response) {
      newPayload.progressProductDist = response.data
    } else {
      newPayload.errorProgressProductDist = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_PRODUCT_DIST_BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressProductDistBlock: true,
    },
  })

  const result = yield race({
    data: call(mService.progressProductDistBlock, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressProductDistBlock: false,
      errorProgressProductDistBlock: null,
    }
    if (response) {
      newPayload.progressProductDistBlock = response.data
    } else {
      newPayload.errorProgressProductDistBlock = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutlet: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutlet, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutlet: false,
      errorProgressOutlet: null,
    }
    if (response) {
      newPayload.progressOutlet = response.data
    } else {
      newPayload.errorProgressOutlet = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_LATLNG_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletLatLngImage: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletLatLngImage, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletLatLngImage: false,
      errorProgressOutletLatLngImage: null,
    }
    if (response) {
      newPayload.progressOutletLatLngImage = response.data
    } else {
      newPayload.errorProgressOutletLatLngImage = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletDist: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletDist, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletDist: false,
      errorProgressOutletDist: null,
    }
    if (response) {
      newPayload.progressOutletDist = response.data
    } else {
      newPayload.errorProgressOutletDist = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_DIST_BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletDistBlock: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletDistBlock, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletDistBlock: false,
      errorProgressOutletDistBlock: null,
    }
    if (response) {
      newPayload.progressOutletDistBlock = response.data
    } else {
      newPayload.errorProgressOutletDistBlock = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletSales: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletSales, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletSales: false,
      errorProgressOutletSales: null,
    }
    if (response) {
      newPayload.progressOutletSales = response.data
    } else {
      newPayload.errorProgressOutletSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_SALES_SPV({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletSalesSpv: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletSalesSpv, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletSalesSpv: false,
      errorProgressOutletSalesSpv: null,
    }
    if (response) {
      newPayload.progressOutletSalesSpv = response.data
    } else {
      newPayload.errorProgressOutletSalesSpv = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_SPG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletSpg: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletSpg, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletSpg: false,
      errorProgressOutletSpg: null,
    }
    if (response) {
      newPayload.progressOutletSpg = response.data
    } else {
      newPayload.errorProgressOutletSpg = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_MD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletMd: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletMd, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletMd: false,
      errorProgressOutletMd: null,
    }
    if (response) {
      newPayload.progressOutletMd = response.data
    } else {
      newPayload.errorProgressOutletMd = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_TL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletTl: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletTl, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletTl: false,
      errorProgressOutletTl: null,
    }
    if (response) {
      newPayload.progressOutletTl = response.data
    } else {
      newPayload.errorProgressOutletTl = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_OUTLET_TL_SPV({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressOutletTlSpv: true,
    },
  })

  const result = yield race({
    data: call(mService.progressOutletTlSpv, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressOutletTlSpv: false,
      errorProgressOutletTlSpv: null,
    }
    if (response) {
      newPayload.progressOutletTlSpv = response.data
    } else {
      newPayload.errorProgressOutletTlSpv = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_PRIMARY_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetPrimarySales: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetPrimarySales, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetPrimarySales: false,
      errorProgressTargetPrimarySales: null,
    }
    if (response) {
      newPayload.progressTargetPrimarySales = response.data
    } else {
      newPayload.errorProgressTargetPrimarySales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetDist: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetDist, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetDist: false,
      errorProgressTargetDist: null,
    }
    if (response) {
      newPayload.progressTargetDist = response.data
    } else {
      newPayload.errorProgressTargetDist = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSales: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSales, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSales: false,
      errorProgressTargetSales: null,
    }
    if (response) {
      newPayload.progressTargetSales = response.data
    } else {
      newPayload.errorProgressTargetSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_BRAND({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetBrand: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetBrand, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetBrand: false,
      errorProgressTargetBrand: null,
    }
    if (response) {
      newPayload.progressTargetBrand = response.data
    } else {
      newPayload.errorProgressTargetBrand = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_CHANNEL_SUB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetChannelSub: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetChannelSub, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetChannelSub: false,
      errorProgressTargetChannelSub: null,
    }
    if (response) {
      newPayload.progressTargetChannelSub = response.data
    } else {
      newPayload.errorProgressTargetChannelSub = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES_LCS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSalesLcs: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSalesLcs, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSalesLcs: false,
      errorProgressTargetSalesLcs: null,
    }
    if (response) {
      newPayload.progressTargetSalesLcs = response.data
    } else {
      newPayload.errorProgressTargetSalesLcs = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES_CB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSalesCb: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSalesCb, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSalesCb: false,
      errorProgressTargetSalesCb: null,
    }
    if (response) {
      newPayload.progressTargetSalesCb = response.data
    } else {
      newPayload.errorProgressTargetSalesCb = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES_RENTAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSalesRental: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSalesRental, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSalesRental: false,
      errorProgressTargetSalesRental: null,
    }
    if (response) {
      newPayload.progressTargetSalesRental = response.data
    } else {
      newPayload.errorProgressTargetSalesRental = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSalesOutlet: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSalesOutlet, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSalesOutlet: false,
      errorProgressTargetSalesOutlet: null,
    }
    if (response) {
      newPayload.progressTargetSalesOutlet = response.data
    } else {
      newPayload.errorProgressTargetSalesOutlet = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_TARGET_SALES_SPG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressTargetSalesSpg: true,
    },
  })

  const result = yield race({
    data: call(mService.progressTargetSalesSpg, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressTargetSalesSpg: false,
      errorProgressTargetSalesSpg: null,
    }
    if (response) {
      newPayload.progressTargetSalesSpg = response.data
    } else {
      newPayload.errorProgressTargetSalesSpg = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_BALANCE_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressBalanceSummary: true,
    },
  })

  const result = yield race({
    data: call(mService.progressBalanceSummary, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressBalanceSummary: false,
      errorProgressBalanceSummary: null,
    }
    if (response) {
      newPayload.progressBalanceSummary = response.data
    } else {
      newPayload.errorProgressBalanceSummary = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_DISTRIBUTOR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressDistributor: true,
    },
  })

  const result = yield race({
    data: call(mService.progressDistributor, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressDistributor: false,
      errorProgressDistributor: null,
    }
    if (response) {
      newPayload.progressDistributor = response.data
    } else {
      newPayload.errorProgressDistributor = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PROGRESS_CERTIFICATE_GT_ONLINE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProgressCertificateGtOnline: true,
    },
  })

  const result = yield race({
    data: call(mService.progressCertificateGtOnline, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProgressCertificateGtOnline: false,
      errorProgressCertificateGtOnline: null,
    }
    if (response) {
      newPayload.progressCertificateGtOnline = response.data
    } else {
      newPayload.errorProgressCertificateGtOnline = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListProduct: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listProduct, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListProduct: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listProduct = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_PRODUCT_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListProductDist: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listProductDist, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListProductDist: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listProductDist = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_PRODUCT_DIST_BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListProductDistBlock: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listProductDistBlock, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListProductDistBlock: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listProductDistBlock = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutlet: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutlet, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutlet: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutlet = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_LATLNG_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletLatLngImage: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }

  const result = yield race({
    data: call(mService.listOutletLatLngImage, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletLatLngImage: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletLatLngImage = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletDist: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletDist, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletDist: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletDist = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_DIST_NEW({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletDistNew: true,
    },
  })
  const { currentPaginationNew: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletDistNew, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletDistNew: false,
      errorListNew: null,
    }
    if (response) {
      newPayload.currentPaginationNew = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.orderNew = { ...order }

      newPayload.listOutletDistNew = response.data
    } else {
      newPayload.errorListNew = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_DIST_BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletDistBlock: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletDistBlock, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletDistBlock: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletDistBlock = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletSales: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletSales, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletSales: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletSales = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_SALES_SPV({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletSalesSpv: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletSalesSpv, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletSalesSpv: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletSalesSpv = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_SPG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletSpg: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletSpg, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletSpg: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletSpg = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_MD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletMd: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletMd, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletMd: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletMd = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_TL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletTl: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletTl, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletTl: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletTl = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_OUTLET_TL_SPV({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListOutletTlSpv: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listOutletTlSpv, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListOutletTlSpv: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listOutletTlSpv = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_PRIMARY_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetPrimarySales: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetPrimarySales, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetPrimarySales: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetPrimarySales = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSales: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSales, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSales: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSales = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_DIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetDist: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetDist, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetDist: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetDist = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_BRAND({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetBrand: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetBrand, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetBrand: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetBrand = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_CHANNEL_SUB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetChannelSub: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetChannelSub, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetChannelSub: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetChannelSub = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES_LCS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSalesLcs: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSalesLcs, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSalesLcs: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSalesLcs = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES_CB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSalesCb: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSalesCb, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSalesCb: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSalesCb = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES_RENTAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSalesRental: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSalesRental, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSalesRental: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSalesRental = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSalesOutlet: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSalesOutlet, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSalesOutlet: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSalesOutlet = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_TARGET_SALES_SPG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListTargetSalesSpg: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listTargetSalesSpg, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListTargetSalesSpg: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listTargetSalesSpg = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_BALANCE_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListBalanceSummary: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listBalanceSummary, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListBalanceSummary: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listBalanceSummary = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_DISTRIBUTOR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListDistributor: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listDistributor, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListDistributor: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listDistributor = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LIST_CERTIFICATE_GT_ONLINE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingListCertificateGtOnline: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.dashboard)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const currentId = payload.id

  const result = yield race({
    data: call(mService.listCertificateGtOnline, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingListCertificateGtOnline: false,
      errorList: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }

      newPayload.listCertificateGtOnline = response.data
    } else {
      newPayload.errorList = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OUTLET_FILTER_AREA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingOutletFilter: true,
    },
  })

  const currentId = payload.id

  const result = yield race({
    data: call(mService.outletFilterArea, currentId, payload),
    cancel: take([actions.CANCEL, actions.RESET_MODAL_PROGRESS]),
  })

  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingOutletFilter: false,
      errorFilterOutlet: null,
    }
    if (response) {
      newPayload.dataFilterOutlet = response.data
    } else {
      newPayload.errorFilterOutlet = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.PROGRESS_PRODUCT, PROGRESS_PRODUCT),
    takeLatest(actions.PROGRESS_PRODUCT_DIST, PROGRESS_PRODUCT_DIST),
    takeLatest(actions.PROGRESS_PRODUCT_DIST_BLOCK, PROGRESS_PRODUCT_DIST_BLOCK),
    takeLatest(actions.PROGRESS_OUTLET, PROGRESS_OUTLET),
    takeLatest(actions.PROGRESS_OUTLET_LATLNG_IMAGE, PROGRESS_OUTLET_LATLNG_IMAGE),
    takeLatest(actions.PROGRESS_OUTLET_DIST, PROGRESS_OUTLET_DIST),
    takeLatest(actions.PROGRESS_OUTLET_DIST_BLOCK, PROGRESS_OUTLET_DIST_BLOCK),
    takeLatest(actions.PROGRESS_OUTLET_SALES, PROGRESS_OUTLET_SALES),
    takeLatest(actions.PROGRESS_OUTLET_SPG, PROGRESS_OUTLET_SPG),
    takeLatest(actions.PROGRESS_OUTLET_SALES_SPV, PROGRESS_OUTLET_SALES_SPV),
    takeLatest(actions.PROGRESS_OUTLET_TL, PROGRESS_OUTLET_TL),
    takeLatest(actions.PROGRESS_OUTLET_TL_SPV, PROGRESS_OUTLET_TL_SPV),
    takeLatest(actions.PROGRESS_OUTLET_MD, PROGRESS_OUTLET_MD),
    takeLatest(actions.PROGRESS_TARGET_PRIMARY_SALES, PROGRESS_TARGET_PRIMARY_SALES),
    takeLatest(actions.PROGRESS_TARGET_DIST, PROGRESS_TARGET_DIST),
    takeLatest(actions.PROGRESS_TARGET_SALES, PROGRESS_TARGET_SALES),
    takeLatest(actions.PROGRESS_TARGET_BRAND, PROGRESS_TARGET_BRAND),
    takeLatest(actions.PROGRESS_TARGET_CHANNEL_SUB, PROGRESS_TARGET_CHANNEL_SUB),
    takeLatest(actions.PROGRESS_TARGET_SALES_LCS, PROGRESS_TARGET_SALES_LCS),
    takeLatest(actions.PROGRESS_TARGET_SALES_CB, PROGRESS_TARGET_SALES_CB),
    takeLatest(actions.PROGRESS_TARGET_SALES_RENTAL, PROGRESS_TARGET_SALES_RENTAL),
    takeLatest(actions.PROGRESS_TARGET_SALES_OUTLET, PROGRESS_TARGET_SALES_OUTLET),
    takeLatest(actions.PROGRESS_TARGET_SALES_SPG, PROGRESS_TARGET_SALES_SPG),
    takeLatest(actions.PROGRESS_BALANCE_SUMMARY, PROGRESS_BALANCE_SUMMARY),
    takeLatest(actions.PROGRESS_DISTRIBUTOR, PROGRESS_DISTRIBUTOR),
    takeLatest(actions.PROGRESS_CERTIFICATE_GT_ONLINE, PROGRESS_CERTIFICATE_GT_ONLINE),

    takeLatest(actions.LIST_PRODUCT, LIST_PRODUCT),
    takeLatest(actions.LIST_PRODUCT_DIST, LIST_PRODUCT_DIST),
    takeLatest(actions.LIST_PRODUCT_DIST_BLOCK, LIST_PRODUCT_DIST_BLOCK),
    takeLatest(actions.LIST_OUTLET, LIST_OUTLET),
    takeLatest(actions.LIST_OUTLET_LATLNG_IMAGE, LIST_OUTLET_LATLNG_IMAGE),
    takeLatest(actions.LIST_OUTLET_DIST, LIST_OUTLET_DIST),
    takeLatest(actions.LIST_OUTLET_DIST_NEW, LIST_OUTLET_DIST_NEW),
    takeLatest(actions.LIST_OUTLET_DIST_BLOCK, LIST_OUTLET_DIST_BLOCK),
    takeLatest(actions.LIST_OUTLET_SALES, LIST_OUTLET_SALES),
    takeLatest(actions.LIST_OUTLET_SPG, LIST_OUTLET_SPG),
    takeLatest(actions.LIST_OUTLET_SALES_SPV, LIST_OUTLET_SALES_SPV),
    takeLatest(actions.LIST_OUTLET_TL, LIST_OUTLET_TL),
    takeLatest(actions.LIST_OUTLET_TL_SPV, LIST_OUTLET_TL_SPV),
    takeLatest(actions.LIST_OUTLET_MD, LIST_OUTLET_MD),
    takeLatest(actions.LIST_TARGET_PRIMARY_SALES, LIST_TARGET_PRIMARY_SALES),
    takeLatest(actions.LIST_TARGET_DIST, LIST_TARGET_DIST),
    takeLatest(actions.LIST_TARGET_SALES, LIST_TARGET_SALES),
    takeLatest(actions.LIST_TARGET_BRAND, LIST_TARGET_BRAND),
    takeLatest(actions.LIST_TARGET_CHANNEL_SUB, LIST_TARGET_CHANNEL_SUB),
    takeLatest(actions.LIST_TARGET_SALES_LCS, LIST_TARGET_SALES_LCS),
    takeLatest(actions.LIST_TARGET_SALES_CB, LIST_TARGET_SALES_CB),
    takeLatest(actions.LIST_TARGET_SALES_RENTAL, LIST_TARGET_SALES_RENTAL),
    takeLatest(actions.LIST_TARGET_SALES_OUTLET, LIST_TARGET_SALES_OUTLET),
    takeLatest(actions.LIST_TARGET_SALES_SPG, LIST_TARGET_SALES_SPG),
    takeLatest(actions.LIST_BALANCE_SUMMARY, LIST_BALANCE_SUMMARY),
    takeLatest(actions.LIST_DISTRIBUTOR, LIST_DISTRIBUTOR),
    takeLatest(actions.LIST_CERTIFICATE_GT_ONLINE, LIST_CERTIFICATE_GT_ONLINE),

    takeLatest(actions.OUTLET_FILTER_AREA, OUTLET_FILTER_AREA),
  ])
}
