const actions = {
  SET_STATE: 'report-noo-tracking/SET_STATE',
  FILTER: 'report-noo-tracking/FILTER',
  CANCEL: 'report-noo-tracking/CANCEL',
  DOWNLOAD: 'report-noo-tracking/DOWNLOAD',
  REPROCESS_FILE: 'report-noo-tracking/REPROCESS_FILE',
  LAST_UPDATED: 'report-noo-tracking/LAST_UPDATED',
}

export default actions
