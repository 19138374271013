import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as transDistUploadService from 'services/webservice/user-access/trans-dist-upload'
import { saveAs } from 'file-saver'
import actions from './actions'

const loadingDownloads = []

function* GET_UNCOMBINE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.uncombine)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(transDistUploadService.uncombine, payload),
    cancel: take(actions.CANCEL_UNCOMBINE),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataUncombine = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD_UNCOMBINE({ payload }) {
  loadingDownloads.push(payload.id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: loadingDownloads,
    },
  })
  const result = yield race({
    data: call(transDistUploadService.download, payload.id, event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total)
      put({
        type: actions.SET_STATE,
        payload: {
          progressDownload: `${percentCompleted} %`,
        },
      })
    }),
    cancel: take(actions.CANCEL_UNCOMBINE),
  })
  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingDownload: loadingDownloads,
      error: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadFile = `File is successfully downloaded!`
      newPayload.messageDownloadFileHeader = 'SUCCESS'
      const index = loadingDownloads.indexOf(payload.id)
      if (index > -1) {
        loadingDownloads.splice(index, 1)
      }
      console.log(response)
    } else {
      newPayload.messageDownloadFileHeader = 'FAILURE'
      newPayload.messageDownloadFile = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DO_REUNCOMBINE() {
  while (true) {
    const { payload } = yield take(actions.DO_REUNCOMBINE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(transDistUploadService.doReuncombine, payload),
      cancel: take(actions.CANCEL_UNCOMBINE),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_UNCOMBINE, GET_UNCOMBINE),
    takeLatest(actions.DOWNLOAD_UNCOMBINE, DOWNLOAD_UNCOMBINE),
    takeLatest(actions.DO_REUNCOMBINE, DO_REUNCOMBINE),
  ])
}
