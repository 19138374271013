import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as transDistUploadDraftService from 'services/webservice/user-access/trans-dist-upload-stock-draft'
import actions from './actions'

const loadingDownloads = []

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(
    state => state.transDistUploadStockDraft,
  )
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(transDistUploadDraftService.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataDistUploadDraft = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(transDistUploadDraftService.options, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataOptions = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        messageUploadFile: null,
        messageUploadFileError: null,
      },
    })
    yield put(showLoading('sectionBar'))
    const result = yield race({
      data: call(transDistUploadDraftService.upload, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      newPayload.errorForm = error

      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
      yield put(hideLoading('sectionBar'))
    }
  }
}

function* DOWNLOAD({ payload }) {
  loadingDownloads.push(payload.id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: loadingDownloads,
    },
  })
  const result = yield race({
    data: call(transDistUploadDraftService.download, payload, event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total)
      put({
        type: actions.SET_STATE,
        payload: {
          progressDownload: `${percentCompleted} %`,
        },
      })
    }),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingDownload: loadingDownloads,
      error: null,
    }

    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const mToken = result.data.response.config.headers.Authorization.replace('Bearer ', '')
      window.location.href = `${result.data.response.request.responseURL}?token=${mToken}`

      newPayload.messageDownloadFile = `File ${filename} is successfully downloaded!`
      newPayload.messageDownloadFileHeader = 'SUCCESS'
    } else {
      newPayload.messageDownloadFileHeader = 'FAILURE'
      newPayload.messageDownloadFile = error
    }

    const index = loadingDownloads.indexOf(payload.id)
    if (index > -1) {
      loadingDownloads.splice(index, 1)
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SUBMIT() {
  while (true) {
    const { payload } = yield take(actions.SUBMIT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        messageFormSuccess: null,
        errorSubmit: null,
      },
    })
    const result = yield race({
      data: call(transDistUploadDraftService.submit, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = response.data.message
      }
      newPayload.errorSubmit = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE() {
  while (true) {
    const { payload } = yield take(actions.DELETE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(transDistUploadDraftService.deleteDraft, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = response.data.message
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(transDistUploadDraftService.filters, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilters = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DELETE_CHILD() {
  while (true) {
    const { payload } = yield take(actions.DELETE_CHILD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(transDistUploadDraftService.deleteChild, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = response.data.message
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, SEARCH),
    takeLatest(actions.OPTIONS, OPTIONS),
    takeLatest(actions.FILTER, FILTER),
    takeLatest(actions.DOWNLOAD, DOWNLOAD),
    UPLOAD(),
    SUBMIT(),
    DELETE(),
    DELETE_CHILD(),
  ])
}
