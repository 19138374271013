import apiClient from 'services/axios'

export async function getProductDistApproval(parameter) {
  return apiClient
    .get('/user/product-dist-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductDistApproval(parameter = {}) {
  return apiClient
    .get('/user/product-dist-approval/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductDistApproval(values) {
  return apiClient
    .post('/user/product-dist-approval/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductDistApproval(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist-approval/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductDistApproval(id, values) {
  return apiClient
    .post(`/user/product-dist-approval/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDistApproval(id) {
  return apiClient
    .delete(`/user/product-dist-approval/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkProductDistApproval(values) {
  return apiClient
    .post(`/user/product-dist-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
