import apiClient from 'services/axios'

export async function getConsumerDistributorProductBundle(parameter) {
  return apiClient
    .get('/user/sfa-consumer-distributor-product-bundle/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerDistributorProductBundle(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-distributor-product-bundle/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerDistributorProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-product-bundle/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerDistributorProductBundle(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-product-bundle/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerDistributorProductBundle(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-distributor-product-bundle/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerDistributorProductBundle(id) {
  return apiClient
    .delete(`/user/sfa-consumer-distributor-product-bundle/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerDistributorProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-distributor-product-bundle/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-distributor-product-bundle/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
