import apiClient from 'services/axios'

export async function getVersion(parameter) {
  return apiClient
    .get('/user/version/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createVersion(parameter = {}) {
  return apiClient
    .get('/user/version/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeVersion(values) {
  return apiClient
    .post('/user/version/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editVersion(id, parameter = {}) {
  return apiClient
    .get(`/user/version/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateVersion(id, values) {
  return apiClient
    .post(`/user/version/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteVersion(id) {
  return apiClient
    .delete(`/user/version/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function versionApp() {
  return apiClient
    .get('/version-app')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
