const actions = {
  SET_STATE: 'outlet-md-inactive/SET_STATE',
  SEARCH: 'outlet-md-inactive/SEARCH',
  CANCEL: 'outlet-md-inactive/CANCEL',
  CREATE: 'outlet-md-inactive/CREATE',
  EDIT: 'outlet-md-inactive/EDIT',
  STORE: 'outlet-md-inactive/STORE',
  UPDATE: 'outlet-md-inactive/UPDATE',
  DELETE: 'outlet-md-inactive/DELETE',
  GET_PERMISSION: 'outlet-md-inactive/GET_PERMISSION',
  SET_PERMISSION: 'outlet-md-inactive/SET_PERMISSION',
  FORCE_LOGOUT: 'outlet-md-inactive/FORCE_LOGOUT',
  RESET_PASSWORD: 'outlet-md-inactive/RESET_PASSWORD',
}

export default actions
