import apiClient from 'services/axios'

export async function getArea(parameter = {}) {
  return apiClient
    .get('/user/area/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createArea(parameter = {}) {
  return apiClient
    .get('/user/area/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeArea(values) {
  return apiClient
    .post('/user/area/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editArea(id, parameter = {}) {
  return apiClient
    .get(`/user/area/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateArea(id, values) {
  return apiClient
    .post(`/user/area/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteArea(id) {
  return apiClient
    .delete(`/user/area/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
