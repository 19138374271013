const actions = {
  SET_STATE: 'schedule/SET_STATE',
  LOAD_SCHEDULE: 'schedule/LOAD_SCHEDULE',
  CANCEL_SCHEDULE: 'schedule/CANCEL_SCHEDULE',
  LOAD_FORM_CREATE: 'schedule/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'schedule/LOAD_FORM_EDIT',
  STORE_SCHEDULE: 'schedule/STORE_SCHEDULE',
  UPDATE_SCHEDULE: 'schedule/UPDATE_SCHEDULE',
  DELETE_SCHEDULE: 'schedule/DELETE_SCHEDULE',
  UPLOAD_FILE: 'schedule/UPLOAD_FILE',
}

export default actions
