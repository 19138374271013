import apiClient from 'services/axios'

export async function filterMd(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/filter-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataMd(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/map-data-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataMd(parameter) {
  return apiClient
    .get('/user/dashboard-sfa/daily-data-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterMdDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/filter-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function mapDataMdDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/map-data-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function dailyDataMdDistributor(parameter) {
  return apiClient
    .get('/distributor/dashboard-sfa/daily-data-md', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
