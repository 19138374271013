import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as myService from 'services/webservice/user-access/outlet-dist-deviasi'
import { saveAs } from 'file-saver'
import actions from './actions'

function* GET_OUTLET_DIST_DEVIASI({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.outletDistDeviasi)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(myService.getOutletDistDeviasi, payload),
    cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataOutletDistDeviasi = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataOutletDistDeviasiCreate: null,
    dataOutletDistDeviasiEdit: null,
    dataMappingNoo: null,
    dataMappingAddSalesman: null,
    dataMappingReferensi: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let dataCall = null
  switch (action.type) {
    case actions.LOAD_FORM_CREATE:
      dataCall = call(myService.createOutletDistDeviasi)
      break
    case actions.LOAD_FORM_EDIT:
      dataCall = call(myService.createOutletDistDeviasi, payload.id)
      break
    case actions.OPTION_MAPPING_NOO:
      dataCall = call(myService.optionMapping, payload.id)
      break
    case actions.OPTION_MAPPING_REFERENSI:
      dataCall = call(myService.optionMapping, payload.id)
      break
    case actions.OPTION_MAPPING_ADD_SALESMAN:
      dataCall = call(myService.optionMapping, payload.id)
      break

    default:
      break
  }
  const result = yield race({
    data: dataCall,
    cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
  })

  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.LOAD_FORM_CREATE:
        newPayload.dataOutletDistDeviasiCreate = response ? response.data : null
        break
      case actions.LOAD_FORM_EDIT:
        newPayload.dataOutletDistDeviasiEdit = response ? response.data : null
        break
      case actions.OPTION_MAPPING_NOO:
        newPayload.dataMappingNoo = response ? response.data : null
        break
      case actions.OPTION_MAPPING_ADD_SALESMAN:
        newPayload.dataMappingAddSalesman = response ? response.data : null
        break
      case actions.OPTION_MAPPING_REFERENSI:
        newPayload.dataMappingReferensi = response ? response.data : null
        break

      default:
        break
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_OUTLET_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.STORE_OUTLET_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(myService.storeOutletDistDeviasi, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_OUTLET_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_OUTLET_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(myService.updateOutletDistDeviasi, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_OUTLET_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.DELETE_OUTLET_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(myService.deleteOutletDistDeviasi, payload.id),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DOWNLOAD_OUTLET_DIST_DEVIASI({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownload: true,
      messageDownloadFile: null,
      errorDownload: null,
    },
  })
  const result = yield race({
    data: call(myService.downloadTemplateOutletDistDeviasi, payload),
    cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDownload: false,
      errorDownload: null,
    }
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageDownloadFile = `File is successfully downloaded!`
    } else {
      newPayload.errorDownload = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* UPLOAD_OUTLET_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.UPLOAD_OUTLET_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(myService.uploadOutletDistDeviasi, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
        messageUploadFile: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageUploadFile = response.data
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* BLOCK_OUTLET_DIST_DEVIASI() {
  while (true) {
    const { payload } = yield take(actions.BLOCK_OUTLET_DIST_DEVIASI)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        errorForm: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(myService.blockOutletDistDeviasi, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_MAPPING() {
  while (true) {
    const { payload } = yield take(actions.SET_MAPPING)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        errorForm: null,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(myService.setMapping, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_DIST_DEVIASI),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* LIST_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingOutlet: true,
      listOutlet: null,
    },
  })
  const result = yield race({
    data: call(myService.listOutlet, payload),
    cancel: take([
      actions.CANCEL_OUTLET_DIST_DEVIASI,
      actions.CANCEL_LIST_OUTLET,
      actions.LOAD_FORM_CREATE,
      actions.LOAD_FORM_EDIT,
    ]),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingOutlet: false,
      errorOutlet: null,
    }
    if (response) {
      newPayload.listOutlet = response.data
    } else {
      newPayload.errorOutlet = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        listOutlet: null,
        loadingOutlet: false,
        errorOutlet: null,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_OUTLET_DIST_DEVIASI, GET_OUTLET_DIST_DEVIASI),
    takeLatest(
      [
        actions.LOAD_FORM_CREATE,
        actions.LOAD_FORM_EDIT,
        actions.OPTION_MAPPING_NOO,
        actions.OPTION_MAPPING_REFERENSI,
        actions.OPTION_MAPPING_ADD_SALESMAN,
      ],
      GET_FORM,
    ),
    takeLatest(actions.DOWNLOAD_OUTLET_DIST_DEVIASI, DOWNLOAD_OUTLET_DIST_DEVIASI),
    takeLatest(actions.LIST_OUTLET, LIST_OUTLET),
    UPLOAD_OUTLET_DIST_DEVIASI(),
    STORE_OUTLET_DIST_DEVIASI(),
    UPDATE_OUTLET_DIST_DEVIASI(),
    BLOCK_OUTLET_DIST_DEVIASI(),
    DELETE_OUTLET_DIST_DEVIASI(),
    SET_MAPPING(),
  ])
}
