const actions = {
  SET_STATE: 'area/SET_STATE',
  LOAD_AREA: 'area/LOAD_AREA',
  LOAD_FORM_CREATE: 'area/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'area/LOAD_FORM_EDIT',
  STORE_AREA: 'area/STORE_AREA',
  UPDATE_AREA: 'area/UPDATE_AREA',
  DELETE_AREA: 'area/DELETE_AREA',
  CANCEL_AREA: 'area/CANCEL_AREA',
}

export default actions
