import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/outlet-spg-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter = {}) {
  return apiClient
    .get(`/user/outlet-spg-temp/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function upload(values) {
  return apiClient
    .post('/user/outlet-spg-temp/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(values) {
  return apiClient
    .delete(`/user/outlet-spg-temp/reset`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function save(values) {
  return apiClient
    .post(`/user/outlet-spg-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
