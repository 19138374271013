import apiClient from 'services/axios'

export async function getCategoryAcc(parameter) {
  return apiClient
    .get('/user/category-acc/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createCategoryAcc(parameter = {}) {
  return apiClient
    .get('/user/category-acc/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeCategoryAcc(values) {
  return apiClient
    .post('/user/category-acc/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editCategoryAcc(id, parameter = {}) {
  return apiClient
    .get(`/user/category-acc/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCategoryAcc(id, values) {
  return apiClient
    .post(`/user/category-acc/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteCategoryAcc(id) {
  return apiClient
    .delete(`/user/category-acc/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
