import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/report-mr/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter(parameter) {
  return apiClient
    .get('/user/report-mr/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function lastUpdatedFile(parameter) {
  return apiClient
    .get('/user/report-mr/last-updated-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/report-mr/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reprocessFile(values) {
  return apiClient
    .post('/user/report-mr/reprocess-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterDist(parameter) {
  return apiClient
    .get('/distributor/report-mr/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadDist(parameter) {
  return apiClient
    .get('/distributor/report-mr/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
