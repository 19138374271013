import apiClient from 'services/axios'

export async function getProductListing(parameter) {
  return apiClient
    .get('/user/product-listing/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductListing(parameter = {}) {
  return apiClient
    .get('/user/product-listing/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductListing(values) {
  return apiClient
    .post('/user/product-listing/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductListing(id, parameter = {}) {
  return apiClient
    .get(`/user/product-listing/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductListing(id, values) {
  return apiClient
    .post(`/user/product-listing/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductListing(id) {
  return apiClient
    .delete(`/user/product-listing/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/product-listing/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-listing/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/product-listing/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/product-listing/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
