const actions = {
  SET_STATE: 'dashboard-sfa-sales/SET_STATE',
  LOAD_FILTER: 'dashboard-sfa-sales/LOAD_FILTER',

  LOAD_MAP_DATA: 'dashboard-sfa-sales/LOAD_MAP_DATA',
  LOAD_DAILY_DATA: 'dashboard-sfa-sales/LOAD_DAILY_DATA',
  LOAD_ABSEN_DATA: 'dashboard-sfa-sales/LOAD_ABSEN_DATA',

  UPDATE_ABSEN_DATA: 'dashboard-sfa-sales/UPDATE_ABSEN_DATA',
  CANCEL_ABSEN_DATA: 'dashboard-sfa-sales/CANCEL_ABSEN_DATA',

  CANCEL: 'dashboard-sfa-sales/CANCEL',
}

export default actions
