import apiClient from 'services/axios'

export async function getDistributorEpm(parameter) {
  return apiClient
    .get('/user/distributor-epm/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createDistributorEpm(parameter = {}) {
  return apiClient
    .get('/user/distributor-epm/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeDistributorEpm(values) {
  return apiClient
    .post('/user/distributor-epm/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editDistributorEpm(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor-epm/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateDistributorEpm(id, values) {
  return apiClient
    .post(`/user/distributor-epm/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDistributorEpm(id) {
  return apiClient
    .delete(`/user/distributor-epm/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/distributor-epm/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
