const actions = {
  SET_STATE: 'year/SET_STATE',
  LOAD_YEAR: 'year/LOAD_YEAR',
  CANCEL_YEAR: 'year/CANCEL_YEAR',
  LOAD_FORM_CREATE: 'year/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'year/LOAD_FORM_EDIT',
  STORE_YEAR: 'year/STORE_YEAR',
  UPDATE_YEAR: 'year/UPDATE_YEAR',
  DELETE_YEAR: 'year/DELETE_YEAR',
}

export default actions
