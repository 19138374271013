import apiClient from 'services/axios'

export async function login(values) {
  return apiClient
    .post('/user-distributor/login', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loginSso(values) {
  return apiClient
    .post('/oauth/token', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePassword(oldpassword, newpassword, confirmpassword) {
  return apiClient
    .post('/user/change-password', {
      old_password: oldpassword,
      new_password: newpassword,
      confirm_password: confirmpassword,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProfile(dataProfile) {
  return apiClient
    .post('/user/update-profile', dataProfile)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProfileImage(values) {
  const formData = new FormData()
  formData.append('user_image', values)

  return apiClient
    .post('/user/update-profile-image', formData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function currentAccount() {
  return apiClient
    .get('/user/profile')
    .then(response => {
      return { response }
    })
    .catch(error => ({ error }))
}

export async function currentGlobalAccount() {
  return apiClient
    .get('/user-distributor/profile')
    .then(response => {
      return { response }
    })
    .catch(error => ({ error }))
}

// NANTI DIPINDAH KE FOLDER WEB SERVICE AJA
export async function userSearch() {
  return apiClient
    .get('/user/user/search')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function logout() {
  return apiClient
    .post('/user/logout')
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forgotPassword(email) {
  return apiClient
    .post('/user-distributor/reset-password-email', {
      email,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(values) {
  return apiClient
    .post('/user/reset-password', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
