const actions = {
  SET_STATE: 'report-no-trans-three-mma-outlet-only/SET_STATE',
  FILTER: 'report-no-trans-three-mma-outlet-only/FILTER',
  CANCEL: 'report-no-trans-three-mma-outlet-only/CANCEL',
  DOWNLOAD: 'report-no-trans-three-mma-outlet-only/DOWNLOAD',
  REPROCESS_FILE: 'report-no-trans-three-mma-outlet-only/REPROCESS_FILE',
  LAST_UPDATED: 'report-no-trans-three-mma-outlet-only/LAST_UPDATED',
}

export default actions
