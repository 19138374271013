const actions = {
  SET_STATE: 'report-opsc-cosmo-by-outlet-and-range/SET_STATE',
  SEARCH: 'report-opsc-cosmo-by-outlet-and-range/SEARCH',
  FILTER: 'report-opsc-cosmo-by-outlet-and-range/FILTER',
  CANCEL: 'report-opsc-cosmo-by-outlet-and-range/CANCEL',
  DOWNLOAD: 'report-opsc-cosmo-by-outlet-and-range/DOWNLOAD',
  REPROCESS_FILE: 'report-opsc-cosmo-by-outlet-and-range/REPROCESS_FILE',
  LAST_UPDATED: 'report-opsc-cosmo-by-outlet-and-range/LAST_UPDATED',
}

export default actions
