import apiClient from 'services/axios'

export async function getBrandCategory(parameter) {
  return apiClient
    .get('/user/brand-category/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createBrandCategory(parameter = {}) {
  return apiClient
    .get('/user/brand-category/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeBrandCategory(values) {
  return apiClient
    .post('/user/brand-category/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editBrandCategory(id, parameter = {}) {
  return apiClient
    .get(`/user/brand-category/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBrandCategory(id, values) {
  return apiClient
    .post(`/user/brand-category/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteBrandCategory(id) {
  return apiClient
    .delete(`/user/brand-category/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
