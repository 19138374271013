const actions = {
  SET_STATE: 'report-md-jago-jualan/SET_STATE',
  FILTER: 'report-md-jago-jualan/FILTER',
  CANCEL: 'report-md-jago-jualan/CANCEL',
  DOWNLOAD: 'report-md-jago-jualan/DOWNLOAD',
  REPROCESS_FILE: 'report-md-jago-jualan/REPROCESS_FILE',
  LAST_UPDATED: 'report-md-jago-jualan/LAST_UPDATED',
}

export default actions
