const actions = {
  SET_STATE: 'outlet-channel/SET_STATE',
  LOAD_OUTLET_CHANNEL: 'outlet-channel/LOAD_OUTLET_CHANNEL',
  CANCEL_OUTLET_CHANNEL: 'outlet-channel/CANCEL_OUTLET_CHANNEL',
  LOAD_FORM_CREATE: 'outlet-channel/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-channel/LOAD_FORM_EDIT',
  STORE_OUTLET_CHANNEL: 'outlet-channel/STORE_OUTLET_CHANNEL',
  UPDATE_OUTLET_CHANNEL: 'outlet-channel/UPDATE_OUTLET_CHANNEL',
  DELETE_OUTLET_CHANNEL: 'outlet-channel/DELETE_OUTLET_CHANNEL',
}

export default actions
