import apiClient from 'services/axios'

export async function getConsumerOutletRange(parameter) {
  return apiClient
    .get('/user/sfa-consumer-outlet-range/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerOutletRange(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-outlet-range/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerOutletRange(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-range/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerOutletRange(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-range/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerOutletRange(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-outlet-range/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerOutletRange(id) {
  return apiClient
    .delete(`/user/sfa-consumer-outlet-range/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerOutletRange(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-range/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-range/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-range/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
