const actions = {
  SET_STATE: 'version/SET_STATE',
  LOAD_VERSION: 'version/LOAD_VERSION',
  CANCEL_VERSION: 'version/CANCEL_VERSION',
  LOAD_FORM_CREATE: 'version/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'version/LOAD_FORM_EDIT',
  STORE_VERSION: 'version/STORE_VERSION',
  UPDATE_VERSION: 'version/UPDATE_VERSION',
  APP_VERSION: 'version/APP_VERSION',
  DELETE_VERSION: 'version/DELETE_VERSION',
}

export default actions
