const actions = {
  SET_STATE: 'trans-dist-upload-distributor/SET_STATE',
  LOAD_UNCOMBINE: 'trans-dist-upload-distributor/LOAD_UNCOMBINE',
  SEARCH_DETAIL: 'trans-dist-upload-distributor/SEARCH_DETAIL',
  SEARCH_LIST: 'trans-dist-upload-distributor/SEARCH_LIST',
  CANCEL: 'trans-dist-upload-distributor/CANCEL',
  LOAD_FORM_CREATE: 'trans-dist-upload-distributor/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'trans-dist-upload-distributor/LOAD_FORM_EDIT',
  STORE: 'trans-dist-upload-distributor/STORE',
  UPDATE: 'trans-dist-upload-distributor/UPDATE',
  DELETE: 'trans-dist-upload-distributor/DELETE',
  DOWNLOAD: 'trans-dist-upload-distributor/DOWNLOAD',
  UPLOAD: 'trans-dist-upload-distributor/UPLOAD',
  RESET: 'trans-dist-upload-distributor/RESET',
  SAVE: 'trans-dist-upload-distributor/SAVE',
  OPTION_DETAIL: 'trans-dist-upload-distributor/OPTION_DETAIL',
  REPROCESS: 'trans-dist-upload-distributor/REPROCESS',
  DELETE_PARENT: 'trans-dist-upload-distributor/DELETE_PARENT',
  DELETE_CHILD: 'trans-dist-upload-distributor/DELETE_CHILD',
  DOWNLOAD_CHILD: 'trans-dist-upload-distributor/DOWNLOAD_CHILD',
  DETAIL_LOG: 'trans-dist-upload-distributor/DETAIL_LOG',
  FILTER: 'trans-dist-upload-distributor/FILTER',
}

export default actions
