const actions = {
  SET_STATE: 'dashboard-sfa-management/SET_STATE',
  LOAD_FILTER: 'dashboard-sfa-management/LOAD_FILTER',

  LOAD_MAP_DATA: 'dashboard-sfa-management/LOAD_MAP_DATA',
  LOAD_DAILY_DATA: 'dashboard-sfa-management/LOAD_DAILY_DATA',
  LOAD_DAILY_SUMMARY_DATA: 'dashboard-sfa-management/LOAD_DAILY_SUMMARY_DATA',
  LOAD_MONTHLY_DATA: 'dashboard-sfa-management/LOAD_MONTHLY_DATA',

  CANCEL: 'dashboard-sfa-management/CANCEL',
}

export default actions
