import apiClient from 'services/axios'

export async function getVariant(parameter) {
  return apiClient
    .get('/user/variant/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createVariant(parameter = {}) {
  return apiClient
    .get('/user/variant/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeVariant(values) {
  return apiClient
    .post('/user/variant/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editVariant(id, parameter = {}) {
  return apiClient
    .get(`/user/variant/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateVariant(id, values) {
  return apiClient
    .post(`/user/variant/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteVariant(id) {
  return apiClient
    .delete(`/user/variant/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
