const actions = {
  SET_STATE: 'closing-month/SET_STATE',
  SEARCH: 'closing-month/SEARCH',
  LIST_YEARLY: 'closing-month/LIST_YEARLY',
  EDIT: 'closing-month/EDIT',
  UPDATE: 'closing-month/UPDATE',
  PERIODICALLY: 'closing-month/PERIODICALLY',
  FILTER_PERIOD: 'closing-month/FILTER_PERIOD',
  CANCEL: 'closing-month/CANCEL',
}

export default actions
