const actions = {
  SET_STATE: 'setting-converter/SET_STATE',
  LOAD_SETTING_CONVERTER: 'setting-converter/LOAD_SETTING_CONVERTER',
  CANCEL_SETTING_CONVERTER: 'setting-converter/CANCEL_SETTING_CONVERTER',
  LOAD_FORM_CREATE: 'setting-converter/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'setting-converter/LOAD_FORM_EDIT',
  STORE_SETTING_CONVERTER: 'setting-converter/STORE_SETTING_CONVERTER',
  UPDATE_SETTING_CONVERTER: 'setting-converter/UPDATE_SETTING_CONVERTER',
  DELETE_SETTING_CONVERTER: 'setting-converter/DELETE_SETTING_CONVERTER',
}

export default actions
