import apiClient from 'services/axios'

export async function filter(parameter) {
  return apiClient
    .get('user/report-sfa-po-detail-salesman/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('user/report-sfa-po-detail-salesman/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function filterDist(parameter) {
  return apiClient
    .get('distributor/report-sfa-po-detail-salesman/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadDist(parameter) {
  return apiClient
    .get('distributor/report-sfa-po-detail-salesman/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
