const actions = {
  SET_STATE: 'mensa-area-sub/SET_STATE',
  SEARCH: 'mensa-area-sub/SEARCH',
  CREATE: 'mensa-area-sub/CREATE',
  EDIT: 'mensa-area-sub/EDIT',
  STORE: 'mensa-area-sub/STORE',
  UPDATE: 'mensa-area-sub/UPDATE',
  DELETE: 'mensa-area-sub/DELETE',
  CANCEL: 'mensa-area-sub/CANCEL',
}

export default actions
