const actions = {
  SET_STATE: 'consumer-outlet/SET_STATE',
  SEARCH: 'consumer-outlet/SEARCH',
  CANCEL: 'consumer-outlet/CANCEL',
  CREATE: 'consumer-outlet/CREATE',
  EDIT: 'consumer-outlet/EDIT',
  STORE: 'consumer-outlet/STORE',
  UPDATE: 'consumer-outlet/UPDATE',
  DELETE: 'consumer-outlet/DELETE',
  UPLOAD: 'consumer-outlet/UPLOAD',
  CANCEL_LIST_OUTLET: 'consumer-outlet/CANCEL_LIST_OUTLET',
  LIST_OUTLET: 'consumer-outlet/LIST_OUTLET',
  LIST_PRODUCT: 'consumer-outlet/LIST_PRODUCT',
}

export default actions
