import apiClient from 'services/axios'

export async function getProductDistBlock(parameter) {
  return apiClient
    .get('/user/product-dist-block/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductDistBlock(parameter = {}) {
  return apiClient
    .get('/user/product-dist-block/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductDistBlock(values) {
  return apiClient
    .post('/user/product-dist-block/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductDistBlock(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist-block/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductDistBlock(id, values) {
  return apiClient
    .post(`/user/product-dist-block/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDistBlock(id) {
  return apiClient
    .delete(`/user/product-dist-block/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
