const actions = {
  SET_STATE: 'schedule-accounting/SET_STATE',
  LOAD_SCHEDULE: 'schedule-accounting/LOAD_SCHEDULE',
  CANCEL_SCHEDULE: 'schedule-accounting/CANCEL_SCHEDULE',
  LOAD_FORM_CREATE: 'schedule-accounting/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'schedule-accounting/LOAD_FORM_EDIT',
  STORE_SCHEDULE: 'schedule-accounting/STORE_SCHEDULE',
  UPDATE_SCHEDULE: 'schedule-accounting/UPDATE_SCHEDULE',
  DELETE_SCHEDULE: 'schedule-accounting/DELETE_SCHEDULE',
  UPLOAD_FILE: 'schedule-accounting/UPLOAD_FILE',
}

export default actions
