import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as serviceApi from 'services/webservice/user-access/target-secondary-sales-spg-temp'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(
    state => state.targetSecondarySalesSpgTemp,
  )
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(serviceApi.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataTargetTemp = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SAVE() {
  while (true) {
    const { payload } = yield take(actions.SAVE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingSave: true,
        errorSave: null,
        messageSave: null,
      },
    })
    const result = yield race({
      data: call(serviceApi.save, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingSave: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageSave = response.data
      }
      newPayload.errorSave = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET() {
  while (true) {
    const { payload } = yield take(actions.RESET)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingReset: true,
        errorReset: null,
        messageReset: null,
      },
    })
    const result = yield race({
      data: call(serviceApi.reset, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingReset: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageReset = response.data
      }
      newPayload.errorReset = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SEARCH, SEARCH), RESET(), SAVE()])
}
