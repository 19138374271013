const actions = {
  SET_STATE: 'target-secondary-sales-cb/SET_STATE',
  SEARCH: 'target-secondary-sales-cb/SEARCH',
  CANCEL: 'target-secondary-sales-cb/CANCEL',
  CANCEL_LIST_OUTLET: 'target-secondary-sales-cb/CANCEL_LIST_OUTLET',
  CREATE: 'target-secondary-sales-cb/CREATE',
  EDIT: 'target-secondary-sales-cb/EDIT',
  STORE: 'target-secondary-sales-cb/STORE',
  UPDATE: 'target-secondary-sales-cb/UPDATE',
  DELETE: 'target-secondary-sales-cb/DELETE',
  FILTER: 'target-secondary-sales-cb/FILTER',
  LIST_OUTLET: 'target-secondary-sales-cb/LIST_OUTLET',
  UPLOAD_FILE: 'target-secondary-sales-cb/UPLOAD_FILE',
  DOWNLOAD: 'target-secondary-sales-cb/DOWNLOAD',
  REMINDER: 'target-secondary-sales-cb/REMINDER',
}

export default actions
