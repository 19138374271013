const actions = {
  SET_STATE: 'product-dist-temp/SET_STATE',
  LOAD_PRODUCT_DIST_TEMP: 'product-dist-temp/LOAD_PRODUCT_DIST_TEMP',
  CANCEL_PRODUCT_DIST_TEMP: 'product-dist-temp/CANCEL_PRODUCT_DIST_TEMP',
  LOAD_FORM_CREATE: 'product-dist-temp/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist-temp/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST_TEMP: 'product-dist-temp/STORE_PRODUCT_DIST_TEMP',
  UPDATE_PRODUCT_DIST_TEMP: 'product-dist-temp/UPDATE_PRODUCT_DIST_TEMP',
  DELETE_PRODUCT_DIST_TEMP: 'product-dist-temp/DELETE_PRODUCT_DIST_TEMP',
  DOWNLOAD_PRODUCT_DIST_TEMP: 'product-dist-temp/DOWNLOAD_PRODUCT_DIST_TEMP',
  UPLOAD_PRODUCT_DIST_TEMP: 'product-dist-temp/UPLOAD_PRODUCT_DIST_TEMP',
  UPLOAD_PRODUCT_DIST_TEMP_V2: 'product-dist-temp/UPLOAD_PRODUCT_DIST_TEMP_V2',
  RESET_PRODUCT_DIST_TEMP: 'product-dist-temp/RESET_PRODUCT_DIST_TEMP',
  SAVE_PRODUCT_DIST_TEMP: 'product-dist-temp/SAVE_PRODUCT_DIST_TEMP',
  NEED_RELOAD: 'product-dist-temp/NEED_RELOAD',
}

export default actions
