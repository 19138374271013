import apiClient from 'services/axios'

export async function getTargetSecondaryBrand(parameter) {
  return apiClient
    .get('/user/target-secondary-brand/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createTargetSecondaryBrand(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-brand/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeTargetSecondaryBrand(values) {
  return apiClient
    .post('/user/target-secondary-brand/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editTargetSecondaryBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/target-secondary-brand/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateTargetSecondaryBrand(id, values) {
  return apiClient
    .post(`/user/target-secondary-brand/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteTargetSecondaryBrand(id) {
  return apiClient
    .delete(`/user/target-secondary-brand/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/target-secondary-brand/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-secondary-brand/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/target-secondary-brand/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
