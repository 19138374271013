import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { UserOutlined, LoadingOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user, userDistributor }) => ({ user, userDistributor })

const ProfileMenu = ({ dispatch, user, userDistributor, history }) => {
  // const [count, setCount] = useState(7)

  const logout = e => {
    e.preventDefault()
    if (window.location.href.indexOf('/distributor/') > -1) {
      dispatch({
        type: 'user-distributor/LOGOUT',
      })
    } else if (window.location.href.indexOf('/admin/') > -1) {
      dispatch({ type: 'user/LOGOUT' })
    }
  }

  useEffect(() => {
    // if (!userDistributor.authorized && window.location.href.indexOf('/distributor/') > -1) {
    //   dispatch(push('/auth-admin/login'))
    // } else if (!user.authorized && window.location.href.indexOf('/admin/') > -1) {
    //   dispatch(push('/auth-admin/login'))
    // }
    if (user.authorized && window.location.href.indexOf('/distributor/') > -1) {
      dispatch(push('/admin/404'))
    } else if (userDistributor.authorized && window.location.href.indexOf('/admin/') > -1) {
      dispatch(push('/distributor/404'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addCount = () => {
    // setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>Halo, {user.user_name || userDistributor.user_name} !</strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>Username: </strong>
          {user.username || userDistributor.username}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || userDistributor.email}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        {user.authorized && (
          <a
            href="#"
            onClick={e => {
              history.push('/admin/profile')
              e.preventDefault()
            }}
          >
            <i className="fe fe-user mr-2" />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        )}
        {userDistributor.authorized && (
          <a
            href="#"
            onClick={e => {
              history.push('/distributor/profile')
              e.preventDefault()
            }}
          >
            <i className="fe fe-user mr-2" />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-home mr-2" />
          <FormattedMessage id="topBar.profileMenu.backToHome" />
          {user.loadingLogout && <LoadingOutlined className="ml-2" />}
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={0}>
          {user.user_image ? (
            <Avatar className={styles.avatar} shape="square" size="large" src={user.user_image} />
          ) : (
            <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
          )}
        </Badge>
      </div>
    </Dropdown>
  )
}

export default withRouter(connect(mapStateToProps)(ProfileMenu))
