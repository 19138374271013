const actions = {
  SET_STATE: 'trans-dist-status-cube-ewom/SET_STATE',
  SEARCH: 'trans-dist-status-cube-ewom/SEARCH',
  FILTER: 'trans-dist-status-cube-ewom/FILTER',
  CANCEL: 'trans-dist-status-cube-ewom/CANCEL',
  LOG_DETAIL: 'trans-dist-status-cube-ewom/LOG_DETAIL',
  REPROCESS: 'trans-dist-status-cube-ewom/REPROCESS',
}

export default actions
