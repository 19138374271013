const actions = {
  SET_STATE: 'report-competitor/SET_STATE',
  SEARCH: 'report-competitor/SEARCH',
  FILTER: 'report-competitor/FILTER',
  CANCEL: 'report-competitor/CANCEL',
  DOWNLOAD: 'report-competitor/DOWNLOAD',
  REPROCESS_FILE: 'report-competitor/REPROCESS_FILE',
  LAST_UPDATED: 'report-competitor/LAST_UPDATED',
}

export default actions
