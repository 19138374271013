const actions = {
  SET_STATE: 'outlet-access/SET_STATE',
  LOAD_OUTLET_ACCESS: 'outlet-access/LOAD_OUTLET_ACCESS',
  CANCEL_OUTLET_ACCESS: 'outlet-access/CANCEL_OUTLET_ACCESS',
  LOAD_FORM_CREATE: 'outlet-access/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-access/LOAD_FORM_EDIT',
  STORE_OUTLET_ACCESS: 'outlet-access/STORE_OUTLET_ACCESS',
  UPDATE_OUTLET_ACCESS: 'outlet-access/UPDATE_OUTLET_ACCESS',
  DELETE_OUTLET_ACCESS: 'outlet-access/DELETE_OUTLET_ACCESS',
  FILTER_OUTLET_ACCESS: 'outlet-access/FILTER_OUTLET_ACCESS',
  PERMISSION_OUTLET_ACCESS: 'outlet-access/PERMISSION_OUTLET_ACCESS',
  REMINDER: 'outlet-access/REMINDER',
  DOWNLOAD_ALL: 'outlet-access/DOWNLOAD_ALL',
  DOWNLOAD_ALL_INACTIVE: 'outlet-access/DOWNLOAD_ALL_INACTIVE',
}

export default actions
