const actions = {
  SET_STATE: 'category-sub-stock/SET_STATE',
  LOAD_CATEGORY_SUB_STOCK: 'category-sub-stock/LOAD_CATEGORY_SUB_STOCK',
  CANCEL_CATEGORY_SUB_STOCK: 'category-sub-stock/CANCEL_CATEGORY_SUB_STOCK',
  LOAD_FORM_CREATE: 'category-sub-stock/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-sub-stock/LOAD_FORM_EDIT',
  STORE_CATEGORY_SUB_STOCK: 'category-sub-stock/STORE_CATEGORY_SUB_STOCK',
  UPDATE_CATEGORY_SUB_STOCK: 'category-sub-stock/UPDATE_CATEGORY_SUB_STOCK',
  DELETE_CATEGORY_SUB_STOCK: 'category-sub-stock/DELETE_CATEGORY_SUB_STOCK',
}

export default actions
