import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as serviceApi from 'services/webservice/user-access/target-secondary-sales-spg-approval'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(
    state => state.targetSecondarySalesSpgApproval,
  )
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(serviceApi.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataTargetApproval = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DELETE() {
  while (true) {
    const { payload } = yield take(actions.DELETE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(serviceApi.deleteData, payload.id, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* BULK() {
  while (true) {
    const { payload } = yield take(actions.BULK)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
        messageFormSuccess: null,
      },
    })
    const result = yield race({
      data: call(serviceApi.bulkApproval, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = response.data
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SEARCH, SEARCH), DELETE(), BULK()])
}
