import { all, takeLatest, select, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-sfa/ro'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingFilter: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(distributor.authorized ? mService.filterRoDistributor : mService.filterRo, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingFilter: false,
      errorFilter: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
    } else {
      newPayload.errorFilter = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_MAP_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMap: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.mapDataRoDistributor : mService.mapDataRo,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMap: false,
      errorMap: null,
    }
    if (response) {
      newPayload.dataMap = response.data
    } else {
      newPayload.errorMap = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_TARGET_CALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingTargetCall: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.targetCallRoDistributor : mService.targetCallRo,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingTargetCall: false,
      errorTargetCall: null,
    }
    if (response) {
      newPayload.dataTargetCall = response.data
    } else {
      newPayload.errorTargetCall = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.LOAD_MAP_DATA, LOAD_MAP_DATA),
    takeLatest(actions.LOAD_TARGET_CALL, LOAD_TARGET_CALL),
  ])
}
