const actions = {
  SET_STATE: 'consumer-distributor/SET_STATE',
  SEARCH: 'consumer-distributor/SEARCH',
  CANCEL: 'consumer-distributor/CANCEL',
  CREATE: 'consumer-distributor/CREATE',
  EDIT: 'consumer-distributor/EDIT',
  STORE: 'consumer-distributor/STORE',
  UPDATE: 'consumer-distributor/UPDATE',
  DELETE: 'consumer-distributor/DELETE',
  UPLOAD: 'consumer-distributor/UPLOAD',
  LIST_PRODUCT: 'consumer-distributor/LIST_PRODUCT',
}

export default actions
