import apiClient from 'services/axios'

export async function getSfaProductTop(parameter) {
  return apiClient
    .get('/user/sfa-product-top/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSfaProductTop(parameter = {}) {
  return apiClient
    .get('/user/sfa-product-top/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSfaProductTop(values) {
  return apiClient
    .post('/user/sfa-product-top/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSfaProductTop(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-product-top/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSfaProductTop(id, values) {
  return apiClient
    .post(`/user/sfa-product-top/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSfaProductTop(id) {
  return apiClient
    .delete(`/user/sfa-product-top/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/sfa-product-top/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-product-top/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function flushDataMss(values) {
  return apiClient
    .post(`/user/sfa-product-top/flush-data-mss`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
