import apiClient from 'services/axios'

export async function getProductDistDeviasi(parameter) {
  return apiClient
    .get('/user/product-dist-deviasi/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProductDistDeviasi(parameter = {}) {
  return apiClient
    .get('/user/product-dist-deviasi/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeProductDistDeviasi(values) {
  return apiClient
    .post('/user/product-dist-deviasi/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editProductDistDeviasi(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist-deviasi/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateProductDistDeviasi(id, values) {
  return apiClient
    .post(`/user/product-dist-deviasi/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteProductDistDeviasi(id) {
  return apiClient
    .delete(`/user/product-dist-deviasi/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadProductDistDeviasi(values) {
  return apiClient
    .post(`/user/product-dist-deviasi/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateProductDistDeviasi(parameter = {}) {
  return apiClient
    .get(`/user/product-dist-deviasi/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function blockProductDistDeviasi(id, parameter = {}) {
  return apiClient
    .post(`/user/product-dist-deviasi/${id}/block`, parameter)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setMapping(id, parameter = {}) {
  return apiClient
    .post(`/user/product-dist-deviasi/${id}/mapping`, {
      ...parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionMapping(parameter = {}) {
  return apiClient
    .get('/user/product-dist-deviasi/option-mapping', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
