const actions = {
  SET_STATE: 'report-activity-daily-spv/SET_STATE',
  SEARCH: 'report-activity-daily-spv/SEARCH',
  FILTER: 'report-activity-daily-spv/FILTER',
  CANCEL: 'report-activity-daily-spv/CANCEL',
  DOWNLOAD: 'report-activity-daily-spv/DOWNLOAD',
  REPROCESS_FILE: 'report-activity-daily-spv/REPROCESS_FILE',
  LAST_UPDATED: 'report-activity-daily-spv/LAST_UPDATED',
}

export default actions
