import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/product'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(mService.getProduct, payload),
    cancel: take(actions.CANCEL_PRODUCT),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataProduct = response.data
      // newPayload.dataProductPermission = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataProductCreate: null,
    dataProductEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.LOAD_FORM_CREATE
  if (isCreate) {
    result = yield race({
      data: call(mService.createProduct),
      cancel: take(actions.CANCEL_PRODUCT),
    })
  } else {
    result = yield race({
      data: call(mService.editProduct, payload.id),
      cancel: take(actions.CANCEL_PRODUCT),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataProductCreate = response ? response.data : null
    } else {
      newPayload.dataProductEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_PRODUCT() {
  while (true) {
    const { payload } = yield take(actions.STORE_PRODUCT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(mService.storeProduct, payload),
      cancel: take(actions.CANCEL_PRODUCT),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_PRODUCT() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PRODUCT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.updateProduct, currentId, payload),
      cancel: take(actions.CANCEL_PRODUCT),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_PRODUCT() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PRODUCT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(mService.deleteProduct, payload.id),
      cancel: take(actions.CANCEL_PRODUCT),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* FILTER_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(mService.filterProduct, payload),
    cancel: take(actions.CANCEL_PRODUCT),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataProductFilter = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* PERMISSION_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(mService.permissionProduct, payload),
    cancel: take(actions.CANCEL_PRODUCT),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataProductPermission = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* REMINDER() {
  while (true) {
    const { payload } = yield take(actions.REMINDER)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingReminder: true,
      },
    })
    const result = yield race({
      data: call(mService.reminder, payload),
      cancel: take(actions.CANCEL_PRODUCT),
    })
    if (result.data) {
      const newPayload = {
        loadingReminder: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageReminderSuccess = response.data
      }
      newPayload.messageReminderError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PRODUCT, GET_PRODUCT),
    takeLatest(actions.FILTER_PRODUCT, FILTER_PRODUCT),
    takeLatest(actions.PERMISSION_PRODUCT, PERMISSION_PRODUCT),
    takeLatest([actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT], GET_FORM),
    STORE_PRODUCT(),
    UPDATE_PRODUCT(),
    DELETE_PRODUCT(),
    REMINDER(),
  ])
}
