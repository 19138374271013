import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/outlet-spg/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/outlet-spg/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/outlet-spg/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-spg/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/outlet-spg/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionsDelete(id) {
  return apiClient
    .get(`/user/outlet-spg/${id}/options-destroy`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id, values) {
  return apiClient
    .delete(`/user/outlet-spg/${id}`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getPermissions(id) {
  return apiClient
    .get(`/user/outlet-spg/${id}/permission`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setPermissions(id, values) {
  return apiClient
    .post(`/user/outlet-spg/${id}/permission`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(id, values) {
  return apiClient
    .post(`/user/outlet-spg/${id}/reset-password`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function forceLogout(id) {
  return apiClient
    .post(`/user/outlet-spg/${id}/force-logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filters() {
  return apiClient
    .get(`/user/outlet-spg/filter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/outlet-spg/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
