const actions = {
  SET_STATE: 'trade-promo-inactive/SET_STATE',
  SEARCH: 'trade-promo-inactive/SEARCH',
  CANCEL: 'trade-promo-inactive/CANCEL',
  CREATE: 'trade-promo-inactive/CREATE',
  EDIT: 'trade-promo-inactive/EDIT',
  STORE: 'trade-promo-inactive/STORE',
  UPDATE: 'trade-promo-inactive/UPDATE',
  DELETE: 'trade-promo-inactive/DELETE',
}

export default actions
