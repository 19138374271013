const actions = {
  SET_STATE: 'sfa-product-top/SET_STATE',
  LOAD_SFA_PRODUCT_TOP: 'sfa-product-top/LOAD_SFA_PRODUCT_TOP',
  CANCEL_SFA_PRODUCT_TOP: 'sfa-product-top/CANCEL_SFA_PRODUCT_TOP',
  CANCEL_LIST_OUTLET: 'sfa-product-top/CANCEL_LIST_OUTLET',
  LOAD_FORM_CREATE: 'sfa-product-top/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'sfa-product-top/LOAD_FORM_EDIT',
  STORE_SFA_PRODUCT_TOP: 'sfa-product-top/STORE_SFA_PRODUCT_TOP',
  UPDATE_SFA_PRODUCT_TOP: 'sfa-product-top/UPDATE_SFA_PRODUCT_TOP',
  DELETE_SFA_PRODUCT_TOP: 'sfa-product-top/DELETE_SFA_PRODUCT_TOP',
  UPLOAD_FILE: 'sfa-product-top/UPLOAD_FILE',
  LIST_OUTLET: 'sfa-product-top/LIST_OUTLET',
  FLUSH_MSS_SFA_PRODUCT_TOP: 'sfa-product-top/FLUSH_MSS_SFA_PRODUCT_TOP',
}

export default actions
