import { all, takeEvery, put, call, take, race } from 'redux-saga/effects'
import { notification } from 'antd'
import * as rect from 'services/rect'
import { push } from 'connected-react-router'
import { getErrorMessage } from 'services/helper/message'
import store from 'store'
import actions from './actions'

const mapAuthProviders = {
  rect: {
    login: rect.login,
    loginSso: rect.loginSso,
    currentAccount: rect.currentAccount,
    logout: rect.logout,
    updatePassword: rect.updatePassword,
    updateProfile: rect.updateProfile,
    updateProfileImage: rect.updateProfileImage,
    currentGlobalAccount: rect.currentGlobalAccount,
  },
}

export function* LOGIN() {
  while (true) {
    const action = yield take('user/LOGIN')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(mapAuthProviders.rect.login, payload)
    let { response } = result
    const { error } = result
    if (response) {
      // console.log(response)
      response = response.data
      store.set('token', response.token)
      store.set('authtype', response.auth)
      store.set('detail_profile', response)
      // window.location.reload()
      let actionTo = ''
      switch (response.auth) {
        case 'user':
          actionTo = 'user'
          break
        case 'distributor':
          actionTo = 'user-distributor'
          break
        default:
          break
      }
      yield put({
        type: `${actionTo}/SET_STATE`,
        payload: {
          authorized: true,
          ...(actionTo === 'user' ? setUserProfile(response) : setUserProfileDistributor(response)),
          loading: false,
          loadingLogout: false,
          loadingInit: false,
          loadingSso: false,
          loadingProfile: false,
          error: null,
          errorInit: null,
        },
      })
      yield put({
        type: `${actionTo}/AUTHENTICATED`,
      })
      notification.success({
        message: 'Login Success',
        description: response.message,
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          error,
        },
      })
    }
  }
}

export function* LOGIN_SSO() {
  while (true) {
    const action = yield take('user/LOGIN_SSO')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingSso: true,
      },
    })
    const result = yield call(mapAuthProviders.rect.loginSso, payload)
    let { response } = result
    const { error } = result
    if (response) {
      // console.log(response)
      response = response.data
      store.set('token', response.token)
      store.set('authtype', response.auth)
      store.set('detail_profile', response)
      // window.location.reload()
      let actionTo = ''
      let actionToOther = ''
      switch (response.auth) {
        case 'user':
          actionTo = 'user'
          actionToOther = 'user-distributor'
          break
        case 'distributor':
          actionTo = 'user-distributor'
          actionToOther = 'user'
          break
        default:
          break
      }
      yield put({
        type: `${actionTo}/SET_STATE`,
        payload: {
          authorized: true,
          ...(actionTo === 'user' ? setUserProfile(response) : setUserProfileDistributor(response)),
          loading: false,
          loadingSso: false,
          loadingLogout: false,
          loadingInit: false,
          loadingProfile: false,
          error: null,
          errorInit: null,
        },
      })
      yield put({
        type: `${actionTo}/AUTHENTICATED`,
      })
      yield put({
        type: `${actionToOther}/SET_STATE`,
        payload: {
          loadingSso: false,
        },
      })
      notification.success({
        message: 'Login Success',
        description: response.message,
      })
    } else {
      const errorMessage = getErrorMessage(error, false)
      sessionStorage.setItem('error_sso', errorMessage)
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loadingSso: false,
        },
      })
      // notification.error({
      //   message: 'Login Failed',
      //   description: errorMessage,
      // })
    }
  }
}

export function* UPDATE_PASSWORD() {
  while (true) {
    const action = yield take('user/UPDATE_PASSWORD')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
        clearFormPassword: false,
      },
    })
    const { result } = yield race({
      result: call(
        mapAuthProviders.rect.updatePassword,
        payload.old_password,
        payload.new_password,
        payload.confirm_password,
      ),
      cancel: take('user/CANCEL_PROFILE'),
    })
    if (result) {
      const { response, error } = result
      const newPayload = {
        loading: false,
        error,
        clearFormPassword: !!response,
      }
      if (response) {
        notification.success({
          message: 'SUCCESS',
          description: response.data.message,
        })
        newPayload.has_change_default_password = 1
      }
      yield put({
        type: 'user/SET_STATE',
        payload: newPayload,
      })
    }
  }
}

export function* UPDATE_PROFILE() {
  while (true) {
    const action = yield take('user/UPDATE_PROFILE')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingProfile: true,
      },
    })
    const { result } = yield race({
      result: call(mapAuthProviders.rect.updateProfile, payload),
      cancel: take('user/CANCEL_PROFILE'),
    })
    if (result) {
      const { response, error } = result
      if (response) {
        notification.success({
          message: 'Update Profile Success',
          description: response.data.message,
        })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...setUserProfile(response ? response.data.data || null : null),
          loadingProfile: false,
          errorProfile: error,
        },
      })
    }
  }
}

function setUserProfile(response) {
  if (!response) {
    return {}
  }
  const mappedPermissions = {}
  Object.keys(response.permissions).forEach(key => {
    mappedPermissions[key.toLowerCase()] = response.permissions[key]
  })
  return {
    id: response.id,
    nik: response.nik,
    user_name: response.user_name,
    user_phone: response.user_phone,
    username: response.username,
    user_image: response.user_image,
    email: response.user_email,
    role: response.role,
    permissions: mappedPermissions,
    use_password: response.use_password || false,
    has_change_default_password: response.has_change_default_password,
  }
}

function setUserProfileDistributor(response) {
  if (!response) {
    return {}
  }
  return {
    id: response.id,
    nik: null,
    user_name: response.dist_child_admin_name || response.username,
    user_phone: response.dist_child_admin_phone,
    username: response.username,
    user_image: null,
    email: response.dist_child_email,
    role: null,
    permissions: [],
    has_change_default_password: response.has_change_default_password,
  }
}

export function* LOAD_PROFILE() {
  // if (window.location.href.indexOf('/admin/') > -1) {
  while (true) {
    yield take('user/LOAD_PROFILE')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingProfile: true,
      },
    })
    const { result } = yield race({
      result: call(mapAuthProviders.rect.currentAccount),
      cancel: take('user/CANCEL_PROFILE'),
    })
    if (result) {
      let { response } = result
      const { error } = result
      if (response) {
        response = response.data
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authorized: true,
            ...setUserProfile(response),
            loadingProfile: false,
            isProfileLoaded: true,
            errorProfile: null,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loadingProfile: false,
            errorProfile: error,
          },
        })
      }
    }
  }
  // }
}

export function* LOAD_CURRENT_ACCOUNT() {
  // if (window.location.href.indexOf('/admin/') > -1) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingInit: true,
    },
  })
  yield put({
    type: 'user-distributor/SET_STATE',
    payload: {
      loadingInit: true,
    },
  })
  const result = yield call(mapAuthProviders.rect.currentGlobalAccount)

  let { response } = result
  const { error } = result

  if (response) {
    response = response.data

    store.set('authtype', response.auth)
    store.set('detail_profile', response)
    let actionTo = ''
    let actionToOther = ''
    switch (response.auth) {
      case 'user':
        actionTo = 'user'
        actionToOther = 'user-distributor'
        break
      case 'distributor':
        actionTo = 'user-distributor'
        actionToOther = 'user'
        break
      default:
        break
    }
    yield put({
      type: `${actionTo}/SET_STATE`,
      payload: {
        authorized: true,
        ...(actionTo === 'user' ? setUserProfile(response) : setUserProfileDistributor(response)),
        loadingInit: false,
        errorInit: null,
      },
    })
    yield put({
      type: `${actionToOther}/SET_STATE`,
      payload: {
        loadingInit: false,
        errorInit: null,
      },
    })
  } else {
    const { response: errorResponse } = error
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingInit: false,
        errorInit: errorResponse && errorResponse.status === 401 ? null : error,
      },
    })
    yield put({
      type: 'user-distributor/SET_STATE',
      payload: {
        loadingInit: false,
        errorInit: errorResponse && errorResponse.status === 401 ? null : error,
      },
    })
  }
  // }
}

export function* LOAD_CURRENT_ACCOUNT_SSO() {
  // if (window.location.href.indexOf('/admin/') > -1) {
  while (true) {
    yield take('user/LOAD_CURRENT_ACCOUNT_SSO')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingSso: true,
      },
    })
    const result = yield call(mapAuthProviders.rect.currentGlobalAccount)

    let { response } = result
    const { error } = result

    if (response) {
      response = response.data

      store.set('authtype', response.auth)
      store.set('detail_profile', response)
      let actionTo = ''
      let actionToOther = ''
      switch (response.auth) {
        case 'user':
          actionTo = 'user'
          actionToOther = 'user-distributor'
          break
        case 'distributor':
          actionTo = 'user-distributor'
          actionToOther = 'user'
          break
        default:
          break
      }
      yield put({
        type: `${actionTo}/SET_STATE`,
        payload: {
          authorized: true,
          ...(actionTo === 'user' ? setUserProfile(response) : setUserProfileDistributor(response)),
          loading: false,
          loadingSso: false,
          loadingLogout: false,
          loadingInit: false,
          loadingProfile: false,
          error: null,
          errorInit: null,
        },
      })
      yield put({
        type: `${actionTo}/AUTHENTICATED`,
      })
      yield put({
        type: `${actionToOther}/SET_STATE`,
        payload: {
          loadingSso: false,
        },
      })
      notification.success({
        message: 'Login Success',
        description: response.message,
      })
    } else {
      const { response: errorResponse } = error
      if (!(errorResponse && errorResponse.status === 401)) {
        const errorMessage = getErrorMessage(error, false)
        sessionStorage.setItem('error_sso', errorMessage)
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loadingSso: false,
        },
      })
    }
  }
  // }
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingLogout: true,
    },
  })
  const { response, error } = yield call(mapAuthProviders.rect.logout)
  if (response) {
    yield put({
      type: 'user/UNAUTHENTICATED',
    })
    // notification.success({
    //   message: 'Logout Success',
    //   description: 'Successfully log out from your account!',
    // })
    yield put(push('/auth-admin/login'))
  } else {
    const { response: errorResponse } = error
    if (!(errorResponse && errorResponse.status === 401)) {
      const defaultMessage = getErrorMessage(error, false)
      notification.error({
        message: 'Logout Failed',
        description: defaultMessage,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loadingLogout: false,
        },
      })
    }
  }
}

function* UPDATE_PROFILE_IMAGE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PROFILE_IMAGE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingImage: true,
      },
    })
    const result = yield race({
      data: call(mapAuthProviders.rect.updateProfileImage, payload),
      cancel: take(actions.CANCEL_PROFILE),
    })
    if (result.data) {
      const { response, error } = result.data
      const newPayload = {
        loadingImage: false,
      }
      if (response) {
        newPayload.messageUploadFile = response.data
        newPayload.user_image = response.data.data.user_image
      }
      newPayload.messageUploadFileError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export function* FORGOT_PASSWORD() {
  while (true) {
    const action = yield take('user/FORGOT_PASSWORD')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(rect.forgotPassword, payload.email)
    let { response } = result
    const { error } = result
    if (response) {
      response = response.data
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: response.message,
      })
    } else {
      const defaultMessage = getErrorMessage(error, false)
      notification.error({
        message: 'FAILURE',
        description: defaultMessage,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          error,
        },
      })
    }
  }
}

export function* RESET_PASSWORD() {
  while (true) {
    const action = yield take('user/RESET_PASSWORD')
    const { payload } = action
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(rect.resetPassword, payload)
    let { response } = result
    const { error } = result
    if (response) {
      response = response.data
      yield put({
        type: 'user/SET_STATE',
        payload: {
          error: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: response.message,
      })
      yield put(push('/auth-admin/login'))
    } else {
      // const defaultMessage = getErrorMessage(error, false)
      // notification.error({
      //   message: 'FAILURE',
      //   description: defaultMessage,
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          error,
        },
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    LOAD_PROFILE(),
    // takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    LOAD_CURRENT_ACCOUNT(),
    LOAD_CURRENT_ACCOUNT_SSO(),
    LOGIN(),
    LOGIN_SSO(),
    UPDATE_PASSWORD(),
    RESET_PASSWORD(),
    FORGOT_PASSWORD(),
    UPDATE_PROFILE(),
    UPDATE_PROFILE_IMAGE(),
  ])
}
