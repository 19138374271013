const actions = {
  SET_STATE: 'brand-group/SET_STATE',
  LOAD_BRAND_GROUP: 'brand-group/LOAD_BRAND_GROUP',
  LOAD_FORM_CREATE: 'brand-group/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'brand-group/LOAD_FORM_EDIT',
  STORE_BRAND_GROUP: 'brand-group/STORE_BRAND_GROUP',
  UPDATE_BRAND_GROUP: 'brand-group/UPDATE_BRAND_GROUP',
  DELETE_BRAND_GROUP: 'brand-group/DELETE_BRAND_GROUP',
  CANCEL_BRAND_GROUP: 'brand-group/CANCEL_BRAND_GROUP',
}

export default actions
