const actions = {
  SET_STATE: 'outlet-dist-access/SET_STATE',
  LOAD_OUTLET_DIST_ACCESS: 'outlet-dist-access/LOAD_OUTLET_DIST_ACCESS',
  CANCEL_OUTLET_DIST_ACCESS: 'outlet-dist-access/CANCEL_OUTLET_DIST_ACCESS',
  PERMISSION_OUTLET_DIST_ACCESS: 'outlet-dist-access/PERMISSION_OUTLET_DIST_ACCESS',
  FILTER: 'outlet-dist-access/FILTER',
  REMINDER: 'outlet-dist-access/REMINDER',
  REMINDER_BLOCK: 'outlet-dist-access/REMINDER_BLOCK',
}

export default actions
