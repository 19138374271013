import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-chart'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadFilterDistributor : mService.loadFilter,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilterProductTopPfMonthly = response.data.data
      newPayload.dataFilterMmaPfMonthly = response.data.data
      newPayload.dataFilterProductTransactionChannel = response.data.data
      newPayload.dataFilterRoPfMonthlySales = response.data.data
      newPayload.dataFilterRateBs = response.data.data
      newPayload.dataFilter = response.data
      newPayload.current_year = response.data.curent_year
      newPayload.current_month = response.data.current_month
      newPayload.current_period = response.data.current_period
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_SALES_DIAGRAM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartSalesDiagram: true,
      errorChartSalesDiagram: false,
    },
  })
  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChartSalesDiagramDistributor
        : mService.loadChartSalesDiagram,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartSalesDiagram: false,
      errorChartSalesDiagram: null,
    }

    if (response) {
      newPayload.dataChartSalesDiagram = response.data
    } else {
      newPayload.errorChartSalesDiagram = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* CHANGE_CURRENT_PERIOD({ payload }) {
  const { period } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      current_period: period,
    },
  })
}

function* LOAD_CHART_REGISTER_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChartRegisterOutletDistributor
        : mService.loadChartRegisterOutlet,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartRegisterOutlet: null,
    }

    if (response) {
      newPayload.dataChartRegisterOutlet = response.data
    } else {
      newPayload.errorChartRegisterOutlet = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACTIVE_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChartActiveOutletDistributor
        : mService.loadChartActiveOutlet,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartActiveOutlet: null,
    }

    if (response) {
      newPayload.dataChartActiveOutlet = response.data
    } else {
      newPayload.errorChartActiveOutlet = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_EFFECTIVE_CALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChartEffectiveCallDistributor
        : mService.loadChartEffectiveCall,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartEffectiveCall: null,
    }

    if (response) {
      newPayload.dataChartEffectiveCall = response.data
    } else {
      newPayload.errorChartEffectiveCall = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_LINE_OF_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChartLineOfProductDistributor
        : mService.loadChartLineOfProduct,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartLineOfProduct: null,
    }

    if (response) {
      newPayload.dataChartLineOfProduct = response.data
    } else {
      newPayload.errorChartLineOfProduct = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_AT_RISK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadChartAtRiskDistributor : mService.loadChartAtRisk,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartAtRisk: null,
    }

    if (response) {
      newPayload.dataChartAtRisk = response.data
    } else {
      newPayload.errorChartAtRisk = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DATA_SALES_MTD_YTD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingSalesMtdYtd: true,
      errorSalesMtdYtd: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadSalesMtdYtdDistributor : mService.loadSalesMtdYtd,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingSalesMtdYtd: false,
    }
    if (response) {
      newPayload.dataSalesMtdYtd = response.data
    } else {
      newPayload.errorSalesMtdYtd = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_BRAND({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartAchievementBrand: true,
      errorChartAchievementBrand: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementBrandDistributor
        : mService.loadAchievementBrand,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChartAchievementBrand: false,
    }
    if (response) {
      newPayload.dataChartAchievementBrand = response.data
    } else {
      newPayload.errorChartAchievementBrand = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartAchievementChannelSub: true,
      errorChartAchievementChannelSub: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementChannelSubDistributor
        : mService.loadAchievementChannelSub,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChartAchievementChannelSub: false,
    }
    if (response) {
      newPayload.dataChartAchievementChannelSub = response.data
    } else {
      newPayload.errorChartAchievementChannelSub = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementTopParetoMt: true,
      errorAchievementTopParetoMt: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementTopParetoMtDistributor
        : mService.loadAchievementTopParetoMt,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementTopParetoMt: false,
    }
    if (response) {
      newPayload.dataAchievementTopParetoMt = response.data
    } else {
      newPayload.errorAchievementTopParetoMt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementTopParetoGt: true,
      errorAchievementTopParetoGt: false,
    },
  })
  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementTopParetoGtDistributor
        : mService.loadAchievementTopParetoGt,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementTopParetoGt: false,
    }
    if (response) {
      newPayload.dataAchievementTopParetoGt = response.data
    } else {
      newPayload.errorAchievementTopParetoGt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_RO_NPD_TYPE_CHANNEL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRoNpdTypeChannel: true,
      errorRoNpdTypeChannel: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadRoNpdTypeChannelDistributor
        : mService.loadRoNpdTypeChannel,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRoNpdTypeChannel: false,
    }
    if (response) {
      newPayload.dataRoNpdTypeChannel = response.data
    } else {
      newPayload.errorRoNpdTypeChannel = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_RO_PF_MONTHLY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRopPfMonthly: true,
      errorRoPfMonthly: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadRoPfMonthlyDistributor : mService.loadRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRopPfMonthly: false,
    }
    if (response) {
      newPayload.dataRoPfMonthly = response.data
    } else {
      newPayload.errorRoPfMonthly = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_FILTER_RO_PF_MONTHLY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRopPfMonthly: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadFilterRoPfMonthlyDistributor
        : mService.loadFilterRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRopPfMonthly: false,
    }
    if (response) {
      newPayload.dataFilterProductTopPfMonthly = response.data
    } else {
      newPayload.errorFilterProductTopPfMonthly = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_SIX_MMA_PF_MONTHLY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMmaPfMonthly: true,
      errorMmaPfMonthly: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadSixMmaPfMonthlyDistributor
        : mService.loadSixMmaPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMmaPfMonthly: false,
    }
    if (response) {
      newPayload.dataMmaPfMonthly = response.data
    } else {
      newPayload.errorMmaPfMonthly = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_FILTER_SIX_MMA_PF_MONTHLY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMmaPfMonthly: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadFilterRoPfMonthlyDistributor
        : mService.loadFilterRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingMmaPfMonthly: false,
    }
    if (response) {
      newPayload.dataFilterMmaPfMonthly = response.data
    } else {
      newPayload.errorFilterMmaPfMonthly = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_CHANNEL_NO_TRANSACTION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChannelNoTransaction: true,
      errorChannelNoTransaction: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadChannelNoTransactionDistributor
        : mService.loadChannelNoTransaction,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChannelNoTransaction: false,
    }
    if (response) {
      newPayload.dataChannelNoTransaction = response.data
    } else {
      newPayload.errorChannelNoTransaction = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DISTRIBUTOR_NEW_TERMINATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDistributorNewTerminate: true,
    },
  })
  const result = yield race({
    data: call(mService.loadDistributorNewTerminate, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingDistributorNewTerminate: false,
    }
    if (response) {
      newPayload.dataDistributorNewTerminate = response.data
    } else {
      newPayload.errorDistributorNewTerminate = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_PRODUCT_TRANSACTION_CHANNEL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProductTransactionChannel: true,
      errorProductTransactionChannel: false,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadProductTransactionChannelDistributor
        : mService.loadProductTransactionChannel,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProductTransactionChannel: false,
    }
    if (response) {
      newPayload.dataProductTransactionChannel = response.data
    } else {
      newPayload.errorProductTransactionChannel = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_FILTER_PRODUCT_TRANSACTION_CHANNEL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingProductTransactionChannel: true,
    },
  })

  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadFilterRoPfMonthlyDistributor
        : mService.loadFilterRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingProductTransactionChannel: false,
    }
    if (response) {
      newPayload.dataFilterProductTransactionChannel = response.data
    } else {
      newPayload.errorFilterProductTransactionChannel = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

// SALES

function* LOAD_CHART_ACHIEVEMENT_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      errorAchievementSales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementSalesDistributor
        : mService.loadAchievementSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
    }
    if (response) {
      newPayload.dataAchievementSales = response.data
    } else {
      newPayload.errorAchievementSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_BRAND_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementBrandSales: true,
      errorAchievementBrandSales: null,
      dataAchievementBrandSalesFromFilter: null, // mengembalikan data filter ke posisi semula
      line_chart_sales_id: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementBrandSalesDistributor
        : mService.loadAchievementBrandSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementBrandSales: false,
    }
    if (response) {
      newPayload.dataAchievementBrandSales = response.data
    } else {
      newPayload.errorAchievementBrandSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_BRAND_SALES_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementBrandSales: true,
      errorAchievementBrandSales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementBrandSalesSpecificDistributor
        : mService.loadAchievementBrandSalesSpecific,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementBrandSales: false,
    }
    if (response) {
      newPayload.dataAchievementBrandSalesFromFilter = response.data
      newPayload.line_chart_sales_id = payload.kode_sales
    } else {
      newPayload.errorAchievementBrandSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementChannelSubSales: true,
      errorAchievementChannelSubSales: null,
      dataAchievementChannelSubSalesFromFilter: null,
      line_chart_sales_id_channel: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementChannelSubSalesDistributor
        : mService.loadAchievementChannelSubSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementChannelSubSales: false,
    }
    if (response) {
      newPayload.dataAchievementChannelSubSales = response.data
    } else {
      newPayload.errorAchievementChannelSubSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementChannelSubSales: true,
      errorAchievementChannelSubSales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadAchievementChannelSubSalesSpecificDistributor
        : mService.loadAchievementChannelSubSalesSpecific,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementChannelSubSales: false,
    }
    if (response) {
      newPayload.dataAchievementChannelSubSalesFromFilter = response.data
      newPayload.line_chart_sales_id_channel = payload.kode_sales
    } else {
      newPayload.errorAchievementChannelSubSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_RO_NPD_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRoNpdSales: true,
      errorRoNpdSales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadRoNpdSalesDistributor : mService.loadRoNpdSales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRoNpdSales: false,
    }
    if (response) {
      newPayload.dataRoNpdSales = response.data
    } else {
      newPayload.errorRoNpdSales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_RO_PF_MONTHLY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRoPfMonthlySales: true,
      errorRoPfMonthlySales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadRoPfMonthlySalesDistributor
        : mService.loadRoPfMonthlySales,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRoPfMonthlySales: false,
    }
    if (response) {
      newPayload.dataRoPfMonthlySales = response.data
    } else {
      newPayload.errorRoPfMonthlySales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_FILTER_PF_MONTHLY_SALES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRoPfMonthlySales: true,
      errorRoPfMonthlySales: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadFilterRoPfMonthlyDistributor
        : mService.loadFilterRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRoPfMonthlySales: false,
    }
    if (response) {
      newPayload.dataFilterRoPfMonthlySales = response.data
    } else {
      newPayload.errorRoPfMonthlySales = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_RATE_BS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRateBs: true,
      errorRateBs: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized ? mService.loadRateBsDistributor : mService.loadRateBs,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRateBs: false,
    }
    if (response) {
      newPayload.dataRateBs = response.data
    } else {
      newPayload.errorRateBs = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_FILTER_RATE_BS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRateBs: true,
      errorRateBs: null,
    },
  })
  const distributor = yield select(state => state.userDistributor)
  const result = yield race({
    data: call(
      distributor.authorized
        ? mService.loadFilterRoPfMonthlyDistributor
        : mService.loadFilterRoPfMonthly,
      payload,
    ),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingRateBs: false,
    }
    if (response) {
      newPayload.dataFilterRateBs = response.data
    } else {
      newPayload.errorRateBs = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.CHANGE_CURRENT_PERIOD, CHANGE_CURRENT_PERIOD),
    takeLatest(actions.LOAD_CHART_SALES_DIAGRAM, LOAD_CHART_SALES_DIAGRAM),

    takeLatest(actions.LOAD_CHART_REGISTER_OUTLET, LOAD_CHART_REGISTER_OUTLET),
    takeLatest(actions.LOAD_CHART_ACTIVE_OUTLET, LOAD_CHART_ACTIVE_OUTLET),
    takeLatest(actions.LOAD_CHART_EFFECTIVE_CALL, LOAD_CHART_EFFECTIVE_CALL),
    takeLatest(actions.LOAD_CHART_LINE_OF_PRODUCT, LOAD_CHART_LINE_OF_PRODUCT),
    takeLatest(actions.LOAD_CHART_AT_RISK, LOAD_CHART_AT_RISK),

    takeLatest(actions.LOAD_DATA_SALES_MTD_YTD, LOAD_DATA_SALES_MTD_YTD),
    //
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_BRAND, LOAD_CHART_ACHIEVEMENT_BRAND),
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB, LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB),
    //

    //
    takeLatest(actions.LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT, LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT),
    takeLatest(actions.LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT, LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT),

    // Load data npd type channel
    takeLatest(actions.LOAD_RO_NPD_TYPE_CHANNEL, LOAD_RO_NPD_TYPE_CHANNEL),
    takeLatest(actions.LOAD_RO_PF_MONTHLY, LOAD_RO_PF_MONTHLY),

    takeLatest(actions.LOAD_FILTER_PF_MONTHLY, LOAD_FILTER_RO_PF_MONTHLY),

    // load data mma pf monthly
    takeLatest(actions.LOAD_SIX_MMA_PF_MONTHLY, LOAD_SIX_MMA_PF_MONTHLY),
    takeLatest(actions.LOAD_FILTER_SIX_MMA_PF_MONTHLY, LOAD_FILTER_SIX_MMA_PF_MONTHLY),

    // load channel & transaction
    takeLatest(actions.LOAD_CHART_CHANNEL_NO_TRANSACTION, LOAD_CHART_CHANNEL_NO_TRANSACTION),
    takeLatest(actions.LOAD_PRODUCT_TRANSACTION_CHANNEL, LOAD_PRODUCT_TRANSACTION_CHANNEL),
    takeLatest(
      actions.LOAD_FILTER_PRODUCT_TRANSACTION_CHANNEL,
      LOAD_FILTER_PRODUCT_TRANSACTION_CHANNEL,
    ),

    // distributor new terminated
    takeLatest(actions.LOAD_DISTRIBUTOR_NEW_TERMINATE, LOAD_DISTRIBUTOR_NEW_TERMINATE),

    // SALES
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_SALES, LOAD_CHART_ACHIEVEMENT_SALES),
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_BRAND_SALES, LOAD_CHART_ACHIEVEMENT_BRAND_SALES),
    takeLatest(
      actions.LOAD_CHART_ACHIEVEMENT_BRAND_SALES_FILTER,
      LOAD_CHART_ACHIEVEMENT_BRAND_SALES_FILTER,
    ),
    takeLatest(
      actions.LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES,
      LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES,
    ),
    takeLatest(
      actions.LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES_FILTER,
      LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB_SALES_FILTER,
    ),
    takeLatest(actions.LOAD_CHART_RO_NPD_SALES, LOAD_CHART_RO_NPD_SALES),
    takeLatest(actions.LOAD_CHART_RO_PF_MONTHLY, LOAD_CHART_RO_PF_MONTHLY),
    takeLatest(actions.LOAD_FILTER_PF_MONTHLY_SALES, LOAD_FILTER_PF_MONTHLY_SALES),
    takeLatest(actions.LOAD_CHART_RATE_BS, LOAD_CHART_RATE_BS),
    takeLatest(actions.LOAD_FILTER_RATE_BS, LOAD_FILTER_RATE_BS),
  ])
}
