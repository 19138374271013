import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as reportService from 'services/webservice/user-access/report-based-estimate'
import { saveAs } from 'file-saver'
import actions from './actions'

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const distributor = yield select(state => state.userDistributor)

  const result = yield race({
    data: call(distributor.authorized ? reportService.filterDist : reportService.filter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      let level = null
      if (response.data) {
        ;({ level } = response.data)
      }
      newPayload.dataFilters = { data: response.data, level }
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    const distributor = yield select(state => state.userDistributor)

    const result = yield race({
      data: call(
        distributor.authorized ? reportService.downloadDist : reportService.download,
        payload,
      ),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loading: false,
        error: null,
      }
      const { response, error } = result.data
      if (response) {
        const filename = response.headers['content-disposition'].split('filename=')[1]
        const file = new File([response.data], `${filename.replaceAll('"', '')}`)
        saveAs(file)
        newPayload.messageSuccess = `File ${filename.replaceAll(
          '"',
          '',
        )} is successfully downloaded!`
      }
      newPayload.messageError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* REPROCESS_FILE() {
  while (true) {
    const { payload } = yield take(actions.REPROCESS_FILE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        messageSuccess: null,
      },
    })
    const result = yield race({
      data: call(reportService.reprocessFile, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loading: false,
        error: null,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageSuccess = response.data.message
      }
      newPayload.messageError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* LAST_UPDATED({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(reportService.lastUpdatedFile, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.messageSuccess = response.data
    } else {
      newPayload.messageError = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* SEARCH_LOG({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingLog: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.reportBasedEstimate)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(reportService.searchLog, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingLog: false,
      errorLog: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataSearch = response.data
    } else {
      newPayload.errorLog = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD_UPLOAD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDownloadUpload: true,
      messageSuccess: null,
    },
  })
  const result = yield race({
    data: call(reportService.downloadUploadFile, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const newPayload = {
      loadingDownloadUpload: false,
      error: null,
    }
    const { response, error } = result.data
    if (response) {
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const file = new File([response.data], `${filename.replaceAll('"', '')}`)
      saveAs(file)
      newPayload.messageSuccess = `File ${filename.replaceAll('"', '')} is successfully downloaded!`
    }
    newPayload.messageError = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FILTER, FILTER),
    takeLatest(actions.LAST_UPDATED, LAST_UPDATED),
    takeLatest(actions.SEARCH_LOG, SEARCH_LOG),
    takeLatest(actions.DOWNLOAD_UPLOAD, DOWNLOAD_UPLOAD),
    REPROCESS_FILE(),
    DOWNLOAD(),
  ])
}
