import apiClient from 'services/axios'

// FILTER
export async function loadFilter(parameter) {
  return apiClient
    .get('user/dashboard-enseval/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartSalesDiagram(parameter) {
  return apiClient
    .get('user/dashboard-enseval/achievement', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartRegisterOutlet(parameter) {
  return apiClient
    .get('user/dashboard-enseval/register-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartActiveOutlet(parameter) {
  return apiClient
    .get('user/dashboard-enseval/active-outlet', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartEffectiveCall(parameter) {
  return apiClient
    .get('user/dashboard-enseval/effective-call', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartLineOfProduct(parameter) {
  return apiClient
    .get('user/dashboard-enseval/line-of-product', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChartAtRisk(parameter) {
  return apiClient
    .get('user/dashboard-enseval/at-risk', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementBrand(parameter) {
  return apiClient
    .get('user/dashboard-enseval/achievement-brand', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementChannelSub(parameter) {
  return apiClient
    .get('user/dashboard-enseval/achievement-channel-sub', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoMt(parameter) {
  return apiClient
    .get('user/dashboard-enseval/achievement-top-paretto-mt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAchievementTopParetoGt(parameter) {
  return apiClient
    .get('user/dashboard-enseval/achievement-top-paretto-gt', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadChannelNoTransaction(parameter) {
  return apiClient
    .get('user/dashboard-enseval/no-trans-type-channel', { params: parameter })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
