import apiClient from 'services/axios'

export async function getDistributorApproval(parameter) {
  return apiClient
    .get('/user/distributor-approval/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function bulkDistributorApproval(values) {
  return apiClient
    .post(`/user/distributor-approval/bulk-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
