const actions = {
  SET_STATE: 'category-sub/SET_STATE',
  LOAD_CATEGORY_SUB: 'category-sub/LOAD_CATEGORY_SUB',
  CANCEL_CATEGORY_SUB: 'category-sub/CANCEL_CATEGORY_SUB',
  LOAD_FORM_CREATE: 'category-sub/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'category-sub/LOAD_FORM_EDIT',
  STORE_CATEGORY_SUB: 'category-sub/STORE_CATEGORY_SUB',
  UPDATE_CATEGORY_SUB: 'category-sub/UPDATE_CATEGORY_SUB',
  DELETE_CATEGORY_SUB: 'category-sub/DELETE_CATEGORY_SUB',
}

export default actions
