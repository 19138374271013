import apiClient from 'services/axios'

export async function getRole(parameter) {
  return apiClient
    .get('/user/role/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createRole(parameter = {}) {
  return apiClient
    .get('/user/role/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeRole(values) {
  return apiClient
    .post('/user/role/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editRole(id, parameter = {}) {
  return apiClient
    .get(`/user/role/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateRole(id, values) {
  return apiClient
    .post(`/user/role/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getRolePermission(id) {
  return apiClient
    .get(`/user/role/${id}/permission`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function setRolePermission(id, values) {
  return apiClient
    .post(`/user/role/${id}/permission`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteRole(id) {
  return apiClient
    .delete(`/user/role/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/role/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
