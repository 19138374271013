import apiClient from 'services/axios'

export async function getPkModule(parameter) {
  return apiClient
    .get('/user/sfa-pk-module/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createPkModule(parameter = {}) {
  return apiClient
    .get('/user/sfa-pk-module/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storePkModule(values) {
  return apiClient
    .post('/user/sfa-pk-module/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editPkModule(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-pk-module/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePkModule(id, values) {
  return apiClient
    .post(`/user/sfa-pk-module/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deletePkModule(id) {
  return apiClient
    .delete(`/user/sfa-pk-module/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
