import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/dashboard-enseval-chart'
import actions from './actions'

function* LOAD_FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield race({
    data: call(mService.loadFilter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilter = response.data
      newPayload.current_year = response.data.curent_year
      newPayload.current_month = response.data.current_month
      newPayload.current_period = response.data.current_period
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_SALES_DIAGRAM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartSalesDiagram: true,
      errorChartSalesDiagram: false,
    },
  })

  const result = yield race({
    data: call(mService.loadChartSalesDiagram, payload),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartSalesDiagram: false,
      errorChartSalesDiagram: null,
    }

    if (response) {
      newPayload.dataChartSalesDiagram = response.data
    } else {
      newPayload.errorChartSalesDiagram = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* CHANGE_CURRENT_PERIOD({ payload }) {
  const { period } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      current_period: period,
    },
  })
}

function* LOAD_CHART_REGISTER_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const result = yield race({
    data: call(mService.loadChartRegisterOutlet, payload),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartRegisterOutlet: null,
    }

    if (response) {
      newPayload.dataChartRegisterOutlet = response.data
    } else {
      newPayload.errorChartRegisterOutlet = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACTIVE_OUTLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const result = yield race({
    data: call(mService.loadChartActiveOutlet, payload),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartActiveOutlet: null,
    }

    if (response) {
      newPayload.dataChartActiveOutlet = response.data
    } else {
      newPayload.errorChartActiveOutlet = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_EFFECTIVE_CALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const result = yield race({
    data: call(mService.loadChartEffectiveCall, payload),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartEffectiveCall: null,
    }

    if (response) {
      newPayload.dataChartEffectiveCall = response.data
    } else {
      newPayload.errorChartEffectiveCall = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_LINE_OF_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartRegisterOutletCs: true,
    },
  })

  const result = yield race({
    data: call(mService.loadChartLineOfProduct, payload),
    cancel: take(actions.CANCEL),
  })

  if (result.data) {
    const { response, error } = result.data

    const newPayload = {
      loadingChartRegisterOutletCs: false,
      errorChartLineOfProduct: null,
    }

    if (response) {
      newPayload.dataChartLineOfProduct = response.data
    } else {
      newPayload.errorChartLineOfProduct = error
    }

    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_BRAND({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartAchievementBrand: true,
      errorChartAchievementBrand: false,
    },
  })

  const result = yield race({
    data: call(mService.loadAchievementBrand, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChartAchievementBrand: false,
    }
    if (response) {
      newPayload.dataChartAchievementBrand = response.data
    } else {
      newPayload.errorChartAchievementBrand = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChartAchievementChannelSub: true,
      errorChartAchievementChannelSub: false,
    },
  })

  const result = yield race({
    data: call(mService.loadAchievementChannelSub, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChartAchievementChannelSub: false,
    }
    if (response) {
      newPayload.dataChartAchievementChannelSub = response.data
    } else {
      newPayload.errorChartAchievementChannelSub = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementTopParetoMt: true,
      errorAchievementTopParetoMt: false,
    },
  })

  const result = yield race({
    data: call(mService.loadAchievementTopParetoMt, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementTopParetoMt: false,
    }
    if (response) {
      newPayload.dataAchievementTopParetoMt = response.data
    } else {
      newPayload.errorAchievementTopParetoMt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingAchievementTopParetoGt: true,
      errorAchievementTopParetoGt: false,
    },
  })

  const result = yield race({
    data: call(mService.loadAchievementTopParetoGt, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingAchievementTopParetoGt: false,
    }
    if (response) {
      newPayload.dataAchievementTopParetoGt = response.data
    } else {
      newPayload.errorAchievementTopParetoGt = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* LOAD_CHART_CHANNEL_NO_TRANSACTION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingChannelNoTransaction: true,
      errorChannelNoTransaction: false,
    },
  })

  const result = yield race({
    data: call(mService.loadChannelNoTransaction, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loadingChannelNoTransaction: false,
    }
    if (response) {
      newPayload.dataChannelNoTransaction = response.data
    } else {
      newPayload.errorChannelNoTransaction = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_FILTER, LOAD_FILTER),
    takeLatest(actions.CHANGE_CURRENT_PERIOD, CHANGE_CURRENT_PERIOD),
    takeLatest(actions.LOAD_CHART_SALES_DIAGRAM, LOAD_CHART_SALES_DIAGRAM),

    takeLatest(actions.LOAD_CHART_REGISTER_OUTLET, LOAD_CHART_REGISTER_OUTLET),
    takeLatest(actions.LOAD_CHART_ACTIVE_OUTLET, LOAD_CHART_ACTIVE_OUTLET),
    takeLatest(actions.LOAD_CHART_EFFECTIVE_CALL, LOAD_CHART_EFFECTIVE_CALL),
    takeLatest(actions.LOAD_CHART_LINE_OF_PRODUCT, LOAD_CHART_LINE_OF_PRODUCT),
    //
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_BRAND, LOAD_CHART_ACHIEVEMENT_BRAND),
    takeLatest(actions.LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB, LOAD_CHART_ACHIEVEMENT_CHANNEL_SUB),
    //

    //
    takeLatest(actions.LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT, LOAD_DATA_ACHIEVEMENT_TOP_PARETO_MT),
    takeLatest(actions.LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT, LOAD_DATA_ACHIEVEMENT_TOP_PARETO_GT),

    // load channel & transaction
    takeLatest(actions.LOAD_CHART_CHANNEL_NO_TRANSACTION, LOAD_CHART_CHANNEL_NO_TRANSACTION),
  ])
}
