import apiClient from 'services/axios'

export async function getSchedule(parameter) {
  return apiClient
    .get('/user/schedule/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSchedule(parameter = {}) {
  return apiClient
    .get('/user/schedule/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeSchedule(values) {
  return apiClient
    .post('/user/schedule/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editSchedule(id, parameter = {}) {
  return apiClient
    .get(`/user/schedule/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSchedule(id, values) {
  return apiClient
    .post(`/user/schedule/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteSchedule(id) {
  return apiClient
    .delete(`/user/schedule/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/schedule/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
