const actions = {
  SET_STATE: 'ar/SET_STATE',
  SEARCH: 'ar/SEARCH',
  CANCEL: 'ar/CANCEL',
  CREATE: 'ar/CREATE',
  EDIT: 'ar/EDIT',
  STORE: 'ar/STORE',
  UPDATE: 'ar/UPDATE',
  DELETE: 'ar/DELETE',
  FILTER: 'ar/FILTER',
  UPLOAD_FILE: 'ar/UPLOAD_FILE',
}

export default actions
