import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/outlet-tl'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* GET_OUTLET_TL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.outletTlInactive)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(mService.getOutletTl, payload),
    cancel: take(actions.CANCEL_OUTLET_TL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataOutletTl = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataOutletTlCreate: null,
    dataOutletTlEdit: null,
    dataListPermission: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  switch (action.type) {
    case actions.LOAD_FORM_CREATE:
      result = yield race({
        data: call(mService.createOutletTl),
        cancel: take(actions.CANCEL_OUTLET_TL),
      })
      break
    case actions.LOAD_FORM_EDIT:
      result = yield race({
        data: call(mService.editOutletTl, payload.id),
        cancel: take(actions.CANCEL_OUTLET_TL),
      })
      break
    case actions.GET_PERMISSION:
      result = yield race({
        data: call(mService.getPermissions, payload.id),
        cancel: take(actions.CANCEL_OUTLET_TL),
      })
      break
    default:
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    switch (action.type) {
      case actions.LOAD_FORM_CREATE:
        newPayload.dataOutletTlCreate = response ? response.data : null
        break
      case actions.LOAD_FORM_EDIT:
        newPayload.dataOutletTlEdit = response ? response.data : null
        break
      case actions.GET_PERMISSION:
        newPayload.dataListPermission = response ? response.data : null
        break
      default:
    }

    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* OPTIONS_DELETE(action) {
  const { payload } = action
  let newPayload = {
    loadingOptionsDelete: true,
    dataDelete: null,
  }

  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })

  const result = yield race({
    data: call(mService.optionsDelete, payload.id),
    cancel: take(actions.CANCEL_OUTLET_TL),
  })

  if (result.data) {
    newPayload = {
      loadingOptionsDelete: false,
    }
    const { response, error } = result.data
    newPayload.dataDelete = response ? response.data : null

    newPayload.errorOptionsDelete = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE_OUTLET_TL() {
  while (true) {
    const { payload } = yield take(actions.STORE_OUTLET_TL)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(mService.storeOutletTl, payload),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
          // message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE_OUTLET_TL() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_OUTLET_TL)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.updateOutletTl, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE_OUTLET_TL() {
  while (true) {
    const { payload } = yield take(actions.DELETE_OUTLET_TL)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(mService.deleteOutletTl, payload.id, { dist_child_id: payload.dist_child_id }),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SET_PERMISSION() {
  while (true) {
    const { payload } = yield take(actions.SET_PERMISSION)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.setPermissions, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* FORCE_LOGOUT() {
  while (true) {
    const { payload } = yield take(actions.FORCE_LOGOUT)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForceLogout: true,
        messageForceLogoutSuccess: null,
        messageForceLogoutError: null,
      },
    })
    const result = yield race({
      data: call(mService.forceLogout, payload.id),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingForceLogout: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageForceLogoutSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageForceLogoutError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET_PASSWORD() {
  while (true) {
    const { payload } = yield take(actions.RESET_PASSWORD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingResetPassword: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.resetPassword, currentId, payload),
      cancel: take(actions.CANCEL_OUTLET_TL),
    })
    if (result.data) {
      const newPayload = {
        loadingResetPassword: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageResetPasswordSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageResetPasswordError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_OUTLET_TL, GET_OUTLET_TL),
    takeLatest(
      [actions.LOAD_FORM_CREATE, actions.LOAD_FORM_EDIT, actions.GET_PERMISSION],
      GET_FORM,
    ),
    takeLatest(actions.OPTIONS_DELETE, OPTIONS_DELETE),
    STORE_OUTLET_TL(),
    UPDATE_OUTLET_TL(),
    DELETE_OUTLET_TL(),
    FORCE_LOGOUT(),
    SET_PERMISSION(),
    RESET_PASSWORD(),
  ])
}
