import apiClient from 'services/axios'

export async function getTargetPrimarySales(parameter) {
  return apiClient
    .get('/user/target-primary-sales/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createTargetPrimarySales(parameter = {}) {
  return apiClient
    .get('/user/target-primary-sales/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeTargetPrimarySales(values) {
  return apiClient
    .post('/user/target-primary-sales/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editTargetPrimarySales(id, parameter = {}) {
  return apiClient
    .get(`/user/target-primary-sales/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateTargetPrimarySales(id, values) {
  return apiClient
    .post(`/user/target-primary-sales/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteTargetPrimarySales(id) {
  return apiClient
    .delete(`/user/target-primary-sales/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/target-primary-sales/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-primary-sales/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listSales(distChildId) {
  return apiClient
    .get(`/user/target-primary-sales/sales/${distChildId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/target-primary-sales/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
