const actions = {
  SET_STATE: 'outlet-active/SET_STATE',
  LOAD_OUTLET_ACTIVE: 'outlet-active/LOAD_OUTLET_ACTIVE',
  CANCEL_OUTLET_ACTIVE: 'outlet-active/CANCEL_OUTLET_ACTIVE',
  CANCEL_LIST_OUTLET: 'outlet-active/CANCEL_LIST_OUTLET',
  LOAD_FORM_CREATE: 'outlet-active/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'outlet-active/LOAD_FORM_EDIT',
  STORE_OUTLET_ACTIVE: 'outlet-active/STORE_OUTLET_ACTIVE',
  LIST_OUTLET: 'outlet-active/LIST_OUTLET',
  LOAD_FORM_DISTRIBUTOR_CHILD_OUTLET_ACTIVE:
    'outlet-active/LOAD_FORM_DISTRIBUTOR_CHILD_OUTLET_ACTIVE',
  UPDATE_OUTLET_ACTIVE: 'outlet-active/UPDATE_OUTLET_ACTIVE',
  DELETE_OUTLET_ACTIVE: 'outlet-active/DELETE_OUTLET_ACTIVE',
}

export default actions
