import { all, takeLatest, put, call, take, race } from 'redux-saga/effects'
import * as reportService from 'services/webservice/user-access/report-incentive-quarter-rsm'
import { saveAs } from 'file-saver'
import actions from './actions'

function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const result = yield race({
    data: call(reportService.filter, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.dataFilters = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* DOWNLOAD() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
    const result = yield race({
      data: call(reportService.download, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loading: false,
        error: null,
      }
      const { response, error } = result.data
      if (response) {
        const filename = response.headers['content-disposition'].split('filename=')[1]
        const file = new File([response.data], `${filename.replaceAll('"', '')}`)
        saveAs(file)
        newPayload.messageSuccess = `File ${filename.replaceAll(
          '"',
          '',
        )} is successfully downloaded!`
      }
      newPayload.messageError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FILTER, FILTER), DOWNLOAD()])
}
