import apiClient from 'services/axios'

export async function getStock(parameter = {}) {
  return apiClient
    .get('/user/stock/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterStock(parameter = {}) {
  return apiClient
    .get('/user/stock/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionsUploadStock(parameter = {}) {
  return apiClient
    .get('/user/stock/options-upload-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadStock(values) {
  return apiClient
    .post('/user/stock/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
