import apiClient from 'services/axios'

export async function options(parameter) {
  return apiClient
    .get('/user/convert-union/options-convert-union', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function process(values) {
  return apiClient
    .post('/user/convert-union/process-convert-union', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
