import apiClient from 'services/axios'

export async function getConsumerOutletProductBundle(parameter) {
  return apiClient
    .get('/user/sfa-consumer-outlet-product-bundle/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createConsumerOutletProductBundle(parameter = {}) {
  return apiClient
    .get('/user/sfa-consumer-outlet-product-bundle/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeConsumerOutletProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-product-bundle/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editConsumerOutletProductBundle(id, parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-product-bundle/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConsumerOutletProductBundle(id, values) {
  return apiClient
    .post(`/user/sfa-consumer-outlet-product-bundle/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteConsumerOutletProductBundle(id) {
  return apiClient
    .delete(`/user/sfa-consumer-outlet-product-bundle/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadConsumerOutletProductBundle(values) {
  return apiClient
    .post('/user/sfa-consumer-outlet-product-bundle/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-product-bundle/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(parameter = {}) {
  return apiClient
    .get(`/user/sfa-consumer-outlet-product-bundle/list-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
