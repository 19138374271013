import apiClient from 'services/axios'

export async function getOutletTemp(parameter) {
  return apiClient
    .get('/user/outlet-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createOutletTemp(parameter = {}) {
  return apiClient
    .get('/user/outlet-temp/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeOutletTemp(values) {
  return apiClient
    .post('/user/outlet-temp/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutletTemp(id, parameter = {}) {
  return apiClient
    .get(`/user/outlet-temp/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutletTemp(id, values) {
  return apiClient
    .post(`/user/outlet-temp/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteOutletTemp(id) {
  return apiClient
    .delete(`/user/outlet-temp/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadTemplateOutletTemp(parameter = {}) {
  return apiClient
    .get(`/user/outlet-temp/download-file`, { params: parameter, responseType: 'blob' })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadOutletTemp(values) {
  return apiClient
    .post('/user/outlet-temp/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetOutletTemp(values) {
  return apiClient
    .delete(`/user/outlet-temp/reset`, { data: values })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveOutletTemp(values) {
  return apiClient
    .post(`/user/outlet-temp/save`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
