const actions = {
  SET_STATE: 'distributor-file/SET_STATE',
  SEARCH: 'distributor-file/SEARCH',
  EDIT: 'distributor-file/EDIT',
  UPDATE: 'distributor-file/UPDATE',
  DELETE: 'distributor-file/DELETE',
  CANCEL: 'distributor-file/CANCEL',
}

export default actions
