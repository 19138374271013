const actions = {
  SET_STATE: 'report-no-trans-four-mma-outlet/SET_STATE',
  FILTER: 'report-no-trans-four-mma-outlet/FILTER',
  CANCEL: 'report-no-trans-four-mma-outlet/CANCEL',
  DOWNLOAD: 'report-no-trans-four-mma-outlet/DOWNLOAD',
  REPROCESS_FILE: 'report-no-trans-four-mma-outlet/REPROCESS_FILE',
  LAST_UPDATED: 'report-no-trans-four-mma-outlet/LAST_UPDATED',
}

export default actions
