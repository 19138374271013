import apiClient from 'services/axios'

export async function getYear(parameter) {
  return apiClient
    .get('/user/year/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createYear(parameter = {}) {
  return apiClient
    .get('/user/year/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeYear(values) {
  return apiClient
    .post('/user/year/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editYear(id, parameter = {}) {
  return apiClient
    .get(`/user/year/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateYear(id, values) {
  return apiClient
    .post(`/user/year/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteYear(id) {
  return apiClient
    .delete(`/user/year/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
