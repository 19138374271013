const actions = {
  SET_STATE: 'product-dist-access/SET_STATE',
  LOAD_PRODUCT_DIST_ACCESS: 'product-dist-access/LOAD_PRODUCT_DIST_ACCESS',
  CANCEL_PRODUCT_DIST_ACCESS: 'product-dist-access/CANCEL_PRODUCT_DIST_ACCESS',
  LOAD_FORM_CREATE: 'product-dist-access/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'product-dist-access/LOAD_FORM_EDIT',
  STORE_PRODUCT_DIST_ACCESS: 'product-dist-access/STORE_PRODUCT_DIST_ACCESS',
  UPDATE_PRODUCT_DIST_ACCESS: 'product-dist-access/UPDATE_PRODUCT_DIST_ACCESS',
  DELETE_PRODUCT_DIST_ACCESS: 'product-dist-access/DELETE_PRODUCT_DIST_ACCESS',
  PERMISSION_PRODUCT_DIST_ACCESS: 'product-dist-access/PERMISSION_PRODUCT_DIST_ACCESS',
}

export default actions
