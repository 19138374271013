import apiClient from 'services/axios'

export async function getPeriod(parameter) {
  return apiClient
    .get('/user/period/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createPeriod(parameter = {}) {
  return apiClient
    .get('/user/period/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storePeriod(values) {
  return apiClient
    .post('/user/period/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editPeriod(id, parameter = {}) {
  return apiClient
    .get(`/user/period/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePeriod(id, values) {
  return apiClient
    .post(`/user/period/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deletePeriod(id) {
  return apiClient
    .delete(`/user/period/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
