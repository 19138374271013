const actions = {
  SET_STATE: 'variant-inactive/SET_STATE',
  LOAD_VARIANT: 'variant-inactive/LOAD_VARIANT',
  CANCEL_VARIANT: 'variant-inactive/CANCEL_VARIANT',
  LOAD_FORM_CREATE: 'variant-inactive/LOAD_FORM_CREATE',
  LOAD_FORM_EDIT: 'variant-inactive/LOAD_FORM_EDIT',
  STORE_VARIANT: 'variant-inactive/STORE_VARIANT',
  UPDATE_VARIANT: 'variant-inactive/UPDATE_VARIANT',
  DELETE_VARIANT: 'variant-inactive/DELETE_VARIANT',
}

export default actions
